import merge                          from 'lodash/merge';
import { getGenders, getOccupations } from 'models/person/constants';
import {
  getAmountRanges,
  getImpactedCovers,
  getInsuredYears,
  getLicenseWithdrawalDurations,
  getLicenseWithdrawalReasons,
  getResponsibilitiesOptions,
}                                     from 'models/driver/constants';

export const getPersonSchema = (person_type = 'main_driver', extraProperties = {}, withRequiredFields = true) => {
  const rootRequiredField = `required_fields.${ person_type }`;
  return merge({
      form:   person_type,
      schema: {
        [person_type]: {
          type:       'section',
          properties: {
            kind:   {
              component: 'text',
              type:      'hidden',
              default:   {
                value: 'person',
              },
            },
            person: {
              type:       'section',
              properties: {
                first_name:                              {
                  component:   'text',
                  label:       t('person.label.first_name'),
                  validations: ['required'],
                },
                last_name:                               {
                  component:   'text',
                  label:       t('person.label.last_name'),
                  validations: ['required'],
                },
                gender:                                  {
                  component:   'radio_list',
                  list:        getGenders(),
                  label:       t('person.label.gender'),
                  validations: ['required'],
                  visible:     withRequiredFields && {
                    property: `${ rootRequiredField }.gender`,
                  },
                },
                phone_number:                            {
                  component:   'text',
                  label:       t('person.label.phone_number'),
                  validations: ['required'],
                  visible:     withRequiredFields && {
                    property: `${ rootRequiredField }.phone_number`,
                  },
                },
                email:                                   {
                  component:   'text',
                  type:        'email',
                  label:       t('person.label.email'),
                  validations: ['required_email'],
                  visible:     withRequiredFields && {
                    property: `${ rootRequiredField }.email`,
                  },
                },
                date_of_birth:                           {
                  label:       t('person.label.birthdate'),
                  component:   'date',
                  validations: ['required', 'date_format'],
                  visible:     withRequiredFields && {
                    property: `${ rootRequiredField }.date_of_birth`,
                  },
                },
                id_card_number:                          {
                  component:   'text',
                  label:       t('activemodel.attributes.policy_owner.id_card_number'),
                  validations: ['required'],
                  visible:     withRequiredFields && {
                    property: `${ rootRequiredField }.id_card_number`,
                  },
                },
                has_insurance_cancellations:             {
                  component:     'true_false',
                  label:         t('clients.policy_owners.form.insurance_cancellations_in_last_5_years'),
                  validations:   ['required'],
                  visible:       withRequiredFields && {
                    property: `${ rootRequiredField }.insurance_cancellations`,
                  },
                  default:       {
                    value: false,
                  },
                  onValueChange: {
                    set: [
                      {
                        to:        `${ person_type }.person.insurance_cancellations`,
                        value:     null,
                        condition: {
                          assertion: 'isFalse',
                        },
                      },
                      {
                        to:        `${ person_type }.person.insurance_cancellations`,
                        value:     [{}],
                        condition: {
                          assertion: 'isTrue',
                        },
                      },
                    ],
                  },
                },
                insurance_cancellations:                 {
                  type:          'array',
                  arrayType:     'table',
                  label:         t('clients.policy_owners.form.insurance_cancellations_in_last_5_years'),
                  array_actions: {
                    max:          10,
                    add_label:    t('clients.policy_owners.form.add_insurance_cancellation'),
                    title:        t('clients.policy_owners.form.insurance_cancellations_in_last_5_years'),
                    remove_label: t('general.actions.delete'),
                  },
                  properties:    {
                    date:    {
                      label:       t('clients.policy_owners.form.date'),
                      component:   'date',
                      validations: ['required', 'date_format'],
                    },
                    company: {
                      label:       t('clients.policy_owners.form.company'),
                      component:   'text',
                      validations: ['required'],
                    },
                    reason:  {
                      label:       t('clients.policy_owners.form.reason'),
                      component:   'text',
                      validations: ['required'],
                    },
                  },
                  visible:       [
                    withRequiredFields && {
                      property: `${ rootRequiredField }.insurance_cancellations`,
                    },
                    {
                      property: `${ person_type }.person.has_insurance_cancellations`,
                    },
                  ],
                },
                car_license_obtention_at:                {
                  label:       t('driver.label.car_license_obtention_at'),
                  component:   'date',
                  validations: ['required', 'date_format'],
                  visible:     [
                    {
                      property:  'contract_request.risk_object.kind',
                      assertion: 'notEqual',
                      value:     'motorbike',
                    },
                    withRequiredFields && {
                      property: `${ rootRequiredField }.car_license_obtention_at`,
                    },
                    {
                      property:  'contract_request.risk_type',
                      assertion: 'equal',
                      value:     'mobility',
                    },
                  ],
                },
                motorbike_license_obtention_at:          {
                  label:       t('driver.label.motorbike_license_obtention_at'),
                  component:   'date',
                  validations: ['required', 'date_format'],
                  visible:     [
                    {
                      property:  'contract_request.risk_object.kind',
                      assertion: 'equal',
                      value:     'motorbike',
                    },
                    withRequiredFields && {
                      property: `${ rootRequiredField }.motorbike_license_obtention_at`,
                    },
                    {
                      property:  'contract_request.risk_type',
                      assertion: 'equal',
                      value:     'mobility',
                    },
                  ],
                },
                took_motorbike_defensive_driving_course: {
                  label:       t('driver.label.took_motorbike_defensive_driving_course'),
                  component:   'true_false',
                  validations: ['required'],
                  visible:     [
                    {
                      property:  'contract_request.risk_object.kind',
                      assertion: 'equal',
                      value:     'motorbike',
                    },
                    withRequiredFields && {
                      property: `${ rootRequiredField }.took_motorbike_defensive_driving_course`,
                    },
                    {
                      property:  'contract_request.risk_type',
                      assertion: 'equal',
                      value:     'mobility',
                    },
                  ],
                },
                occupation:                              {
                  label:       t('person.label.occupation'),
                  component:   'radio_list',
                  list:        getOccupations(),
                  validations: ['required'],
                  visible:     withRequiredFields && {
                    property: `${ rootRequiredField }.occupation`,
                  },
                },
                insured_years_as_main_driver:            {
                  label:       t('driver.label.insured_years_as_main_driver'),
                  component:   'radio_list',
                  list:        getInsuredYears(),
                  validations: ['required'],
                  visible:     [
                    withRequiredFields && {
                      property: `${ rootRequiredField }.insured_years_as_main_driver`,
                    },
                    {
                      property:  'contract_request.risk_type',
                      assertion: 'equal',
                      value:     'mobility',
                    },
                  ],
                },
                has_vehicle_accidents:                   {
                  label:         t('driver.label.vehicle_accident'),
                  component:     'true_false',
                  validations:   ['required'],
                  visible:       [
                    withRequiredFields && {
                      property: `${ rootRequiredField }.vehicle_accidents`,
                    },
                    {
                      property:  'contract_request.risk_type',
                      assertion: 'equal',
                      value:     'mobility',
                    },
                  ],
                  default:       {
                    value: false,
                  },
                  onValueChange: {
                    set: [
                      {
                        to:        `${ person_type }.person.vehicle_accidents`,
                        value:     null,
                        condition: {
                          assertion: 'isFalse',
                        },
                      },
                      {
                        to:        `${ person_type }.person.vehicle_accidents`,
                        value:     [{}],
                        condition: {
                          assertion: 'isTrue',
                        },
                      },
                    ],
                  },
                },
                vehicle_accidents:                       {
                  type:          'array',
                  arrayType:     'table',
                  label:         t('driver.label.vehicle_accident'),
                  array_actions: {
                    add_label:    t('clients.drivers.driver_form.add_vehicle_accident'),
                    title:        t('driver.label.vehicle_accident'),
                    remove_label: t('general.actions.delete'),
                  },
                  properties:    {
                    date:           {
                      label:       t('clients.policy_owners.form.date'),
                      component:   'date',
                      validations: ['required', 'date_format'],
                    },
                    amount_range:   {
                      component:   'select',
                      list:        getAmountRanges(),
                      label:       t('clients.drivers.driver_form.amount_range'),
                      validations: ['required'],
                    },
                    impacted_cover: {
                      component:   'select',
                      list:        getImpactedCovers(),
                      label:       t('clients.drivers.driver_form.impacted_cover'),
                      validations: ['required'],
                    },
                    responsibility: {
                      component:   'select',
                      list:        getResponsibilitiesOptions(),
                      label:       t('clients.drivers.driver_form.responsibility'),
                      validations: ['required'],
                    },
                  },
                  visible:       [
                    {
                      property: `${ person_type }.person.has_vehicle_accidents`,
                    },
                    {
                      property:  'contract_request.risk_type',
                      assertion: 'equal',
                      value:     'mobility',
                    },
                  ],
                },
                has_driving_license_withdrawals:         {
                  component:   'true_false',
                  label:       t('driver.label.driving_license_withdrawal'),
                  validations: ['required'],
                  visible:     [
                    withRequiredFields && {
                      property: `${ rootRequiredField }.driving_license_withdrawals`,
                    },
                    {
                      property:  'contract_request.risk_type',
                      assertion: 'equal',
                      value:     'mobility',
                    },
                  ],
                  default:     {
                    value: false,
                  },
                  onValueChange: {
                    set: [
                      {
                        to:        `${ person_type }.person.driving_license_withdrawals`,
                        value:     null,
                        condition: {
                          assertion: 'isFalse',
                        },
                      },
                      {
                        to:        `${ person_type }.person.driving_license_withdrawals`,
                        value:     [{}],
                        condition: {
                          assertion: 'isTrue',
                        },
                      },
                    ],
                  },
                },
                driving_license_withdrawals:             {
                  type:          'array',
                  arrayType:     'table',
                  label:         t('driver.label.driving_license_withdrawal'),
                  array_actions: {
                    add_label:    t('clients.drivers.driver_form.add_driving_license_withdrawal'),
                    title:        t('driver.label.driving_license_withdrawal'),
                    remove_label: t('general.actions.delete'),
                  },
                  properties:    {
                    date:     {
                      label:       t('clients.drivers.driver_form.date'),
                      component:   'date',
                      validations: ['required', 'date_format'],
                    },
                    duration: {
                      label:     t('clients.drivers.driver_form.duration'),
                      component: 'select',
                      list:      getLicenseWithdrawalDurations(),
                    },
                    reason:   {
                      label:     t('clients.drivers.driver_form.reason'),
                      component: 'select',
                      list:      getLicenseWithdrawalReasons(),
                    },
                  },
                  visible:       [
                    {
                      property: `${ person_type }.person.has_driving_license_withdrawals`,
                    },
                    {
                      property:  'contract_request.risk_type',
                      assertion: 'equal',
                      value:     'mobility',
                    },
                  ],
                },
                license_number:                          {
                  label:       t('driver.label.license_number'),
                  component:   'text',
                  validations: ['required'],
                  visible:     [
                    withRequiredFields && {
                      property: `${ rootRequiredField }.license_number`,
                    },
                    {
                      property:  'contract_request.risk_type',
                      assertion: 'equal',
                      value:     'mobility',
                    },
                  ],
                },
                address:                                 {
                  label:                t('activerecord.attributes.driver.person.address'),
                  component:            'address',
                  inlineContainerClass: 'top',
                  requiredFields:       {
                    street_name:   withRequiredFields ? `${ rootRequiredField }.street_name` : true,
                    street_number: withRequiredFields ? `${ rootRequiredField }.street_number` : true,
                    city:          withRequiredFields ? `${ rootRequiredField }.city` : true,
                    street_box:    withRequiredFields ? `${ rootRequiredField }.street_box` : true,
                    zip_code:      withRequiredFields ? `${ rootRequiredField }.zip_code` : true,
                    letter:        withRequiredFields ? `${ rootRequiredField }.letter` : false,
                  },
                },
              },
            },
          },
        },
      },
    },
    extraProperties,
  );
};
