import React                 from 'react';
import { connect }           from 'react-redux';
import $                     from 'jquery';
import UIkit                 from 'uikit';
import {
  withRouter,
  NavLink, Link,
}                            from 'react-router-dom';
import {
  AssociateSubMenu,
  ProviderMainUserSubMenu,
}                            from 'models/menus/provider_menu';
import { logOutCurrentUser } from 'models/current_user/requests';
import Icon                  from 'components/ui/icon';
import confirmBeforeAction    from 'components/ui/buttons/helpers/confirm_before_action';
import WarningMessage        from 'images/subscription_warning.svg';
import withTooltip           from 'components/ui/tooltip/with_tooltip';
import { getAuthorizationToken }         from 'services/authorization_token';

const Img = ({ src, alt, children, ...rest }) => (
  <div { ...rest }>
    { children }
    <img src={ src } alt={ alt } />
  </div>
);

const TooltipedImg = withTooltip()(Img);

class UserMenu extends React.Component {
  constructor(props) {
    super(props);
    this.logoutUser = this.logoutUser.bind(this);
    this.redirectTo = this.redirectTo.bind(this);
    this.dropdown = null;
  }

  componentDidMount() {
    const dropdown = UIkit.dropdown('#user-menu-dropdown', {
      mode: 'click',
      pos:  'bottom-right',
    });
    $('.user-menu-link').on('click', () => {
      dropdown.hide();
    });
  }

  logoutUser() {
    const { dispatch } = this.props;
    dispatch(logOutCurrentUser());
  }

  redirectTo(path) {
    const { history } = this.props;
    history.push(path);
  }

  render() {
    const ConfirmNavLink = confirmBeforeAction()(NavLink);
    const getLinkComponent = (confirm) => confirm ? ConfirmNavLink : NavLink;
    const { menu, displayName, activeSubscription, subscriptionPending, authorizations } = this.props;
    return (
      <div className="menu-right">
        { subscriptionPending && (
          <TooltipedImg
            className="subscription-warning"
            src={ WarningMessage }
            alt="warning_subscription_icon"
            data-purpose="warning_subscription_icon"
            tooltip={ t('providers.account.subscription.subscription_pending') }
          />
        ) }
        { !activeSubscription && !authorizations.is_agent && (
          <TooltipedImg
            className="subscription-warning"
            src={ WarningMessage }
            alt="warning_subscription_icon"
            data-purpose="warning_subscription_icon"
            tooltip={ t('providers.account.subscription.subscription_ended') }
          />
        ) }
        <div className="user-menu">
          <button
            type="button"
            className="user-menu-button"
            data-purpose="user_menu_button"
          >
            <Icon icon="profile" className="uk-hidden@m profile-icon" />
            <span className="uk-visible@m uk-flex uk-flex-middle">
              <span className="user-name">{ `${ displayName } ` }</span>
              <Icon icon="arrow-down" className="user-menu-dropdown-icon" />
            </span>
          </button>
          <div id="user-menu-dropdown">
            <ul className="uk-nav uk-dropdown-nav user-menu-nav">
              {
                menu.map((menu_item, id) => {
                  const Link = getLinkComponent(menu_item.confirm);
                  if (menu_item.authorization && !authorizations[menu_item.authorization]) {
                    return null;
                  }
                  return (
                    <li key={ id }>
                      <Link
                        to={ menu_item.path }
                        data-purpose={ `${ menu_item.slug }_button` }
                        className={ `user-menu-link ${ menu_item.className || '' }` }
                        onClick={ menu_item.confirm ? this.logoutUser : null }
                      >
                        <Icon icon={ menu_item.icon } />
                        { menu_item.label() }
                      </Link>
                    </li>
                  );
                })
              }
              { this.props.withProductFactoryLink && (process.env.REACT_APP_ENV === 'development' || process.env.REACT_APP_ENV === 'staging') && (
                <li>
                  <a
                    target="_blank"
                    className="user-menu-link"
                    href={ `${process.env.REACT_APP_API_PROTOCOL}://app.${process.env.REACT_APP_INSURGATE_DOMAIN}/login?authorizationToken=${getAuthorizationToken()}` }
                  >
                    Insurgate
                  </a>
                </li>
              ) }
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { current_user, account } = state;
  const showAccountMenu = current_user.profile.authorizations.account_admin;
  const subscription = account && account.subscription;
  const subscriptionPending = subscription && (subscription.need_sca || subscription.need_new_source);
  return {
    menu:                showAccountMenu ? ProviderMainUserSubMenu : AssociateSubMenu,
    displayName:         current_user.profile.display_name,
    activeSubscription:  subscription && subscription.active,
    subscriptionPending,
    authorizations:      current_user.profile.authorizations,
  };
};

export default withRouter(connect(mapStateToProps)(UserMenu));
