import React                             from 'react';
import { useDispatch, useSelector }      from 'react-redux';
import { API_DOMAIN, API_PROTOCOL }      from 'constants/application_constants';
import FileInput                         from 'components/ui/form/file_input';
import { Field }                         from 'redux-form';
import { setContractRequest }            from 'models/wizard/store_actions';
import { deleteContractRequestDocument } from 'models/contract_request/requests';

const DocumentFields = ({ labelAddition }) => {
  const documents = useSelector(state => state.wizard.contract_request.documents);
  const contractRequestId = useSelector(state => state.wizard.contract_request.id);
  const dispatch = useDispatch();
  const onDelete = (file) => {
    return deleteContractRequestDocument(file.id, contractRequestId);
  };
  return (
    <Field
      name="documents"
      inline
      containerClass="top"
      label={ t('wizard.housing.pictures', { count: documents.length }) }
      endpoint={ `${ API_PROTOCOL }://${ API_DOMAIN }/contract_requests/${ contractRequestId }/documents` }
      labelClass="label-column"
      files={ documents }
      labelAddition={ labelAddition }
      component={
        FileInput
      }
      onDelete={ onDelete }
      onUploaded={ ({ contract_request }) => {
        dispatch(setContractRequest(contract_request));
      } }
    />
  );
};

export default DocumentFields;
