export const withoutJsonFieldProps = ({
  purpose,
  onInput,
  formName,
  symbol,
  onValueChange,
  change,
  loading,
  InputAddition,
  nameOverride,
  prefillOnMount,
  fieldKey,
  prefillFrom,
  inArray,
  withValues,
  normalizeOnMount,
  noClass,
  extraFields,
  visible,
  __field,
  list,
  ...restProps
}) => {
  return restProps;
};
