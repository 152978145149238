import React       from 'react';
import take        from 'lodash/take';
import SimpleBar   from 'simplebar-react';
import Avatar      from 'components/avatar';
import ProductLogo from 'components/tarifications/table/product_logo';

const PRODUCT_COUNT_TO_DISPLAY = 4;

const ProductTinyList = ({ products }) => {
  const toDisplay = take(products, PRODUCT_COUNT_TO_DISPLAY);
  const restCount = products.length - PRODUCT_COUNT_TO_DISPLAY;
  if (!products.length) {
    return '-';
  }
  return (
    <div className="uk-inline product-tiny-list">
      <div className="product-logos">
        { toDisplay.length === 1 && (
          <React.Fragment>
            <span className="product-name">{ toDisplay[0].name }</span>
          </React.Fragment>
        ) }
        { restCount > 0 && (
          <span className="rest-product">+ { restCount }</span>
        ) }
        { toDisplay.map((product, id) => (
          <ProductLogo product={ product } key={ id } size={ 20 } />
        )) }
      </div>
      { toDisplay.length > 0 && (
        <div data-uk-dropdown="pos: right" className="p-10">
          <SimpleBar className="warning-tooltip-content">
            <ul className="uk-list p-10 m-0">
              { products.map((product, id) => {
                return (
                  <li
                    key={ id }
                    className={ `uk-flex uk-align-center uk-flex-middle ${ id < products.length - 1 ? 'mb-5' : 'mb-0' }` }
                  >
                    <ProductLogo product={ product } className="mr-5" size={ 20 } />
                    { product.name }
                  </li>
                );
              }) }
            </ul>
          </SimpleBar>
        </div>
      ) }
    </div>
  );
};

export default ProductTinyList;
