import React         from 'react';
import NumberService from 'services/number';

const usageIsPrivate = {
  property:  'usage.vehicle_usage',
  assertion: 'equal',
  value:     'Private_only',
};

const mainDriverIsOlderThan25years = {
  property: 'main_driver.person.date_of_birth',
  custom:   'min25years',
};

export const getAxaConstructorVehicleSchema = () => {
  const carSchema = {
    form:      'vehicle',
    formProps: {
      updateUnregisteredFields: true,
    },
    schema:    {
      vehicle: {
        type:       'section',
        properties: {
          kind:                                {
            component:   'radio_list',
            label:       t('axa.constructor.vehicle.vehicle_kind'),
            validations: ['required'],
            default:     {
              value: 'car',
            },
            list:        [
              {
                value: 'car',
                label: t('risks.reference.car'),
              },
              {
                value: 'van',
                label: t('risks.reference.van'),
              },
            ],
          },
          date_of_first_circulation:           {
            component:   'date',
            validations: ['required', 'date_format'],
            label:       t('activemodel.attributes.car.date_of_first_circulation'),
          },
          brand:                               {
            component:      'text',
            label:          t('activerecord.attributes.car.brand'),
            validations:    ['required'],
            default:        {
              from: 'contract_request.tarifications[0].product.metadata.car_brand',
            },
            componentProps: {
              disabled: true,
            },
          },
          model:                               {
            component:   'select',
            label:       t('activerecord.attributes.car.model'),
            validations: ['required'],
            list:        {
              from: 'contract_request.tarifications[0].product.metadata.car_models',
            },
            extraItems:  [
              {
                value: 'not_found',
                label: t('schemas.axa.constructor.axa_constructor_vehicle_schema.not_found'),
              },
            ],
          },
          manual_model:                        {
            component:   'text',
            label:       t('schemas.axa.constructor.axa_constructor_vehicle_schema.manual_model'),
            validations: ['required'],
            visible:     [{
              property:  'vehicle.model',
              assertion: 'equal',
              value:     'not_found',
            }],
          },
          fuel_type:                           {
            component:   'select',
            label:       t('activerecord.attributes.car.fuel_type'),
            validations: ['required'],
            list:        [
              {
                label: t('clients.cars.car_form.fuel_type_options.gasoline'),
                value: 'Petrol',
              },
              {
                label: t('clients.cars.car_form.fuel_type_options.diesel'),
                value: 'Diesel',
              },
              {
                label: t('clients.cars.car_form.fuel_type_options.lpg'),
                value: 'LPG',
              },
              {
                label: t('clients.cars.car_form.fuel_type_options.electric'),
                value: 'Electric',
              },
              {
                label: t('clients.cars.car_form.fuel_type_options.natural_gas'),
                value: 'Natural_gas',
              },
              {
                label: t('clients.cars.car_form.fuel_type_options.hybrid_gasoline'),
                value: 'Hybrid_petrol',
              },
              {
                label: t('clients.cars.car_form.fuel_type_options.hybrid_diesel'),
                value: 'Hybrid_diesel',
              },
            ],
          },
          power:                               {
            component:   'text',
            label:       t('activerecord.attributes.car.power'),
            symbol:      'KW',
            validations: ['required'],
          },
          mono_volume:                         {
            component:   'true_false',
            validations: ['required'],
            label:       t('axa.constructor.vehicle.genre_list.monovolume'),
            visible:     [
              {
                property: 'main_driver.person.date_of_birth',
                custom:   'isBetween30and45yearsOld',
              },
              {
                property:  'vehicle.kind',
                assertion: 'notEqual',
                value:     'van',
              },
            ],
          },
          custom_driving_aides_count:          {
            tooltip:     t('schemas.axa.constructor.axa_constructor_vehicle_schema.driving_aids_tooltip_html'),
            component:   'text',
            label:       t('schemas.axa.constructor.axa_constructor_vehicle_schema.driving_aids_count'),
            validations: ['required'],
            type:        'number',
          },
          trailer_usage:                       {
            component:   'true_false',
            label:       t('clients.typed_contract_requests.usage_modal.trailer_usage'),
            validations: ['required'],
            default:     {
              value: false,
            },
          },
          seats_count:                         {
            component:   'text',
            type:        'number',
            label:       t('activerecord.attributes.car.seats_count'),
            validations: ['required'],
            parse:       Number,
            default:     {
              value: 5,
            },
          },
          catalogue_price_without_vat:         {
            component:   'price',
            label:       t('activerecord.attributes.car.catalogue_price_without_vat'),
            validations: ['required'],
          },
          options_catalogue_price_without_vat: {
            component:     'price',
            label:         t('activerecord.attributes.car.options_catalogue_price_without_vat'),
            validations:   ['required'],
            withValues:    {
              catalogue_price_without_vat:         'vehicle.catalogue_price_without_vat',
              options_catalogue_price_without_vat: 'vehicle.options_catalogue_price_without_vat',
            },
            fieldAddition: {
              className: 'global-message warning xs p-5',
              render:    (props = {}) => {
                const { catalogue_price_without_vat, options_catalogue_price_without_vat } = props;
                const totalCataloguePriceWithOptions = (catalogue_price_without_vat ? parseFloat(`${ catalogue_price_without_vat }`.replace(' ', '').replace(',', '.')) : 0) + (options_catalogue_price_without_vat ? parseFloat(`${ options_catalogue_price_without_vat }`.replace(' ', '').replace(',', '.')) : 0);
                return (
                  <p className="mb-0 mt-10">
                    <span className="global-message warning xs p-5">
                      { t_html('wizard.car.total_option_and_catalog_price_html', { total_price: NumberService.formatCurrency(totalCataloguePriceWithOptions, '€') }) }
                    </span>
                  </p>
                );
              },
            },
          },
          purchase_price_without_vat:          {
            component:   'price',
            label:       t('activerecord.attributes.car.purchase_price_without_vat'),
            validations: ['required'],
            visible:     [
              {
                property: 'vehicle.date_of_first_circulation',
                custom:   'isAtLeast24monthsAnd1day',
              },
            ],
          },
          vat_nature:                          {
            component:   'select',
            validations: ['required'],
            label:       t('axa.constructor.vehicle.vat_nature'),
            default:     {
              value: '21%_VAT',
            },
            list:        [
              {
                label: t('axa.constructor.vehicle.vat_nature_list.no_vat'),
                value: 'No_VAT',
              },
              {
                label: t('axa.constructor.vehicle.vat_nature_list.vat_on_the_margin'),
                value: 'VAT_on_the_margin',
              },
              {
                label: t('axa.constructor.vehicle.vat_nature_list.vat_21_percent'),
                value: '21%_VAT',
              },
              {
                label: t('axa.constructor.vehicle.vat_nature_list.no_invoice'),
                value: 'No_invoice',
              },
            ],
          },
        },
      },
      usage:   {
        type:       'section',
        title:      t('wizard.car.about_usage'),
        properties: {
          vehicle_usage:     {
            label:       t('clients.typed_contract_requests.usage_modal.vehicle_usage'),
            list:        [
              {
                value: 'Private_only',
                label: t('axa.constructor.vehicle.usage_list.private_only'),
              },
              {
                value: 'Private_and_Professional',
                label: t('axa.constructor.vehicle.usage_list.private_and_professional'),
              },
            ],
            validations: ['required'],
            default:     {
              value: 'Private_only',
            },
            component:   'radio_list',
          },
          annual_mileage_km: {
            label:       t('axa.constructor.vehicle.annual_mileage_km'),
            validations: ['required'],
            component:   'radio_list',
            default:     {
              value: 'Undeclared',
            },
            list:        [
              {
                value: 'Undeclared',
                label: t('axa.constructor.vehicle.annual_mileage_km_list.undeclared'),
              },
              {
                value:   'Max_5000',
                label:   t('axa.constructor.vehicle.annual_mileage_km_list.max_5000'),
                visible: [
                  usageIsPrivate,
                  mainDriverIsOlderThan25years,
                ],
              },
              {
                value:   'Max_10000',
                label:   t('axa.constructor.vehicle.annual_mileage_km_list.max_10000'),
                visible: [
                  usageIsPrivate,
                  mainDriverIsOlderThan25years,
                ],
              },
            ],
          },
        },
      },
    },
  };
  return carSchema;
};
