import Api                                from 'services/api';
import Alert                              from 'services/alert';
import { getAuthorizationToken }          from 'services/authorization_token';
import { ASSETS_DOMAIN, ASSETS_PROTOCOL } from 'constants/application_constants';
import { openLinkOrDownloadFile }         from 'services/file';

export const fetchFolders = (page = '1', filters = {}) => {
  return new Promise((resolve, reject) => {
    Api.get('folders', {
      params: {
        page,
        ...filters,
      },
    }).then((response) => {
      resolve(response.data);
    }).catch((error) => {
      Alert.error(t('providers.customers.fetch_contract_request_error'));
      reject(error);
    });
  });
};

export const generateQuickAnswer = contractRequestId => new Promise((resolve) => {
  const path = `${ ASSETS_PROTOCOL }://${ ASSETS_DOMAIN }/pdfs/contract_requests/${ contractRequestId }/activities.pdf?authorization_token=${ getAuthorizationToken() }`;
  resolve(openLinkOrDownloadFile(path, 'document.pdf'));
});
