import Api   from 'services/api';
import Alert from 'services/alert';

export const signOffer = (tarification) => {
  return new Promise((resolve, reject) => {
    Api.post(`tarifications/${ tarification.id }/offer/sign`).then((response) => {
      resolve(response.data);
    }).catch((error) => {
      Alert.error(t('provider.tarification.create_offer_error'));
      reject(error);
    });
  });
};
