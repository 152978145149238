import React                                 from 'react';
import { Provider }                          from 'react-redux';
import thunk                                 from 'redux-thunk';
import { applyMiddleware, compose }          from 'redux';
import { Helmet }                            from 'react-helmet';
import createPlugin                          from 'bugsnag-react';
import LogRocket                             from 'logrocket';
import bugsnag                               from 'bugsnag-js';
import { ToastContainer }                    from 'react-toastify';
import { initLogrocket, actionSanitizer }    from 'services/logrocket';
import { getCurrentLocale, init }            from 'services/i18n';
import Segment                               from 'helpers/segment';
import CheckForAppUpdate                     from 'helpers/check_for_app_update';
import CurrentUserByRoleContainer            from 'helpers/current_user_by_role_container';
import ErrorCatcher                          from 'helpers/error_catcher';
import ApplicationContext                    from 'helpers/application_context';
import applicationStore, { setInitialState } from 'models/application/store';
import getConfig                             from 'models/config/requests';
import Loader                                from 'components/ui/loader';
import EnvironmentBanner                     from 'components/ui/banner/environment_banner';
import CheckForIntermediarySession           from 'components/intermediaries/check_for_intermediary_session';
import { Elements }                          from '@stripe/react-stripe-js';
import { loadStripe }                        from '@stripe/stripe-js';

import {
  BUGSNAG_API_KEY,
  ENV,
  PRODUCTION,
  SEGMENT_KEY,
  STRIPE_PUBLISHABLE_KEY
} from 'constants/application_constants';

import favicon              from 'images/favicon.png';
import appleIcon152         from 'images/icons/apple-touch-icon-152.png';
import appleIcon167         from 'images/icons/apple-touch-icon-167.png';
import appleIcon180         from 'images/icons/apple-touch-icon-180.png';
import DesktopApplication   from 'helpers/desktop_application';
import WithGoogleMap        from 'helpers/with_google_map';
import IEAlert              from 'components/ie_alert';
import { getCableConsumer } from 'services/action_cable/cable';
import ActionCableProvider  from 'services/action_cable/provider';
import ConfigSocket         from 'scenes/application/config_socket';

const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);

initLogrocket();

const bugsnagClient = bugsnag({
  appVersion:          '1.0.0',
  apiKey:              BUGSNAG_API_KEY,
  notifyReleaseStages: ['production', 'staging', 'validation'],
  releaseStage:        ENV,
  enabledErrorTypes:   {
    unhandledExceptions: true,
    unhandledRejections: false,
  },
  beforeSend:          (report) => {
    report.updateMetaData('LogRocket', {
      sessionURL: LogRocket.sessionURL,
    });
  },
});

class OpenBrokerApp extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ready:  false,
      config: null,
    };
  }

  componentDidMount() {
    init().then(() => {
      this.setState({
        ready: true,
      });
    });
    getConfig().then(({ config }) => {
      this.setState({
        config,
      });
    });
    window.bugsnagClient = bugsnagClient;
  }

  render() {
    const ErrorBoundary = bugsnagClient.use(createPlugin(React));
    const { config, ready } = this.state;
    if (!config || !ready) {
      return <Loader middleOfPage />;
    }

    let composeEnhancers = compose;
    const reduxDevtool = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;

    if (!PRODUCTION && reduxDevtool) {
      composeEnhancers = reduxDevtool;
    }
    const initialState = { config };

    const store = applicationStore(
      setInitialState(initialState),
      composeEnhancers(
        applyMiddleware(thunk, LogRocket.reduxMiddleware(
          {
            actionSanitizer,
          },
        )),
      ),
    );

    return (
      <Provider store={ store }>
        <Segment segmentKey={ SEGMENT_KEY } />
        <Helmet defaultTitle="OpenBroker">
          <html lang={ getCurrentLocale() } />
          <meta charSet="utf-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1.0" />
          <meta name="apple-mobile-web-app-capable" content="yes" />
          <meta name="apple-mobile-web-app-status-bar-style" content="black" />
          <link rel="apple-touch-icon" href={ appleIcon152 } />
          <link rel="apple-touch-icon" sizes="152x152" href={ appleIcon152 } />
          <link rel="apple-touch-icon" sizes="180x180" href={ appleIcon180 } />
          <link rel="apple-touch-icon" sizes="167x167" href={ appleIcon167 } />
          <meta name="apple-mobile-web-app-title" content="Openbroker" />
          <link rel="icon" href={ favicon } type="image/png" />
          <link rel="shortcut icon" href={ favicon } type="image/png" />
          <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons" />
          <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Noto+Sans:400,700" />
          <script src="https://polyfill.io/v3/polyfill.min.js?features=String.prototype.repeat%2CDOMTokenList%2CNode.prototype.contains" />
          <script src="https://js.stripe.com/v3/" />
        </Helmet>
        <CheckForAppUpdate />
        <EnvironmentBanner />
        <ActionCableProvider cable={ getCableConsumer() }>
          <ApplicationContext.Provider value={ { bugsnagClient } }>
            <ErrorBoundary>
              <WithGoogleMap>
                <div>
                  <ConfigSocket />
                  <ToastContainer
                    position="top-right"
                    autoClose={ 5000 }
                    hideProgressBar
                    newestOnTop={ false }
                    closeOnClick
                    rtl={ false }
                    pauseOnVisibilityChange
                    draggable={ false }
                    draggablePercent={ 10 }
                    pauseOnHover
                  />
                  <ErrorCatcher>
                    <Elements stripe={ stripePromise } options={ { locale: getCurrentLocale() } }>
                      <CheckForIntermediarySession>
                        <CurrentUserByRoleContainer />
                      </CheckForIntermediarySession>
                    </Elements>
                  </ErrorCatcher>
                  <DesktopApplication />
                  <IEAlert />
                </div>
              </WithGoogleMap>
            </ErrorBoundary>
          </ApplicationContext.Provider>
        </ActionCableProvider>
      </Provider>
    );
  }
}

export default OpenBrokerApp;
