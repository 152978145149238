import React, { createContext } from 'react';

const FieldArrayContext = createContext({
  field:    null,
  form:     null,
  change:   null,
  fieldKey: null,
});

export default FieldArrayContext;
