export const getViviumBuildingEvaluationSurfaceGridSchema = () => {
  return {
    surface: {
      type:       'section',
      visible:    {
        property:  'evaluation.evaluation_method',
        assertion: 'equal',
        value:     'surface_grid',
      },
      properties: {
        surface_grid_scope_warning: {
          type:      'text',
          text:      t('schemas.vivium.building.vivium_building_evaluation_surface_grid_schema.surface_grid_scope_warning'),
          className: 'global-message warning',
        },
        shared_boundary:            {
          label:     t('schemas.vivium.building.vivium_building_evaluation_surface_grid_schema.shared_boundary'),
          component: 'select',
          list:      [
            {
              value: 'attached',
              label: t('schemas.vivium.building.vivium_building_evaluation_surface_grid_schema.shared_boundary'),
            },
            {
              value: 'semi_attached',
              label: t('schemas.vivium.building.vivium_building_evaluation_surface_grid_schema.semi_attached'),
            },
            {
              value: 'not_attached',
              label: t('schemas.vivium.building.vivium_building_evaluation_surface_grid_schema.not_attached'),
            },
          ],
          visible:   [{
            property:  'evaluation.evaluation_method',
            assertion: 'equal',
            value:     'surface_grid',
          }],
        },
        surface_grid_title:         {
          type:  'title',
          title: t('vivium.evaluation.methods.surface_grid'),
        },
        presence_of:                {
          component:     'table',
          inline:        false,
          table_columns: [{
            name:  'habitation',
            label: t('vivium.evaluation.surface_grid.habitation_part'),
          }, {
            name:  'commercial',
            label: t('vivium.evaluation.surface_grid.commercial_part'),
          }],
          properties:    {
            wooden_or_natural_stone_floor: {
              component:         'checkbox',
              inline:            false,
              showCheckboxLabel: false,
              label:             t('vivium.evaluation.surface_grid.grid.wooden_or_natural_stone_floor'),
            },
            textile_coating:               {
              component:         'checkbox',
              inline:            false,
              showCheckboxLabel: false,
              label:             t('vivium.evaluation.surface_grid.grid.textile_coating'),
            },
            with_elevator:                 {
              component:         'checkbox',
              inline:            false,
              showCheckboxLabel: false,
              label:             t('vivium.evaluation.surface_grid.grid.with_elevator'),
            },
            inner_door_solid_wood:         {
              component:         'checkbox',
              inline:            false,
              showCheckboxLabel: false,
              label:             t('vivium.evaluation.surface_grid.grid.inner_door_solid_wood'),
            },
            workplan_natural_stone:        {
              component:         'checkbox',
              inline:            false,
              showCheckboxLabel: false,
              label:             t('vivium.evaluation.surface_grid.grid.workplan_natural_stone'),
            },
            built_in_appliances:           {
              component:         'checkbox',
              inline:            false,
              showCheckboxLabel: false,
              label:             t('vivium.evaluation.surface_grid.grid.built_in_appliances'),
            },
            more_than_1_bathroom:          {
              component:         'checkbox',
              inline:            false,
              showCheckboxLabel: false,
              label:             t('vivium.evaluation.surface_grid.grid.more_than_1_bathroom'),
            },
            individual_heating:            {
              component:         'checkbox',
              inline:            false,
              showCheckboxLabel: false,
              label:             t('vivium.evaluation.surface_grid.grid.individual_heating'),
            },
            fireplace:                     {
              component:         'checkbox',
              inline:            false,
              showCheckboxLabel: false,
              label:             t('vivium.evaluation.surface_grid.grid.fireplace'),
            },
            general_smoke_detector:        {
              component:         'checkbox',
              inline:            false,
              showCheckboxLabel: false,
              label:             t('vivium.evaluation.surface_grid.grid.general_smoke_detector'),
            },
            facade_with_balcony:           {
              component:         'checkbox',
              inline:            false,
              showCheckboxLabel: false,
              label:             t('vivium.evaluation.surface_grid.grid.facade_with_balcony'),
            },
            narrow_construction:           {
              component:         'checkbox',
              inline:            false,
              showCheckboxLabel: false,
              label:             t('vivium.evaluation.surface_grid.grid.narrow_construction'),
            },
          },
        },
        count_title:                {
          type:  'title',
          title: t('vivium.evaluation.surface_grid.count'),
        },
        solar_panels_count:         {
          component:   'text',
          type:        'number',
          validations: ['required'],
          label:       t('vivium.evaluation.surface_grid.solar_panels_count'),
        },
        habitation_surface:         {
          component:   'text',
          symbol:      'm²',
          validations: ['required'],
          label:       t('vivium.evaluation.surface_grid.habitation_surface'),
        },
        commercial_surface:         {
          component:   'text',
          symbol:      'm²',
          validations: ['required'],
          label:       t('vivium.evaluation.surface_grid.commercial_surface'),
        },
        basement_or_attic:          {
          component:   'text',
          symbol:      'm²',
          validations: ['required'],
          label:       t('vivium.evaluation.surface_grid.basement_or_attic'),
        },
        external_garage:            {
          component:   'text',
          symbol:      'm²',
          validations: ['required'],
          label:       t('vivium.evaluation.surface_grid.external_garage'),
        },
      },
    },
  };
};
