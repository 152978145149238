import React from 'react';

export const getAxaBuildingCommentSchema = () => {
  return {
    form:   'building_comment_form',
    schema: {
      building: {
        type:       'section',
        title:      t('wizard.building.comment.title'),
        properties: {
          comment:   {
            label:                t('wizard.building.comment.description'),
            className:            'uk-height-medium',
            inlineContainerClass: 'top',
            containerClass:       'uk-width-1-1',
            withValues:           {
              claimHistoryType:      'claim_history.claim_history_type',
              isContractAvailable:   'previous_contract.is_contract_available',
              isAssessmentAvailable: 'building.assessment.is_assessment_available',
            },
            component:            'textarea',
            labelClass:           'label-column',
            labelAddition:        ({ claimHistoryType, isContractAvailable, isAssessmentAvailable }) => (
              <div className="global-message info mt-20">
                <p>{ t('wizard.building.comment.file_to_add') }</p>
                <ul>
                  { isContractAvailable && (
                    <li>
                      { t('wizard.building.previous_contract.previous_contract_present') }
                    </li>
                  ) }
                  { isAssessmentAvailable && (
                    <li>
                      { t('wizard.building.assessment.report') }
                    </li>
                  ) }
                  { claimHistoryType === 'Document' && (
                    <li>
                      { t('wizard.building.claim_history.upload_document') }
                    </li>
                  ) }
                  <li>
                    { t('wizard.building.comment.all_other_document') }
                  </li>
                </ul>
              </div>
            ),
          },
          documents: {
            component: 'uploader',
            label:     t('wizard.building.comment.file', { count: 0 }),
          },
        },
      },
    },
  };
};
