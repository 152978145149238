import React                      from 'react';
import PortabilityRightsForm      from 'components/gdpr/portability_rights/protability_rights_form';
import AccountRemovalForm         from 'components/gdpr/account_removal/account_removal_form';
import ProcessingLimitRequestForm from 'components/gdpr/processing_limit_requests/processing_limit_requests_form';
import RectificationRequestForm   from 'components/gdpr/data_rectification_request/data_rectification_request_form';
import {
  dataPortabilityRightRequests,
  dataRectificationRequests,
  accountRemovalRequests,
  processingLimitRequests,
}                                 from 'models/gdpr/request';
import openDialogOnClick          from 'components/ui/buttons/helpers/open_dialog_on_click';

const OpenDialogOnClickButton = openDialogOnClick()(() => {
  return null;
});

class GdprModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      gdprFor: 'data_portability_rights',
    };
    this.getContent = this.getContent.bind(this);
    this.dialog = false;
  }

  getContent() {
    const { gdprFor } = this.state;
    switch (gdprFor) {
      case 'data_portability_rights':
        return <PortabilityRightsForm onSubmit={ dataPortabilityRightRequests } />;
      case 'data_processing_limit_requests':
        return <ProcessingLimitRequestForm onSubmit={ processingLimitRequests } />;
      case 'data_rectification_requests':
        return <RectificationRequestForm onSubmit={ dataRectificationRequests } />;
      case 'account_removal':
        return <AccountRemovalForm onSubmit={ accountRemovalRequests } />;
      default:
        return <PortabilityRightsForm onSubmit={ dataPortabilityRightRequests } />;
    }
  }

  open(gdprFor) {
    this.setState({
      gdprFor,
    }, () => {
      this.dialog.openDialog();
    });
  }

  render() {
    return (
      <OpenDialogOnClickButton
        ref={ (ref) => this.dialog = ref }
      >
        { this.getContent() }
      </OpenDialogOnClickButton>
    );
  }
}


export default GdprModal;
