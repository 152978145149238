import React, { useEffect }        from 'react';
import { useHistory }              from 'react-router-dom';
import { getCookie, removeCookie } from 'services/cookies';
import { REDIRECT_AFTER_SIGN_IN }  from 'constants/application_constants';

const RedirectAfterSignIn = () => {
  const history = useHistory();
  useEffect(() => {
    const shouldRedirectPath = getCookie(REDIRECT_AFTER_SIGN_IN);
    if (shouldRedirectPath === '/') {
      removeCookie(REDIRECT_AFTER_SIGN_IN);
      return;
    }
    if (shouldRedirectPath) {
      removeCookie(REDIRECT_AFTER_SIGN_IN);
      history.push(shouldRedirectPath);
    }
  }, []);

  return null;
};


export default RedirectAfterSignIn;
