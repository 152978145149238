import moment             from 'moment';
import React, {
  useContext,
}                         from 'react';
import find               from 'lodash/find';
import sum                from 'lodash/sum';
import compact            from 'lodash/compact';
import isObject           from 'lodash/isObject';
import every              from 'lodash/every';
import has                from 'lodash/has';
import { checkCondition } from 'components/ui/json_form/json_form_helpers';
import JsonFormContext    from 'components/ui/json_form/json_form_context';
import FieldWithAsyncList from 'components/ui/json_form/field_with_async_list';

const shouldParseAssertionList = [
  'isAbove',
  'isBelow',
];

export const checkVisibleCondition = (visibleCondition, namePrefix, fieldKey, getPropertyValue, debug = false) => {
  const { property, assertion, expectation, value, total_of_properties, custom } = visibleCondition;
  if (debug || visibleCondition.debug) {
    console.group();
    log('fieldKey', fieldKey);
    log('visibleCondition', visibleCondition);
    log('property', property);
    log('assertion', assertion);
    log('expectation', expectation);
    log('value', value);
    log('custom', custom);
    log('total_of_properties', total_of_properties);
    console.groupEnd();
  }

  let propertyValue = null;
  if (total_of_properties) {
    propertyValue = sum(compact(total_of_properties.map((property_name) => {
      const val = getPropertyValue(property_name.includes('arrayFieldName') ? property_name.replace('arrayFieldName', namePrefix) : property_name);
      return parseInt(val);
    })));
  } else {
    propertyValue = getPropertyValue(property);
  }

  if (visibleCondition.propertyAs) {
    if (visibleCondition.propertyAs === 'year' && propertyValue) {
      propertyValue = moment().diff(moment(propertyValue, 'YYYY-MM-DD'), 'years');
    }
  }

  if (assertion && shouldParseAssertionList.includes(assertion)) {
    propertyValue = parseFloat(propertyValue);
  }

  if (debug || visibleCondition.debug) {
    console.group(`CheckVisibleCondition ${ fieldKey }`);
    log('getPropertyValue', propertyValue);
    log('property', property);
    console.groupEnd();
  }


  try {
    if (Object.keys(visibleCondition).length === 1 && visibleCondition.property) {
      checkCondition({
        property:  propertyValue,
        assertion: 'isTrue',
      });
    }
    checkCondition({
      property: propertyValue,
      assertion,
      expectation,
      value,
      custom,
    });

    return true;
  } catch (e) {
    if (debug) {
      console.warn('condition did not pass for', property);
    }
    return false;
  }
};

const asJsonFormField = () => Component => {

  return (props) => {
    const withValues = {};
    const { field, namePrefix, fieldKey } = props;
    const { getPropertyValue } = useContext(JsonFormContext);
    let disabled = false;
    let showFieldAddition = false;
    if (field.visible) {
      if (!Array.isArray(field.visible) && !checkVisibleCondition(field.visible, namePrefix, fieldKey, getPropertyValue)) {
        return null;
      }
      if (Array.isArray(field.visible) && !every(field.visible, (visibleCondition) => checkVisibleCondition(visibleCondition, namePrefix, fieldKey, getPropertyValue))) {
        return null;
      }
    }

    if (field.visibleOr) {
      if (!find(field.visibleOr, (visibleCondition) => checkVisibleCondition(visibleCondition, namePrefix, fieldKey, getPropertyValue))) {
        console.groupEnd();
        return null;
      }
    }

    if (field.visibleAnd) {
      if (!every(field.visibleAnd, (visibleCondition) => checkVisibleCondition(visibleCondition, namePrefix, fieldKey, getPropertyValue))) {
        return null;
      }
    }

    if (field.disabled) {
      if (!Array.isArray(field.disabled)) {
        let disabledCondition = JSON.parse(JSON.stringify(field.disabled));
        if (disabledCondition.property.includes('arrayFieldName')) {
          disabledCondition.property = disabledCondition.property.replace('arrayFieldName', namePrefix);
        }
        disabled = !checkVisibleCondition(disabledCondition, namePrefix, fieldKey, getPropertyValue);
      } else {
        disabled = every(field.disabled, (disabledCondition) => checkVisibleCondition(disabledCondition, namePrefix, fieldKey, getPropertyValue));
      }
    }

    if (field.fieldAddition) {
      if (field.fieldAddition.visible) {
        if (!Array.isArray(field.fieldAddition.visible)) {
          showFieldAddition = checkVisibleCondition(field.fieldAddition.visible, namePrefix, fieldKey, getPropertyValue);
        } else {
          showFieldAddition = every(field.fieldAddition.visible, (visibleCondition) => checkVisibleCondition(visibleCondition, namePrefix, fieldKey, getPropertyValue));
        }
      }
    }

    if (field.withValues) {
      Object.keys(field.withValues).forEach((valueKey) => {
        withValues[valueKey] = getPropertyValue(field.withValues[valueKey]);
      });
    }

    if (field.asyncList) {
      return (
        <FieldWithAsyncList
          { ...props }
          Component={ Component }
          withValues={ withValues }
          fieldAddition={ showFieldAddition && field.fieldAddition }
          disabled={ disabled }
        />
      );
    }

    if (isObject(field.list) && field.list.from) {
      const { field: fieldProps, ...restProps } = props;
      let list = [];
      const fromList = getPropertyValue(field.list.from) || [];
      if (field.extraItems) {
        list = [...fromList, ...field.extraItems];
      } else {
        list = [...fromList];
      }

      return (
        <Component
          field={
            {
              ...fieldProps,
              list,
            }
          }
          { ...restProps }
          withValues={ withValues }
          fieldAddition={ showFieldAddition && field.fieldAddition }
          disabled={ disabled }
        />
      );
    }

    if (field.list) {
      if (!field.sourceList) {
        field.sourceList = JSON.parse(JSON.stringify(field.list));
      }
      field.list = field.sourceList.filter((item) => {
        if (!has(item, 'visible')) {
          return true;
        }
        if (Array.isArray(item.visible)) {
          const allVisibleConditions = every(item.visible, (visibleCondition) => {
            return checkVisibleCondition(visibleCondition, namePrefix, fieldKey, getPropertyValue);
          });
          return allVisibleConditions;
        }
        return checkVisibleCondition(item.visible, namePrefix, fieldKey, getPropertyValue);
      });
      field.list = field.list.filter((item) => {
        if (!has(item, 'visibleOr')) {
          return true;
        }
        return !!find(item.visibleOr, (visibleCondition) => checkVisibleCondition(visibleCondition, namePrefix, fieldKey, getPropertyValue));
      });
    }

    return (
      <Component
        withValues={ withValues }
        fieldAddition={ showFieldAddition && field.fieldAddition }
        { ...props }
        disabled={ disabled }
      />
    );
  };
};

export default asJsonFormField;
