import { createConsumer }                         from '@rails/actioncable';
import { getAuthorizationToken }                  from 'services/authorization_token';
import { API_PROTOCOL, CABLE_DOMAIN, CABLE_PATH } from 'constants/application_constants';

export const getCableConsumer = () => (
  createConsumer(`${ API_PROTOCOL }://${ CABLE_DOMAIN }${ CABLE_PATH !== '' ? `/${ CABLE_PATH }` : '' }?authorization_token=${ getAuthorizationToken() }`)
);

export const getDistributorCableConsumer = () => (
  createConsumer(`${ API_PROTOCOL }://${ CABLE_DOMAIN }${ CABLE_PATH !== '' ? `/${ CABLE_PATH }` : '' }?distributor_authorization_token=${ getAuthorizationToken() }`)
);
