import { getAbexList } from 'models/application/constants';

export const getBuildingSpecificationsSchema = () => {
  return {
    specifications: {
      type:       'section',
      title:      t('wizard.building.specifications.title'),
      properties: {
        sum_insured:             {
          validations: ['required', 'maxLength10', 'only_int_price'],
          label:       t('wizard.building.assessment.sum_insured'),
          component:   'price',
        },
        abex:                    {
          validations: ['required'],
          label:       t('wizard.building.assessment.abex'),
          component:   'select',
          list:        getAbexList(),
        },
      },
      visible:    {
        property:  'building.evaluation.sub_type',
        assertion: 'equal',
        value:     'Specifications',
      },
    },
  };
};
