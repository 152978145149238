export const getViviumBuildingPreviousContractSchema = () => {
  return {
    form:   'building_evaluation_wizard_form',
    schema: {
      previous_contract:  {
        type:       'section',
        title:      t('wizard.building.previous_contract.title'),
        properties: {
          is_previously_insured:       {
            component:   'true_false',
            validations: ['required'],
            label:       t('schemas.vivium.building.vivium_building_previous_contract_schema.is_previously_insured'),
            default:     {
              value: false,
            },
          },
          has_insurance_cancellations: {
            component:     'true_false',
            label:         t('clients.policy_owners.form.insurance_cancellations_in_last_5_years'),
            validations:   ['required'],
            default:       {
              value: false,
            },
            onValueChange: {
              set: [
                {
                  to:        'insurance_cancellations',
                  value:     null,
                  condition: {
                    assertion: 'isFalse',
                  },
                },
                {
                  to:        'insurance_cancellations',
                  value:     [{}],
                  condition: {
                    assertion: 'isTrue',
                  },
                },
              ],
            },
            visible:       {
              property:  'previous_contract.is_previously_insured',
              assertion: 'isTrue',
            },
          },
          insurance_cancellations:     {
            type:          'array',
            arrayType:     'table',
            label:         t('clients.policy_owners.form.insurance_cancellations_in_last_5_years'),
            array_actions: {
              max:          10,
              add_label:    t('clients.policy_owners.form.add_insurance_cancellation'),
              title:        t('clients.policy_owners.form.insurance_cancellations_in_last_5_years'),
              remove_label: t('general.actions.delete'),
            },
            properties:    {
              date:    {
                label:       t('clients.policy_owners.form.date'),
                component:   'date',
                validations: ['required', 'date_format'],
              },
              company: {
                label:       t('clients.policy_owners.form.company'),
                component:   'text',
                validations: ['required'],
              },
              reason:  {
                label:       t('clients.policy_owners.form.reason'),
                component:   'text',
                validations: ['required'],
              },
            },
            visible:       [
              {
                property:  'previous_contract.is_previously_insured',
                assertion: 'isTrue',
              },
              {
                property: 'previous_contract.has_insurance_cancellations',
              },
            ],
          },
        },
      },
      history_title:      {
        type:       'title',
        title:      t('wizard.building.claim_history.title'),
      },
      five_last_year_s_p: {
        component:   'radio_list',
        label:       t('vivium.previous_contract.five_last_year_s_p'),
        validations: ['required'],
        list:        [
          {
            value: 'less_than_30_percent',
            label: t('vivium.previous_contract.less_than_30_percent'),
          },
          {
            value: 'more_than_30_percent',
            label: t('schemas.vivium.building.vivium_building_steps.more_than_30_percent'),
          },
        ],
      },
      less_than_30_percent_message:     {
        component: 'message',
        className: 'global-message info uk-width-1-1',
        message:   t('vivium.building.less_than_30_percent_message'),
        visible:   {
          property:  'five_last_year_s_p',
          assertion: 'equal',
          value:     'less_than_30_percent',
        },
      },
      more_than_30_percent_message:     {
        component: 'message',
        className: 'global-message danger uk-width-1-1',
        message:   t('vivium.building.more_than_30_percent_message'),
        visible:   {
          property:  'five_last_year_s_p',
          assertion: 'equal',
          value:     'more_than_30_percent',
        },
      },
    },
  };
};
