import React, { useState }      from 'react';
import PropTypes                from 'prop-types';
import {
  SubmissionError,
}                               from 'redux-form';
import {
  searchCarByVinNumber,
}                               from 'models/car/requests';
import { connect }              from 'react-redux';
import Button                   from 'components/ui/buttons/button';
import LoadOnClickButton        from 'components/ui/buttons/load_on_click_button';
import CarByLicenceNumberResume from 'components/vehicle/car_panel_resume';
import SearchByLicencePlateForm from 'components/vehicle/search_by_licence_plate_form';
import Icon                     from 'components/ui/icon';
import { trackEvent }           from 'services/segment';

const SearchByLicencePlateFormContainer = ({ cancel, onCarSelect, label, crKind }) => {
  const [car, setCar] = useState(null);
  const [plateNumber, setPlateNumber] = useState(null);

  const search = ({ plate_number }) => {
    setPlateNumber(plate_number);
    return searchCarByVinNumber(plate_number).then(({ car }) => {
      trackEvent('InformexLicensePlateSearch', {
        plate_number,
        car,
      });
      setCar(car);
      if (!car.febiac_id) {
        trackEvent('InformexLicensePlateSearchNoFebiacId');
      }
    }).catch((error) => {
      throw new SubmissionError({
        plate_number: error.data.error_message,
      });
    });
  };

  if (car) {
    const cannotSelect = (crKind !== car.extra_attributes.risk_object) || !car.febiac_id;
    return (
      <React.Fragment>
        <CarByLicenceNumberResume
          plateNumber={ plateNumber }
          vehicle={ car }
        >
          <React.Fragment>
            { crKind !== car.extra_attributes.risk_object && (
              <div className="pl-20 pr-20 pt-20 m-0">
                <div
                  className="global-message warning"
                >
                  <p className="m-0">
                    {
                      t('clients.cars.car_form.informex_risk_object_alert', {
                        plate_kind: t(`risks.reference.${ car.extra_attributes.risk_object }`),
                        cr_kind:    t(`risks.reference.${ crKind }`),
                      })
                    }
                  </p>
                </div>
              </div>
            ) }
            { !car.febiac_id && (
              <div className="pl-20 pr-20 pt-20 m-0">
                <div
                  className="global-message danger"
                >
                  <p className="m-0 uk-flex uk-flex-middle">
                    <Icon icon="warning" className="mr-10" />
                    { t('clients.cars.car_form.informex_plate_febiac_id') }
                  </p>
                </div>
              </div>
            ) }
            <div className="uk-flex uk-flex-between mt-20">
              <Button
                secondary
                data-purpose="remove_car_result_button"
                onClick={ () => setCar(null) }
              >
                { t('general.actions.cancel') }
              </Button>
              <LoadOnClickButton
                primary
                disabled={ cannotSelect }
                onClick={ () => onCarSelect({
                  ...car,
                  source: 'informex',
                }) }
                data-purpose="select_car_result_button"
              >
                { t('clients.typed_contract_requests.car_selection_modal.select_this_car') }
              </LoadOnClickButton>
            </div>
          </React.Fragment>

        </CarByLicenceNumberResume>
      </React.Fragment>
    );
  }

  return (
    <SearchByLicencePlateForm
      onSubmit={ search }
      label={ label }
    >
      { cancel && (
        <Button
          secondary
          data-purpose="other_car_creation_mode_button"
          onClick={ cancel }
        >
          { t('clients.typed_contract_requests.car_selection_modal.other_create_mode') }
        </Button>
      ) }
    </SearchByLicencePlateForm>
  );
};

SearchByLicencePlateFormContainer.defaultProps = {
  cancel: null,
};

SearchByLicencePlateFormContainer.propTypes = {
  onCarSelect: PropTypes.func.isRequired,
  cancel:      PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    crKind: state.wizard.contract_request.risk_object.kind,
  };
};

export default connect(mapStateToProps)(SearchByLicencePlateFormContainer);
