import WizardStepperContext               from 'components/wizard_stepper/wizard_context';
import React, { useContext }              from 'react';
import { useSelector }                    from 'react-redux';
import { getAxaBuildingSteps }            from 'schemas/axa/building/axa_building_steps';
import { getViviumBuildingSteps }         from 'schemas/vivium/building/vivium_building_steps';
import StepList                           from 'components/wizard_stepper/step_list';
import { getProductsFromContractRequest } from 'models/wizard/constants';

const BuildingSteps = ({ matchPath }) => {
  const contractRequest = useSelector((state) => state.wizard.contract_request);
  const selectedProductSlug = getProductsFromContractRequest(contractRequest)[0].slug;
  const { recomputeTarifications } = useContext(WizardStepperContext);
  let steps = [];
  if (selectedProductSlug === 'axa-building') {
    steps = getAxaBuildingSteps(matchPath, contractRequest);
  } else if (selectedProductSlug === 'vivium-building' || selectedProductSlug === 'vivium-rental-building') {
    steps = getViviumBuildingSteps(matchPath, contractRequest, recomputeTarifications);
  }
  return <StepList steps={ steps } />;
};

export default BuildingSteps;
