import { getCompanyTypes } from 'models/company/constants';

export const getAxaCoOwnedSyndicateSchema = () => {
  return {
    form:   'building_co_owned_syndicate',
    schema: {
      co_owned_syndicate: {
        type:       'section',
        properties: {
          co_owned_syndicate_present: {
            default:       {
              value: true,
            },
            component:     'true_false',
            validations:   ['required'],
            label:         t('wizard.building.co_owned_syndicate.co_owned_syndicate_present'),
          },
          co_owned_syndicate:         {
            type:       'group',
            visible:    [
              {
                property:  'co_owned_syndicate.co_owned_syndicate_present',
                assertion: 'isNotFalse',
              },
            ],
            properties: {
              policy_owner_is_co_owned_syndicate: {
                component:     'true_false',
                validations:   ['required'],
                label:         t('wizard.building.co_owned_syndicate.policy_owner_is_co_owned_syndicate'),
                default:       {
                  value: true,
                },
                visibleOr:     [
                  {
                    property:  'policy_owner.company.company_type',
                    assertion: 'notEqual',
                    value:     'ACP',
                  },
                  {
                    property:  'policy_owner.kind',
                    assertion: 'equal',
                    value:     'person',
                  },
                ],
                onValueChange: {
                  prefill: {
                    prefillOnMount:           true,
                    from:                     {
                      store: 'contract_request.data.policy_owner',
                    },
                    changes:                  [
                      {
                        from: 'kind',
                        to:   'co_owned_syndicate.type',
                      },
                      {
                        from: 'company.name',
                        to:   'co_owned_syndicate.name',
                      },
                      {
                        from: 'person.first_name',
                        to:   'co_owned_syndicate.first_name',
                      },
                      {
                        from: 'person.last_name',
                        to:   'co_owned_syndicate.last_name',
                      },
                      {
                        from: 'person.email',
                        to:   'co_owned_syndicate.email',
                      },
                      {
                        from: 'company.email',
                        to:   'co_owned_syndicate.email',
                      },
                      {
                        from: 'person.phone_number',
                        to:   'co_owned_syndicate.phone_number',
                      },
                      {
                        from: 'company.phone_number',
                        to:   'co_owned_syndicate.phone_number',
                      },
                      {
                        from: 'company.vat_number',
                        to:   'co_owned_syndicate.vat_number',
                      },
                      {
                        from: 'person.address',
                        to:   'co_owned_syndicate.address',
                      },
                      {
                        from: 'company.address',
                        to:   'co_owned_syndicate.address',
                      },
                    ],
                    clearOnConditionUnfilled: true,
                    conditions:               [
                      {
                        operator:  'and',
                        assertion: 'isTrue',
                      },
                      {
                        operator:  'and',
                        property:  'co_owned_syndicate',
                        assertion: 'deepEqual',
                        value:     {
                          property: 'defaultValues.co_owned_syndicate',
                        },
                      },
                    ],
                  },
                },
              },
              type:                               {
                component:   'multi_button',
                validations: ['required'],
                label:       t('wizard.building.co_owned_syndicate.type'),
                list:        [
                  {
                    value: 'person',
                    label: t('general.policy_owner_type.PhysicalPolicyOwner'),
                  },
                  {
                    value: 'company',
                    label: t('general.policy_owner_type.CorporationPolicyOwner'),
                  },
                ],
                default:     {
                  value: 'company',
                },
              },
              name:                               {
                component:   'text',
                validations: ['required'],
                label:       t('clients.companies.form.name'),
                visible:     [
                  {
                    property:  'co_owned_syndicate.type',
                    assertion: 'equal',
                    value:     'company',
                  },
                ],
              },
              company_type:                       {
                component:   'radio_list',
                validations: ['required'],
                label:       t('clients.companies.form.company_type'),
                list:        getCompanyTypes(['ACP', 'ASBL', 'SA', 'SCA', 'SCRI', 'SCRL', 'SCS', 'SNC', 'SPRL', 'SPRL_S', 'SRL']),
                visible:     [
                  {
                    property:  'co_owned_syndicate.type',
                    assertion: 'equal',
                    value:     'company',
                  },
                ],
              },
              vat_number:                         {
                component: 'text',
                label:     t('company.label.optional_vat_number'),
                visible:   [
                  {
                    property:  'co_owned_syndicate.type',
                    assertion: 'equal',
                    value:     'company',
                  },
                ],
              },
              first_name:                         {
                component:   'text',
                validations: ['required'],
                label:       t('person.label.first_name'),
                visible:     [
                  {
                    property:  'co_owned_syndicate.type',
                    assertion: 'equal',
                    value:     'person',
                  },
                ],
              },
              last_name:                          {
                component:   'text',
                validations: ['required'],
                label:       t('person.label.last_name'),
                visible:     [
                  {
                    property:  'co_owned_syndicate.type',
                    assertion: 'equal',
                    value:     'person',
                  },
                ],
              },
              email:                              {
                component:   'text',
                symbol:      '@',
                validations: ['email'],
                label:       t('person.label.email_optional'),
              },
              phone_number:                       {
                component: 'text',
                label:     t('person.label.phone_number_optional'),
              },
              address:                            {
                label:                t('wizard.building.co_owned_syndicate.address_label'),
                component:            'address',
                inlineContainerClass: 'top',
                requiredFields:       {
                  street_name:   true,
                  street_number: true,
                  city:          true,
                  street_box:    true,
                  zip_code:      true,
                  letter:        false,
                },
              },
            },
          },
        },
      },
    },
  };
};
