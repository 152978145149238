import React                              from 'react';
import { useSelector }                    from 'react-redux';
import ProductLogo                        from 'components/tarifications/table/product_logo';
import { getProductsFromContractRequest } from 'models/wizard/constants';

const NavigationProductHeader = () => {
  const selectedProducts = useSelector((state) => getProductsFromContractRequest(state.wizard.contract_request));
  const displayProductHeader = selectedProducts.length === 1;
  if (!displayProductHeader) {
    return null;
  }
  const selectedProduct = selectedProducts[0];
  return (
    <div className="wizard-navigation-product-header">
      <ProductLogo product={ selectedProduct } />
      <span className="title">
        { selectedProduct.name }
      </span>
    </div>
  );
};

export default NavigationProductHeader;
