import React             from 'react';
import openDialogOnClick from 'components/ui/buttons/helpers/open_dialog_on_click';
import Button            from 'components/ui/buttons/button';
import DocumentsTable    from 'components/documents/table';

const OpenButton = ({ onClick, disabled, className, label, ...rest }) => {
  return (
    <Button
      outline
      small
      disabled={ disabled }
      className={ `mr-10 ${ className }` }
      data-purpose="print_selection_button"
      onClick={ () => {
        onClick();
      } }
      icon="print"
      { ...rest }
    >
      { label || t('documents.other_documents') }
    </Button>
  );
};
const PrintDialogButton = openDialogOnClick()(OpenButton);

const OpenDocumentTableInDialogButton = ({
  tarifications,
  contractRequestId,
  disabled,
  className,
  label,
  ...rest
}) => {
  return (
    <PrintDialogButton
      disabled={ (disabled || !tarifications.length) }
      dialogTitle={ t('documents.available_documents') }
      className={ className }
      label={ label }
      { ...rest }
    >
      <DocumentsTable
        tarifications={ tarifications }
        contractRequestId={ contractRequestId }
        noTitle
      />
    </PrintDialogButton>
  );
};

export default OpenDocumentTableInDialogButton;
