import React                                                                from 'react';
import LoadOnClickButton                                                    from 'components/ui/buttons/load_on_click_button';
import { useDispatch, useSelector }                                         from 'react-redux';
import { sendTarificationOffer }                                            from 'models/tarifications/requests';
import { updateTarification, setContractRequestAuthorizations }             from 'models/wizard/store_actions';
import { isSocketConnected }                                                from 'models/config/reducer';
import { fetchContractRequestAndUpdateInStore, setPollingForTarifications } from 'models/wizard/dispatch_actions';

const AskAxaOfferDocumentButton = ({ tarification, onDocumentAsked }) => {
  const isSocketUp = useSelector((state) => isSocketConnected(state));
  if (!tarification.authorizations.offer || tarification.computing) {
    return null;
  }

  const dispatch = useDispatch();
  const ask = () => {
    dispatch(setContractRequestAuthorizations({ update: false, destroy: false }));
    return sendTarificationOffer(tarification).then((tarificationSent) => {
      dispatch(updateTarification(tarificationSent));
      if (!isSocketUp) {
        dispatch(setPollingForTarifications([tarification], (updatedTarification) => {
          dispatch(updateTarification(updatedTarification));
          dispatch(fetchContractRequestAndUpdateInStore(updatedTarification.contract_request_id));
        }));
      }
      if (onDocumentAsked) {
        onDocumentAsked();
      }
    });
  };

  return (
    <LoadOnClickButton
      large
      primary
      onClick={ ask }
    >
      { t('axa.constructor.generate_documents') }
    </LoadOnClickButton>
  );
};

export default AskAxaOfferDocumentButton;
