import compact from "lodash/compact";

const createOptionsList = (translations) => {
  return Object.keys(translations).map((k) => {
    return {
      value: k,
      label: translations[k],
    };
  });
};

export const getLicenseWithdrawalDurations = () =>
  createOptionsList(t("driving_license_withdrawal.reference.durations"));

export const getLicenseWithdrawalReasons = () =>
  createOptionsList(t("driving_license_withdrawal.reference.reasons"));

export const getImpactedCovers = (filter = null) => {
  const impactedCovers = [
    {
      value: "assistance",
      label: t("covers.reference.assistance"),
    },
    {
      value: "broken_glass",
      label: t("covers.reference.broken_glass"),
    },
    {
      value: "damage",
      label: t("covers.reference.damage"),
    },
    {
      value: "driver",
      label: t("covers.reference.driver"),
    },
    {
      value: "fire",
      label: t("covers.reference.fire"),
    },
    {
      value: "legal_protection",
      label: t("covers.reference.legal_protection"),
    },
    {
      value: "other",
      label: t("covers.reference.other"),
    },
    {
      value: "passengers",
      label: t("covers.reference.passengers"),
    },
    {
      value: "r_c",
      label: t("covers.reference.r_c"),
    },
    {
      value: "theft",
      label: t("covers.reference.theft"),
    },
    {
      value: "natural_disaster",
      label: t("covers.reference.natural_disaster"),
    },
  ];
  if (filter) {
    return compact(
      impactedCovers.filter((cover) => {
        if (filter.find((item) => item === cover.value)) {
          return cover;
        }
      })
    );
  }
  return impactedCovers;
};

export const getResponsibilitiesOptions = () => {
  const options = [
    {
      value: "",
      label: t("providers.offers.show.offer_product.select_option"),
    },
    {
      value: "not_at_fault",
      label: t("responsibility.not_at_fault"),
    },
    {
      value: "at_fault",
      label: t("responsibility.at_fault"),
    },
    {
      value: "shared",
      label: t("responsibility.shared"),
    },
    {
      value: "unknown",
      label: t("responsibility.unknown"),
    },
  ];
  return options;
};

export const getAmountRanges = () => [
  {
    value: "< 1000€",
    label: "< 1000€",
  },
  {
    value: "1000€-2500€",
    label: "1000€-2500€",
  },
  {
    value: "2500€-5000€",
    label: "2500€-5000€",
  },
  {
    value: "> 5000€",
    label: "> 5000€",
  },
];

export const getInsuredYears = () => {
  const insuredYears = [];
  for (let i = 0; i <= 5; i++) {
    insuredYears.push({
      value: i,
      id: i,
      label: i,
    });
  }
  return insuredYears;
};
