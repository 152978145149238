import React, { useContext }      from 'react';
import SearchByLicencePlateButton from 'components/vehicle/search_by_licence_plate_button';
import JsonFormContext            from 'components/ui/json_form/json_form_context';

const SearchByPlateField = ({ change, prefix }) => {
  const { disabled } = useContext(JsonFormContext);
  const onCarByLicensePlate = (car) => {
    change(`${ prefix }.source`, 'informex');
    setTimeout(() => {
      change(`${ prefix }.febiac_genre`, car.genre);
      Object.keys(car).forEach((key) => {
        change(`${ prefix }.${ key }`, car[key]);
        log(`CHANGE ${ prefix }.${ key } by ${ car[key] }`);
      });
      change(`${ prefix }.adjusted_power`, car.power);
      change(`${ prefix }.source_data`, car);
    });
  };
  return (
    <div className="uk-flex uk-flex-right">
      <SearchByLicencePlateButton
        className="mb-20"
        disabled={ disabled }
        onCarSelect={ onCarByLicensePlate }
        data-purpose="search_by_plate_button"
      />
    </div>
  );
};

export default SearchByPlateField;
