import React, { useState, useEffect } from 'react';
import {
  DOCK_SELECTED_TARIFICATIONS,
  trackWizardStep,
}                                     from 'models/wizard/constants';
import { getTarificationsPdf }        from 'models/tarifications/requests';
import Button                         from 'components/ui/buttons/button';
import { useDispatch, useSelector }   from 'react-redux';
import { toggleDocumentLoading }      from 'models/documents/store_actions';
import { getBtnCrRef }                from 'models/documents/reducer';
import { GOT_DOCUMENT_RESPONSE }      from 'models/documents/constants';

const PdfButton = ({ onClick, disabled, className, label, purpose, ...rest }) => {
  return (
    <Button
      outline
      small
      disabled={ disabled }
      className={ className }
      data-purpose={ purpose || 'print_selection_button' }
      onClick={ onClick }
      secondary
      icon="docx"
      { ...rest }
    >
      { label || t('wizard.resume.get_pdf') }
    </Button>
  );
};

const GetPdfButton = ({ tarifications, contractRequest, productTemplate, inlineLoadingText, className }) => {
  const btnIsLoading = useSelector(state => state.documents[getBtnCrRef(contractRequest.id)]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const onGotDocument = (e) => {
    if (e.detail.contract_request_id === contractRequest.id) {
      setLoading(false);
    }
  };

  useEffect(() => {
    window.addEventListener(GOT_DOCUMENT_RESPONSE, onGotDocument);
    return () => {
      window.removeEventListener(GOT_DOCUMENT_RESPONSE, onGotDocument);
    };
  });

  if (productTemplate) {
    if (tarifications.length === 0 || tarifications.length > 1) {
      return null;
    }
    if (!tarifications[0].product.has_template) {
      return null;
    }
  }
  const print = () => {
    trackWizardStep(DOCK_SELECTED_TARIFICATIONS, {
      contract_request_id: contractRequest.id,
      tarification_ids:    tarifications.map(tarif => tarif.id),
      account_template:    true,
    });
    setLoading(true);
    return getTarificationsPdf(contractRequest.id, tarifications, productTemplate).then(() => {
      dispatch(toggleDocumentLoading(contractRequest.id, true));
    });
  };

  return (
    <span className={ inlineLoadingText ? 'uk-flex uk-flex-middle' : '' }>
      <PdfButton
        onClick={ print }
        loading={ loading || btnIsLoading }
        disabled={ loading || btnIsLoading || !tarifications.length }
        className={ className }
      />
      { (loading || btnIsLoading) && (
        <p className={ `small-italic mt-0 ${ inlineLoadingText ? 'm-0 ml-10' : 'mt-10' }` }>{ t('pdf.generating_message') }</p>
      ) }
    </span>
  );
};

export default GetPdfButton;
