import merge               from 'lodash/merge';
import { getCompanyTypes } from 'models/company/constants';

export const getCompanySchema = (company_for = 'policy_owner', extraProperties = {}, withRequiredFields = true) => {
  const rootRequiredField = `required_fields.${ company_for }`;
  return merge({
    form:   company_for,
    schema: {
      [company_for]: {
        type:       'section',
        properties: {
          name:                        {
            component:   'text',
            label:       t('clients.companies.form.name'),
            validations: ['required'],
          },
          company_type:                {
            component:   'radio_list',
            label:       t('clients.companies.form.company_type'),
            list:        getCompanyTypes(),
            validations: ['required'],
            visible:     withRequiredFields && {
              property: `${ rootRequiredField }.company_type`,
            },
          },
          vat_number:                  {
            component:   'text',
            label:       t('clients.companies.form.vat_number'),
            validations: ['required'],
            visible:     withRequiredFields && {
              property: `${ rootRequiredField }.vat_number`,
            },
          },
          phone_number:                {
            component:   'text',
            label:       t('clients.companies.form.phone_number'),
            validations: ['required'],
            visible:     withRequiredFields && {
              property: `${ rootRequiredField }.phone_number`,
            },
          },
          email:                       {
            component:   'text',
            label:       t('clients.companies.form.email'),
            validations: ['required_email'],
            visible:     withRequiredFields && {
              property: `${ rootRequiredField }.email`,
            },
          },
          employees_count:             {
            component:   'text',
            label:       t('clients.companies.form.employees_count'),
            validations: ['required'],
            visible:     withRequiredFields && {
              property: `${ rootRequiredField }.employees_count`,
            },
          },
          has_insurance_cancellations: {
            component:     'true_false',
            label:         t('clients.policy_owners.form.insurance_cancellations_in_last_5_years'),
            validations:   ['required'],
            visible:       withRequiredFields && {
              property: `${ rootRequiredField }.insurance_cancellations`,
            },
            default:       {
              value: false,
            },
            onValueChange: {
              set: [
                {
                  to:        `${ company_for }.company.insurance_cancellations`,
                  value:     null,
                  condition: {
                    assertion: 'isFalse',
                  },
                },
              ],
            },
          },
          insurance_cancellations:     {
            type:          'array',
            arrayType:     'table',
            label:         t('clients.policy_owners.form.insurance_cancellations_in_last_5_years'),
            array_actions: {
              max:          10,
              add_label:    t('clients.policy_owners.form.add_insurance_cancellation'),
              title:        t('clients.policy_owners.form.insurance_cancellations_in_last_5_years'),
              remove_label: t('general.actions.delete'),
            },
            properties:    {
              date:    {
                label:       t('clients.policy_owners.form.date'),
                component:   'date',
                validations: ['required', 'date_format'],
              },
              company: {
                label:       t('clients.policy_owners.form.company'),
                component:   'text',
                validations: ['required'],
              },
              reason:  {
                label:       t('clients.policy_owners.form.reason'),
                component:   'text',
                validations: ['required'],
              },
            },
            visible:       {
              property: `${ company_for }.company.has_insurance_cancellations`,
            },
          },
          address:                     {
            label:                t('activerecord.attributes.driver.person.address'),
            component:            'address',
            inlineContainerClass: 'top',
            requiredFields:       {
              street_name:   withRequiredFields ? `${ rootRequiredField }.street_name` : true,
              street_number: withRequiredFields ? `${ rootRequiredField }.street_number` : true,
              city:          withRequiredFields ? `${ rootRequiredField }.city` : true,
              street_box:    withRequiredFields ? `${ rootRequiredField }.street_box` : true,
              zip_code:      withRequiredFields ? `${ rootRequiredField }.zip_code` : true,
              letter:        withRequiredFields ? `${ rootRequiredField }.letter` : false,
            },
          },
        },
      },
    },
  }, extraProperties);
};
