import React, { useContext }                                   from 'react';
import { useSelector }                                         from 'react-redux';
import has                                                     from 'lodash/has';
import set                                                     from 'lodash/set';
import {
  canHaveSecondaryDriver,
  haveSelectedMainDriver,
  haveSelectedPolicyOwner,
  isNotLead,
  canCreateTarification,
  haveSelectedContract,
  haveSelectedOffer,
  haveSelectedVehicle,
  cannotCreateTarification,
  haveSignedOffer,
}                                                              from 'models/wizard_navigation/constants';
import GenericStep                                             from 'components/wizard_stepper/generic_step';
import { getPolicyOwnerSchema }                                from 'schemas/shared/policy_owner_schema';
import { getPersonSchema }                                     from 'schemas/mobility/person_schema';
import { getAxaConstructorVehicleSchema }                      from 'schemas/axa/constructor/axa_constructor_vehicle_schema';
import { getAxaConstructorContractSchema }                     from 'schemas/axa/constructor/axa_constructor_contract_schema';
import TarificationStep                                        from 'components/wizard/tarification_step';
import SendToBackofficeButton
                                                               from 'components/backoffice/send_to_backoffice_button';
import WizardContext                                           from 'components/wizard/wizard_context';
import { Link }                                                from 'react-router-dom';
import { getActivities, getAxaConstructorRecoverableRateList } from 'models/person/constants';
import { getAxaConstructorCompanyTypes }                       from 'models/company/constants';
import AxaConstructorAskOfferStep
                                                               from 'components/axa/constructor/axa_constructor_ask_offer_step';
import ContractDataStep                                        from 'components/wizard_stepper/contract_data_step';
import ContractStep                                            from 'components/wizard_stepper/contract_step';
import AxaConstructorSendToBackofficeStep
                                                               from 'components/axa/constructor/axa_constructor_send_to_backoffice_step';
import { getAxaPreviousCompanySchema }                         from 'schemas/axa/constructor/axa_previous_company_schema';
import { getAxaConstructorMainDriverSchema }                   from 'schemas/axa/constructor/axa_constructor_main_driver_schema';

export const getAxaConstructorPolicyOwnerSchema = () => {
  return getPolicyOwnerSchema({
    schema: {
      policy_owner: {
        properties: {
          vehicle_change:          {
            component: 'true_false',
            label:     t('schemas.axa.constructor.axa_constructor_steps.vehicle_change'),
            default:   {
              value: false,
            },
          },
          actual_contract_number:  {
            component:   'text',
            label:       t('schemas.axa.constructor.axa_constructor_steps.contract_number'),
            validations: ['required', 'axaConstructorContractLength'],
            visible:     {
              property:  'policy_owner.vehicle_change',
              assertion: 'isTrue',
            },
            tooltip:     t('axa.constructor.actual_contract_number_tooltip'),
          },
          vat_title:               {
            type:  'title',
            title: t('wizard.policy_owner.recoverable_vat_title'),
          },
          recoverable_vat:         {
            label:       t('clients.typed_contract_requests.usage_modal.recoverable_vat'),
            validations: ['required'],
            component:   'true_false',
            default:     {
              value: false,
            },
          },
          recoverable_rate:        {
            component:   'select',
            label:       t('clients.typed_contract_requests.usage_modal.recoverable_rate'),
            validations: ['required'],
            list:        getAxaConstructorRecoverableRateList(),
            visible:     {
              property: 'policy_owner.recoverable_vat',
            },
          },
          manual_recoverable_rate: {
            component:   'text',
            label:       t('schemas.axa.constructor.axa_constructor_steps.manual_recoverable_rate'),
            validations: ['required'],
            symbol:      '%',
            visible:     {
              property:  'policy_owner.recoverable_rate',
              assertion: 'equal',
              value:     'VAT_recoverable_<_50%',
            },
          },
          company:                 {
            properties: {
              name:         {
                validations: ['required', 'axaConstructorCompanyNameValidation'],
              },
              activity:     {
                label:       t('activity_sector.label'),
                component:   'select',
                list:        getActivities(),
                validations: ['required'],
              },
              company_type: {
                component: 'select',
                list:      getAxaConstructorCompanyTypes(),
              },
            },
          },
        },
      },
    },
  });
};

export const getAxaConstructorSecondaryDriverSchema = () => {
  return getPersonSchema('secondary_driver');
};

export const getConstructorSteps = (matchPath, contractRequest, recomputeTarifications) => {
  let policyOwnerSchema = set(getAxaConstructorPolicyOwnerSchema(), 'schema.policy_owner.properties_order', [
    'search_in_bms',
    'locale',
    'vehicle_change',
    'actual_contract_number',
  ]);
  policyOwnerSchema = set(policyOwnerSchema, 'schema.policy_owner.properties.company.properties.vat_number.validations', ['required', 'only_int', 'minLength10', 'maxLength10']);
  return [
    {
      label: t('wizard.navigation.about_contract_request'),
      icon:  'file',
      slug:  'contract_request',
      kind:  'group',
      order: 1,
      steps: [
        {
          path:           `${ matchPath }/policy_owner`,
          title:          t('wizard.policy_owner.title'),
          label:          t('wizard.navigation.policy_owner'),
          icon:           'company',
          Component:      GenericStep,
          purpose:        'policy_owner_step',
          componentProps: {
            ...policyOwnerSchema,
            onNextStep: {
              recomputeTarifications,
            },
          },
          order:          1,
        },
        {
          path:           `${ matchPath }/main_driver`,
          label:          t('wizard.navigation.main_driver'),
          title:          t('wizard.main_driver.title'),
          warningTooltip: 'main_driver',
          purpose:        'main_driver_step',
          disabledRules:  [
            haveSelectedPolicyOwner,
          ],
          icon:           'driver',
          Component:      GenericStep,
          componentProps: {
            ...getAxaConstructorMainDriverSchema(),
            onNextStep: {
              recomputeTarifications,
            },
          },
          order:          2,
        },
        {
          path:           `${ matchPath }/secondary_driver`,
          label:          t('schemas.axa.constructor.axa_constructor_steps.secondary_driver'),
          title:          t('schemas.axa.constructor.axa_constructor_steps.secondary_driver'),
          warningTooltip: 'secondary_driver',
          purpose:        'secondary_driver_step',
          disabledRules:  [
            haveSelectedPolicyOwner,
            haveSelectedMainDriver,
          ],
          displayRules:   [
            canHaveSecondaryDriver,
          ],
          icon:           'driver',
          Component:      GenericStep,
          componentProps: {
            ...getAxaConstructorSecondaryDriverSchema(),
            onNextStep: {
              recomputeTarifications,
            },
          },
          order:          3,
        },
        {
          path:           `${ matchPath }/vehicle`,
          label:          t('wizard.navigation.vehicle'),
          title:          t('wizard.car.title'),
          purpose:        'vehicle_step',
          disabledRules:  [
            haveSelectedPolicyOwner,
            haveSelectedMainDriver,
          ],
          icon:           'car',
          Component:      GenericStep,
          componentProps: {
            ...getAxaConstructorVehicleSchema(),
            onNextStep: {
              recomputeTarifications,
            },
          },
          order:          4,
        },
      ],
    },
    {
      path:           `${ matchPath }/send_to_backoffice`,
      label:          t('schemas.axa.constructor.axa_constructor_steps.send_to_backoffice'),
      title:          t('schemas.axa.constructor.axa_constructor_steps.send_to_backoffice'),
      purpose:        'send_to_backoffice_step',
      disabledRules:  [
        haveSelectedPolicyOwner,
        haveSelectedMainDriver,
        haveSelectedVehicle,
      ],
      displayRules:   [
        cannotCreateTarification,
      ],
      icon:           'assistance',
      Component:      AxaConstructorSendToBackofficeStep,
      order:          2,
      StepMainAction: () => {
        return (
          <Link
            className="wizard-button primary"
            to="/folders"
            data-purpose="finish_button"
          >
            { t('general.actions.finish') }
          </Link>
        );
      },
    },
    has(contractRequest, 'tarifications[0]') && {
      path:          `${ matchPath }/tarification_${ contractRequest.tarifications[0].id }`,
      title:         t('schemas.axa.constructor.axa_constructor_steps.offer_configuration'),
      order:         3,
      disabledRules: [
        haveSelectedPolicyOwner,
        haveSelectedMainDriver,
        haveSelectedVehicle,
      ],
      displayRules:  [
        canCreateTarification,
      ],
      label:         t('schemas.axa.constructor.axa_constructor_steps.offer_configuration'),
      icon:          'product-parameters',
      error:         contractRequest.tarifications[0].last_error_response,
      loading:       contractRequest.tarifications[0].computing,
      purpose:       'axa_constructor_tarification_step',
      Component:     (props) => {
        return (
          <TarificationStep { ...props } tarificationId={ contractRequest.tarifications[0].id } />
        );
      },
    },
    {
      order:          4,
      path:           `${ matchPath }/previous_company`,
      title:          t('schemas.axa.constructor.axa_constructor_steps.previous_company'),
      disabledRules:  [
        haveSelectedPolicyOwner,
        haveSelectedMainDriver,
        haveSelectedVehicle,
      ],
      displayRules:   [
        isNotLead,
        canCreateTarification,
      ],
      label:          t('schemas.axa.constructor.axa_constructor_steps.previous_company'),
      icon:           'folder',
      purpose:        'previous_company',
      loading:        contractRequest.tarifications[0].computing,
      Component:      GenericStep,
      componentProps: {
        ...getAxaPreviousCompanySchema(),
        disableStep:   !contractRequest.tarifications[0].authorizations.offer,
        stepFor:       'offer',
        initialValues: has(contractRequest, 'tarifications[0].offer.data') ? contractRequest.tarifications[0].offer.data : {}
      },
    },
    {
      order:          5,
      path:           `${ matchPath }/ask_offer`,
      title:          t('axa.constructor.navigation.offer'),
      disabledRules:  [
        haveSelectedPolicyOwner,
        haveSelectedMainDriver,
        haveSelectedVehicle,
      ],
      displayRules:   [
        isNotLead,
        canCreateTarification,
      ],
      label:          t('axa.constructor.navigation.offer'),
      icon:           'folder',
      purpose:        'offer_step',
      loading:        contractRequest.tarifications[0].computing,
      Component:      AxaConstructorAskOfferStep,
      StepMainAction: () => {
        const contractRequest = useSelector((state) => state.wizard.contract_request);
        const { toggleStepMainActionAvailable } = useContext(WizardContext);
        if ((!contractRequest.authorizations.create_tarification && !contractRequest.tarifications[0].sent_to_backoffice) || contractRequest.tarifications[0].authorizations.offer) {
          toggleStepMainActionAvailable(false);
          return null;
        }
        return (
          <React.Fragment>
            <SendToBackofficeButton
              tarification={ contractRequest.tarifications[0] }
              contractRequest={ contractRequest }
            />
          </React.Fragment>
        );
      },
    },
    {
      order:          6,
      path:           `${ matchPath }/contract_data`,
      title:          t('schemas.axa.constructor.axa_constructor_steps.contract_data'),
      disabledRules:  [
        haveSelectedPolicyOwner,
        haveSelectedMainDriver,
        haveSelectedVehicle,
        haveSelectedOffer,
        haveSignedOffer,
      ],
      displayRules:   [
        isNotLead,
        canCreateTarification,
      ],
      label:          t('schemas.axa.constructor.axa_constructor_steps.contract_data'),
      icon:           'contract',
      purpose:        'contract_data_step',
      Component:      ContractDataStep,
      loading:        contractRequest.tarifications[0].computing,
      componentProps: {
        tarificationId: contractRequest.tarifications[0].id,
        ...getAxaConstructorContractSchema(),
      },
    },
    {
      order:          7,
      path:           `${ matchPath }/ask_contract`,
      title:          t('schemas.axa.constructor.axa_constructor_steps.ask_contract'),
      disabledRules:  [
        haveSelectedPolicyOwner,
        haveSelectedMainDriver,
        haveSelectedVehicle,
        haveSelectedOffer,
        haveSignedOffer,
        haveSelectedContract,
      ],
      displayRules:   [
        isNotLead,
        canCreateTarification,
      ],
      label:          t('schemas.axa.constructor.axa_constructor_steps.ask_contract'),
      icon:           'contract',
      purpose:        'contract_step',
      loading:        contractRequest.tarifications[0].computing,
      Component:      ContractStep,
      componentProps: {
        tarificationId: contractRequest.tarifications[0].id,
      },
    },
  ];
};

export default getConstructorSteps;
