import React               from 'react';
import { connect }         from 'react-redux';
import moment              from 'moment';
import has                 from 'lodash/has';
import Icon                from 'components/ui/icon';
import openInDrawer        from 'components/ui/drawer/helpers/open_in_drawer';
import SearchInBMSForm     from 'components/bms/search_in_bms_form';
import { trackEvent }      from 'services/segment';
import { BMS_BUTTON_USED } from 'models/wizard/constants';
import { isCrLead }        from 'models/contract_request/constants';

const SearchInBMSDrawer = openInDrawer()(SearchInBMSForm);

const SearchInBMSButton = ({ onResultSelect, className, canSearchInBMS, dispatch, ...restProps }) => {

  if (!canSearchInBMS) {
    return null;
  }

  return (
    <SearchInBMSDrawer
      className="uk-width-2-5"
      orientation="right"
      onResultSelect={ (result) => {
        const { street_name, street_number, street_box, zip_code, country, city, date_of_birth, car_license_obtention_at, ...rest } = result;
        trackEvent(BMS_BUTTON_USED);
        onResultSelect({
          ['address.street_name']:   street_name,
          ['address.street_number']: street_number,
          ['address.street_box']:    street_box,
          ['address.zip_code']:      zip_code,
          ['address.city']:          city,
          ['address.country']:       country,
          date_of_birth,
          car_license_obtention_at,
          ...rest,
        });
        window.closeDrawer && window.closeDrawer({});
      } }
    >
      <button
        type="button"
        className={ `uk-button button-search small ${ className }` }
        data-purpose="search_in_bms_button"
        { ...restProps }
      >
        <Icon icon="search" />
        <span className="text">
          { t('wizard.bms.search_in_bms') }
        </span>
      </button>
    </SearchInBMSDrawer>
  );
};

const mapStateToProps = (state) => {
  return {
    canSearchInBMS: has(state, 'current_user.profile.authorizations.has_bms_import') ? (state.current_user.profile.authorizations.has_bms_import && !isCrLead(state.wizard.contract_request)) : false,
  };
};

export default connect(mapStateToProps)(SearchInBMSButton);
