import React             from 'react';
import merge             from 'lodash/merge';
import translationsFr    from 'locales/fr.yml';
import translationsFrNew from 'locales/new.fr.yml';
import localizationsFr   from 'locales/localization.fr.yml';
import translationsNl    from 'locales/translation.nl.yml';
import localizationsNl   from 'locales/localization.nl.yml';
import Moment            from 'react-moment';
import { getCookie }     from 'services/cookies';

export const setI18nLocale = (locale) => {
  I18n.locale = locale;
  Moment.globalLocale = I18n.locale;
};

export const setI18nTranslations = () => (
  new Promise((resolve) => {
    I18n.translations = getTranslations();
    resolve();
  })
);

export const t = (text, params) => I18n.t(text, { ...params });

export const t_html = (text, params) => {
  const trusted = {
    __html: I18n.t(text, { ...params }),
  };
  return <span dangerouslySetInnerHTML={ trusted } />;
};

export const getCurrentLocale = () => (
  I18n.locale
);

export const getTranslations = () => (
  merge(
    translationsFr,
    translationsFrNew,
    localizationsFr,
    translationsNl,
    localizationsNl,
  )
);

export const init = () => {
  return new Promise((resolve) => {
    I18n.translations = getTranslations();
    I18n.defaultLocale = getCookie('user_locale') || 'nl';
    I18n.locale = getCookie('user_locale') || 'nl';
    resolve();
  });
};
