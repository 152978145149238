import update         from 'immutability-helper';
import compact        from 'lodash/compact';
import { moreRecent } from 'models/tarifications/constants';

const defaultState = {
  steps:       [],
  groups:      [],
  navigation:  [],
  active_step: null,
};


const wizardStepperReducer = (state = defaultState, action) => {
  switch (action.type) {
    case 'WIZARD_STEPPER/SET_ACTIVE_STEP':
      return update(state, {
        active_step: { $set: action.step },
      });

    case 'WIZARD_STEPPER/CLEAN':
      return defaultState;

    case 'WIZARD_STEPPER/SET_NAVIGATION':
      if (state.navigation.length > 0) {
        const actualTarificationsStep = state.navigation.find(item => item.slug === 'tarifications');
        const newNavigation = action.navigation.map((item) => {
          if (item.slug === 'tarifications' && actualTarificationsStep) {
            item.steps = compact(item.steps.map((sub) => {
              const subInActual = actualTarificationsStep.steps.find(actualSub => actualSub.path === sub.path);
              if (!subInActual) {
                return sub;
              }
              if (moreRecent(subInActual.tarification_data, sub.tarification_data)) {
                return sub;
              } else {
                return subInActual;
              }
            }));
            return item;
          } else {
            return item;
          }
        });
        return update(state, {
          navigation: { $set: compact(newNavigation) },
        });
      }

      return update(state, {
        navigation: { $set: action.navigation },
      });
    default:
      return state;
  }
};

export default wizardStepperReducer;
