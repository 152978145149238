import React           from 'react';
import { convertLead } from 'models/leads/request';
import { withRouter }  from 'react-router';
import Button          from 'components/ui/buttons/button';

const ConvertLeadButton = ({ leadId, history }) => {
  const convert = (ev) => {
    ev.preventDefault();
    return convertLead(leadId).then((contractRequest) => {
      history.push(`/folders/${ contractRequest.id }/tarification_${ contractRequest.tarifications[0].id }`);
    });
  };
  return (
    <Button
      small
      primary
      onClick={ convert }
    >
      { t('leads.convert_to_cr') }
    </Button>
  );
};

export default withRouter(ConvertLeadButton);
