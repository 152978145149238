export const viviumHousingSchema = () => ({
  form:   'vivium_housing',
  schema: {
    housing: {
      type:       'section',
      properties: {
        same_address_than_policy_owner: {
          component:     'true_false',
          label:         'Même adresse que celle du preneur',
          onValueChange: {
            prefill: {
              prefillOnMount:           true,
              from:                     {
                store: 'policy_owner',
              },
              changes:                  [
                {
                  from: 'company.address',
                  to:   'housing.address',
                },
                {
                  from: 'person.address',
                  to:   'housing.address',
                },
              ],
              clearOnConditionUnfilled: true,
              condition:                {
                assertion: 'isTrue',
              },
            },
          },
          default:       {
            value: true,
          },
        },
        alarm_system:             {
          label:       t('wizard.housing.alarm_system'),
          component:   'select',
          validations: [
            'required',
          ],
          list:        [],
        },
        under_construction:       {
          label:       t('wizard.housing.under_construction'),
          component:   'true_false',
          validations: [
            'required',
          ],
        },
        floor:                    {
          label:       t('wizard.housing.floor'),
          component:   'text',
          type:        'number',
          min:         0,
          step:        1,
          validations: [
            'required',
          ],
        },
        rent:                     {
          label:       t('wizard.housing.rent'),
          component:   'text',
          type:        'number',
          symbol:      '€',
          validations: [
            'required',
          ],
        },
        room_count:                    {
          label:       t('wizard.housing.room_count'),
          component:   'text',
          type:        'number',
          min:         0,
          step:        1,
          validations: [
            'required',
          ],
        },
        cellar:       {
          label:       t('wizard.housing.cellar'),
          component:   'true_false',
          validations: [
            'required',
          ],
        },
        armored_door:       {
          label:       t('wizard.housing.armored_door'),
          component:   'true_false',
          validations: [
            'required',
          ],
        },
        address:                  {
          label:          'Adresse',
          component:      'address',
          formFor:        'address',
          displayMap:     true,
          requiredFields: {
            street_name:   true,
            street_number: true,
            city:          true,
            street_box:    true,
            zip_code:      true,
          },
        },
      },
    },
  },
});
