import React                             from 'react';
import { useSelector }                   from 'react-redux';
import Icon                              from 'components/ui/icon';
import openInDrawer                      from 'components/ui/drawer/helpers/open_in_drawer';
import SearchByLicencePlateFormContainer from 'components/vehicle/search_by_licence_plate_form_container';
import { DEVELOPMENT }                   from 'constants/application_constants';
import Button                            from 'components/ui/buttons/button';
import { newIntercomMessage }            from 'services/intercom';
import { trackEvent }                    from 'services/segment';

const SearchByLicencePlateContainer = ({ ...restProps }) => {
  const canSearchByPlate = useSelector((state) => {
    return state.current_user.profile.authorizations.search_car_by_plate;
  });
  if (!canSearchByPlate) {
    return (
      <div data-purpose="ask_for_informex_integration">
        <p>
          { t_html('clients.typed_contract_requests.car_selection_modal.ask_for_informex_description_html') }
        </p>
        { !DEVELOPMENT && (
          <p className="uk-text-center mt-20">
            <Button
              primary
              data-purpose="send_intercom_message"
              onClick={ newIntercomMessage }
              icon="message"
            >
              { t('general.actions.contact_us') }
            </Button>
          </p>
        ) }
      </div>
    );
  }
  return (
    <SearchByLicencePlateFormContainer { ...restProps } />
  );
};

const SearchByLicensePlateDrawer = openInDrawer()(SearchByLicencePlateContainer);

const SearchByLicencePlateButton = ({ onCarSelect, className, ...restProps }) => {

  return (
    <SearchByLicensePlateDrawer
      className="uk-width-2-5"
      orientation="right"
      onCarSelect={ (car) => {
        onCarSelect(car);
        trackEvent('InformexLicensePlateSearchUsed', { car });
        window.closeDrawer && window.closeDrawer();
      } }
    >
      <button
        className={ `uk-button button-search small ${ className }` }
        data-purpose="search_by_plate_button"
        { ...restProps }
      >
        <Icon icon="search" />
        <span className="text">
          { t('wizard.car.search_by_vin_number') }
        </span>
      </button>
    </SearchByLicensePlateDrawer>);
};


export default SearchByLicencePlateButton;
