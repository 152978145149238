export const getAxaConstructorOccupationList = () => {
  return [
    {
      value: 'Civil_servant',
      label: t('models.axa.constants.axa_constructor_constants.civil_servant'),
    },
    {
      value: 'Teacher',
      label: t('models.axa.constants.axa_constructor_constants.teacher'),
    },
    {
      value: 'Employee',
      label: t('models.axa.constants.axa_constructor_constants.employee'),
    },
    {
      value: 'Independent',
      label: t('models.axa.constants.axa_constructor_constants.independent'),
    },
    {
      value: 'Retired',
      label: t('models.axa.constants.axa_constructor_constants.retired'),
    },
    {
      value: 'Other_occupation',
      label: t('models.axa.constants.axa_constructor_constants.other_occupation'),
    },
  ];
};
