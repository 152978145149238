import React                      from 'react';
import { useSelector }   from 'react-redux';
import { Redirect, useLocation }  from 'react-router-dom';
import { getCookie, setCookie }   from 'services/cookies';
import { REDIRECT_AFTER_SIGN_IN } from 'constants/application_constants';

const RedirectOnSignInPage = () => {
  const location = useLocation();
  setCookie(REDIRECT_AFTER_SIGN_IN, location.pathname);
  const { available_locales } = useSelector((state) => state.config);
  const publicPageLocale = getCookie('public_page_locale');
  let localeToUse = null;
  if (publicPageLocale && publicPageLocale !== 'undefined') {
    localeToUse = publicPageLocale;
  }
  if (!localeToUse) {
    const navigatorLocale = navigator.language.match(/^[a-z]{2}/)[0];
    localeToUse = available_locales[navigatorLocale] ? navigatorLocale : 'nl';
  }
  const pathToRedirect = `/${ localeToUse }/user/sign_in`;

  if (pathToRedirect) {
    return <Redirect to={ pathToRedirect } />;
  } else {
    return null;
  }
};


export default RedirectOnSignInPage;
