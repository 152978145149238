import React     from 'react';
import PropTypes from 'prop-types';
import Icon      from 'components/ui/icon/index';

const Pagination = ({ pagination: { total_pages, current_page }, current_page_gap, onPageChange, currentPage, className, ...rest }) => {
  if (total_pages === 1) {
    return null;
  }
  const pages = [];
  if (((current_page + current_page_gap) > total_pages) && ((current_page - current_page_gap) <= 0)) {
    for (let i = 1; i < current_page; i++) {
      if (i !== current_page) {
        pages.push(i);
      }
    }
    pages.push(current_page);
    for (let j = current_page; j <= total_pages; j++) {
      if (j !== current_page) {
        pages.push(j);
      }
    }
  } else {
    for (let i = current_page - current_page_gap; i < current_page; i++) {
      if (i > 0) {
        pages.push(i);
      }
    }
    pages.push(current_page);
    for (let j = current_page + 1; j <= current_page + current_page_gap; j++) {
      if (j !== current_page && j <= total_pages) {
        pages.push(j);
      }
    }
  }
  const previousPage = current_page - 1;
  const nextPage = current_page + 1;
  const canPreviousPage = (previousPage) > 0;
  const canNextPage = nextPage <= total_pages;
  const canLastPage = current_page < total_pages;
  const canFirstPage = current_page > 1;

  return (
    <div className="uk-flex uk-flex-center mt-20 mb-20">
      <ul className={ `uk-pagination ob-pagination ${ className }` } { ...rest }>
        <li disabled={ !canFirstPage } className={ !canFirstPage ? 'uk-disabled' : '' }>
          <a onClick={ () => canFirstPage && onPageChange(1) }>
            <Icon icon="arrow-previous" />
          </a>
        </li>
        <li disabled={ !canPreviousPage } className={ !canPreviousPage ? 'uk-disabled' : '' }>
          <a onClick={ () => canPreviousPage && onPageChange(previousPage) }>
            <Icon icon="arrow-left" />
          </a>
        </li>
        {
          pages.map((page, index) => (
            <li
              key={ index }
              className={ page === current_page ? 'uk-active' : '' }
            >
              <a onClick={ () => onPageChange(page) }>{ page }</a>
            </li>
          ))
        }
        <li disabled={ !canNextPage } className={ !canNextPage ? 'uk-disabled' : '' }>
          <a onClick={ () => canNextPage && onPageChange(nextPage) }>
            <Icon icon="arrow-right" />
          </a>
        </li>
        <li disabled={ !canLastPage } className={ !canLastPage ? 'uk-disabled' : '' }>
          <a onClick={ () => canLastPage && onPageChange(total_pages) }>
            <Icon icon="arrow-next" />
          </a>
        </li>
      </ul>
    </div>
  );
};

Pagination.defaultProps = {
  current_page_gap: 3,
};

Pagination.propTypes = {
  pagination:       PropTypes.shape({
    current_page: PropTypes.number.isRequired,
    total_pages:  PropTypes.number.isRequired,
    size:         PropTypes.number,
    total_count:  PropTypes.number,
  }).isRequired,
  onPageChange:     PropTypes.func.isRequired,
  current_page_gap: PropTypes.number,
};

export default Pagination;
