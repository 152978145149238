export const getAxaEvaluationSchema = () => {
  return {
    evaluation: {
      type:       'section',
      title:      t('wizard.building.evaluation.title'),
      properties: {
        evaluation_type: {
          component:   'select',
          validations: ['required'],
          label:       t('wizard.building.evaluation.type'),
          list:        [
            {
              value: 'Unlimited',
              label: t('wizard.building.evaluation.types.unlimited'),
            },
            {
              value: '1st_risk',
              label: t('wizard.building.evaluation.types.first_risk'),
            },
            {
              value: 'Condition_of_average',
              label: t('wizard.building.evaluation.types.condition_of_average'),
            },
          ],
        },
        sub_type:        {
          component:   'select',
          label:       t('wizard.building.evaluation.sub_type'),
          validations: ['required'],
          visible:     [
            {
              property:  'building.evaluation.evaluation_type',
              assertion: 'exists',
            },
            {
              property:  'building.evaluation.evaluation_type',
              assertion: 'notEqual',
              value:     'Condition_of_average',
            },
          ],
          list:        [
            {
              value: 'Grid',
              label: t('wizard.building.evaluation.sub_types.grid'),
            },
            {
              value: 'Assessment',
              label: t('wizard.building.evaluation.sub_types.assessment'),
            },
            {
              value:   'Specifications',
              label:   t('wizard.building.evaluation.sub_types.specifications'),
              visible: {
                property:  'building.evaluation.evaluation_type',
                assertion: 'notEqual',
                value:     '1st_risk',
              },
            },
          ],
        },
      },
    },
  };
};
