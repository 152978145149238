export const getAxaBuildingUseSchema = () => {
  return {
    use: {
      type:       'section',
      title:      t('wizard.building.use.title'),
      properties: {
        residential_use:      {
          component:   'multi_button',
          validations: ['required'],
          label:       t('wizard.building.use.residential_use'),
          list:        [
            {
              value: 'Residential',
              label: t('wizard.building.use.residential_use_options.residential'),
            },
            {
              value: 'Mixed',
              label: t('wizard.building.use.residential_use_options.mixed'),
            },
          ],
        },
        is_office_use:        {
          component:     'true_false',
          validations:   ['required'],
          label:         t('wizard.building.use.is_office_use'),
          default:       {
            value: false,
          },
          visible:       {
            property:  'building.use.residential_use',
            assertion: 'equal',
            value:     'Mixed',
          },
          tooltip:       t('wizard.building.use.is_office_use_tooltip_html'),
          onValueChange: {
            set: [
              {
                to:        'building.use.office_use_details[0]',
                value:     {},
                condition: {
                  assertion: 'isTrue',
                },
              },
              {
                to:        'building.use.office_use_details',
                value:     null,
                condition: {
                  assertion: 'isFalse',
                },
              },
            ],
          },
        },
        office_use_details:   {
          type:          'array',
          label:         '',
          arrayType:     'table',
          array_actions: {
            max:          10,
            add_label:    t('wizard.building.use.office_use.add'),
            title:        t('wizard.building.use.office_use.office'),
            remove_label: t('general.actions.delete'),
            empty_label:  t('wizard.building.use.office_use.empty'),
          },
          visible:       {
            property:  'building.use.is_office_use',
            assertion: 'isTrue',
          },
          properties:    {
            name:    {
              validations: ['required', 'only_letter_and_space'],
              label:       t('wizard.building.use.office_use.name'),
              component:   'text',
            },
            surface: {
              validations:   ['required', 'only_int'],
              label:         t('wizard.building.use.office_use.surface'),
              component:     'text',
              symbol:        'm²',
              type:          'number',
              onValueChange: {
                sum: {
                  from: 'building.use.office_use_details[].surface',
                  to:   'building.grid.surface_office',
                },
              },
            },
          },
        },
        is_business_use:      {
          component:     'true_false',
          validations:   ['required'],
          label:         t('wizard.building.use.is_business_use'),
          default:       {
            value: false,
          },
          visible:       {
            property:  'building.use.residential_use',
            assertion: 'equal',
            value:     'Mixed',
          },
          tooltip:       t('wizard.building.use.is_business_use_tooltip'),
          onValueChange: {
            set: [
              {
                to:        'building.use.business_use_details[0]',
                value:     {
                  is_horeca:   false,
                  is_deep_fat: false,
                  is_hot_food: false,
                },
                condition: {
                  assertion: 'isTrue',
                },
              },
              {
                to:        'building.use.business_use_details',
                value:     null,
                condition: {
                  assertion: 'isFalse',
                },
              },
            ],
          },
        },
        business_use_details: {
          type:          'array',
          label:         '',
          arrayType:     'table',
          array_actions: {
            max:          10,
            add_label:    t('wizard.building.use.building_use.add'),
            title:        t('wizard.building.use.building_use.business'),
            remove_label: t('general.actions.delete'),
            empty_label:  t('wizard.building.use.building_use.empty'),
          },
          visible:       {
            property:  'building.use.is_business_use',
            assertion: 'isTrue',
          },
          properties:    {
            name:        {
              validations: ['required', 'only_letter_and_space'],
              label:       t('wizard.building.use.building_use.name'),
              component:   'text',
            },
            surface:     {
              validations:   ['required', 'only_int'],
              label:         t('wizard.building.use.building_use.surface'),
              symbol:        'm²',
              component:     'text',
              type:          'number',
              onValueChange: {
                sum: {
                  from: 'building.use.business_use_details[].surface',
                  to:   'building.grid.surface_commercial',
                },
              },
            },
            is_horeca:   {
              label:     t('wizard.building.use.building_use.is_horeca'),
              component: 'checkbox',
              default:   {
                value: false,
              },
            },
            is_deep_fat: {
              label:     t('wizard.building.use.building_use.is_deep_fat'),
              component: 'checkbox',
              disabled:  {
                property:  'building.use.arrayFieldName.is_horeca',
                assertion: 'isTrue',
              },
              default:   {
                value: false,
              },
            },
            is_hot_food: {
              label:     t('wizard.building.use.building_use.is_hot_food'),
              component: 'checkbox',
              disabled:  {
                property:  'building.use.arrayFieldName.is_horeca',
                assertion: 'isTrue',
              },
              default:   {
                value: false,
              },
            },
          },
        },
        business_percentage:  {
          component:   'multi_button',
          validations: ['required'],
          label:       t('wizard.building.use.business_percentage'),
          visible:     {
            property:  'building.use.is_business_use',
            assertion: 'isTrue',
          },
          list:        [
            {
              value: '<10',
              label: '< 10 %',
            },
            {
              value: '<20',
              label: '< 20 %',
            },
            {
              value: '>=20',
              label: '>= 20 %',
            },
            {
              value: '>50',
              label: '> 50 %',
            },
          ],
        },
      },
    },
  };
};
