import React                     from 'react';
import classNames                from 'classnames';
import FormErrorMessage          from 'components/ui/form/form_error_message';
import { fieldWrapper }          from 'components/ui/json_form/fields/field_wrapper';
import { withoutJsonFieldProps } from 'components/ui/json_form/fields/constants';

const TextArea = ({
  input: {
           onChange,
           ...restInput
         },
  label,
  placeholder,
  meta = {},
  id,
  disabled,
  purpose,
  showLabel,
  className,
  containerClass,
  fieldAddition,
  ...    rest
}) => {
  const { asyncValidating, ...restMeta } = meta;
  return (
    <React.Fragment>
      <div
        className={
          classNames('input-container', {
            [containerClass]: !!containerClass,
          })
        }
      >
        { label && showLabel && (
          <label htmlFor={ id }>{ label }</label>
        ) }
        <textarea
          id={ id }
          className={ classNames('uk-textarea uk-form-small', {
            'uk-form-danger': meta.touched && meta.error,
            'input--warning': meta.touched && meta.warning,
            [className]:      !!className,
          }) }
          onChange={ onChange }
          { ...restInput }
          placeholder={ placeholder || label }
          data-purpose={ purpose }
          disabled={ disabled || asyncValidating }
          { ...withoutJsonFieldProps(rest) }
        />
        <FormErrorMessage { ...restMeta } name={ restInput.name } label={ label } />
        { fieldAddition && fieldAddition }
      </div>
    </React.Fragment>
  );

};

export default fieldWrapper()(TextArea);
