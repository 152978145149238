import Api from 'distributor/services/api';

export const getConfig = () => {
  return new Promise((resolve, reject) => {
    Api.get('/config').then((response) => {
      resolve(response.data);
    }).catch((error) => {
      reject(error);
    });
  });
};

export default getConfig;
