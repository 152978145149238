import React      from 'react';
import { Helmet } from 'react-helmet';
import { ENV, RECORDING }    from 'constants/application_constants';

const EnvironmentBanner = () => {
  const displayBanner = (ENV !== 'production' && ENV !== 'cypress');
  if (!displayBanner || RECORDING) {
    return null;
  }
  return (
    <React.Fragment>
      <Helmet>
        <body data-env-banner="has--environment-banner" />
      </Helmet>
      <div className={ `banner ${ ENV }` }>
        { ENV }
      </div>
    </React.Fragment>
  );
};

export default EnvironmentBanner;
