import React, { useState, useEffect }        from 'react';
import { useDispatch }                       from 'react-redux';
import PropTypes                             from 'prop-types';
import getConfig                             from 'models/config/requests';
import { getConfig as getDistributorConfig } from 'distributor/models/config/requests';
import { setConfig as setDistributorConfig } from 'distributor/models/config/store_actions';
import setConfig                             from 'models/config/store_actions';
import Loader                                from 'components/ui/loader';

const WithApplicationConfig = ({ configFor, children }) => {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  const forDistributor = () => (
    configFor === 'distributor'
  );

  useEffect(() => {
    const method = forDistributor() ? getDistributorConfig : getConfig;
    method().then(({ config }) => {
      if (forDistributor()) {
        dispatch(setDistributorConfig(config));
      } else {
        dispatch(setConfig(config));
      }
      setLoading(false);
    }).catch(() => {
      setLoading(false);
    });
  }, []);

  if (loading) {
    return <Loader middleOfPage />;
  }
  return children;
};

WithApplicationConfig.defaultProps = {
  configFor: ''
};

WithApplicationConfig.propTypes = {
  configFor: PropTypes.string
};

export default WithApplicationConfig;
