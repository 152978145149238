import React            from 'react';
import classNames       from 'classnames';
import uniqBy           from 'lodash/uniqBy';
import Loader           from 'components/ui/loader';
import FormErrorMessage from 'components/ui/form/form_error_message';
import { fieldWrapper } from 'components/ui/json_form/fields/field_wrapper';

const RadioInputList = ({
  input,
  list,
  loading,
  onSelected,
  meta,
  label,
  asButton,
  disabled,
  onValueChange,
}) => {
  return (
    <React.Fragment>
      { !loading && (
        <div
          className={
            classNames('', {
              'uk-form-danger':                                  meta.touched && meta.error,
              'uk-list multi-column-list button-list m-0':       asButton,
              'field uk-grid-small uk-child-width-auto uk-grid': !asButton,
            })
          }
        >
          {
            uniqBy(list, (item) => item.value).map((item, id) => (
              <label
                htmlFor={ `${ input.name }_${ item.id || item.value }` }
                key={ id }
                className={ classNames('', {
                  'select-button': asButton,
                  'selected':      asButton && (`${ input.value }` === `${ item.value }`),
                }) }
              >
                <input
                  className="uk-radio mr-10"
                  type="radio"
                  value={ item.value }
                  name={ input.name }
                  id={ `${ input.name }_${ item.id || item.value }` }
                  checked={ `${ input.value }` === `${ item.value }` }
                  disabled={ disabled || item.disabled }
                  onChange={ (ev) => {
                    input.onChange(ev.target.value);
                    if (onSelected) {
                      onSelected(ev.target.value);
                    }
                    if (onValueChange) {
                      onValueChange(ev.target.value);
                    }
                  } }
                />
                { item.label }
              </label>
            ))
          }
          <FormErrorMessage { ...meta } name={ input.name } label={ label } />
        </div>
      ) }
      {
        loading && <Loader />
      }
    </React.Fragment>
  );

};

export default fieldWrapper()(RadioInputList);
