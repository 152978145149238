import React                 from 'react';
import { connect }           from 'react-redux';
import { withRouter }        from 'react-router-dom';
import Title                 from 'components/ui/titles';
import ProfileForm           from 'components/user/profile/profile_form';
import confirmBeforeAction   from 'components/ui/buttons/helpers/confirm_before_action';
import { updateCurrentUser } from 'models/current_user/requests';
import GdprModal             from 'components/gdpr/gdpr_modal';
import Button                from 'components/ui/buttons/button';

const Profile = ({ current_user, dispatch, offices }) => {
  let gdprModalRef = null;
  const onProfileSubmit = (values) => {
    return dispatch(updateCurrentUser(values)).then(() => location.reload());
  };

  const ConfirmBeforeDeleteAccount = confirmBeforeAction()((props) => {
    return <Button { ...props } />;
  });

  return (
    <React.Fragment>
      <GdprModal ref={ gdprModal => gdprModalRef = gdprModal } />
      <div className="block p-20 top-border">
        <div className="uk-flex uk-flex-middle mb-20">
          <Title size="h2" className="mb-0" data-purpose="profile_form_title">{ t('profiles.show.title') }</Title>
          <div className="actions uk-margin-auto-left">
            { current_user.profile.authorizations.gdpr_menu && (
              <div className="uk-inline">
                <Button
                  data-purpose="profile_submenu_button"
                  icon="more-vert"
                />
                <div data-uk-dropdown="mode: click" className="p-20">
                  <ul className="uk-list uk-list--small-margin">
                    <li>
                      <Button
                        type="button"
                        outline
                        icon="assignment"
                        className="uk-text-left"
                        onClick={ () => gdprModalRef.open('data_portability_rights') }
                      >
                        { t('gdpr.options.data_portability_rights') }
                      </Button>
                    </li>
                    <li>
                      <Button
                        type="button"
                        outline
                        icon="assignment"
                        className="uk-text-left"
                        onClick={ () => gdprModalRef.open('data_processing_limit_requests') }
                      >
                        { t('gdpr.options.data_processing_limit_requests') }
                      </Button>
                    </li>
                    <li>
                      <Button
                        type="button"
                        outline
                        icon="assignment"
                        className="uk-text-left"
                        onClick={ () => gdprModalRef.open('data_rectification_requests') }
                      >
                        { t('gdpr.options.data_rectification_requests') }
                      </Button>
                    </li>
                    <li>
                      <ConfirmBeforeDeleteAccount
                        className="uk-button error uk-text-left"
                        type="button"
                        icon="delete_forever"
                        onClick={ () => gdprModalRef.open('account_removal') }
                      >
                        { t('gdpr.options.account_removal') }
                      </ConfirmBeforeDeleteAccount>
                    </li>
                  </ul>
                </div>
              </div>
            ) }
          </div>
        </div>
        <ProfileForm
          onSubmit={ onProfileSubmit }
          initialValues={ current_user.profile }
          offices={ offices }
        />
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { account: { offices = [] }, current_user } = state;
  return {
    current_user,
    offices: offices.map(office => (
      {
        value: office.id,
        label: office.alias,
      }
    )),
  };
};

export default connect(mapStateToProps)(withRouter(Profile));
