import React, { useState, useEffect } from 'react';
import Select, { components }         from 'react-select';
import Loader                         from 'components/ui/loader';
import FormErrorMessage               from 'components/ui/form/form_error_message';
import { fieldWrapper }               from 'components/ui/json_form/fields/field_wrapper';

const SelectInput = ({
  input,
  id,
  list = [],
  loading,
  onSelected,
  meta,
  className,
  placeholder,
  disabled,
  isClearable,
  showLabel,
  label,
  InputAddition,
  onValueChange,
  disabledIfEmptyList,
}) => {
  const [inputAddition, setInputAddition] = useState(null);
  useEffect(() => {
    if (InputAddition) {
      setInputAddition(<InputAddition />);
    }
  }, []);
  const onSelect = (selected) => {
    input.onChange(selected && selected.value.toString());
    if (onSelected) {
      onSelected(selected && selected);
    }
    if (onValueChange) {
      onValueChange(selected.value);
    }
  };

  const getValue = () => {
    if (!list) {
      return null;
    }
    if (!input.value && input.value !== 0) {
      return null;
    }
    return list.find(item => item.value.toString() === input.value.toString());
  };

  return (
    <React.Fragment>
      {
        !loading && (
          <div className="input-container" data-purpose={ input.name }>
            { label && showLabel && (
              <label htmlFor={ id }>{ label }</label>
            ) }
            <Select
              name={ input.name }
              onChange={ onSelect }
              value={ getValue() }
              options={ list }
              isClearable={ isClearable }
              id={ id }
              isDisabled={ disabled || (disabledIfEmptyList && !list.length) }
              placeholder={ placeholder || t('general.actions.select') }
              className={ `Select ${ id } react-select-${ input.name } react-select-${ className } ${ className }` }
            />
          </div>
        ) }
      {
        loading && <Loader />
      }
      { inputAddition && !loading && inputAddition }
      <FormErrorMessage { ...meta } name={ input.name } lable={ label } />
    </React.Fragment>
  );
};

export default fieldWrapper()(SelectInput);
