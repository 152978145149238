import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'promise-polyfill/src/polyfill';
import 'whatwg-fetch';

import React         from 'react';
import ReactDOM      from 'react-dom';
import OpenbrokerApp from 'scenes/application';
import moment        from 'moment/moment';
import Moment        from 'react-moment';

import 'url-search-params-polyfill';

import 'moment/locale/fr';
import 'moment/locale/nl';
import 'uikit/dist/js/uikit.min';
import 'uikit/dist/js/uikit-icons.min';

Moment.globalMoment = moment;

if (navigator) {
  if (navigator.serviceWorker) {
    navigator.serviceWorker.getRegistrations && navigator.serviceWorker.getRegistrations()
      .then((registrationsArray) => {
        registrationsArray.forEach(registration => registration && registration.unregister && registration.unregister());
      });
  }
  if (navigator.appName == 'Microsoft Internet Explorer' || !!(navigator.userAgent.match(/Trident/) || navigator.userAgent.match(/rv:11/)) || (typeof $.browser !== 'undefined' && $.browser.msie == 1)) {
    const root = document.getElementsByTagName('html')[0];
    root.setAttribute('class', 'ie');
  }
}

ReactDOM.render(<OpenbrokerApp />, document.getElementById('root'));
