import React        from 'react';
import {
  reduxForm,
  Field,
}                   from 'redux-form';
import SubmitButton from 'components/ui/form/submit_button';
import Title        from 'components/ui/titles';
import Box          from 'components/ui/box';

const AccountRemovalForm = ({ submitting, handleSubmit }) => (
  <React.Fragment>
    <Title
      size="h3"
      className="uk-text-center"
    >
      { t('gdpr.options.account_removal') }
    </Title>
    <form onSubmit={ handleSubmit }>
      <Field
        type="text"
        name="message"
        component="textarea"
        className="uk-textarea"
        required
      />
      <SubmitButton
        className="mt-20"
        type="submit"
        loading={ submitting }
        warning
        text={ t('gdpr.options.account_removal') }
      />
    </form>
  </React.Fragment>
);

export default reduxForm({
  form: 'account_removal',
})(AccountRemovalForm);
