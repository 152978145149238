import { fetchCarData } from 'models/car/requests';
import { converter }    from 'models/car/constants';

export const getCarFromCarapiSchema = () => {
  return {
    febiac_form: {
      type:       'group',
      visible:    {
        property:  `vehicle.source`,
        assertion: 'notEqual',
        value:     'informex',
      },
      properties: {
        car_resume:                {
          type:      'custom',
          component: 'car_resume',
          visible:   {
            property:  `vehicle.source`,
            assertion: 'equal',
            value:     'api',
          },
        },
        build_year:                {
          type:      'hidden',
          component: 'text',
        },
        date_of_first_circulation: {
          component:     'date',
          validations:   ['required', 'date_format'],
          label:         t('activemodel.attributes.car.date_of_first_circulation'),
          onValueChange: {
            set: [
              {
                to:    `vehicle.brand`,
                value: null,
              },
              {
                to:    `vehicle.model`,
                value: null,
              },
              {
                to:    `vehicle.fuel_type`,
                value: null,
              },
              {
                to:    `vehicle.power`,
                value: null,
              },
              {
                to:    `vehicle.version`,
                value: null,
              },
              {
                to:    `vehicle.build_year`,
                value: null,
              },
              {
                to:    `vehicle.febiac_id`,
                value: null,
              },
            ],
          },
        },
        brand:                     {
          component:           'select',
          label:               t('activerecord.attributes.car.brand'),
          disabledIfEmptyList: true,
          asyncList:           {
            get:    (params) => {
              return fetchCarData('brands', params).then((list) => list.map((item) => ({
                value: item,
                label: item,
              })));
            },
            params: [
              {
                property:    'contract_request.risk_object.kind',
                param_name:  'risk_object',
                validations: [{
                  custom: 'required',
                }],
              },
              {
                property:    `vehicle.date_of_first_circulation`,
                param_name:  'date_of_first_circulation',
                validations: [{
                  custom: 'date_format',
                }],
              },
            ],
          },
          onValueChange:       {
            set: [
              {
                to:    `vehicle.model`,
                value: null,
              },
              {
                to:    `vehicle.fuel_type`,
                value: null,
              },
              {
                to:    `vehicle.power`,
                value: null,
              },
              {
                to:    `vehicle.version`,
                value: null,
              },
              {
                to:    `vehicle.build_year`,
                value: null,
              },
            ],
          },
        },
        model:                     {
          component:           'select',
          label:               t('activerecord.attributes.car.model'),
          disabledIfEmptyList: true,
          asyncList:           {
            get:    (params) => {
              return fetchCarData('models', params).then((list) => list.map((item) => ({
                value: item,
                label: item,
              })));
            },
            params: [
              {
                property:    'contract_request.risk_object.kind',
                param_name:  'risk_object',
                validations: [{
                  custom: 'required',
                }],
              },
              {
                property:    `vehicle.date_of_first_circulation`,
                param_name:  'date_of_first_circulation',
                validations: [{
                  custom: 'date_format',
                }],
              },
              {
                property:    `vehicle.brand`,
                param_name:  'brand',
                validations: [{
                  custom: 'required',
                }],
              },
            ],
          },
          onValueChange:       {
            set: [
              {
                to:    `vehicle.fuel_type`,
                value: null,
              },
              {
                to:    `vehicle.power`,
                value: null,
              },
              {
                to:    `vehicle.version`,
                value: null,
              },
              {
                to:    `vehicle.build_year`,
                value: null,
              },
            ],
          },
        },
        fuel_type:                 {
          component:           'select',
          label:               t('activerecord.attributes.car.fuel_type'),
          disabledIfEmptyList: true,
          asyncList:           {
            get:    (params) => {
              return fetchCarData('fuel_types', params).then((list) => list.map((item) => ({
                value: item,
                label: t(`clients.cars.car_form.fuel_type_options.${ item }`),
              })));
            },
            params: [
              {
                property:    'contract_request.risk_object.kind',
                param_name:  'risk_object',
                validations: [{
                  custom: 'required',
                }],
              },
              {
                property:    `vehicle.date_of_first_circulation`,
                param_name:  'date_of_first_circulation',
                validations: [{
                  custom: 'date_format',
                }],
              },
              {
                property:    `vehicle.brand`,
                param_name:  'brand',
                validations: [{
                  custom: 'required',
                }],
              },
              {
                property:    `vehicle.model`,
                param_name:  'model',
                validations: [{
                  custom: 'required',
                }],
              },
            ],
          },
          onValueChange:       {
            set: [
              {
                to:    `vehicle.power`,
                value: null,
              },
              {
                to:    `vehicle.version`,
                value: null,
              },
              {
                to:    `vehicle.build_year`,
                value: null,
              },
            ],
          },
        },
        power:                     {
          component:           'select',
          label:               t('activerecord.attributes.car.power'),
          disabledIfEmptyList: true,
          extraFields:         {
            properties: {
              set_adjusted_power: {
                component: 'button',
                secondary: true,
                label:     t('wizard.car.exact_power_not_found'),
                onClick:   {
                  set: {
                    property: `vehicle.with_adjusted_power`,
                    value:    'true',
                  },
                },
              },
            },
          },
          asyncList:           {
            get:    (params) => {
              return fetchCarData('powers', params).then((list) => list.map((item) => ({
                value: item,
                label: `${ item } KW / ${ converter.kw(item) } CV`,
              })));
            },
            params: [
              {
                property:    'contract_request.risk_object.kind',
                param_name:  'risk_object',
                validations: [{
                  custom: 'required',
                }],
              },
              {
                property:    `vehicle.date_of_first_circulation`,
                param_name:  'date_of_first_circulation',
                validations: [{
                  custom: 'date_format',
                }],
              },
              {
                property:    `vehicle.brand`,
                param_name:  'brand',
                validations: [{
                  custom: 'required',
                }],
              },
              {
                property:    `vehicle.model`,
                param_name:  'model',
                validations: [{
                  custom: 'required',
                }],
              },
              {
                property:    `vehicle.fuel_type`,
                param_name:  'fuel_type',
                validations: [{
                  custom: 'required',
                }],
              },
            ],
          },
          onValueChange:       {
            set: [
              {
                to:    `vehicle.version`,
                value: null,
              },
              {
                to:    `vehicle.build_year`,
                value: null,
              },
            ],
          },
        },
        version:                   {
          component:    'vehicle_selector',
          label:        t('activerecord.attributes.car.version'),
          vehicleLabel: 'vehicle',
        },
      },
    },
  };
};
