import React                         from 'react';
import { connect }                   from 'react-redux';
import PublicPagesContainer          from 'scenes/public_pages';
import CheckForSession               from 'helpers/check_for_session';
import { currentUserRoleIsDisabled } from 'models/current_user/reducer';
import Title                         from 'components/ui/titles';
import DisabledAccount               from 'images/disabled_account.svg';
import Button                        from 'components/ui/buttons/button';
import { logOutCurrentUser }         from 'models/current_user/requests';
import MaintenanceMode               from 'helpers/maintenance_mode';
import AuthentifiedContainer         from 'scenes/authentified_container';

let Container = ({ isLoggedOut, disabledRole, dispatch }) => {
  if (disabledRole) {
    return (
      <React.Fragment>
        <div className="error-container">
          <img src={ DisabledAccount } alt="Account disabled" />
          <Title size="h2" className="mb-40">
            { t('disabled_account.title') }
          </Title>
          <p>{ t_html('disabled_account.explanation_html') }</p>
          <Button
            large
            primary
            onClick={ () => dispatch(logOutCurrentUser()) }
          >
            { t('general.sign_out') }
          </Button>
        </div>
      </React.Fragment>
    );
  }
  return (
    <MaintenanceMode>
      { isLoggedOut && <PublicPagesContainer /> }
      { !isLoggedOut && (
        <AuthentifiedContainer />
      ) }
    </MaintenanceMode>
  );
};

const mapStateToProps = (state) => {

  const { current_user } = state;
  return {
    isLoggedOut:  !current_user.profile,
    disabledRole: currentUserRoleIsDisabled(state),
  };
};

Container = connect(mapStateToProps)(Container);

const CurrentUserByRoleContainer = () => {
  return (
    <CheckForSession>
      <Container />
    </CheckForSession>
  );
};

export default CurrentUserByRoleContainer;
