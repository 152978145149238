import React          from 'react';
import { withRouter } from 'react-router-dom';

class ScrollToTopOnRouteChange extends React.Component {
  componentDidUpdate({ location: prevLocation }) {
    const { location } = this.props;
    if (location !== prevLocation) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    const { children } = this.props;
    return children;
  }
}

export default withRouter(ScrollToTopOnRouteChange);
