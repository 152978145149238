import React           from 'react';
import {
  reduxForm,
  Field,
}                      from 'redux-form';
import SymbolTextInput from 'components/ui/form/symbol_text_input';
import SubmitButton    from 'components/ui/form/submit_button';
import Title           from 'components/ui/titles';

const PortabilityRightForm = ({ submitting, handleSubmit }) => (
  <React.Fragment>
    <Title
      size="h3"
      className="uk-text-center"
    >
      { t('gdpr.options.data_portability_rights') }
    </Title>
    <p>{ t('gdpr.data_portability_rights.show.info_1') }</p>
    <p>{ t('gdpr.data_portability_rights.show.info_2') }</p>
    <form onSubmit={ handleSubmit } className="mt-20">
      <Field
        type="email"
        symbol="@"
        name="email"
        component={ SymbolTextInput }
        required
      />
      <SubmitButton
        type="submit"
        className="mt-20"
        loading={ submitting }
      />
    </form>
  </React.Fragment>
);

export default reduxForm({
  form: 'portability_right_form',
})(PortabilityRightForm);
