import React, {
  useEffect,
  useContext,
}                      from 'react';
import has             from 'lodash/has';
import JsonFormContext from 'components/ui/json_form/json_form_context';

const withDestroyValueOnUnmount = () => Component => {
  return (props) => {
    const { getPropertyValue } = useContext(JsonFormContext);
    const { input: { onChange }, inArray, preventDestroyValueOnUnmount = false } = props;
    useEffect(() => {
      return () => {
        if (inArray) {
          return;
        }
        if (preventDestroyValueOnUnmount) {
          return;
        }
        onChange(null);
      };
    }, []);

    useEffect(() => {
      if (has(props, '__field.default.from') && !props.input.value) {
        onChange(getPropertyValue(props.__field.default.from));
      }
    }, []);
    return <Component { ...props } />;
  };
};

export default withDestroyValueOnUnmount;
