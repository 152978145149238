import Api from 'services/api';

export const getConfig = (configFor) => {
  return new Promise((resolve, reject) => {
    Api.get(configFor === 'distributor' ? 'distributor/config' : 'config').then((response) => {
      resolve(response.data);
    }).catch((error) => {
      reject(error);
    });
  });
};

export default getConfig;
