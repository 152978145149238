import Alert                                    from 'services/alert';
import Api                                      from 'services/api';
import { setUserInStore }                       from 'models/current_user/store_actions';
import { removeAuthorizationToken }             from 'services/authorization_token';
import { DESKTOP_SIGN_OUT, sendDesktopAppData } from 'services/desktop_app';
import { removeCookie }                         from 'services/cookies';
import { REDIRECT_AFTER_SIGN_IN }               from 'constants/application_constants';
import { getCurrentLocale }                     from 'services/i18n';

export const getCurrentUser = () => (
  (dispatch) => (
    new Promise((resolve, reject) => {
      Api.get('profile').then((response) => {
        const { profile } = response.data;
        dispatch(setUserInStore(profile));
        resolve(profile);
      }).catch((error) => {
        console.error('error', error);
        Alert.error('Error while fetching user data');
        reject();
      });
    })
  )
);

export const updateCurrentUser = (data) => (
  (dispatch) => (
    new Promise((resolve, reject) => {
      Api.put('profile', data).then((response) => {
        const { profile } = response.data;
        dispatch(setUserInStore(profile));
        Alert.success(t('profiles.actions.update.success'));
        resolve(profile);
      }).catch(() => {
        Alert.error(t('profiles.actions.update.error'));
        reject();
      });
    })
  )
);

export const logOutCurrentUser = (andRedirect = true) => (
  (dispatch) => (
    new Promise((resolve, reject) => {
      Api.delete('sessions').then(() => {
        removeAuthorizationToken();
        removeCookie(REDIRECT_AFTER_SIGN_IN);
        sendDesktopAppData(DESKTOP_SIGN_OUT);
        dispatch({ type: 'CURRENT_USER/DELETE' });
        if (andRedirect) {
          location.href = `/${ getCurrentLocale() }/user/sign_in?logout=true`;
        }
        resolve();
      }).catch(() => {
        Alert.error(t('profiles.actions.logout.error'));
        reject();
      });
    })
  )
);
