import React                 from 'react';
import LeadEndedIllustration from 'images/lead_end.svg';

const LeadFinished = ({ className }) => {
  return (
    <div
      className={ `uk-flex uk-flex-middle uk-width-1-1 folder-empty-state p-20 ${ className }` }
      data-purpose="lead_ended"
    >
      <div className="uk-text-center uk-width-1-1">
        <img
          src={ LeadEndedIllustration }
          alt="No lead"
          className="uk-width-1-5"
        />
        <p className="uk-text-center mt-20 pl-40 pr-40">
          { t('leads.ended.explanation') }
        </p>
      </div>
    </div>
  );
};

export default LeadFinished;
