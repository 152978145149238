import React                from 'react';
import ContractErrors       from 'components/contract/contract_errors';
import SendContractButton   from 'components/contract/send_contract_button';
import asWizardStep         from 'components/wizard_stepper/as_wizard_step';
import { useSelector }      from 'react-redux';
import ContractInformations from 'distributor/components/contracts/contract_informations';
import SignContractButton   from 'components/contract/sign_contract_button';
import DocumentsTable       from 'components/documents/table';

const ContractStep = ({ tarificationId }) => {
  const tarification = useSelector((state) => state.wizard.contract_request.tarifications.find((tarification) => tarification.id === tarificationId));
  const contractRequest = useSelector((state) => state.wizard.contract_request);
  return (
    <React.Fragment>
      <ContractErrors contract={ tarification.contract } />
      <DocumentsTable
        tarifications={ contractRequest.tarifications }
        contractRequestId={ contractRequest.id }
        filters={ ['contract'] }
      />
      <p className="uk-text-center">
        <SendContractButton tarification={ tarification } />
      </p>
      <ContractInformations contract={ tarification.contract } />
      <p className="uk-text-center">
        <SignContractButton tarification={ tarification } />
      </p>
    </React.Fragment>
  );
};

export default asWizardStep()(ContractStep);
