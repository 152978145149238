import Api   from 'services/api';
import Alert from 'services/alert';

export const searchCarByVinNumber = vin_number => (
  new Promise((resolve, reject) => {
    Api.get('search/car', { params: { vin_number } }).then((response) => {
      resolve(response.data);
    }).catch((error) => {
      Alert.error(t('clients.typed_contract_requests.car_selection_modal.search_by_vin_number_error'));
      reject(error);
    });
  })
);

export const fetchCarData = (part, params) => {
  return new Promise((resolve, reject) => {
    Api.get(`/carapi/attributes/${ part }`, { params: params }).then((response) => {
      resolve(response.data);
    }).catch(reject);
  });
};

export const fetchVehicles = params => (
  new Promise((resolve, reject) => {
    Api.get('carapi/vehicles', { params }).then((response) => {
      resolve(response.data);
    }).catch((error) => {
      Alert.error(t('clients.cars.car_form.fetch_api_error'));
      reject(error);
    });
  })
);
