import React       from 'react';
import PropTypes   from 'prop-types';
import { NavLink } from 'react-router-dom';
import classNames  from 'classnames';
import LogoWhite   from 'images/open_broker_logo_white.svg';
import LogoDark    from 'images/open_broker_logo_dark.svg';

const Logo = ({ url, className, to, dark }) => {
  const logo = url || (dark ? LogoDark : LogoWhite);

  return (
    <NavLink
      className={ classNames('logo', className) }
      to={ to }
    >
      <img
        src={ logo }
        data-purpose="main_header_logo_image"
        alt="broker logo"
      />
    </NavLink>
  );
};

Logo.defaultProps = {
  dark:      false,
  url:       null,
  className: null,
  to:        '/'
};

Logo.propTypes = {
  url:       PropTypes.string,
  className: PropTypes.string,
  dark:      PropTypes.bool,
  to:        PropTypes.string
};

export default Logo;
