import React                                  from 'react';
import compact                                from 'lodash/compact';
import TarificationStep                       from 'components/wizard/tarification_step';
import GenericStep                            from 'components/wizard_stepper/generic_step';
import { aedesHousingSchema }                 from 'schemas/aedes/p_500/aedes_housing_schema';
import { getAedesHousingContractSchema }      from 'schemas/aedes/p_500/aedes_housing_contract_schema';
import { filterSchema, getPolicyOwnerSchema } from 'schemas/shared/policy_owner_schema';

import {
  isOffer,
  haveSelectedPolicyOwner,
  haveSelectedHousing,
}                               from 'models/wizard_navigation/constants';
import SubmitStepAndCreateOffer from 'components/wizard/submit_step_and_create_offer';
import AedesP500OfferStep       from 'components/aedes/aedes_p500_offer_step';
import SingleProductResume      from 'components/resume/single_product_resume';
import OfferStep                from 'components/wizard_stepper/offer_step';
import { isCrLead }             from 'models/contract_request/constants';

export const getAedesHousingSteps = (matchPath, contractRequest, recomputeTarifications) => {
  let policyOwnerSchema = filterSchema(getPolicyOwnerSchema({
    schema: {
      policy_owner: {
        properties: {
          kind:                       {
            default:       {
              value: 'person',
            },
            onValueChange: {
              set: [
                {
                  to:        'policy_owner.other_policy_owner_details[0]',
                  value:     {},
                  condition: {
                    assertion: 'equal',
                    value:     'company',
                  },
                },
              ],
            },
          },
          other_policy_owner_details: {
            type:          'array',
            label:         t('aedes.housing.policy_owner.other_policy_owner'),
            array_actions: {
              max:          10,
              add_label:    t('aedes.housing.policy_owner.other_policy_owner'),
              title:        t('aedes.housing.policy_owner.add_policy_owner'),
              remove_label: t('general.actions.delete'),
            },
            properties:    {
              date_of_birth: {
                label:       t('wizard.housing.other_policy_owner.birthdate'),
                component:   'date',
                validations: ['required', 'min50years'],
              },
            },
            visible:       {
              property:  'policy_owner.kind',
              assertion: 'equal',
              value:     'company',
            },
          },
          company_warning_message:    {
            inline:    false,
            component: 'message',
            className: 'global-message warning p-20 uk-text-center',
            message:   t('aedes.housing.policy_owner_min_50_year_company_warning'),
            visible:   {
              property:  'policy_owner.kind',
              assertion: 'equal',
              value:     'company',
            },
          },
          person:                     {
            properties: {
              date_of_birth: {
                validations: ['required', 'min50years'],
              },
            },
          },
        },
      },
    },
  }), isCrLead(contractRequest));
  return compact([
    {
      label: t('wizard.navigation.folder'),
      icon:  'file',
      slug:  'contract_request',
      kind:  'group',
      order: 2,
      steps: [
        {
          path:           `${ matchPath }/policy_owner`,
          title:          t('wizard.policy_owner.title'),
          label:          t('wizard.navigation.policy_owner'),
          icon:           'company',
          Component:      GenericStep,
          order:          2,
          purpose:        'policy_owner',
          componentProps: {
            ...policyOwnerSchema,
            onNextStep: {
              recomputeTarifications,
            },
          },
        },
        {
          path:           `${ matchPath }/housing`,
          disabledRules:  [
            haveSelectedPolicyOwner,
          ],
          title:          t('wizard.navigation.building'),
          label:          t('wizard.navigation.building'),
          icon:           contractRequest.risk_object.icon,
          order:          3,
          Component:      GenericStep,
          purpose:        'housing',
          componentProps: {
            ...aedesHousingSchema(),
            onNextStep: {
              recomputeTarifications,
            },
          },
        },
      ],
    },
    {
      path:           `${ matchPath }/tarification_${ contractRequest.tarifications[0].id }`,
      order:          3,
      title:          t('wizard.navigation.configuration', { name: contractRequest.tarifications[0].product.display_name }),
      label:          t('wizard.navigation.solo_product', { product_name: contractRequest.tarifications[0].product.display_name }),
      error:          contractRequest.tarifications[0].last_error_response,
      loading:        contractRequest.tarifications[0].computing,
      icon:           'product-parameters',
      disabledRules:  [
        haveSelectedPolicyOwner,
        haveSelectedHousing,
      ],
      purpose:        'tarification',
      Component:      TarificationStep,
      componentProps: {
        tarificationId: contractRequest.tarifications[0].id,
        disabled:       contractRequest.status === 'offer',
        subtitle:       t('aedes.housing.tarification_subtitle'),
      },
    },
    {
      path:           `${ matchPath }/resume`,
      title:          t('wizard.navigation.tarification_documents'),
      order:          4,
      disabledRules:  [
        haveSelectedPolicyOwner,
        haveSelectedHousing,
      ],
      label:          t('wizard.navigation.tarification_documents'),
      icon:           'folder',
      purpose:        'resume_step',
      Component:      SingleProductResume,
      componentProps: {
        showCreateOffer: false,
      },
    },
    {
      path:           `${ matchPath }/ask_offer`,
      order:          5,
      title:          t('aedes.housing.wizard.ask_offer'),
      disabledRules:  [
        haveSelectedPolicyOwner,
        haveSelectedHousing,
      ],
      label:          t('aedes.housing.wizard.ask_offer'),
      icon:           'contract',
      Component:      OfferStep,
      componentProps: {
        tarificationId: contractRequest.tarifications[0].id,
        ...getAedesHousingContractSchema(),
      },
      StepMainAction: (props) => {
        return (
          <SubmitStepAndCreateOffer
            tarificationId={ contractRequest.tarifications[0].id }
            { ...props }
            label={ t('aedes.housing.ask_offer_button') }
          />
        );
      },
    },
    {
      path:          `${ matchPath }/offer_reference`,
      order:         6,
      title:         t('aedes.housing.wizard.offer_reference_title'),
      disabledRules: [
        haveSelectedPolicyOwner,
        haveSelectedHousing,
        isOffer,
      ],
      label:         t('aedes.housing.wizard.offer_reference_title'),
      icon:          'offer',
      Component:     AedesP500OfferStep,
    },
  ]);
};
