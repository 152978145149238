import Api from 'services/api';

export const searchInBMSDB = search => (
  new Promise((resolve, reject) => {
    Api.get(`bms/search/${ search }`).then((response) => {
      resolve(response.data);
    }).catch((error) => {
      reject(error.data.errors);
    });
  })
);
