import { getViviumBuildingEvaluationApartmentsGridSchema } from 'schemas/vivium/building/vivium_building_evaluation_apartments_grid_schema';
import { getViviumBuildingEvaluationSurfaceGridSchema }    from 'schemas/vivium/building/vivium_building_evaluation_surface_grid_schema';
import { getViviumBuildingSumInsuredSchema }               from 'schemas/vivium/building/vivium_building_sum_insured_schema';

const isNotFirstRisk = {
  property:  'evaluation.evaluation_type',
  assertion: 'notEqual',
  value:     'first_risk',
};

const isNotProportionalRule = {
  property:  'evaluation.evaluation_type',
  assertion: 'notEqual',
  value:     'proportional_rule',
};

const max4Floors = {
  property:  'building.floor_count',
  assertion: 'isBelow',
  value:     5,
};

const max4Flats = {
  property:  'building.flat_count',
  assertion: 'isBelow',
  value:     5,
};

const habitationOnly = {
  property:  'building.usage',
  assertion: 'equal',
  value:     'habitation_only',
};

const haveHoreca = {
  property:  'building.usage',
  assertion: 'equal',
  value:     'horeca',
};

const moreThan9Floors = {
  property:  'building.floor_count',
  assertion: 'isAbove',
  value:     8,
};

export const getViviumEvaluationSchema = () => {
  const schema = {
    form:   'vivium_building_evaluation',
    schema: {
      evaluation: {
        type:       'section',
        properties: {
          evaluation_method:    {
            label:       t('vivium.evaluation.evaluation_method'),
            component:   'select',
            react_key:   ['evaluation.evaluation_type'],
            validations: [
              'required',
            ],
            list:        [
              {
                value:   'surface_grid',
                label:   t('vivium.evaluation.methods.surface_grid'),
                visible: [
                  {
                    property:  'building.usage',
                    assertion: 'notEqual',
                    value:     'horeca',
                  },
                  {
                    property:  'building.floor_count',
                    assertion: 'isBelow',
                    value:     10,
                  },
                ],
              },
              {
                value:     'gudrun',
                label:     t('vivium.evaluation.methods.gudrun'),
                visibleOr: [
                  haveHoreca,
                  moreThan9Floors,
                ],
              },
              {
                value:     'risk_control',
                label:     t('vivium.evaluation.methods.risk_control'),
                visibleOr: [
                  haveHoreca,
                  moreThan9Floors,
                ],
              },
              {
                value:     'broker_with_dealership',
                label:     t('vivium.evaluation.methods.broker_with_dealership'),
                visibleOr: [
                  haveHoreca,
                  moreThan9Floors,
                ],
              },
              {
                value:     'expertise_fieldunderwriter',
                label:     t('vivium.evaluation.methods.expertise_fieldunderwriter'),
                visibleOr: [
                  haveHoreca,
                  moreThan9Floors,
                ],
              },
              {
                value: 'connect_value',
                label: t('vivium.evaluation.methods.connect_value'),
              },
              {
                value: 'resumption_concurrence_grid',
                label: t('vivium.evaluation.methods.resumption_concurrence_grid'),
              },
              {
                value: 'by_policy_owner',
                label: t('vivium.evaluation.methods.by_policy_owner'),
              },
              {
                value: 'resumption_concurrence_expertise',
                label: t('vivium.evaluation.methods.resumption_concurrence_expertise'),
              },
              {
                value: 'first_risk_building',
                label: t('vivium.evaluation.methods.first_risk_building'),
              },
              {
                value:   'condition_of_average',
                label:   t('vivium.evaluation.methods.condition_of_average'),
                visible: [
                  {
                    property:  'evaluation.evaluation_type',
                    assertion: 'equal',
                    value:     'proportional_rule',
                  },
                ],
              },
            ],
          },
          ...getViviumBuildingEvaluationApartmentsGridSchema(),
          ...getViviumBuildingEvaluationSurfaceGridSchema(),
          ...getViviumBuildingSumInsuredSchema(),
          external_solar_panel: {
            component:   'price',
            label:       t('vivium.evaluation.external_solar_panel'),
            validations: ['required'],
            default:     {
              value: 0,
            },
          },
        },
      },
    },
  };
  return schema;
};
