import React               from 'react';
import { Field }           from 'redux-form';
import classNames          from 'classnames';
import CheckboxInput       from 'components/ui/json_form/fields/checkbox';
import withInlineContainer from 'components/ui/json_form/fields/with_inline_container';
import FormErrorMessage    from 'components/ui/form/form_error_message';

const CheckboxList = ({
  input,
  meta = {},
  options,
  list,
  label,
  className,
  asButton,
  inline,
  name,
}) => {
  const array = options || list;
  return (
    <React.Fragment>
      <ul className={
        classNames(className || 'uk-list', {
          'button-list m-0':   asButton,
          'multi-column-list': !asButton,
          'pl-20':             !asButton && !inline,
          'error':             meta.touched && meta.error,
        }) }
      >
        {
          array.map((option, index) => (
            <li className="item" key={ index }>
              <Field
                name={ `${ name }.${ option.value }` }
                label={ option.label }
                component={ CheckboxInput }
              />
            </li>
          ))
        }
      </ul>
      <FormErrorMessage { ...meta } label={ label } name={ input.name } />
    </React.Fragment>
  );
};

export default withInlineContainer()(CheckboxList);
