import React       from 'react';
import { connect } from 'react-redux';

const GlobalMessage = ({ message, messageType }) => {
  if (!message) {
    return null;
  }
  return (
    <div className="pl-20 pr-20 pt-20 m-0 global-message-container">
      <div
        className={ `global-message ${ messageType }` }
      >
        <p className="m-0">{ message }</p>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    message:     state.config.global_message,
    messageType: state.config.global_message_type,
  };
};

export default connect(mapStateToProps)(GlobalMessage);
