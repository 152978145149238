import React  from 'react';
import Button from 'components/ui/buttons/button';

const BMSPersonResult = ({ person, className, ...rest }) => {
  return (
    <div
      data-purpose="bms_result"
      className={ `uk-section-muted person-bms ${ className }` }
      { ...rest }
    >
      <div className="data">
        <p className="name">{ person.first_name } { person.last_name }</p>
        <p>
          <span className="label">{ t('activerecord.attributes.driver.person.birthdate') } :</span> { person.date_of_birth || '-' }
        </p>
        <p><span className="label">{ t('preview.license_number') } :</span> { person.license_number || '-' }</p>
        <p>
          <span className="label">{ t('clients.contract_requests.params.car_license_obtention_at') } :</span> { person.car_license_obtention_at || '-' }
        </p>
        <p>
          <span className="label">{ t('company.accounts.phone_number') } :</span> { person.phone_number || '-' }
        </p>
        <p className="uk-text-bold">{ t('clients.companies.form.company') }</p>
        <p>
          <span className="label">{ t('clients.companies.form.company_type') } :</span> { person.company_type || '-' }
        </p>
        <p>
          <span className="label">{ t('preview.vat_number') } :</span> { person.vat_number || '-' }
        </p>
        <p className="uk-text-bold">{ t('providers.show.address.one') } : </p>
        <p>{ person.street_name ? `${ person.street_name } ` : '' } { person.street_number ? `${ person.street_number } ` : '' } { person.street_box ? `${ person.street_box } ` : '' }</p>
        <p>{ person.zip_code ? `${ person.zip_code } ` : '' } { person.city ? `${ person.city } ` : '' } { person.country ? `${ person.country } ` : '' }</p>
      </div>
      <div className="actions">
        <Button
          small
          secondary
          data-purpose="use_bms_result_button"
        >
          { t('wizard.bms.person.prefill_with_data') }
        </Button>
      </div>
    </div>
  );
};

export default BMSPersonResult;
