import Api   from 'services/api';
import Alert from 'services/alert';

export const sendContract = (tarification) => {
  return new Promise((resolve, reject) => {
    Api.post(`tarifications/${ tarification.id }/contract/send`).then((response) => {
      resolve(response.data);
    }).catch((error) => {
      Alert.error(t('models.contracts.requests.error'));
      reject(error);
    });
  });
};

export const signContract = (tarification) => {
  return new Promise((resolve, reject) => {
    Api.post(`tarifications/${ tarification.id }/contract/sign`).then((response) => {
      resolve(response.data);
    }).catch((error) => {
      Alert.error(t('models.contracts.requests.error'));
      reject(error);
    });
  });
};
