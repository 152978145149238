import React, { useState, useEffect } from 'react';
import { useSelector }                from 'react-redux';
import { fetchFolders }               from 'models/folders/request';
import { convertForTable }            from 'models/folders/constants';
import map                            from 'lodash/map';
import compact                        from 'lodash/compact';
import FilterBar                      from 'components/filter_bar';
import Pagination                     from 'components/pagination';
import CreateFolderButton             from 'components/folder/create_folder_button';
import Table                          from 'components/folder/table/table';
import FolderEmptyState               from 'components/folder/folder_empty_state';
import ProductEmptyState              from 'components/product/product_empty_state';

const directions = {
  asc:  'desc',
  desc: 'asc',
};

const FolderTable = () => {
  const [loading, setLoading] = useState(true);
  const [contractRequests, setContractRequests] = useState([]);
  const [pagination, setPagination] = useState(null);
  const [sortDirection, setSortDirection] = useState('desc');
  const [sortBy, setSortBy] = useState('updated_at');
  const [page, setPage] = useState(1);
  const [filters, setFilters] = useState(null);
  const hasIntermediaries = useSelector((state) => state.current_user.profile.authorizations.has_intermediaries);
  const statusList = useSelector((state) => state.config.options_collections.contract_request_statuses);

  const integrations = useSelector((state) => state.integrations.active.list);

  const fetch = () => {
    setLoading(true);
    const params = {
      sort_direction: sortDirection,
      sort_by:        sortBy,
      search_term:    filters.searchTerm,
      status:         map(filters.status, (status) => status.value).join(','),
      is_manager:     filters.myObjectsOnly,
    };
    fetchFolders(page, params).then((response) => {
      setContractRequests(convertForTable(response.contract_requests));
      setPagination(response.pagination);
      setLoading(false);
    }).catch(() => {
      setLoading(false);
    });
  };

  useEffect(() => {
    if (filters) {
      fetch();
    }
  }, [page, sortDirection, sortBy]);

  useEffect(() => {
    if (filters) {
      if (page !== 1) {
        setPage(1);
      } else {
        fetch();
      }
    }
  }, [filters]);

  const sort = (newSortBy) => {
    if (sortBy === newSortBy) {
      setSortDirection(directions[sortDirection]);
    }
    setSortBy(newSortBy);
  };

  const onArchived = (contractRequestToArchive) => {
    setContractRequests(contractRequests.filter((contractRequest) => contractRequest.id !== contractRequestToArchive.id));
  };

  const columns = compact([
    {
      label:     t('folders.table.header.risk_object'),
      accessor:  'risk_object',
      htmlAttrs: {
        className: 'uk-table-shrink uk-text-center p-10',
      },
      sortKey:   'risk_object',
    },
    {
      label:     t('folders.table.header.name'),
      accessor:  'name',
      htmlAttrs: {
        className: '',
      },
    },
    {
      label:     t('folders.table.header.products'),
      accessor:  'products',
      htmlAttrs: {
        className: 'uk-width-small uk-text-left',
      },
    },
    {
      label:     t('folders.table.header.status'),
      accessor:  'status',
      htmlAttrs: {
        className: 'uk-width-small uk-text-center',
      },
    },
    {
      label:     t('folders.table.header.reference'),
      accessor:  'technical_reference',
      htmlAttrs: {
        className: 'uk-width-small',
      },
      sortKey:   'technical_reference',
    },
    {
      label:     t('folders.table.header.provider_manager'),
      accessor:  'manager_user_display_name',
      htmlAttrs: {
        className: 'uk-width-small',
      },
      sortKey:   'manager_user_display_name',
    },
    hasIntermediaries && {
      label:     t('folders.table.header.intermediary_display_name'),
      accessor:  'intermediary_display_name',
      htmlAttrs: {
        className: 'uk-width-small',
      },
      sortKey:   'intermediary_display_name',
    },
    {
      label:     t('folders.table.header.updated_at'),
      accessor:  'updated_at',
      htmlAttrs: {
        className: 'uk-width-small uk-text-right',
      },
      sortKey:   'updated_at',
    },
  ]);
  return (
    <React.Fragment>
      { contractRequests.length > 0 && (
        <div className="uk-flex mb-20">
          <CreateFolderButton
            className="uk-button-primary"
            alignLeft
          />
        </div>
      ) }
      <div
        className="block p-20 top-border"
        data-purpose="folder_index"
      >
        <React.Fragment>
          <FilterBar
            onChange={ (newFilters) => {
              setFilters(newFilters);
            } }
            filters={ ['searchTerm', 'status', 'myObjectsOnly'] }
            options={ { status: { options: statusList } } }
            defaultValues={ { status: [] } }
          />
          <Table
            columns={ columns }
            data={ contractRequests }
            loading={ loading }
            sortedBy={ sortBy }
            sortDirection={ sortDirection }
            sortBy={ sort }
            statuses={ statusList }
            onArchived={ onArchived }
          />
        </React.Fragment>
        { !contractRequests.length && !loading && integrations.length > 0 && (
          <FolderEmptyState archivedFolders={ filters.onlyArchived } />
        ) }
        { integrations.length === 0 && !loading && (
          <ProductEmptyState />
        ) }
      </div>
      { pagination && contractRequests.length > 0 && (
        <Pagination
          pagination={ pagination }
          onPageChange={ (newPage) => {
            setPage(newPage);
          } }
        />
      ) }
    </React.Fragment>
  );
};

export default FolderTable;
