import React, { useState, useEffect } from 'react';
import { useHistory, useParams }      from 'react-router';
import { getProducts }                from 'models/product/requests';
import Loader                         from 'components/ui/loader';
import Alert                          from 'services/alert';
import { goToNewFolder }              from 'models/folders/constants';

const CreateFolderRoute = () => {
  const [creatingFolder, toggleCreatingFolder] = useState(false);
  const { product_slug } = useParams();
  const history = useHistory();


  useEffect(() => {
    if (product_slug) {
      toggleCreatingFolder(true);
      getProducts({
        includes: 'insurance_company,risk_objects',
      }).then(({ products }) => {
        const selectedProduct = products.find(p => p.slug === product_slug);
        if (!selectedProduct) {
          Alert.error(t('wizard.products.product_unavailable'));
          return history.push('/folders');
        }
        goToNewFolder(null, selectedProduct.risk_objects[0], selectedProduct);
      });
    } else {
      return history.push('/folders');
    }
  }, []);

  if (creatingFolder) {
    return <Loader middleOfPage />;
  }

  return null;
};

export default CreateFolderRoute;
