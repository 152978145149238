import { getAbexList } from 'models/application/constants';

export const getBuildingAssessmentSchema = () => {
  return {
    assessment: {
      type:       'section',
      title:      t('wizard.building.assessment.title'),
      properties: {
        sum_insured:             {
          validations: ['required', 'maxLength10', 'only_int_price'],
          label:       t('wizard.building.assessment.sum_insured'),
          component:   'price',
        },
        abex:                    {
          validations: ['required'],
          label:       t('wizard.building.assessment.abex'),
          component:   'select',
          list:        getAbexList(),
        },
        is_assessment_available: {
          component:   'true_false',
          label:       t('wizard.building.assessment.is_assessment_available'),
          validations: ['required'],
        },
        upload_message:          {
          component: 'message',
          className: 'global-message info uk-width-1-1',
          message:   t('wizard.building.assessment.upload_message'),
          visible:   {
            property: 'building.assessment.is_assessment_available',
          },
        },
      },
      visible:    {
        property:  'building.evaluation.sub_type',
        assertion: 'equal',
        value:     'Assessment',
      },
    },
  };
};
