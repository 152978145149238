import { getAbexList } from 'models/application/constants';

export const getViviumBuildingSumInsuredSchema = () => {
  return {
    sum_insured: {
      type:       'section',
      properties: {
        sum_insured:         {
          validations: ['required', 'maxLength10', 'only_int_price'],
          label:       t('wizard.building.assessment.sum_insured'),
          component:   'price',
        },
        sum_insured_message: {
          type:      'text',
          className: 'global-message warning xs p-5 mt-0 mb-0',
          inline:    true,
          text:      t('schemas.vivium.building.vivium_building_sum_insured_schema.sum_insured_message'),
        },
        abex:                {
          validations: ['required'],
          label:       t('wizard.building.assessment.abex'),
          component:   'select',
          list:        getAbexList(),
        },
      },
      visible:    [
        {
          property:  'evaluation.evaluation_method',
          assertion: 'notEqual',
          value:     'p_v_number_of_rooms_grid',
        },
        {
          property:  'evaluation.evaluation_method',
          assertion: 'notEqual',
          value:     'surface_grid',
        },
        {
          property:  'evaluation.evaluation_method',
          assertion: 'exists',
        },
      ],
    },
  };
};
