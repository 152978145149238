import React                          from 'react';
import LoadOnClickButton              from 'components/ui/buttons/load_on_click_button';
import confirmBeforeAction            from 'components/ui/buttons/helpers/confirm_before_action';
import { useDispatch, useSelector }   from 'react-redux';
import { signContract }               from 'models/contracts/requests';
import { updateTarification }         from 'models/wizard/store_actions';
import { setPollingForTarifications } from 'models/wizard/dispatch_actions';
import { isSocketConnected }          from 'models/config/reducer';

const ConfirmLoadingButton = confirmBeforeAction()(LoadOnClickButton);

const SignContractButton = ({ shouldConfirm = true, tarification, onContractSign }) => {
  if (!tarification.authorizations.sign_contract || tarification.computing) {
    return null;
  }
  const isSocketUp = useSelector((state) => isSocketConnected(state));
  const Component = shouldConfirm ? ConfirmLoadingButton : LoadOnClickButton;
  const dispatch = useDispatch();
  const sign = () => {
    signContract(tarification).then(({ tarification: signContractTarification }) => {
      dispatch(updateTarification(signContractTarification));
      if (!isSocketUp) {
        dispatch(setPollingForTarifications([tarification], (updatedTarification) => {
          dispatch(updateTarification(updatedTarification));
        }));
      }
      if (onContractSign) {
        onContractSign();
      }
    });
  };
  return (
    <Component
      primary
      confirmText={ t('axa.constructor.contract.confirm_sign_contract.text') }
      confirmTitle={ t('axa.constructor.contract.confirm_sign_contract.title') }
      title={ t('axa.constructor.contract.sign_contract') }
      large
      onClick={ sign }
    >
      { t('axa.constructor.contract.sign_contract') }
    </Component>
  );
};

export default SignContractButton;
