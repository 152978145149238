import React, { useContext } from 'react';
import JsonFormContext       from 'components/ui/json_form/json_form_context';
import Button                from 'components/ui/buttons/button';
import JsonFormFields        from 'components/ui/json_form/json_form_fields';
import { getDefaultValues }  from 'components/ui/json_form/json_form_helpers';
import FieldArrayContext     from 'components/ui/json_form/json_field_array_context';
import Icon                  from 'components/ui/icon';

const TableArrayFields = ({ fields }) => {
  const { field, form, change } = useContext(FieldArrayContext);
  const { getPropertyValue } = useContext(JsonFormContext);
  const columns = Object.keys(field.properties).map((property) => field.properties[property].label);
  return (
    <div className="uk-width-1-1" data-purpose={ fields.name }>
      <table className="uk-table uk-table-small uk-table-middle uk-table-divider">
        <thead>
          <tr>
            <th className="uk-text-center uk-table-shrink" />
            { columns.map((col, key) => (<th key={ key }>{ col }</th>)) }
            <th className="uk-text-center uk-table-shrink">
              { t('general.actions.actions') }
            </th>
          </tr>
        </thead>
        { fields.length === 0 && field.array_actions.empty_label && (
          <tbody>
            <tr>
              <td
                className="uk-text-center uk-text-muted"
                colSpan={ columns.length + 2 }
              >
                { field.array_actions.empty_label }
              </td>
            </tr>
          </tbody>
        ) }
        <tbody>
          { fields.map((array_item, id) => {
            return (
              <tr key={ id }>
                <td>#{ id + 1 }</td>
                <JsonFormFields
                  schema={ field.properties }
                  namePrefix={ array_item }
                  change={ change }
                  form={ form }
                  inArray={ true }
                  fieldWrapper="td"
                  fieldOverride={ {
                    inline: false,
                    label:  false,
                  } }
                />
                <td className="uk-text-center">
                  <Icon
                    icon="delete"
                    className="error"
                    onClick={ () => fields.remove(id) }
                    data-purpose="remove_item_button"
                  />
                </td>
              </tr>
            );
          }) }
        </tbody>
        <tfoot>
          <tr>
            <td
              className="uk-text-center"
              colSpan={ columns.length + 2 }
            >
              <Button
                type="button"
                primary
                data-purpose="add_item_button"
                disabled={ field.array_actions.max && fields.length >= field.array_actions.max }
                onClick={ () => {
                  if (field.array_actions.max && fields.length === field.array_actions.max) {
                    return;
                  }
                  fields.push(getDefaultValues(field.properties, getPropertyValue));
                } }
              >
                { field.array_actions.add_label }
              </Button>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
};

export default TableArrayFields;
