import React                from 'react';
import { useSelector }      from 'react-redux';
import { goToNewFolder }    from 'models/folders/constants';
import openDialogOnClick    from 'components/ui/buttons/helpers/open_dialog_on_click';
import Icon                 from 'components/ui/icon';
import SubscriptionRequired from 'components/subscription/subscription_required';

const MultiTarifButton = (props) => {
  return <div
    className="product-card-item"
    data-purpose="multi_tarif_product_button"
    { ...props }
  >
    <Icon
      icon="folder"
      className="product-card-icon"
    />
    <div className="product-card-name">
      { t('folders.multi_tarif_auto') }
    </div>
  </div>;
};

const OpenSubscriptionRequiredModalButton = openDialogOnClick()(MultiTarifButton);

const MultiTarificationButton = ({ forLead, beforeOnClick }) => {
  const hasMultiTarification = useSelector(state => state.current_user.profile.authorizations.has_multi_tarif);
  const activeSubscription = useSelector(state => state.current_user.profile.authorizations.has_active_subscription);

  if (!hasMultiTarification) {
    return null;
  }

  if (activeSubscription) {
    return (
      <MultiTarifButton onClick={ (e) => {
        goToNewFolder(e, null, null, forLead);
      } } />
    );
  }

  return (
    <OpenSubscriptionRequiredModalButton
      dialogTitle={ t('subscriptions.required') }
      beforeOpenDialog={ beforeOnClick }
    >
      <SubscriptionRequired />
    </OpenSubscriptionRequiredModalButton>
  );
};

export default MultiTarificationButton;
