import React           from 'react';
import PropTypes       from 'prop-types';
import { useSelector } from 'react-redux';
import MainMenu        from 'components/main_menu/main_menu';
import Logo            from 'components/main_header/logo';
import UserMenu        from 'components/main_header/user_menu';
import Helmet          from 'react-helmet';

const MainHeader = ({ mainMenu, publicPage }) => {
  const logoUrl = useSelector((state) => state.account && state.account.logo.url);

  return (
    <div className="main-menu">
      <Helmet>
        <body data-main-menu="has--main-menu" />
      </Helmet>
      <Logo url={ logoUrl } />
      { !publicPage && (
        <React.Fragment>
          <MainMenu menu={ mainMenu } />
          <UserMenu withProductFactoryLink />
        </React.Fragment>
      ) }
    </div>
  );
};

MainHeader.defaultProps = {
  mainMenu:   [],
  publicPage: false,
};

MainHeader.propTypes = {
  mainMenu:   PropTypes.array,
  publicPage: PropTypes.bool,
};

export default MainHeader;
