import React           from 'react';
import {
  reduxForm,
  FormSection,
  Field,
}                      from 'redux-form';
import SubmitButton    from 'components/ui/form/submit_button';
import SymbolTextInput from 'components/ui/form/symbol_text_input';
import TextInput       from 'components/ui/form/text_input';
import SelectInput     from 'components/ui/form/select_input';
import Icon            from 'components/ui/icon';
import Title           from 'components/ui/titles';
import {
  locales,
  notificationFrequencies,
}                      from 'constants/application_constants';
import Margin          from 'components/ui/margin';
import InputLabel      from 'components/ui/form/input_label';

const minLength = (value) => {
  if (value && value.length < 6) {
    return t('profiles.edit.password_rules');
  }
  return null;
};

const passwordsMatch = (value, allValues) => {
  if (value !== allValues.password) {
    return t('activerecord.attributes.user.password_confirmation_invalid');
  }
  return null;
};

const ProfileForm = (props) => {
  const { handleSubmit, submitting, offices, initialValues } = props;
  return (
    <form
      onSubmit={ handleSubmit }
      data-purpose="profile_form"
    >
      <div
        data-uk-grid
        className="uk-grid-small"
      >
        <div className="uk-width-1-1@s uk-width-1-2@m">
          <Margin>
            <InputLabel htmlFor="first_name">
              <Icon icon="user" /> { t('profiles.edit.first_name') }
            </InputLabel>
            <Field
              name="first_name"
              id="first_name"
              placeholder={ t('profiles.edit.first_name_placeholder') }
              component={ TextInput }
              type="text"
            />
          </Margin>
        </div>
        <div className="uk-width-1-1@s uk-width-1-2@m">
          <Margin>
            <InputLabel htmlFor="last_name">
              <Icon icon="user" /> { t('profiles.edit.last_name') }
            </InputLabel>
            <Field
              name="last_name"
              id="last_name"
              placeholder={ t('profiles.edit.last_name_placeholder') }
              component={ TextInput }
              type="text"
            />
          </Margin>
        </div>
        <div className="uk-width-1-1@s uk-width-1-2@m">
          <Margin>
            <InputLabel htmlFor="email">
              <Icon icon="email" /> { t('profiles.edit.email') }
            </InputLabel>
            <Field
              name="email"
              id="email"
              placeholder={ t('profiles.edit.email_placeholder') }
              component={ SymbolTextInput }
              type="email"
              symbol="@"
              autoComplete="not-now"
            />
          </Margin>
        </div>
        <div className="uk-width-1-1@s uk-width-1-2@m">
          <Margin>
            <InputLabel htmlFor="locale">
              <Icon icon="language" /> { t('profiles.edit.locale') }
            </InputLabel>
            <Field
              name="locale"
              purpose="locale"
              id="locale"
              className="locale"
              list={ locales() }
              component={ SelectInput }
            />
          </Margin>
        </div>
        { initialValues.authorizations.change_office && (
          <div className="uk-width-1-1@s uk-width-1-2@m">
            <Margin>
              <InputLabel htmlFor="office_id">
                { t('profiles.edit.default_office') }
              </InputLabel>
              <Field
                name="office_id"
                id="office_id"
                className="office_id"
                list={ offices }
                component={ SelectInput }
              />
            </Margin>
          </div>
        ) }
      </div>
      <Title size="h3">{ t('profiles.edit.new_password_title') }</Title>
      <p className="small-italic">{ t('profiles.edit.password_hint') }</p>
      <div
        data-uk-grid
        className="uk-grid-small"
      >
        <div className="uk-width-1-1@s uk-width-1-2@m">
          <Margin>
            <InputLabel htmlFor="password">
              <Icon icon="lock" /> { t('profiles.edit.password') }
            </InputLabel>
            <Field
              name="password"
              id="password"
              autoComplete="new-password"
              placeholder={ t('profiles.edit.password_placeholder') }
              component={ SymbolTextInput }
              symbol={ <Icon icon="lock" /> }
              type="password"
              data-private="lipsum"
              validate={ [minLength, passwordsMatch] }
            />
          </Margin>
        </div>
        <div className="uk-width-1-1@s uk-width-1-2@m">
          <Margin>
            <InputLabel htmlFor="confirm_password">
              <Icon icon="lock" /> { t('profiles.edit.confirm_password') }
            </InputLabel>
            <Field
              name="confirm_password"
              id="confirm_password"
              data-private="lipsum"
              autoComplete="new-password"
              placeholder={ t('profiles.edit.confirm_password_placeholder') }
              component={ SymbolTextInput }
              symbol={ <Icon icon="lock" /> }
              type="password"
              validate={ [minLength, passwordsMatch] }
            />
          </Margin>
        </div>
      </div>
      <SubmitButton
        large
        containerClassName="mt-20"
        loading={ submitting }
        data-purpose="profile_submit_button"
      />
    </form>
  );
};


export default reduxForm({
  form: 'user_profile',
})(ProfileForm);
