import React from 'react';

export const getAxaPreviousCompanySchema = () => {
  return {
    form:   'previous_company_schema',
    schema: {
      misc:             {
        type:       'section',
        properties: {
          has_previous_company: {
            component: 'true_false',
            label:     t('schemas.axa.constructor.axa_constructor_steps.has_previous_company'),
            default:   {
              value: false,
            },
          },
        },
      },
      previous_company: {
        type:       'section',
        visible:    {
          property:    'misc.has_previous_company',
          assertion:   'isTrue',
          validations: ['required'],
        },
        properties: {
          company_code:        {
            component: 'select',
            label:     t('schemas.axa.constructor.axa_constructor_steps.previous_company'),
            validations: ['required'],
            list:      [
              {
                value: 'B00942',
                label: 'ANSVAR VERZEKERINGSMIJ.NV',
              },
              {
                value: 'B09224',
                label: 'NV NAUTICA',
              },
              {
                value: 'B00933',
                label: 'M.D.V. ASSURANCES S.A.',
              },
              {
                value: 'B02467',
                label: 'QUINN DIRECT INSURANCE LIMITED',
              },
              {
                value: 'B00042',
                label: 'LA MEDICALE',
              },
              {
                value: 'B09058',
                label: 'DE BUYL CATHERINE',
              },
              {
                value: 'B00527',
                label: 'LEGAL & GENERAL ASS. SOC.',
              },
              {
                value: 'B00125',
                label: 'AGF ASSUBEL',
              },
              {
                value: 'B00373',
                label: 'LE MANS ASSURANCES',
              },
              {
                value: 'B01009',
                label: 'PROTECT',
              },
              {
                value: 'B00978',
                label: 'HELIOS RISQUES DIVERS',
              },
              {
                value: 'B00039',
                label: 'AXA BELGIUM',
              },
              {
                value: 'B00971',
                label: 'BELSTAR',
              },
              {
                value: 'B05537',
                label: 'IBIS',
              },
              {
                value: 'B00906',
                label: 'ASSAR',
              },
              {
                value: 'B01005',
                label: 'SEGUROS GRUPO VITALICIO',
              },
              {
                value: 'B10536',
                label: 'TOYOTA ASSURANCES',
              },
              {
                value: 'B00664',
                label: 'SWISS LIFE BELGIUM',
              },
              {
                value: 'B00670',
                label: 'SCHWEIZ ASSURANCE',
              },
              {
                value: 'B01014',
                label: 'S-LEVEN',
              },
              {
                value: 'B01012',
                label: 'ZA VERZEKERINGEN',
              },
              {
                value: 'B02018',
                label: 'SAMPO INDUSTRIAL INSURANCE NV',
              },
              {
                value: 'B00878',
                label: 'JUSTITIA',
              },
              {
                value: 'B00032',
                label: 'AMEV ASSURANCE',
              },
              {
                value: 'B00915',
                label: 'TAISHO MARINE AND FIRE INS LTD',
              },
              {
                value: 'B00897',
                label: 'PRO DEO',
              },
              {
                value: 'B00777',
                label: 'NURNBERGER ALLGEMEINE VERSICHERUNG',
              },
              {
                value: 'B00660',
                label: 'ETHIAS ACCIDENT DU TRAVAIL',
              },
              {
                value: 'B00154',
                label: 'LES PATRONS REUNIS',
              },
              {
                value: 'B02550',
                label: 'NN INSURANCE BELGIUM NV',
              },
              {
                value: 'B00146',
                label: 'LLOYD CONTINENTAL',
              },
              {
                value: 'B02079',
                label: 'ZURICH INSURANCE PLC-BELGIUM BRANCH',
              },
              {
                value: 'B00112',
                label: 'NORWICH UNION',
              },
              {
                value: 'B00556',
                label: 'MUTUELLE ELECTRIQUE D\'ASSURANCES',
              },
              {
                value: 'B00560',
                label: 'OHRA BELGIUM VIE',
              },
              {
                value: 'B09115',
                label: 'MIGRATION AXA',
              },
              {
                value: 'B01331',
                label: 'AVIKA',
              },
              {
                value: 'B00938',
                label: 'AFIA INS. CY',
              },
              {
                value: 'B00964',
                label: 'PARTNERS ASSURANCES',
              },
              {
                value: 'B00770',
                label: 'ASS. INTERNATIONALES',
              },
              {
                value: 'B00809',
                label: 'CAISSE PREVOYANCE DES PHARMACIENS',
              },
              {
                value: 'B00730',
                label: 'C.I.A.R.',
              },
              {
                value: 'B00087',
                label: 'FEDERALE ASSURANCE',
              },
              {
                value: 'B02003',
                label: 'ONDERLINGE BOSSENVERZEKERING',
              },
              {
                value: 'B00900',
                label: 'SAFE INSURANCE SA',
              },
              {
                value: 'B00751',
                label: 'RHIN & MOSELLE',
              },
              {
                value: 'B00640',
                label: 'XXX',
              },
              {
                value: 'B00055',
                label: 'SUN ALLIANCE SA',
              },
              {
                value: 'B05100',
                label: 'HAENECOUR J & CO',
              },
              {
                value: 'B09208',
                label: 'BVBA PHILIP KNIGHT & CO',
              },
              {
                value: 'B10606',
                label: 'INEAS INSURANCE COMPANY',
              },
              {
                value: 'B00015',
                label: 'DE KORTRIJKSE VERZEKERING',
              },
              {
                value: 'B00973',
                label: 'R + V',
              },
              {
                value: 'B02393',
                label: 'OPTIMCO',
              },
              {
                value: 'B97003',
                label: 'UNION NAT DES MUTUALITES LIBERALES',
              },
              {
                value: 'B97005',
                label: 'CAISSE AUX ASS MALADIE INVALIDITE',
              },
              {
                value: 'B00469',
                label: 'LA FAMILLE (VIE)',
              },
              {
                value: 'B00945',
                label: 'M.A.S.T.I.',
              },
              {
                value: 'B05600',
                label: 'IMMOAF',
              },
              {
                value: 'B01525',
                label: 'ACTELDIRECT',
              },
              {
                value: 'B01011',
                label: 'SPARKASSEN',
              },
              {
                value: 'B00447',
                label: 'THE DOWA FIRE & MARINE INS CY',
              },
              {
                value: 'B00948',
                label: 'A.P.Z.',
              },
              {
                value: 'FICTIFID',
                label: 'Compagnie non définie',
              },
              {
                value: 'B01019',
                label: 'PROVIDIS',
              },
              {
                value: 'B00135',
                label: 'PRECAM',
              },
              {
                value: 'B01052',
                label: 'OVAP VEV',
              },
              {
                value: 'B00738',
                label: 'NATIONALE NEDERLANDEN',
              },
              {
                value: 'B00661',
                label: 'ETHIAS INCENDIE',
              },
              {
                value: 'B01050',
                label: 'AIM BELGIE',
              },
              {
                value: 'B00928',
                label: 'CREDIMO',
              },
              {
                value: 'B00991',
                label: 'WINTERTHUR EUROPE-VIE',
              },
              {
                value: 'B00591',
                label: 'NORDSTERN',
              },
              {
                value: 'B08600',
                label: 'MINERVA UNDERWRITERS',
              },
              {
                value: 'B02831',
                label: 'MSIG INSURANCE EUROPE AG',
              },
              {
                value: 'B00993',
                label: 'OMNIVER SA',
              },
              {
                value: 'B00722',
                label: 'NEW ZEALAND INS. CY',
              },
              {
                value: 'B00951',
                label: 'ALBINGIA VERSICHERUNGS AG',
              },
              {
                value: 'B00600',
                label: 'ACE INSURANCE',
              },
              {
                value: 'B02004',
                label: 'AEGON SCHAD C-O BROADSPIRE',
              },
              {
                value: 'B00113',
                label: 'XXX',
              },
              {
                value: 'B00905',
                label: 'DELTA LLOYD LIFE',
              },
              {
                value: 'B05538',
                label: 'SA AVISE',
              },
              {
                value: 'B05536',
                label: 'SCOTTISH INSURANCE CORPORATION',
              },
              {
                value: 'B00415',
                label: 'C.A.M.A.T',
              },
              {
                value: 'B00958',
                label: 'ANDREW WEIR INSURANCE COMPANY LTD',
              },
              {
                value: 'B01400',
                label: 'ARCES SA',
              },
              {
                value: 'B00037',
                label: 'BELFIUS',
              },
              {
                value: 'B01395',
                label: 'ASSURANCES DE LA POSTE',
              },
              {
                value: 'B00033',
                label: 'FIDEA SA',
              },
              {
                value: 'B00149',
                label: 'XXX',
              },
              {
                value: 'B00935',
                label: 'CENTRAAL BEHEER',
              },
              {
                value: 'B09311',
                label: 'SA JEAN VERHEYEN',
              },
              {
                value: 'B01449',
                label: 'NN NON-LIFE INSURANCE',
              },
              {
                value: 'B00754',
                label: 'SOCIETA ITALIANA ASSICURAZIONI TRAS',
              },
              {
                value: 'B00480',
                label: 'FRANCE',
              },
              {
                value: 'B00827',
                label: 'GROUPE VICTOIRE',
              },
              {
                value: 'B00455',
                label: 'ENGLISH & AMERICAN INS. CY LTD',
              },
              {
                value: 'B00742',
                label: 'BOREAS',
              },
              {
                value: 'B09423',
                label: 'DANISH MARKET',
              },
              {
                value: 'B00056',
                label: 'AEGON',
              },
              {
                value: 'B02789',
                label: 'ROYAL & SUN ALLIANCE INSURANCE',
              },
              {
                value: 'B97004',
                label: 'UNION NATIONALE MUTUALITES NEUTRES',
              },
              {
                value: 'B00725',
                label: 'YASUDA FIRE & MARINE INS.',
              },
              {
                value: 'B00487',
                label: 'AXA ASSISTANCE',
              },
              {
                value: 'B00122',
                label: 'NEW HAMPSHIRE INS',
              },
              {
                value: 'B00062',
                label: 'EX-SECOURS DE BELGIQUE',
              },
              {
                value: 'B00537',
                label: 'LONDON & HULL MARITIME',
              },
              {
                value: 'B00950',
                label: 'AMEV ASSURANCES VIE',
              },
              {
                value: 'B00983',
                label: 'ZURICH INSURANCE PLC',
              },
              {
                value: 'B00930',
                label: 'AACHENER UND MUNCHENER V',
              },
              {
                value: 'B00987',
                label: 'LONDON GENERAL INSURANCE CY',
              },
              {
                value: 'B00979',
                label: 'CARDIF-VIE',
              },
              {
                value: 'B00383',
                label: 'IRISK SPRL',
              },
              {
                value: 'B00445',
                label: 'ARAG SA',
              },
              {
                value: 'B01013',
                label: 'HAENECOUR',
              },
              {
                value: 'B05312',
                label: 'CB DIRECT LPS',
              },
              {
                value: 'B00361',
                label: 'AVIABEL',
              },
              {
                value: 'B00086',
                label: 'CAFB',
              },
              {
                value: 'B00771',
                label: 'KEMPER',
              },
              {
                value: 'B00896',
                label: 'BELASSUR',
              },
              {
                value: 'B00100',
                label: 'SUN ALLIANCE',
              },
              {
                value: 'B00464',
                label: 'EUROPA',
              },
              {
                value: 'B00763',
                label: 'THE CONTINENTAL INSURANCE COMPANY',
              },
              {
                value: 'B00580',
                label: 'NIEUWE NATIONALE VERZEK. SOCIETEIT',
              },
              {
                value: 'B02727',
                label: 'GREENVAL INS C-O BROADSPIRE',
              },
              {
                value: 'B00868',
                label: 'AGF ASSUBEL',
              },
              {
                value: 'B00907',
                label: 'PROTECTION ASSIST AUTO',
              },
              {
                value: 'B00593',
                label: 'NORWICH UNION LIFE INS  SOCIETY',
              },
              {
                value: 'B00109',
                label: 'ALLIANCE ASSURANCE COMPAGNY',
              },
              {
                value: 'B09015',
                label: 'DE KORTRIJKSE VERZEKERING',
              },
              {
                value: 'B00151',
                label: 'WIGHAM POLAND BELGIUM',
              },
              {
                value: 'B00335',
                label: 'ASSITALIA',
              },
              {
                value: 'B05606',
                label: 'INEAS INSURANCE COMPANY',
              },
              {
                value: 'B00468',
                label: 'LA FAMILLE',
              },
              {
                value: 'B00970',
                label: 'DBV EN PARTNER LEVEN',
              },
              {
                value: 'B00999',
                label: 'ING LIFE',
              },
              {
                value: 'B00024',
                label: 'AXA BELGIUM',
              },
              {
                value: 'B00370',
                label: 'BELGAMAR',
              },
              {
                value: 'B00602',
                label: 'PACIFIC EMPOYERS INSURANCE COMPANY',
              },
              {
                value: 'B00860',
                label: 'LLOYD\'S OF LONDON',
              },
              {
                value: 'B00430',
                label: 'CONSTANTIA',
              },
              {
                value: 'B05004',
                label: 'ONDERL BRANDVERZEK AVEL-ANZE-KAST',
              },
              {
                value: 'B00091',
                label: 'AGF L\'ESCAUT',
              },
              {
                value: 'B00163',
                label: 'THEMIS',
              },
              {
                value: 'B00035',
                label: 'GENERAL ACCIDENT',
              },
              {
                value: 'B09363',
                label: 'LONDON MARKET',
              },
              {
                value: 'B02002',
                label: 'THE YASUDA KASAI INS CO OF EUROPE',
              },
              {
                value: 'B02312',
                label: 'ACE EUROPEAN GROUP LIMITED',
              },
              {
                value: 'B00320',
                label: 'AMFB',
              },
              {
                value: 'B05201',
                label: 'FOYER ASSURANCES',
              },
              {
                value: 'B00634',
                label: 'RHONE-MEDITERRANEE',
              },
              {
                value: 'B00014',
                label: 'KBC',
              },
              {
                value: 'B00939',
                label: 'BOREAS LEVEN',
              },
              {
                value: 'B97002',
                label: 'UNION NATIONALE MUTUALITES LIBRES',
              },
              {
                value: 'B00791',
                label: 'WURTTEMB. FEUERVERS. AG STUTTGART',
              },
              {
                value: 'B97006',
                label: 'CAISSE SOINS DE SANTE SNCB HOLD',
              },
              {
                value: 'B00985',
                label: 'DELA VERZEKERINGEN NV',
              },
              {
                value: 'B00888',
                label: 'EUROP ASSISTANCE',
              },
              {
                value: 'B00089',
                label: 'EAGLE STAR INSURANCE COMPANY LTD',
              },
              {
                value: 'B00394',
                label: 'FB ASSU - EX CGER',
              },
              {
                value: 'B00493',
                label: 'GOTHAER VERSICHERUNGSBANK VVAG',
              },
              {
                value: 'B10665',
                label: 'EURO INSURANCES LTD',
              },
              {
                value: 'B01008',
                label: 'C G A',
              },
              {
                value: 'B00199',
                label: 'EXCESS INSURANCE COMPANY LIMITED',
              },
              {
                value: 'B00699',
                label: 'VESTA',
              },
              {
                value: 'B02652',
                label: 'BALOISE BELGIUM NV',
              },
              {
                value: 'B00901',
                label: 'ALICO',
              },
              {
                value: 'B00965',
                label: 'AVON INSURANCE PLC',
              },
              {
                value: 'B00523',
                label: 'ITALIA-ASSICURAZIONI',
              },
              {
                value: 'B00967',
                label: 'ZILVEREN KRUIS SCHADEVERZ. N.V.',
              },
              {
                value: 'B09205',
                label: 'BELGO AMERICAN UNDERWRITING OFFICE',
              },
              {
                value: 'B00179',
                label: 'FONDS COMMUN DE GARANTIE BELGE',
              },
              {
                value: 'B00998',
                label: 'TVM BELGIE',
              },
              {
                value: 'B00192',
                label: 'RVS',
              },
              {
                value: 'B00429',
                label: 'DROUOT ASSURANCES',
              },
              {
                value: 'B02279',
                label: 'ACTEL',
              },
              {
                value: 'B00922',
                label: 'NIPPON INSURANCE CY.',
              },
              {
                value: 'B00345',
                label: 'LES ASSUR  FEDERALES  ACC  TRAVAIL',
              },
              {
                value: 'B00424',
                label: 'COMPAGN.DES MARINIERS FRANCO-BELGE',
              },
              {
                value: 'B00984',
                label: 'G B    LEX   VOIR JURIS',
              },
              {
                value: 'B00331',
                label: 'ARFIN',
              },
              {
                value: 'B00076',
                label: 'CHARTIS EUROPE',
              },
              {
                value: 'B00316',
                label: 'ALGEMENE VERVOERVERZEKERING',
              },
              {
                value: 'B00078',
                label: 'XXX',
              },
              {
                value: 'B01030',
                label: 'FINA LIFE',
              },
              {
                value: 'B07850',
                label: 'UNIREAS',
              },
              {
                value: 'B00318',
                label: 'ALPINA',
              },
              {
                value: 'B02796',
                label: 'TVM BELGIUM NV',
              },
              {
                value: 'B00716',
                label: 'MONDIAL ASSISTANCE',
              },
              {
                value: 'B01017',
                label: 'LIBRA-RE',
              },
              {
                value: 'B00601',
                label: 'ORTELIUS',
              },
              {
                value: 'B00500',
                label: 'GUARDIAN ROYAL (GESTION TULKENS)',
              },
              {
                value: 'B05023',
                label: 'ONDERLINGE BRANDVERZEKER ROESELAERE',
              },
              {
                value: 'B00874',
                label: 'CICERO',
              },
              {
                value: 'B01015',
                label: 'ATV SA',
              },
              {
                value: 'B00051',
                label: 'VIVIUM',
              },
              {
                value: 'B02191',
                label: 'HDI GERLING VERZEKERINGEN NV',
              },
              {
                value: 'B00420',
                label: 'L\'EUROPEENNE MARCHANDISES BAGAGES',
              },
              {
                value: 'B02387',
                label: 'AIOI NISSAY DOWA INS EUROPE',
              },
              {
                value: 'B00016',
                label: 'FLANDRE',
              },
              {
                value: 'B00956',
                label: 'METLIFE INSURANCE SA',
              },
              {
                value: 'B01258',
                label: 'FOYER ASSURANCES SA',
              },
              {
                value: 'B00962',
                label: 'PRESERVATRICE FONCIERE TIARD',
              },
              {
                value: 'B00690',
                label: 'EURAL UNITAS',
              },
              {
                value: 'B02314',
                label: 'VIVIUM SA',
              },
              {
                value: 'B00859',
                label: 'NV LUC THIBAUT & CO "VERZEKERAARS"',
              },
              {
                value: 'B00106',
                label: 'ASSURANCE LIEGEOISE',
              },
              {
                value: 'B01029',
                label: 'ING INSURANCE',
              },
              {
                value: 'B02405',
                label: 'CATLIN BELGIUM',
              },
              {
                value: 'B05665',
                label: 'EURO INSURANCES LTD C O AON BELGIUM',
              },
              {
                value: 'B00780',
                label: 'SPHERE DRAKE INS. CY PLC',
              },
              {
                value: 'B00969',
                label: 'FIRM SA',
              },
              {
                value: 'B01025',
                label: 'EUROPAEA',
              },
              {
                value: 'B02001',
                label: 'ALLIANZ NEDERLAND',
              },
              {
                value: 'B00986',
                label: 'GROUPE AUXIA',
              },
              {
                value: 'B00130',
                label: 'DVV VERZEKERINGEN',
              },
              {
                value: 'B01027',
                label: 'AXA COLONIA VERSICHERUNG',
              },
              {
                value: 'B00097',
                label: 'ALLIANZ BENELUX SA',
              },
              {
                value: 'B00185',
                label: 'NEUFCHATELOISE',
              },
              {
                value: 'B00703',
                label: 'VITA ZURICH VIE',
              },
              {
                value: 'B00747',
                label: 'DBV   PARTNERS ASSURANCES',
              },
              {
                value: 'B01023',
                label: 'AMAZON INSURANCE',
              },
              {
                value: 'B00095',
                label: 'BALOISE BELGIUM NV',
              },
              {
                value: 'B00161',
                label: 'A V M',
              },
              {
                value: 'B01021',
                label: 'CHIYODA FIRE & MARINE',
              },
              {
                value: 'B00402',
                label: 'CDA',
              },
              {
                value: 'B00988',
                label: 'LONDON GENERAL INSURANCE',
              },
              {
                value: 'B00067',
                label: 'WINTERTHUR EUROPE ASS',
              },
              {
                value: 'B00606',
                label: 'PEARL ASSURANCE COMPANY PLC',
              },
              {
                value: 'B07700',
                label: 'H. G. TULKENS',
              },
              {
                value: 'B00196',
                label: 'ETHIAS',
              },
              {
                value: 'B00063',
                label: 'ZELIA',
              },
              {
                value: 'B00542',
                label: 'MAGDEBURGER VERSICHERUNG AG',
              },
              {
                value: 'B00980',
                label: 'MENSURA LEVEN NV',
              },
              {
                value: 'B00940',
                label: 'FUJI FIRE & MARINE INS CY LTD',
              },
              {
                value: 'B00595',
                label: 'VICTORIA-VESTA',
              },
              {
                value: 'B10201',
                label: 'FOYER ASSURANCES',
              },
              {
                value: 'B09201',
                label: 'NV ANTWERP MARINE INSURANCE',
              },
              {
                value: 'B00072',
                label: 'ZURICH INSURANCE PLC',
              },
              {
                value: 'B00870',
                label: 'ALLIANCE BATELIERE DE LA SAMBRE BEL',
              },
              {
                value: 'B06680',
                label: 'HENRI J. MOERKERKE',
              },
              {
                value: 'B00721',
                label: 'C.E.A.I.',
              },
              {
                value: 'B01036',
                label: 'WURTTEMBERGISCHE VERSICHERUNG AG',
              },
              {
                value: 'B02145',
                label: 'ALLIANZ GLOBAL CORP & SPECIALITY AG',
              },
              {
                value: 'B00551',
                label: 'MINSTER INSURANCE COMPAGNY LTD',
              },
              {
                value: 'B01034',
                label: 'CB DIRECT',
              },
              {
                value: 'B01055',
                label: 'FONDS DE GARANTIE VOYAGES',
              },
              {
                value: 'B00418',
                label: 'C.O.B.A.C.',
              },
              {
                value: 'B99999',
                label: 'CIE FICT - MIGRATION',
              },
              {
                value: 'B01396',
                label: 'SERVIS',
              },
              {
                value: 'B00203',
                label: 'SKANDIA BELGIUM',
              },
              {
                value: 'B00333',
                label: 'ASCO ASSURANCES CONTINENTALES',
              },
              {
                value: 'B04400',
                label: 'GEENSEN AND CO',
              },
              {
                value: 'B00354',
                label: 'DELPHI VERZEKERINGEN',
              },
              {
                value: 'B00960',
                label: 'DE POPERINGSE',
              },
              {
                value: 'B00952',
                label: 'CONTASSUR',
              },
              {
                value: 'B00914',
                label: 'SECURITAS BREMER ALLGEMEINE VERS AG',
              },
              {
                value: 'B00745',
                label: 'AMLIN EUROPE',
              },
              {
                value: 'B00850',
                label: 'SA SATREX NV',
              },
              {
                value: 'B00723',
                label: 'NICHIDO FIRE & MARINE INS. CY LTD',
              },
              {
                value: 'B00937',
                label: 'GOUDSE VERZEKERING MAATSCHAPPIJ',
              },
              {
                value: 'B00058',
                label: 'P & V ASSURANCES SCRL',
              },
              {
                value: 'B00126',
                label: 'AMMA',
              },
              {
                value: 'B01004',
                label: 'AFILIFE',
              },
              {
                value: 'B00786',
                label: 'MANNHEIMER VERSICHERUNGSGESELLSCH.',
              },
              {
                value: 'B00356',
                label: 'LAR',
              },
              {
                value: 'B09529',
                label: 'GERMAN MARKET',
              },
              {
                value: 'B08001',
                label: 'SA AVISE',
              },
              {
                value: 'B09313',
                label: 'ITALIAN MARKET',
              },
              {
                value: 'B00124',
                label: 'ALPHA INSURANCE',
              },
              {
                value: 'B09510',
                label: 'SWISS MARKET',
              },
              {
                value: 'B08005',
                label: 'BROADSPIRE POUR CRAWFORD',
              },
              {
                value: 'B00388',
                label: 'DE BRUGSE VERZEKERING',
              },
              {
                value: 'B08003',
                label: 'ALBIC',
              },
              {
                value: 'B00166',
                label: 'ABEILLE PAIX',
              },
              {
                value: 'B00145',
                label: 'GENERALI BELGIUM',
              },
              {
                value: 'B05031',
                label: 'ONDERLINGE BRANDVERZ ST ELIGIUSGILD',
              },
              {
                value: 'B00944',
                label: 'SECUREX - VIE',
              },
              {
                value: 'B00134',
                label: 'ASS DE PREVOYANCE ET D\'ASSURANCE SA',
              },
              {
                value: 'B09528',
                label: 'SWEDISH MARKET',
              },
              {
                value: 'B01053',
                label: 'KORFINA',
              },
              {
                value: 'B01001',
                label: 'MEGA',
              },
              {
                value: 'B00651',
                label: 'SECURA',
              },
              {
                value: 'B09024',
                label: 'AXA BELGIUM',
              },
              {
                value: 'B00060',
                label: 'AXA BELGIUM',
              },
              {
                value: 'B01003',
                label: 'MEGA-LIFE',
              },
              {
                value: 'B00059',
                label: 'CGU',
              },
              {
                value: 'B00324',
                label: 'ANTVERPIA',
              },
              {
                value: 'B00805',
                label: 'SECUREX',
              },
              {
                value: 'B02518',
                label: 'ALPHA INSURANCE AS',
              },
              {
                value: 'B08004',
                label: 'MAAF ASSURANCES CO AIS CIRC INTER',
              },
              {
                value: 'B02015',
                label: 'XL INSURANCE COMPANY LTD',
              },
              {
                value: 'B01039',
                label: 'NVS SALLAND',
              },
              {
                value: 'B00968',
                label: 'E P I M',
              },
              {
                value: 'B00167',
                label: 'DELTA LLOYD LIFE',
              },
              {
                value: 'B00959',
                label: 'HEDDINGTON INSURANCE U.K.L.T.D.',
              },
              {
                value: 'B00530',
                label: 'LEVANTE',
              },
              {
                value: 'B00618',
                label: 'LA P S  CAISSE COMMUNE',
              },
              {
                value: 'B00953',
                label: 'AEGON VIE S A',
              },
              {
                value: 'B01040',
                label: 'INTERPOLIS SCHADE',
              },
              {
                value: 'B00687',
                label: 'DAS',
              },
              {
                value: 'B00127',
                label: 'LA METROPOLE',
              },
              {
                value: 'B01123',
                label: 'DELTA LLOYD SCHADEVERZEKERING',
              },
              {
                value: 'B00949',
                label: 'U.C.M. VIE',
              },
              {
                value: 'B00787',
                label: 'TRINITY INSURANCE COMPANY LIMITED',
              },
              {
                value: 'B09312',
                label: 'DUTCH MARKET',
              },
              {
                value: 'B00947',
                label: 'MONDIAL ASSISTANCE',
              },
              {
                value: 'B00071',
                label: 'WINTERTHUR',
              },
              {
                value: 'B00096',
                label: 'BALOISE INSURANCE',
              },
              {
                value: 'B01455',
                label: 'TOURING ASSURANCES',
              },
              {
                value: 'B00315',
                label: 'HAGELUNIE-ZOETERMEER',
              },
              {
                value: 'B00401',
                label: 'SECURITE BELGE',
              },
              {
                value: 'B00110',
                label: 'THE LONDON ASSUR-NAVIGA',
              },
              {
                value: 'B09215',
                label: 'XXXX',
              },
              {
                value: 'B04600',
                label: 'GERENE, HENDRIKX & DE WINTER',
              },
              {
                value: 'B00589',
                label: 'NORD-DEUTSCHE VERSICHERUNG AG',
              },
              {
                value: 'B97001',
                label: 'UNION NATIONALE DES MUT SOCIALISTES',
              },
              {
                value: 'B00338',
                label: 'AGF ASSUBEL',
              },
              {
                value: 'B02943',
                label: 'VAN BREDA CAR FINANCE',
              },
              {
                value: 'B00904',
                label: 'HAMBURG MANNHEIMER SACH AG',
              },
              {
                value: 'B00996',
                label: 'TOKIO AND FIRE INSURANCE CY LTD',
              },
              {
                value: 'B00847',
                label: 'SUN ASSISTANCE',
              },
              {
                value: 'B01093',
                label: 'BALOISE BELGIUM SA',
              },
              {
                value: 'B05200',
                label: 'STANISLAS HAINE',
              },
              {
                value: 'B00893',
                label: 'LAR',
              },
              {
                value: 'B00662',
                label: 'ETHIAS VIE',
              },
              {
                value: 'B00362',
                label: 'BALOISE',
              },
              {
                value: 'B00164',
                label: 'MENSURA',
              },
              {
                value: 'B09362',
                label: 'FRENCH MARKET',
              },
              {
                value: 'B00034',
                label: 'UAB',
              },
              {
                value: 'B01032',
                label: 'ROYAL INSURANCE GLOBAL',
              },
              {
                value: 'B00499',
                label: 'GUARDIAN ROYAL (GESTION IMMOAF)',
              },
              {
                value: 'B00099',
                label: 'OHRA BELGIUM',
              },
              {
                value: 'B01010',
                label: 'ZURICH INSURANCE PLC',
              },
              {
                value: 'B00435',
                label: 'CORONA DIRECT',
              },
              {
                value: 'B00472',
                label: 'CHUBB INSURANCE CY OF EUROPE SE',
              },
              {
                value: 'B00902',
                label: 'SOVEREIGN MARITIME & GENERAL INS CY',
              },
              {
                value: 'B00994',
                label: 'SINT-DONATUSGILDE',
              },
              {
                value: 'B00461',
                label: 'ASG SYNDICAT GENERAL',
              },
              {
                value: 'B00028',
                label: 'JOSI',
              },
              {
                value: 'B05400',
                label: 'HDI-GERLING ASSURANCES',
              },
              {
                value: 'B00982',
                label: 'ACEP',
              },
              {
                value: 'B00992',
                label: 'ALPHA LIFE SA',
              },
              {
                value: 'B01800',
                label: 'BDM',
              },
              {
                value: 'B00129',
                label: 'L\'ARDENNE PREVOYANTE',
              },
              {
                value: 'B00592',
                label: 'COMMERCIAL UNION ASS  CIE PLC',
              },
              {
                value: 'B08333',
                label: 'IBIS',
              },
              {
                value: 'B01080',
                label: 'NAVIGATORS NV',
              },
              {
                value: 'B00573',
                label: 'BALOISE BELGIUM NV',
              },
              {
                value: 'B09530',
                label: 'AMERICAN MARKET',
              },
              {
                value: 'B00990',
                label: 'HARTFORD INTERNATIONAL INT. INS.',
              },
              {
                value: 'B00019',
                label: 'OHRA BELGIUM',
              },
              {
                value: 'B00963',
                label: 'ALLIANZ VERSICHERUNGS AG',
              },
              {
                value: 'B00346',
                label: 'LES ASSURANCES FEDERALESVIE',
              },
              {
                value: 'B00976',
                label: 'CHARTIS EUROPE',
              },
              {
                value: 'B00579',
                label: 'NIEUW ROTTERDAM ASSURANTIE MIJ NV',
              },
              {
                value: 'B00700',
                label: 'VICTORIA FEUER VERSICHERUNG A.G.',
              },
              {
                value: 'B00342',
                label: 'LES ASSURANCES DU CREDIT',
              },
              {
                value: 'B02551',
                label: 'NN INSURANCE SERVICES BELGIUM',
              },
              {
                value: 'B00171',
                label: 'LION BELGE',
              },
              {
                value: 'B00981',
                label: 'LA BELGIQUE SA',
              },
              {
                value: 'B00344',
                label: 'LES ASSUR. FEDERALES (SAL. GARANTI)',
              },
              {
                value: 'B00330',
                label: 'A.R.C.O.',
              },
              {
                value: 'B08002',
                label: 'VANDER HAEGEN',
              },
              {
                value: 'B00796',
                label: 'NOWM VERZEKERINGEN',
              },
              {
                value: 'B02812',
                label: 'ARAG SE BRANCH BELGIUM',
              },
              {
                value: 'B00080',
                label: 'AGF L\'ESCAUT',
              },
              {
                value: 'B09204',
                label: 'SA BELGIAN MARINE INSURERS',
              },
              {
                value: 'B00077',
                label: 'URBAINE UAP',
              },
              {
                value: 'B00123',
                label: 'CB 1821',
              },
              {
                value: 'B00379',
                label: 'BLACK SEA AND GENERAL INS. CY LTD',
              },
              {
                value: 'B08950',
                label: 'LSC VAN OMMEN NV',
              },
              {
                value: 'B00165',
                label: 'ETHIAS',
              },
              {
                value: 'B00359',
                label: 'AUDI',
              },
              {
                value: 'B01037',
                label: 'PIETTE & PARTNERS',
              },
              {
                value: 'B06600',
                label: 'MICHIELS & LAMQUET',
              },
              {
                value: 'B00103',
                label: 'UNION DES ASSUREURS',
              },
              {
                value: 'B00079',
                label: 'AG INSURANCE',
              },
              {
                value: 'B00105',
                label: 'URANUS',
              },
              {
                value: 'B01007',
                label: 'OMNIVER VIE',
              },
              {
                value: 'B02013',
                label: 'SCOTTISH INSURANCE CORPORATION',
              },
              {
                value: 'B00066',
                label: 'UNION ET PHENIX ESPAGNOL',
              },
              {
                value: 'B00497',
                label: 'GROUPE JOSI 1909',
              },
              {
                value: 'B00862',
                label: 'UNION ASSURES BENELUX',
              },
              {
                value: 'B00372',
                label: 'AXA CAISSE COMMUNE',
              },
              {
                value: 'B00339',
                label: 'MENSURA',
              },
              {
                value: 'B00767',
                label: 'HDI-GERLING ASSURANCES',
              },
              {
                value: 'B00026',
                label: 'DE MIDDENSTAND CO',
              },
              {
                value: 'B00955',
                label: 'SYNDICAT GENERAL',
              },
              {
                value: 'B00858',
                label: 'ARGENTA',
              },
              {
                value: 'B00463',
                label: 'EUROMEX',
              },
              {
                value: 'B00997',
                label: 'ANTVERPIA - LEVEN',
              },
              {
                value: 'B97000',
                label: 'ALLIANCE NATIONALE MUT CHRETIENNES',
              },
              {
                value: 'B09057',
                label: 'HAENECOUR & CO',
              },
              {
                value: 'B01042',
                label: 'NCM INTERNATIONAL',
              },
              {
                value: 'B00739',
                label: 'DKV BELGIUM SA',
              },
              {
                value: 'B00995',
                label: 'MICA',
              },
              {
                value: 'B00781',
                label: 'SEA LLOYD',
              },
              {
                value: 'B00921',
                label: 'E.M.A.N.I.',
              },
              {
                value: 'B01024',
                label: 'HDI-GERLING ASSURANCES SA',
              },
              {
                value: 'B01031',
                label: 'ROYAL LIFE',
              },
              {
                value: 'B00733',
                label: 'CIE EURO-BELGE DE REASSURANCE',
              },
              {
                value: 'B00789',
                label: 'ASSUCOM',
              },
              {
                value: 'B00098',
                label: 'MERCATOR ASSURANCES SA',
              },
              {
                value: 'B00306',
                label: 'AGRIPPINA VERSICHERUNG A.G.',
              },
              {
                value: 'B00929',
                label: 'HAFTP. DER DEUTSCHER IND.',
              },
              {
                value: 'B00158',
                label: 'UNION FENIX',
              },
              {
                value: 'B00735',
                label: 'ERGO INSURANCES SA',
              },
              {
                value: 'B00162',
                label: 'SOC GEN ASS CREDIT FONC',
              },
              {
                value: 'B00313',
                label: 'ALBION INSURANCE COMPANY',
              },
              {
                value: 'B00989',
                label: 'VTV AG',
              },
              {
                value: 'B00484',
                label: 'KREDIETWAARBORG',
              },
              {
                value: 'B00519',
                label: 'SECUREX AT',
              },
              {
                value: 'B00175',
                label: 'VOORZIENIGHEID A.P.R.A.',
              },
              {
                value: 'B02642',
                label: 'CHUBB INSURANCE CY OF EUROPE SE',
              },
              {
                value: 'B00934',
                label: 'SOCIETA REALE MUTUA DI ASS TORINO',
              },
              {
                value: 'B00605',
                label: 'DE NEDERLANDEN 1870',
              },
              {
                value: 'B01136',
                label: 'AIG EUROPE LIMITED',
              },
              {
                value: 'B06370',
                label: 'CANADIAN MARKET',
              },
              {
                value: 'B00539',
                label: 'LONDON & OVERSAES INS. CY',
              },
              {
                value: 'B00562',
                label: 'LA MUTUELLE PATRONALE',
              },
              {
                value: 'B00936',
                label: 'VIA ASSURANCES IARD NORD ET MONDE',
              },
              {
                value: 'B00390',
                label: 'MENSURA',
              },
              {
                value: 'B00941',
                label: 'BOONEN L. CH. S.A.',
              },
              {
                value: 'B02152',
                label: 'EURO INSURANCES LIMITED',
              },
              {
                value: 'B00057',
                label: 'AXA INDUSTRY',
              },
              {
                value: 'B08000',
                label: 'ASBL MSCA',
              },
              {
                value: 'B00355',
                label: 'ASSURANCES DU NOTARIAT S.A.',
              },
            ],
          },
          contract_reference:  {
            component: 'text',
            label:     t('schemas.axa.constructor.axa_constructor_steps.contract_reference'),
            validations: ['required', 'maxLength12'],
          },
          contract_lapse_date: {
            component: 'date',
            label:     t('schemas.axa.constructor.axa_constructor_steps.contract_lapse_date'),
            validations: ['required'],
          },
        },
      },
    },
  };
};
