import React         from 'react';
import { Link }      from 'react-router-dom';
import NotFoundImage from 'images/404_not_found.svg';

const NotFound = () => {
  return (
    <div className="uk-flex uk-flex-middle uk-width-1-1 folder-empty-state p-20" data-purpose="404_not_found">
      <div className="uk-text-center uk-width-1-1">
        <img
          src={ NotFoundImage }
          alt="Not found illustration"
          className="uk-width-1-5"
        />
        <p className="uk-text-center mt-20 pl-40 pr-40">
          { t('api.error_messages.not_found') }
        </p>
        <p className="uk-text-center">
          <Link
            to="/"
            className="uk-button uk-button-primary large"
            data-purpose="go_home_button"
          >
            { t('general.actions.back') }
          </Link>
        </p>
      </div>
    </div>
  );
};

export default NotFound;
