import React                               from 'react';
import compact                             from 'lodash/compact';
import omit                                from 'lodash/omit';
import set                                 from 'lodash/set';
import TarificationStep                    from 'components/wizard/tarification_step';
import GenericStep                         from 'components/wizard_stepper/generic_step';
import {
  getPolicyOwnerSchema,
}                                          from 'schemas/shared/policy_owner_schema';
import { getViviumBuildingAskOfferSchema } from 'schemas/vivium/building/vivium_building_ask_offer_schema';
import { getViviumBuildingSchema }         from 'schemas/vivium/building/vivium_building_schema';
import { getViviumEvaluationSchema }       from 'schemas/vivium/building/vivium_building_evaluation_schema';
import { getViviumContentCoverSchema }     from 'schemas/vivium/building/vivium_building_content_cover_schema';

import {
  haveSelectedPolicyOwner,
}                                                  from 'models/wizard_navigation/constants';
import {
  haveSelectedViviumBuilding,
  haveSelectedViviumEvaluation,
  hasLessThan4FlatsOrSumInsuredLessThan500K,
}                                                  from 'schemas/vivium/building/constants';
import { getViviumBuildingPreviousContractSchema } from 'schemas/vivium/building/vivium_building_previous_contract_schema';
import { getViviumBuildingScopeSchema }            from 'schemas/vivium/building/vivium_building_scope_schema';
import DocumentStep                                from 'components/wizard/documents_step';

const getViviumBuildingPolicyOwnerSchema = () => {
  let schema = getPolicyOwnerSchema({
    schema: {
      policy_owner: {
        properties_order: [
          'search_in_bms',
          'customer_is_policy_owner',
          'condition',
          'kind',
        ],
        properties:       {
          kind:      {
            component: 'multi_button',
            list:      [{
              label: t('general.policy_owner_type.PhysicalPolicyOwner'),
              value: 'person',
            }, {
              label: t('general.policy_owner_type.CorporationPolicyOwner'),
              value: 'company',
            }, {
              label: t('schemas.vivium.building.vivium_building_steps.policy_owner_kind_acp'),
              value: 'acp',
            }],
            label:     t('wizard.policy_owner.policy_owner_type'),
            default:   {
              value: 'person',
            },
          },
          condition: {
            label:       t('vivium.building.policy_owner.condition'),
            component:   'multi_button',
            validations: ['required'],
            list:        [
              {
                value: 'owner',
                label: t('vivium.building.policy_owner.owner'),
              },
              {
                value: 'tenant',
                label: t('vivium.building.policy_owner.tenant'),
              },
            ],
          },
          person:    {
            properties_order: [
              'gender',
            ],
          },
          acp:       {
            visible:    [{
              property:  'policy_owner.kind',
              assertion: 'equal',
              value:     'acp',
            }],
            type:       'section',
            properties: {
              name:    {
                component:   'text',
                label:       t('schemas.vivium.building.vivium_building_steps.acp_name'),
                validations: ['required'],
              },
              address: {
                label:                t('activerecord.attributes.driver.person.address'),
                component:            'address',
                inlineContainerClass: 'top',
                requiredFields:       {
                  street_name:   true,
                  street_number: true,
                  city:          true,
                  street_box:    true,
                  zip_code:      true,
                  letter:        false,
                },
              },
            },
          },
        },
      },
    },
  }, false);
  schema = set(schema, 'schema.policy_owner.properties.company.properties.company_type', {
    component:   'text',
    label:       t('clients.companies.form.company_type'),
    validations: ['required'],
  });
  return omit(schema, [
    'schema.policy_owner.properties.person.properties.email',
    'schema.policy_owner.properties.person.properties.date_of_birth',
    'schema.policy_owner.properties.person.properties.id_card_number',
    'schema.policy_owner.properties.person.properties.occupation',
    'schema.policy_owner.properties.person.properties.phone_number',
    'schema.policy_owner.properties.person.properties.has_insurance_cancellations',
    'schema.policy_owner.properties.person.properties.insurance_cancellations',
    'schema.policy_owner.properties.company.properties.email',
    'schema.policy_owner.properties.company.properties.phone_number',
    'schema.policy_owner.properties.company.properties.employees_count',
    'schema.policy_owner.properties.company.properties.vat_number',
    'schema.policy_owner.properties.company.properties.has_insurance_cancellations',
    'schema.policy_owner.properties.company.properties.insurance_cancellations',
    'schema.policy_owner.properties.locale',
  ]);
};

export const getViviumBuildingSteps = (matchPath, contractRequest, recomputeTarifications) => (
  compact([
    {
      path:           `${ matchPath }/scope`,
      label:          t('schemas.vivium.building.vivium_building_steps.scope'),
      title:          t('schemas.vivium.building.vivium_building_steps.scope_step_title'),
      icon:           'information',
      forceFirst:     true,
      order:          1,
      Component:      GenericStep,
      componentProps: {
        ...getViviumBuildingScopeSchema(),
        onNextStep: {
          recomputeTarifications,
        },
      },
    },
    {
      label: t('wizard.navigation.about_contract_request'),
      icon:  'file',
      slug:  'contract_request',
      kind:  'group',
      order: 2,
      steps: [
        {
          path:           `${ matchPath }/policy_owner`,
          title:          t('wizard.policy_owner.title'),
          label:          t('wizard.navigation.policy_owner'),
          icon:           'company',
          order:          2,
          Component:      GenericStep,
          componentProps: {
            ...getViviumBuildingPolicyOwnerSchema(),
            onNextStep: {
              recomputeTarifications,
            },
          },
        },
        {
          order:          3,
          path:           `${ matchPath }/previous_contract`,
          title:          t('schemas.vivium.building.vivium_building_steps.history'),
          label:          t('schemas.vivium.building.vivium_building_steps.history'),
          icon:           'business-deal-handshake-circle',
          purpose:        'previous_contract_step',
          disabledRules:  [
            haveSelectedPolicyOwner,
          ],
          Component:      GenericStep,
          componentProps: {
            ...getViviumBuildingPreviousContractSchema(),
            onNextStep: {
              recomputeTarifications,
            },
          },
        },
        {
          path:           `${ matchPath }/building`,
          title:          t('wizard.building.title'),
          label:          t('wizard.building.title'),
          disabledRules:  [
            haveSelectedPolicyOwner,
          ],
          order:          4,
          icon:           'building',
          Component:      GenericStep,
          componentProps: {
            ...getViviumBuildingSchema(),
            onNextStep: {
              recomputeTarifications,
            },
          },
        },
        {
          path:           `${ matchPath }/evaluation`,
          title:          t('wizard.building.evaluation.title'),
          label:          t('wizard.building.evaluation.title'),
          icon:           'list',
          disabledRules:  [
            haveSelectedPolicyOwner,
            haveSelectedViviumBuilding,
          ],
          purpose:        'evaluation_step',
          order:          5,
          Component:      GenericStep,
          componentProps: {
            ...getViviumEvaluationSchema(),
            onNextStep: {
              recomputeTarifications,
            },
          },
        }
      ],
    },
    {
      path:           `${ matchPath }/tarification_${ contractRequest.tarifications[0].id }`,
      title:          t('schemas.vivium.building.vivium_building_steps.tarification_title'),
      label:          t('schemas.vivium.building.vivium_building_steps.tarification_title'),
      error:          contractRequest.tarifications[0].last_error_response,
      loading:        contractRequest.tarifications[0].computing,
      order:          3,
      icon:           'product-parameters',
      disabledRules:  [
        haveSelectedPolicyOwner,
        haveSelectedViviumBuilding,
        haveSelectedViviumEvaluation,
      ],
      purpose:        'tarification',
      Component:      TarificationStep,
      componentProps: {
        tarificationId: contractRequest.tarifications[0].id,
      },
    },
    {
      order:          5,
      path:           `${ matchPath }/ask_offer`,
      title:          t('schemas.vivium.building.vivium_building_steps.ask_offer'),
      disabledRules:  [
        haveSelectedPolicyOwner,
        haveSelectedViviumBuilding,
        haveSelectedViviumEvaluation,
      ],
      label:          t('schemas.vivium.building.vivium_building_steps.ask_offer'),
      icon:           'folder',
      purpose:        'offer_step',
      loading:        contractRequest.tarifications[0].computing,
      Component:      GenericStep,
      componentProps: {
        ...getViviumBuildingAskOfferSchema(),
      },
    },
    {
      order:         6,
      path:          `${ matchPath }/documents`,
      title:         t('schemas.vivium.building.vivium_building_steps.documents'),
      disabledRules: [
        haveSelectedPolicyOwner,
        haveSelectedViviumBuilding,
        haveSelectedViviumEvaluation,
      ],
      label:         t('schemas.vivium.building.vivium_building_steps.documents'),
      icon:          'text-block',
      purpose:       'documents',
      loading:       contractRequest.tarifications[0].computing,
      Component:     DocumentStep,
    },
  ])
);
