import { withRouter }                from 'react-router';
import React                         from 'react';
import { connect }                   from 'react-redux';
import snakeCase                     from 'lodash/snakeCase';
import Loader                        from 'components/ui/loader';
import Icon                          from 'components/ui/icon';
import ConvertLeadButton             from 'components/lead/convert_lead_button';
import { currentUserIsIntermediary } from 'models/current_user/reducer';

const LeadTable = withRouter(({
  columns, data, history, loading, sortedBy, sortDirection, sortBy, showActions, currentUserIsIntermediary,
}) => {
  return (
    <React.Fragment>
      <table
        className="uk-table uk-table-small uk-table-justify uk-table-divider uk-table-hover lead-table"
      >
        <thead>
          <tr>
            { columns.map(col => (
              <th
                key={ snakeCase(col.label) }
                { ...col.htmlAttrs }
                onClick={ () => {
                  if (col.sortKey) {
                    sortBy(col.sortKey);
                  }
                } }
              >
                { col.label }
                { sortedBy === col.sortKey && (
                  <React.Fragment>
                    { sortDirection === 'asc' && (<Icon icon="arrow-sort-up" />) }
                    { sortDirection === 'desc' && (<Icon icon="arrow-sort-down" />) }
                  </React.Fragment>
                ) }
              </th>
            )) }
            { showActions && (
              <th className="uk-width-small uk-text-right">
                { t('leads.table.header.actions') }
              </th>
            ) }
          </tr>
        </thead>
        { !loading && (
          <tbody key={ loading }>
            { data.map(lead => (
              <tr
                key={ lead.link }
                onClick={ (!lead.active ) ? () => history.push(lead.link) : (e) => e.preventDefault() }
              >
                { columns.map(col => (
                  <td
                    key={ `${ lead.id }_${ snakeCase(col.label) }` }
                    { ...col.htmlAttrs }
                  >
                    { lead[col.accessor] }
                  </td>
                )) }
                { showActions && (
                  <td className="uk-width-small uk-text-right">
                    <ConvertLeadButton leadId={ lead.id } />
                  </td>
                ) }
              </tr>
            )) }
          </tbody>
        ) }
        { loading && (
          <tbody key={ loading }>
            <tr>
              <td
                className="uk-text-center"
                colSpan={ columns.length }
              >
                <Loader />
              </td>
            </tr>
          </tbody>
        ) }
      </table>
    </React.Fragment>
  );
});

const mapStateToProps = (state) => {
  return {
    currentUserIsIntermediary: currentUserIsIntermediary(state),
  };
};

export default connect(mapStateToProps)(LeadTable);
