import React  from 'react';
import moment from 'moment';

const ContractInformations = ({ contract }) => {
  if (!contract) {
    return null;
  }
  const { requested_at, signed_at } = contract;
  if (!requested_at && !signed_at) {
    return null;
  }
  return (
    <div className="global-message info p-20">
      { requested_at && (
        <p className="uk-text-center mb-0">{ t('distributor.components.contracts.contract_informations.requested_at', { date: moment(requested_at).format('DD/MM/YYYY - HH:mm') }) }</p>
      ) }
      { signed_at && (
        <p className="uk-text-center mt-20 mb-0">{ t('distributor.components.contracts.contract_informations.signed_at', { date: moment(signed_at).format('DD/MM/YYYY - HH:mm') }) }</p>
      ) }
    </div>
  );
};

export default ContractInformations;
