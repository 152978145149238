import React               from 'react';
import withInlineContainer from 'components/ui/json_form/fields/with_inline_container';

const FormMessage = ({ message, className }) => {
  return (
    <div className={ className || 'global-message danger p-20' }>
      { message }
    </div>
  );
};

export default withInlineContainer()(FormMessage);
