export const getViviumBuildingScopeSchema = () => ({
  form:   'vivium_building_content_cover',
  schema: {
    scope: {
      type:       'section',
      properties: {
        scope:         {
          type:      'text',
          text_html: t('schemas.vivium.building.vivium_building_steps.scope_text_html'),
        }
      },
    },
  },
});
