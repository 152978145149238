import React            from 'react';
import FormErrorMessage from 'components/ui/form/form_error_message';
import { fieldWrapper } from 'components/ui/json_form/fields/field_wrapper';

const CheckboxInput = ({
  input: {
           onChange,
           value,
           ...restInput
         },
  label,
  showCheckboxLabel = true,
  meta = {},
  disabled,
  purpose,
  onValueChange,
  onCheck,
}) => {
  const { error, warning: asyncValidating } = meta;

  const onChangeCheckbox = (ev) => {
    onChange(ev);
    if (onCheck) {
      onCheck(ev.target.checked);
    }
    if (onValueChange) {
      onValueChange(ev.target.checked);
    }
  };

  return (
    <React.Fragment>
      <label htmlFor={ restInput.name }>
        <input
          id={ restInput.name }
          className={ `uk-checkbox ${ error ? 'uk-form-danger' : '' }` }
          type="checkbox"
          data-purpose={ purpose }
          disabled={ disabled || asyncValidating }
          checked={ value }
          onChange={ onChangeCheckbox }
          { ...restInput }
        />
        { showCheckboxLabel && label }
      </label>
      <FormErrorMessage { ...meta } name={ restInput.name } label={ label } />
    </React.Fragment>
  );
};

export default fieldWrapper()(CheckboxInput);
