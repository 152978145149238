import React                                from 'react';
import {
  FormSection,
  reduxForm,
  FieldArray,
  Field,
}                                           from 'redux-form';
import { useSelector }                      from 'react-redux';
import RadioInputList                       from 'components/ui/form/radio_input_list';
import InlineContainer                      from 'components/ui/form/inline_container';
import CheckboxInput                        from 'components/ui/form/checkbox_input';
import AtradiusFields
                                            from 'components/tarifications/building/atradius_fields';
import LiabilityFields
                                            from 'components/tarifications/building/liability_fields';
import CheckboxGroup                        from 'components/ui/form/checkbox_group';
import SelectInput                          from 'components/ui/form/select_input';
import { getActiveIntegrationForCompanyId } from 'models/integrations/reducer';
import TooltipOrDialog                      from 'components/ui/tooltip/tooltip_or_dialog';
import RadioTrueFalse                       from 'components/ui/form/radio_true_false';

const BuildingTarificationForm = ({
  initialValues,
}) => {
  const availableIntegrations = useSelector(state => getActiveIntegrationForCompanyId(state, initialValues.insurance_company.id).integrations.map((integration) => {
    return {
      value: integration.id,
      label: integration.name,
    };
  }));

  const indirectLossPercentageOptions = [
    {
      label: t('wizard.building.tarification.cover_options.indirect_loss_percentage.none'),
      value: '0',
    },
    {
      label: t('wizard.building.tarification.cover_options.indirect_loss_percentage.indirect_loss_percentage_5'),
      value: '5',
    },
    {
      label: t('wizard.building.tarification.cover_options.indirect_loss_percentage.indirect_loss_percentage_10'),
      value: '10',
    },
  ];

  const waiverOfAppealOptions = [
    {
      label: t('wizard.building.tarification.cover_options.waiver_of_appeal.none'),
      value: 'None',
    },
    {
      label: t('wizard.building.tarification.cover_options.waiver_of_appeal.over_50'),
      value: '>50',
    },
    {
      label: t('wizard.building.tarification.cover_options.waiver_of_appeal.lower_or_equal_to_50'),
      value: '<=50',
    },
  ];

  const basisOptions = [
    {
      label: t('wizard.building.tarification.basis.basis_1'),
      value: '1',
    },
    {
      label: t('wizard.building.tarification.basis.basis_2'),
      value: '2',
    },
    {
      label: t('wizard.building.tarification.basis.basis_3'),
      value: '3',
    },
    {
      label: t('wizard.building.tarification.basis.basis_5'),
      value: '5',
    },
    {
      label: t('wizard.building.tarification.basis.basis_10'),
      value: '10',
    },
  ];
  const buildimoLiabilityOptions = [
    {
      label: t('wizard.building.tarification.cover_protection.none'),
      value: 'none',
    },
    {
      label: t('wizard.building.tarification.cover_protection.basic'),
      value: 'basic',
    },
  ];
  const buildimaxLiabilityOptions = [
    ...buildimoLiabilityOptions,
    {
      label: t('wizard.building.tarification.cover_protection.comprehensive'),
      value: 'extended',
    },
  ];
  const premiumPackOptions = [
    {
      value: true,
      label: t('general.bool_values.true'),
    },
    {
      value: false,
      label: t('general.bool_values.false'),
    },
  ];

  return (
    <React.Fragment>
      <form>
        { availableIntegrations.length > 1 && (
          <div className="mb-20 uk-grid-small" data-uk-grid>
            <div className="uk-width-1-3@m uk-margin-auto-left">
              <label className="uk-form-label mb-10" htmlFor="integration_id">
                { t('provider.tarification.actions.select_integration') }
              </label>
              <Field
                name="integration_id"
                list={ availableIntegrations }
                placeholder={ t('provider.tarification.actions.select_integration') }
                component={ SelectInput }
              />
            </div>
          </div>
        ) }
        <FormSection name="selected_options[0]">
          <Field
            name="id"
            component="input"
            type="hidden"
            value="product_request"
          />
          <FormSection name="value">
            <FormSection name="buildimo">
              <InlineContainer
                inlineContainerClass="top"
                label={ t('wizard.building.tarification.is_buildimo') }
              >
                <div className="uk-width-1-1">
                  <div className="mb-10">
                    <p className="uk-form-label mb-10">
                      { t('wizard.building.tarification.basis.title') }
                    </p>
                    <Field
                      name="basis"
                      options={ basisOptions }
                      asButton
                      component={ CheckboxGroup }
                    />
                  </div>
                  <div className="mb-10">
                    <p className="uk-form-label mb-10">
                      { t('wizard.building.tarification.cover_protection.title') }
                    </p>
                    <Field
                      label={ t('wizard.building.tarification.cover_protection.title') }
                      name="liability"
                      list={ buildimoLiabilityOptions }
                      component={ RadioInputList }
                    />
                  </div>
                </div>
              </InlineContainer>
            </FormSection>
            <FormSection name="buildimax">
              <InlineContainer
                inlineContainerClass="top"
                label={ t('wizard.building.tarification.is_buildimax') }
              >
                <div className="uk-width-1-1">
                  <div className="mb-10">
                    <p className="uk-form-label mb-10">
                      { t('wizard.building.tarification.basis.title') }
                    </p>
                    <Field
                      name="basis"
                      options={ basisOptions }
                      asButton
                      component={ CheckboxGroup }
                    />
                  </div>
                  <div className="mb-10">
                    <p className="uk-form-label mb-10">
                      { t('wizard.building.tarification.cover_protection.title') }
                    </p>
                    <Field
                      label={ t('wizard.building.tarification.cover_protection.title') }
                      name="liability"
                      list={ buildimaxLiabilityOptions }
                      component={ RadioInputList }
                    />
                  </div>
                </div>
              </InlineContainer>
            </FormSection>
            <FormSection name="buildimax">
              <InlineContainer
                inlineContainerClass="top"
                label={ t('wizard.building.tarification.is_buildimax_all_risk') }
              >
                <div className="uk-width-1-1">
                  <Field
                    name="premium_pack"
                    list={ premiumPackOptions }
                    asButton
                    component={ RadioInputList }
                  />
                </div>
              </InlineContainer>
            </FormSection>
            <FormSection name="cover_options">
              <Field
                name="indirect_loss_percentage"
                label={ t('wizard.building.tarification.cover_options.indirect_loss_percentage.label') }
                inline
                list={ indirectLossPercentageOptions }
                component={ RadioInputList }
              />
              <Field
                name="waiver_of_appeal"
                label={ t('wizard.building.tarification.cover_options.waiver_of_appeal.label') }
                inline
                list={ waiverOfAppealOptions }
                component={ RadioInputList }
              />
              <InlineContainer
                label={ t('wizard.building.tarification.cover_options.is_technical_installations.label') }>
                <Field
                  name="is_technical_installation"
                  component={ RadioTrueFalse }
                />
              </InlineContainer>
            </FormSection>
            <InlineContainer
              label={ t('wizard.building.tarification.atradius.title') }
              tooltip={ <TooltipOrDialog className="ml-10"
                                         content={ t('wizard.building.tarification.atradius.tooltip') }
                                         dialogTitle={ t('wizard.building.tarification.atradius.title') } /> }
            >
              <FieldArray
                name="cover_atradius"
                component={ AtradiusFields }
              />
            </InlineContainer>
            <InlineContainer
              label={ t('wizard.building.tarification.cover_liability.title') }
            >
              <FieldArray
                name="cover_liability"
                component={ LiabilityFields }
              />
            </InlineContainer>
          </FormSection>
        </FormSection>
      </form>
    </React.Fragment>
  );
};


export default reduxForm({
  enableReinitialize: true,
})(BuildingTarificationForm);
