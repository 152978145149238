import React                       from 'react';
import NumberService               from 'services/number';
import {
  getCarOptionsList,
  getTransmissionList,
}                                  from 'models/car/constants';
import { getUsageList }            from 'models/contract_request/constants';
import { getCarForInformexSchema } from 'schemas/mobility/car_from_informex_schema';
import { getCarFromCarapiSchema }  from 'schemas/mobility/car_from_carapi_schema';

export const getCarSchema = (forVan) => {
  const vehicle = 'vehicle';
  const schemaFor = forVan ? 'van' : 'car';

  return {
    form:      'vehicle',
    formProps: {
      updateUnregisteredFields: true,
    },
    schema:    {
      vehicle: {
        type:       'section',
        properties: {
          kind:                                {
            type:    'hidden',
            default: {
              value: schemaFor,
            },
          },
          search_by_plate:                     {
            component: 'search_by_plate',
            prefix:    [
              'vehicle',
            ],
          },
          with_adjusted_power:                 {
            component: 'text',
            type:      'hidden',
          },
          febiac_genre:                        {
            component: 'text',
            type:      'hidden',
          },
          source:                              {
            component: 'text',
            type:      'hidden',
          },
          ...getCarFromCarapiSchema(vehicle),
          ...getCarForInformexSchema(),
          febiac_id:                           {
            component:   'text',
            type:        'hidden',
            validations: ['required'],
            inline:      false,
          },
          adjusted_power:                      {
            component:   'text',
            symbol:      'KW',
            label:       t('activerecord.attributes.car.adjusted_power'),
            tooltip:     t('activerecord.attributes.car.adjusted_power_tooltip'),
            validations: ['required'],
            visible:     [
              {
                property: `required_fields.vehicle.power`,
              },
              {
                property:  `vehicle.with_adjusted_power`,
                assertion: 'equal',
                value:     'true',
              },
            ],
          },
          seats_count:                         {
            component:   'text',
            type:        'number',
            label:       t('activerecord.attributes.car.seats_count'),
            validations: ['required'],
            parse:       Number,
            visible:     {
              property: `required_fields.vehicle.seats_count`,
            },
          },
          catalogue_price_without_vat:         {
            component:   'price',
            label:       t('activerecord.attributes.car.catalogue_price_without_vat'),
            validations: ['required'],
            visible:     {
              property: `required_fields.vehicle.catalogue_price`,
            },
          },
          options_catalogue_price_without_vat: {
            component:     'price',
            label:         t('activerecord.attributes.car.options_catalogue_price_without_vat'),
            validations:   ['required'],
            visible:       {
              property: 'required_fields.vehicle.options_catalogue_price',
            },
            withValues:    {
              catalogue_price_without_vat:         `vehicle.catalogue_price_without_vat`,
              options_catalogue_price_without_vat: `vehicle.options_catalogue_price_without_vat`,
            },
            fieldAddition: {
              className: 'global-message warning xs p-5',
              render:    (props = {}) => {
                const { catalogue_price_without_vat, options_catalogue_price_without_vat } = props;
                const totalCataloguePriceWithOptions = (catalogue_price_without_vat ? parseFloat(`${ catalogue_price_without_vat }`.replace(' ', '').replace(',', '.')) : 0) + (options_catalogue_price_without_vat ? parseFloat(`${ options_catalogue_price_without_vat }`.replace(' ', '').replace(',', '.')) : 0);
                return (
                  <p className="mb-0 mt-10">
                  <span className="global-message warning xs p-5">
                    { t_html('wizard.car.total_option_and_catalog_price_html', { total_price: NumberService.formatCurrency(totalCataloguePriceWithOptions, '€') }) }
                  </span>
                  </p>
                );
              },
            },
          },
          purchase_price_without_vat:          {
            component:   'price',
            label:       t('activerecord.attributes.car.purchase_price_without_vat'),
            validations: ['required'],
            visible:     {
              property: `required_fields.vehicle.purchase_price`,
            },
          },
          purchase_price:                      {
            component:   'price',
            label:       t('activerecord.attributes.car.purchase_price'),
            validations: ['required'],
            visible:     {
              property: `required_fields.vehicle.purchase_price_with_vat`,
            },
          },
          driving_aids:                        {
            label:                t('clients.cars.car_form.driving_aids'),
            component:            'checkbox_list',
            inlineContainerClass: 'top',
            list:                 getCarOptionsList(),
            visible:              {
              property: `required_fields.vehicle.driving_aids`,
            },
          },
          second_hand:                         {
            component:   'true_false',
            validations: ['required'],
            label:       t('activerecord.attributes.car.second_hand'),
          },
          second_hand_purchase_date:           {
            component:   'date',
            validations: ['required', 'date_format'],
            label:       t('activerecord.attributes.car.second_hand_purchase_date'),
            visible:     [
              {
                property: `vehicle.second_hand`,
              },
            ],
          },
          purchase_mileage:                    {
            component:   'text',
            label:       t('activerecord.attributes.car.purchase_mileage'),
            validations: ['required'],
            visible:     [
              {
                property: `required_fields.vehicle.purchase_mileage`,
              },
              {
                property: `vehicle.second_hand`,
              },
            ],
          },
          genre:                               {
            component:   'select',
            validations: ['required'],
            list:        {
              from: 'options_collections.car_genres',
            },
            label:       t('activerecord.attributes.car.genre'),
            visible:     {
              property: `required_fields.vehicle.genre`,
            },
          },
          transmission:                        {
            component:   'radio_input',
            validations: ['required'],
            list:        getTransmissionList(),
            label:       t('activerecord.attributes.car.transmission'),
            visible:     {
              property: `required_fields.vehicle.transmission`,
            },
          },
          convertible:                         {
            component:   'true_false',
            validations: ['required'],
            label:       t('activerecord.attributes.car.convertible'),
            visible:     {
              property: `required_fields.vehicle.convertible`,
            },
          },
          four_wheel_drive:                    {
            component:   'true_false',
            validations: ['required'],
            label:       t('activerecord.attributes.car.four_wheel_drive'),
            visible:     {
              property: `required_fields.vehicle.four_wheel_drive`,
            },
          },
          jeep:                                {
            component:   'true_false',
            validations: ['required'],
            label:       t('activerecord.attributes.car.jeep'),
            visible:     {
              property: `required_fields.vehicle.jeep`,
            },
          },
          mono_volume_vehicle:                 {
            component:   'true_false',
            validations: ['required'],
            label:       t('activerecord.attributes.car.mono_volume_vehicle'),
            visible:     {
              property: `required_fields.vehicle.mono_volume_vehicle`,
            },
          },
          sport_vehicle:                       {
            component:   'true_false',
            validations: ['required'],
            label:       t('activerecord.attributes.car.sport_vehicle'),
            visible:     {
              property: `required_fields.vehicle.sport_vehicle`,
            },
          },
          vin_number:                        {
            component:   'text',
            validations: ['required'],
            label:       t('activerecord.attributes.car.vin_number'),
            visible:     {
              property: 'required_fields.vehicle.vin_number',
            },
          },
          chassis_number:                      {
            component:   'text',
            validations: ['required'],
            label:       t('activerecord.attributes.car.chassis_number'),
            visible:     {
              property: `required_fields.vehicle.chassis_number`,
            },
          },
        },
      },
      usage:   {
        type:       'section',
        title:      t('wizard.car.about_usage'),
        visibleOr:  [
          {
            property: 'required_fields.base.max_annual_mileage_km',
          },
          {
            property: 'required_fields.vehicle.usage',
          },
          {
            property: 'required_fields.base.recoverable_vat',
          },
          {
            property: 'required_fields.base.carport',
          },
        ],
        properties: {
          vehicle_usage:         {
            label:       t('clients.typed_contract_requests.usage_modal.vehicle_usage'),
            list:        getUsageList(),
            validations: ['required'],
            component:   'radio_list',
            visible:     {
              property: 'required_fields.vehicle.usage',
            },
          },
          max_annual_mileage_km: {
            label:         t('clients.typed_contract_requests.usage_modal.annual_mileage_km'),
            validations:   ['required'],
            component:     'text',
            extraPropsFor: 'int',
            symbol:        'km',
            visible:       {
              property: 'required_fields.base.max_annual_mileage_km',
            },
          },
          recoverable_vat:       {
            label:       t('clients.typed_contract_requests.usage_modal.recoverable_vat'),
            validations: ['required'],
            component:   'true_false',
            visible:     [
              {
                property: 'required_fields.base.recoverable_vat',
              },
            ],
          },
          recoverable_rate:      {
            component:   'text',
            label:       t('clients.typed_contract_requests.usage_modal.recoverable_rate'),
            validations: ['required', 'not0'],
            visible:     {
              property: 'usage.recoverable_vat',
            },
          },
          carport:               {
            label:       t('activerecord.attributes.car_contract_request.carport'),
            validations: ['required'],
            component:   'true_false',
            visible:     {
              property: 'required_fields.base.carport',
            },
            default:     {
              value: false,
            },
          },
        },
      },
    },
  };
};
