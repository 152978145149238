import React                          from 'react';
import LoadOnClickButton              from 'components/ui/buttons/load_on_click_button';
import confirmBeforeAction            from 'components/ui/buttons/helpers/confirm_before_action';
import { useDispatch, useSelector }   from 'react-redux';
import { signOffer }                  from 'models/offers/requests';
import { updateTarification }         from 'models/wizard/store_actions';
import { setPollingForTarifications } from 'models/wizard/dispatch_actions';
import { isSocketConnected }          from 'models/config/reducer';

const ConfirmLoadingButton = confirmBeforeAction()(LoadOnClickButton);

const SignOfferButton = ({ shouldConfirm = true, tarification, onOfferSign }) => {
  if (!tarification.authorizations.sign_offer || tarification.computing) {
    return null;
  }
  const isSocketUp = useSelector((state) => isSocketConnected(state));
  const Component = shouldConfirm ? ConfirmLoadingButton : LoadOnClickButton;
  const dispatch = useDispatch();
  const sign = () => {
    signOffer(tarification).then(({ tarification: signOfferTarification }) => {
      dispatch(updateTarification(signOfferTarification));
      if (!isSocketUp) {
        dispatch(setPollingForTarifications([tarification], (updatedTarification) => {
          dispatch(updateTarification(updatedTarification));
        }));
      }
      if (onOfferSign) {
        onOfferSign();
      }
    });
  };
  return (
    <Component
      primary
      confirmText={ t('axa.constructor.offer.confirm_create_offer.text') }
      confirmTitle={ t('axa.constructor.offer.confirm_create_offer.title') }
      title={ t('axa.constructor.offer.sign_offer') }
      large
      onClick={ sign }
    >
      { t('axa.constructor.offer.sign_offer') }
    </Component>
  );
};

export default SignOfferButton;
