import React              from 'react';
import FolderIllustration from 'images/folder_empty_state.svg';
import CreateFolderButton from 'components/folder/create_folder_button';

const FolderEmptyState = ({ archivedFolders }) => {
  return (
    <div className="uk-flex uk-flex-middle uk-width-1-1 folder-empty-state p-20" data-purpose="folder_empty_state">
      <div className="uk-text-center uk-width-1-1">
        <img
          src={ FolderIllustration }
          alt="No folder"
          className="uk-width-1-5"
        />
        <p className="uk-text-center mt-20 pl-40 pr-40">
          { archivedFolders ? t('wizard.folders.empty_state_archived') : t('wizard.folders.empty_state') }
        </p>
        { !archivedFolders && (
          <div className="uk-text-center">
            <CreateFolderButton
              className="uk-button-primary large"
            />
          </div>
        ) }
      </div>
    </div>
  );
};


export default FolderEmptyState;
