import React                   from 'react';
import asWizardStep            from 'components/wizard_stepper/as_wizard_step';
import { useSelector }         from 'react-redux';
import SendToBackofficeButton  from 'components/backoffice/send_to_backoffice_button';
import SentToBackofficeMessage from 'components/backoffice/sent_to_backoffice_message';

const AxaConstructorSendToBackofficeStep = () => {
  const contractRequest = useSelector((state) => state.wizard.contract_request);
  const tarification = contractRequest.tarifications[0];
  return (
    <div>
      <p className="uk-text-center">
        <SendToBackofficeButton tarification={ tarification } removeStepAction={ false } />
      </p>
      <SentToBackofficeMessage tarification={ tarification } contractRequest={ contractRequest } />
    </div>
  );
};

export default asWizardStep()(AxaConstructorSendToBackofficeStep);
