export const getViviumBuildingAskOfferSchema = () => {
  return {
    form:   'ask_offer',
    schema: {
      policy_owner: {
        type:       'section',
        title:      t('schemas.vivium.building.vivium_building_ask_offer_schema.complementary_policy_owner_information'),
        properties: {
          locale:  {
            component:   'radio_list',
            list:        {
              from: 'available_locales',
            },
            default:     {
              from: 'current_user.profile.locale',
            },
            validations: ['required'],
            label:       t('schemas.shared.policy_owner_schema.locale'),
          },
          person:  {
            type:       'section',
            visible:    [{
              property:  'policy_owner.kind',
              assertion: 'equal',
              value:     'person',
            }],
            properties: {
              phone_number: {
                component: 'text',
                label:     t('person.label.phone_number_optional'),
              },
              email:        {
                component: 'text',
                type:      'email',
                label:     t('person.label.email_optional'),
              },
            },
          },
          company: {
            type:       'section',
            visibleOr:  [{
              property:  'policy_owner.kind',
              assertion: 'equal',
              value:     'company',
            },
              {
                property:  'policy_owner.kind',
                assertion: 'equal',
                value:     'acp',
              }],
            properties: {
              vat_number:   {
                component:   'text',
                label:       t('clients.companies.form.vat_number'),
                validations: ['required'],
              },
              phone_number: {
                component: 'text',
                label:     t('person.label.phone_number_optional'),
              },
              email:        {
                component: 'text',
                type:      'email',
                label:     t('person.label.email_optional'),
              },
            },
          },
        },
      },
    },
  };
};
