import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector }   from 'react-redux';
import { submit }                     from 'redux-form';
import has                            from 'lodash/has';
import asWizardStep                   from 'components/wizard_stepper/as_wizard_step';
import {
  updateWizardTarificationContract,
}                                     from 'models/wizard/dispatch_actions';
import JsonForm                       from 'components/ui/json_form/json_form';
import { flattenKeys }                from 'services/object';
import $                              from 'jquery';
import JsonFormErrors                 from 'components/ui/json_form/json_form_errors';
import Loader                         from 'components/ui/loader';
import ContractErrors                 from 'components/contract/contract_errors';

const ContractDataStep = (props) => {
  const {
          nextStep,
          setNextLoading,
          setNextAction,
          form,
          schema,
          formProps = {},
          tarificationId,
        } = props;
  const [formErrors, setErrors] = useState([]);
  const dispatch = useDispatch();
  const contractRequest = useSelector((state) => state.wizard.contract_request);
  const initialValues = contractRequest.tarifications.find((tarif) => tarif.id === tarificationId);

  const updateTarificationContractData = (contractData) => {
    setNextLoading(true);
    if (contractData) {
      return dispatch(updateWizardTarificationContract(tarificationId, contractData))
        .then(() => {
          setNextLoading(false);
        })
        .catch(() => {
          setNextLoading(false);
        });
    }
  };

  const onSubmit = (data) => {
    if (!initialValues.authorizations.contract) {
      nextStep();
      return;
    }
    updateTarificationContractData(data).then(() => {
      nextStep();
    });
  };

  const submitForm = () => {
    dispatch(submit(form));
  };

  useEffect(() => {
    setNextAction(() => {
      return submitForm();
    });
  }, []);

  if (initialValues.computing) {
    return (<Loader />);
  }

  return (
    <React.Fragment>
      <JsonForm
        form={ form }
        schema={ schema }
        disabled={ !contractRequest.tarifications[0].authorizations.contract }
        initialValues={ has(initialValues, 'contract.data') ? initialValues.contract.data : {} }
        onSubmit={ onSubmit }
        onSubmitFail={ (errors) => {
          if (!errors) {
            setErrors([]);
            return;
          }
          setTimeout(() => {
            const errorsByPath = flattenKeys(errors);
            const allErrors = Object.keys(errorsByPath).map((path) => {
              const $item = $(`[data-error="${ path }"]`);
              return {
                path,
                $item,
                label:   $item.data('error-label'),
                message: $item.text(),
              };
            });
            if (allErrors && allErrors.length > 0) {
              setErrors(allErrors);
            }
            const firstError = Object.keys(errorsByPath)[0];
            const target = $(`[data-error="${ firstError }"]`);
            if (!target[0]) {
              return;
            }
            $([document.documentElement, document.body]).animate({
              scrollTop: target.offset().top - 150,
            }, 500);
          }, 1000);
        } }
        { ...formProps }
      />
      { formErrors.length > 0 && (
        <JsonFormErrors errors={ formErrors } />
      ) }
      <ContractErrors contract={ initialValues.contract } />
    </React.Fragment>
  );
};

export default asWizardStep(true)(ContractDataStep);
