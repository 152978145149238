import React, { useState, useContext }     from 'react';
import PropTypes                           from 'prop-types';
import {
  Field,
  FormSection,
  formValueSelector,
  getFormMeta,
}                                          from 'redux-form';
import { connect }                         from 'react-redux';
import GoogleMapReact                      from 'google-map-react';
import ImageMarker                         from 'images/map_marker.svg';
import { onlyInt, required }               from 'models/application/constants';
import label                               from 'components/ui/form/input_label';
import AddressPartWithAutocomplete         from 'components/address/address_part_with_autocomplete';
import { GOOGLE_JAVASCRIPT_API_KEY, TEST } from 'constants/application_constants';
import JsonFormContext                     from 'components/ui/json_form/json_form_context';
import Input                               from 'components/ui/json_form/fields/input';
import withInlineContainer                 from 'components/ui/json_form/fields/with_inline_container';

const createMapOptions = (maps) => {
  return {
    zoomControlOptions: {
      position: maps.ControlPosition.RIGHT_CENTER,
      style:    maps.ZoomControlStyle.SMALL,
    },
    streetViewControl:  true,
    mapTypeId:          maps.MapTypeId.SATELLITE,
  };
};

const OpenbrokerMapMarker = () => (
  <img
    src={ ImageMarker }
    alt=""
    style={ {
      height:    '60px',
      transform: 'translate(-50%, -50%)',
    } }
  />
);

const AddressFormJson = (
  {
    input,
    change,
    requiredFields,
    sectionName,
    coords,
    validates = {},
    address,
    displayMap = false,
    noSection = false,
    nameOverride,
    labels,
  }) => {
        const [map, setMap] = useState(null);
        const { getPropertyValue, disabled } = useContext(JsonFormContext);

        const {
                street_name:   street_name_required_field,
                street_number: street_number_required_field,
                city:          city_required_field,
                street_box:    street_box_required_field,
                zip_code:      zip_code_required_field,
              } = requiredFields;

        const street_name = typeof street_name_required_field === 'string' ? getPropertyValue(street_name_required_field) : street_name_required_field;
        const street_number = typeof street_number_required_field === 'string' ? getPropertyValue(street_number_required_field) : street_number_required_field;
        const city = typeof city_required_field === 'string' ? getPropertyValue(city_required_field) : city_required_field;
        const street_box = typeof street_box_required_field === 'string' ? getPropertyValue(street_box_required_field) : street_box_required_field;
        const zip_code = typeof zip_code_required_field === 'string' ? getPropertyValue(zip_code_required_field) : zip_code_required_field;

        const showAddressForm = () => (
          street_name || street_number || city || street_box || zip_code
        );

        const precompleteAddress = (address) => {
          Object.keys(address).forEach((part) => {
            change(`${ nameOverride || input.name }.${ part }`, address[part]);
          });
        };

        if (!showAddressForm()) {
          return null;
        }

        const updateMap = (value, label, firstMap) => {
          const mapToUse = map || firstMap;
          if (!mapToUse) {
            return;
          }
          const addressToLook = {
            ...address,
            [label]: value,
          };

          const service = new google.maps.places.PlacesService(mapToUse);
          service.findPlaceFromQuery({
            query:  Object.values(addressToLook).join(' '),
            fields: ['geometry'],
          }, (results, status) => {
            if (status === google.maps.places.PlacesServiceStatus.OK) {
              change(`${ nameOverride || input.name }.lat`, results[0].geometry.location.lat());
              change(`${ nameOverride || input.name }.lng`, results[0].geometry.location.lng());
            }
          });
        };
        const addressForm = (
          <div className="uk-width-1-1">
            <div data-uk-grid="" className="uk-grid-small address-form">
              <Field
                type="hidden"
                name="lat"
                id="lat"
                component="input"
              />
              <Field
                type="hidden"
                name="lng"
                id="lng"
                component="input"
              />
              <div className="uk-width-3-5">
                <label htmlFor="street_name">
                  { t('activerecord.attributes.address.street_name') }
                </label>
                <Field
                  type="text"
                  disabled={ disabled || !street_name }
                  placeholder={ t('general.address.street') }
                  name="street_name"
                  id="street_name"
                  precompleteAddress={ precompleteAddress }
                  validate={ street_name && (validates.street_name || [required]) }
                  component={ AddressPartWithAutocomplete }
                  searchOptions={ { types: ['address'] } }
                  precompleteInputs={ ['street_number', 'zip_code', 'city'] }
                  autoComplete="not-now"
                />
              </div>
              <div className="uk-width-1-5">
                <label htmlFor="street_number">
                  { t('activerecord.attributes.address.street_number') }
                </label>
                <Field
                  type="number"
                  placeholder={ t('general.address.number') }
                  name="street_number"
                  id="street_number"
                  disabled={ !street_number }
                  validate={ street_number && (validates.street_number || [required]) }
                  component={ Input }
                  inline={ false }
                  onInput={ (val) => updateMap(val, 'street_number') }
                  autoComplete="not-now"
                />
              </div>
              <div className="uk-width-1-5">
                <label htmlFor="street_box">
                  { t('activerecord.attributes.address.street_box') }
                </label>
                <Field
                  type="text"
                  name="street_box"
                  id="street_box"
                  disabled={ !street_number }
                  placeholder={ t('general.address.street_box') }
                  validate={ street_number && (validates.street_box || []) }
                  component={ Input }
                  inline={ false }
                  onInput={ (val) => updateMap(val, 'street_box') }
                  autoComplete="not-now"
                  maxLength="3"
                />
              </div>
              <div className="uk-width-3-5">
                <label htmlFor="city">
                  { labels && labels.city ? labels.city : t('activerecord.attributes.address.city') }
                </label>
                <Field
                  type="text"
                  placeholder={ t('general.address.city') }
                  validate={ city && (validates.city || [required]) }
                  name="city"
                  disabled={ !city }
                  id="city"
                  component={ Input }
                  inline={ false }
                  onInput={ (val) => updateMap(val, 'city') }
                  autoComplete="not-now"
                />
              </div>
              <div className="uk-width-2-5">
                <label htmlFor="zip_code">
                  { t('activerecord.attributes.address.zip_code') }
                </label>
                <Field
                  type="text"
                  placeholder={ t('general.address.postal_code') }
                  validate={ zip_code && (validates.zip_code || [required, onlyInt]) }
                  name="zip_code"
                  disabled={ !zip_code }
                  id="zip_code"
                  component={ Input }
                  inline={ false }
                  onInput={ (val) => updateMap(val, 'zip_code') }
                  autoComplete="not-now"
                />
              </div>
            </div>
            { displayMap && !TEST && (
              <div
                className="mt-20"
                style={ {
                  height: '300px',
                  width:  '100%',
                } }
              >
                <GoogleMapReact
                  bootstrapURLKeys={ { key: GOOGLE_JAVASCRIPT_API_KEY } }
                  defaultCenter={ {
                    lat: 50.503887,
                    lng: 4.469936,
                  } }
                  defaultZoom={ 15 }
                  center={ coords }
                  zoom={ coords ? 19 : 15 }
                  onGoogleApiLoaded={ ({ map }) => {
                    setMap(map);
                    updateMap(null, null, map);
                  } }
                  options={ (maps) => {
                    return createMapOptions(maps);
                  } }
                >
                  { coords && (
                    <OpenbrokerMapMarker
                      lat={ coords.lat }
                      lng={ coords.lng }
                    />
                  ) }
                </GoogleMapReact>
              </div>
            ) }
          </div>
        );

        if (noSection) {
          return addressForm;
        }

        return (
          <FormSection name={ sectionName }>
            { addressForm }
          </FormSection>
        );

      }
;


const mapStateToProps = (state, ownProps) => {
  const { input: { name }, formName, nameOverride } = ownProps;
  let nameForProp = nameOverride || name;
  if (!formName) {
    return {
      formMetas: {},
    };
  }
  const selector = formValueSelector(formName);
  const lat = selector(state, `${ nameForProp }.lat`);
  const lng = selector(state, `${ nameForProp }.lng`);
  const coords = lat && lng ? {
    lat,
    lng,
  } : null;
  return {
    formMetas: getFormMeta(formName)(state),
    coords,
    address:   {
      street_name:   selector(state, `${ nameForProp }.street_name`),
      street_number: selector(state, `${ nameForProp }.street_number`),
      zip_code:      selector(state, `${ nameForProp }.zip_code`),
      street_box:    selector(state, `${ nameForProp }.street_box`),
      city:          selector(state, `${ nameForProp }.city`),
    },
  };
};

AddressFormJson.propTypes = {
  inline: PropTypes.bool,
};

AddressFormJson.defaultProps = {
  inline:      true,
  sectionName: 'address',
};

export default connect(mapStateToProps)(withInlineContainer()(AddressFormJson));
