import React from 'react';

const ProductCompanyLogo = ({ product, ...rest }) => {
  if (!product || !product.insurance_company.logo) {
    return null;
  }
  return (
    <img
      src={ product.insurance_company.logo.big.url }
      alt={ product.insurance_company.name }
      width="80px"
      { ...rest }
    />
  );
};

export default ProductCompanyLogo;
