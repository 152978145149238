import React, { useContext }        from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAedesHousingSteps }     from 'schemas/aedes/p_500/aedes_housing_steps';
import { getViviumHousingSteps }    from 'schemas/vivium/housing/vivium_housing_steps';
import StepList
                                    from 'components/wizard_stepper/step_list';
import WizardStepperContext
                                    from 'components/wizard_stepper/wizard_context';
import { getAedesPlocSchema }       from 'schemas/aedes/p_loc/aedes_ploc_schema';
import { setDefaultRequiredFields } from 'models/wizard/store_actions';
import {
  DEFAULT_REQUIRED_FIELDS_AEDES_P500,
  getProductsFromContractRequest,
}                                   from 'models/wizard/constants';

const HousingSteps = ({ matchPath }) => {
  const contractRequest = useSelector((state) => state.wizard.contract_request);
  const selectedProductSlug = getProductsFromContractRequest(contractRequest)[0].slug;
  const dispatch = useDispatch();
  const { recomputeTarifications } = useContext(WizardStepperContext);
  let steps = [];
  if (selectedProductSlug === 'vivium-habitation') {
    steps = getViviumHousingSteps(matchPath, contractRequest, recomputeTarifications);
  } else if (selectedProductSlug === 'aedes-p500') {
    dispatch(setDefaultRequiredFields(DEFAULT_REQUIRED_FIELDS_AEDES_P500));
    steps = getAedesHousingSteps(matchPath, contractRequest, recomputeTarifications);
  } else if (selectedProductSlug === 'aedes-ploc') {
    steps = getAedesPlocSchema(matchPath, contractRequest, recomputeTarifications);
  }
  return <StepList steps={ steps } />;
};

export default HousingSteps;
