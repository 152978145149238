import Cookies                                              from 'js-cookie';
import { DOMAIN_WITHOUT_PORT, FOR_DISTRIBUTOR, IS_NETLIFY } from 'constants/application_constants';

const cookieDomain = FOR_DISTRIBUTOR ? DOMAIN_WITHOUT_PORT : `.app.${ DOMAIN_WITHOUT_PORT }`;
export const getCookie = name => (
  Cookies.get(name)
);

export const setCookie = (name, value) => {
  return Cookies.set(name, value, IS_NETLIFY ? null : { domain: cookieDomain });
};

export const removeCookie = (name) => {
  Cookies.remove(name, IS_NETLIFY ? null : { domain: cookieDomain });
  Cookies.remove(name, { domain: '.openbroker.be' });
};
