import React    from 'react';
import ReactDOM from 'react-dom';
import UIkit    from 'uikit';

class WithDrawer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open:          false,
      drawerContent: null,
      drawerProps:   null,
      onClose:       null,
    };
    this.openDrawer = this.openDrawer.bind(this);
    this.closeDrawer = this.closeDrawer.bind(this);
    this.emptyDrawer = this.emptyDrawer.bind(this);
    this.checkAndClose = this.checkAndClose.bind(this);
    this.setMouseDownInBar = this.setMouseDownInBar.bind(this);
    this.drawerContent = document.getElementById('drawer-content');
    this.drawerContainer = document.getElementById('drawer');
    this.drawer = null;
    this.mouseDownInBar = null;
  }

  componentDidMount() {
    window.openInDrawer = this.openDrawer;
    window.closeDrawer = this.closeDrawer;
  }


  setMouseDownInBar(ev) {
    this.mouseDownInBar = true;
  }

  checkAndClose(ev) {
    if ($(ev.target).hasClass('uk-offcanvas-overlay') && !this.mouseDownInBar) {
      this.closeDrawer();
    }
    this.mouseDownInBar = false;
  }

  openDrawer(drawerContent, drawerProps, onClose) {
    const { orientation, className } = drawerProps;
    this.setState({
      open: true,
      drawerContent,
      drawerProps,
      onClose,
    }, () => {
      if (className) {
        $('.uk-offcanvas-bar').addClass(className);
      }
      this.drawer = UIkit.offcanvas(this.drawerContainer, {
        overlay: true,
        mode:    'slide',
        flip:    orientation === 'right',
        bgClose: false,
      });
      this.drawer.show();
      UIkit.util.on('#drawer', 'hidden', this.emptyDrawer);
      $('.uk-offcanvas-bar').on('mousedown', this.setMouseDownInBar);
      setTimeout(() => { // Prevent direct close and make drawer stuck on doubleclick on openInDrawerButton
        $('.uk-offcanvas-overlay').on('click', this.checkAndClose);
      }, 1000);
    });
  }

  async closeDrawer() {
    UIkit.offcanvas(this.drawerContainer).hide();
    await $('.uk-offcanvas-overlay').off('click', this.checkAndClose);
  }

  emptyDrawer() {
    const { drawerProps, onClose } = this.state;
    const { className } = drawerProps;
    if (className) {
      $('.uk-offcanvas-bar').removeClass(className);
    }
    if (onClose) {
      onClose();
    }
    this.setState({
      open: false,
    });
  }

  render() {
    const { open, drawerContent } = this.state;
    const { children } = this.props;
    return (
      <React.Fragment>
        { open && (
          ReactDOM.createPortal(
            drawerContent,
            this.drawerContent,
          )
        ) }
        { children }
      </React.Fragment>
    );
  }
}

export default WithDrawer;
