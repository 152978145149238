import React                          from 'react';
import { useSelector }                from 'react-redux';
import asWizardStep                   from 'components/wizard_stepper/as_wizard_step';
import SingleProductTarificationError from 'components/resume/single_product_tarification_error';
import ProductLogo                    from 'components/tarifications/table/product_logo';
import DocumentsTable                 from 'components/documents/table';
import SentToBackofficeMessage        from 'components/backoffice/sent_to_backoffice_message';
import AskAxaOfferDocumentButton      from 'components/axa/constructor/ask_axa_offer_document_button';
import SignOfferButton                from 'components/offer/sign_offer_button';
import OfferErrors                    from 'components/offer/offer_errors';
import OfferInformations              from 'distributor/components/offers/offer_informations';

const AxaConstructorAskOfferStep = () => {
  const contractRequest = useSelector((state) => state.wizard.contract_request);
  const tarification = contractRequest.tarifications[0];
  return (
    <div>
      <div className="tarification-resume uk-flex uk-flex-middle mb-20">
        <div className="uk-width-1-1@s uk-width-3-5@m uk-width-4-5@l title">
          <ProductLogo product={ tarification.product } />
          { `${ tarification.product.insurance_company.name } - ${ tarification.product.name }` }
        </div>
      </div>
      { tarification.offer && !tarification.computing && (
        <OfferErrors offer={ tarification.offer } />
      ) }
      { !tarification.computing && tarification.error_encountered && (
        <div className="mb-20">
          <SingleProductTarificationError
            tarification={ tarification }
            className="p-20 tarification-product-error"
          />
        </div>
      ) }
      <p className="uk-text-center">
        <AskAxaOfferDocumentButton tarification={ tarification } />
      </p>
      <DocumentsTable
        tarifications={ contractRequest.tarifications }
        contractRequestId={ contractRequest.id }
        filters={ ['offer'] }
      />
      <OfferInformations offer={ contractRequest.tarifications[0].offer } tarification={contractRequest.tarifications[0]} />
      <p className="uk-text-center">
        <SignOfferButton tarification={ tarification } />
      </p>
      <SentToBackofficeMessage
        tarification={ contractRequest.tarifications[0] }
        contractRequest={ contractRequest }
      />
    </div>
  );
};

export default asWizardStep()(AxaConstructorAskOfferStep);
