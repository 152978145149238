import React, { useEffect }                  from 'react';
import { useDispatch }                       from 'react-redux';
import { fetchIntegrationsAndUpdateInStore } from 'models/integrations/dispatch_actions';

const WithIntegrations = ({ children }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchIntegrationsAndUpdateInStore());
  }, []);

  return children;
};

export default WithIntegrations;
