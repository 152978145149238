import React        from 'react';
import PropTypes    from 'prop-types';
import Title        from 'components/ui/titles';
import Button       from 'components/ui/buttons/button';
import ErrorImage   from 'images/error_encountered.svg';

class ErrorCatcher extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true });
    if (window.bugsnagClient) {
      window.bugsnagClient.notify(error);
    }
  }

  goBack() {
    window.location.href = '/';
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;
    if (hasError) {
      return (
        <React.Fragment>
          <div className="error-container">
            <img src={ ErrorImage } alt="Error encountered" />
            <Title size="h2" className="mb-40">
              { t('general.error.title') }
            </Title>
            <p>{ t_html('general.error.explanation_html') }</p>
            <Button
              large
              primary
              onClick={ this.goBack }
            >
              { t('general.actions.back') }
            </Button>
          </div>
        </React.Fragment>
      );
    }
    return children;
  }
}

ErrorCatcher.propTypes = {
  children: PropTypes.any.isRequired,
};

export default ErrorCatcher;
