export const ProviderMainMenu = [
  {
    label:   () => t('folders.menu_title'),
    path:    '/folders',
    purpose: 'folder_link',
  },
  {
    label:         () => t('leads.menu_title'),
    path:          '/leads',
    purpose:       'leads_link',
    authorization: 'has_intermediaries',
  },
];

export const IntermediaryMainMenu = [
  {
    label:   () => t('leads.menu_title'),
    path:    '/leads',
    purpose: 'leads_link',
  },
];

export const AssociateSubMenu = [
  {
    label: () => t('general.my_profile'),
    path:  '/profile',
    slug:  'my_profile',
    icon:  'profile',
  },
  {
    label:     () => t('general.sign_out'),
    path:      '/users/sign_out',
    slug:      'logout',
    icon:      'logout',
    className: 'error',
    confirm:   true,
  },
];

export const ProviderMainUserSubMenu = [
  {
    label: () => t('general.my_profile'),
    path:  '/profile',
    slug:  'my_profile',
    icon:  'profile',
  },
  {
    label: () => t('general.my_brokerage'),
    path:  '/account/settings',
    slug:  'my_brokerage',
    icon:  'application-settings',
  },
  {
    label:     () => t('general.sign_out'),
    path:      '/users/sign_out',
    slug:      'logout',
    icon:      'logout',
    className: 'error',
    confirm:   true,
  },
];
