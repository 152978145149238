import React                         from 'react';
import { connect }                   from 'react-redux';
import FolderIllustration            from 'images/folder_empty_state.svg';
import NotSentLeadIllustration       from 'images/lead_not_sent.svg';
import { currentUserIsIntermediary } from 'models/current_user/reducer';
import CreateFolderButton            from 'components/folder/create_folder_button';

const LeadEmptyState = ({ isIntermediary, status }) => {
  return (
    <div className="uk-flex uk-flex-middle uk-width-1-1 folder-empty-state p-20" data-purpose="lead_empty_state">
      <div className="uk-text-center uk-width-1-1">
        <img
          src={ status === 'draft_lead' ? FolderIllustration : NotSentLeadIllustration }
          alt="No lead"
          className="uk-width-1-5"
        />
        <p className="uk-text-center mt-20 pl-40 pr-40">
          { isIntermediary && (
            <React.Fragment>
              { status === 'draft_lead' ? t('leads.empty_state.intermediary_description') : t('leads.no_complete_lead') }
            </React.Fragment>
          ) }
          { !isIntermediary && t('leads.empty_state.provider_description') }
        </p>
        { (status === 'draft_lead' || status === 'lead') && isIntermediary && (
          <div className="uk-text-center">
            <CreateFolderButton
              forLead
              className="uk-button-primary large"
            />
          </div>
        ) }
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isIntermediary: currentUserIsIntermediary(state),
  };
};

export default connect(mapStateToProps)(LeadEmptyState);
