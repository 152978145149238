import React, { useContext } from 'react';
import JsonFormContext       from 'components/ui/json_form/json_form_context';

const withDisabledForm = () => Component => {
  return (props) => {
    const { disabled } = useContext(JsonFormContext);
    return <Component { ...props } disabled={ disabled } />;
  };
};

export default withDisabledForm;
