import React, { useEffect, useState, useContext } from 'react';
import { useDispatch, useSelector }               from 'react-redux';
import { Link }                                   from 'react-router-dom';
import has                                        from 'lodash/has';
import StepList                                   from 'components/wizard_stepper/step_list';
import {
  DEFAULT_REQUIRED_FIELDS_AXA_CONSTRUCTOR, FINISH_FOLDER,
  DEFAULT_REQUIRED_FIELDS_MAZDA_INSURANCE, trackWizardStep,
}                                                 from 'models/wizard/constants';
import { contractRequestIsLead }                  from 'components/wizard_stepper/constants';
import {
  canHaveSecondaryDriver,
  haveRiskObject,
  haveSelectedCover,
  haveSelectedMainDriver,
  haveSelectedPolicyOwner,
  isMotorbikeContractRequest,
  isBikeContractRequest,
  isCarContractRequest,
  isVanContractRequest,
  isNotLead,
  haveAtLeastOneProduct,
  notLeadAndHaveAtLeastOneProduct,
  notLeadAndHaveSelectedCover,
}                                                 from 'models/wizard_navigation/constants';
import RiskObjectStep                             from 'components/wizard/risk_object_step';
import ProductStep                                from 'components/wizard/product_step';
import CoverStep                                  from 'components/wizard/cover_step';
import ResumeStep                                 from 'components/wizard/resume_step';
import { getTarificationSteps }                   from 'schemas/shared/tarification_steps';
import LoadOnClickButton                          from 'components/ui/buttons/load_on_click_button';
import { useHistory }                             from 'react-router';
import { finishDraftLead }                        from 'models/leads/request';
import { getPolicyOwnerSchemaFiltered }           from 'schemas/shared/policy_owner_schema';
import GenericStep                                from 'components/wizard_stepper/generic_step';
import { getPersonSchema }                        from 'schemas/mobility/person_schema';
import { getCarSchema }                           from 'schemas/mobility/car_schema';
import { getBikeSchema }                          from 'schemas/mobility/bike_schema';
import { getMotorbikeSchema }                     from 'schemas/mobility/motorbike_schema';
import WizardStepperContext                       from 'components/wizard_stepper/wizard_context';
import { currentUserIsIntermediary }              from 'models/current_user/reducer';
import getConstructorSteps                        from 'schemas/axa/constructor/axa_constructor_steps';
import getMazdaInsuranceSteps                     from 'schemas/van_dessel/mazda_insurance_steps';
import { setDefaultRequiredFields }               from 'models/wizard/store_actions';
import { isCrLead }                               from 'models/contract_request/constants';

export const getMobilitySteps = (matchPath, contractRequest, recomputeTarifications) => {
  const policyOwnerSchema = getPolicyOwnerSchemaFiltered(isCrLead(contractRequest));
  return [
    {
      path:      `${ matchPath }/risk_object`,
      label:     t('wizard.navigation.risk_object'),
      title:     t('wizard.risk_object.title'),
      purpose:   'risk_object_step',
      icon:      contractRequest.risk_object ? contractRequest.risk_object.icon : 'car',
      Component: RiskObjectStep,
      order:     1,
    },
    {
      path:          `${ matchPath }/products`,
      label:         t('wizard.navigation.products'),
      title:         t('wizard.products.title'),
      purpose:       'product_step',
      disabledRules: [
        haveRiskObject,
      ],
      displayRules:  [
        isNotLead,
      ],
      icon:          'quick-tarification',
      Component:     ProductStep,
      order:         2,
    },
    {
      path:           `${ matchPath }/covers`,
      label:          t('wizard.navigation.covers'),
      title:          t('wizard.covers.title'),
      purpose:        'cover_step',
      disabledRules:  [
        haveRiskObject,
        haveAtLeastOneProduct,
      ],
      displayRules:   [
        isNotLead,
      ],
      icon:           'list',
      Component:      CoverStep,
      componentProps: {
        onNextStep: {
          recomputeTarifications,
        },
      },
      order:          3,
    },
    {
      label:          t('wizard.navigation.about_contract_request'),
      icon:           'file',
      slug:           'contract_request',
      kind:           'group',
      order:          4,
      warningTooltip: ['base', 'car', 'policy_owner', 'main_driver', 'secondary_driver'],
      steps:          [
        {
          path:           `${ matchPath }/policy_owner`,
          disabledRules:  [
            haveRiskObject,
            notLeadAndHaveAtLeastOneProduct,
            notLeadAndHaveSelectedCover,
          ],
          title:          t('wizard.policy_owner.title'),
          label:          t('wizard.navigation.policy_owner'),
          icon:           'company',
          Component:      GenericStep,
          purpose:        'policy_owner_step',
          componentProps: {
            ...policyOwnerSchema,
            onNextStep: {
              recomputeTarifications,
            },
          },
          order:          2,
        },
        {
          path:           `${ matchPath }/main_driver`,
          label:          t('wizard.navigation.main_driver'),
          title:          t('wizard.main_driver.title'),
          warningTooltip: 'main_driver',
          purpose:        'main_driver_step',
          disabledRules:  [
            haveRiskObject,
            notLeadAndHaveAtLeastOneProduct,
            notLeadAndHaveSelectedCover,
            haveSelectedPolicyOwner,
            haveSelectedCover,
          ],
          icon:           'driver',
          Component:      GenericStep,
          componentProps: {
            ...getPersonSchema('main_driver', {
              schema: {
                main_driver: {
                  properties_order: [
                    'search_in_bms',
                    'policy_owner_is_main_driver',
                  ],
                  properties:       {
                    search_in_bms:               {
                      component: 'bms',
                      prefix:    [
                        'main_driver',
                      ],
                    },
                    policy_owner_is_main_driver: {
                      label:         t('wizard.policy_owner.policy_owner_is_main_driver'),
                      component:     'true_false',
                      visible:       {
                        property:  'policy_owner.kind',
                        assertion: 'equal',
                        value:     'person',
                      },
                      default:       {
                        value: true,
                      },
                      onValueChange: {
                        prefill: {
                          prefillOnMount:           true,
                          from:                     {
                            store: 'policy_owner.person',
                          },
                          to:                       'main_driver.person',
                          clearOnConditionUnfilled: true,
                          conditions:               [
                            {
                              operator:  'and',
                              assertion: 'isTrue',
                            },
                            {
                              operator:  'or',
                              property:  'main_driver.person',
                              assertion: 'deepEqual',
                              value:     {
                                property: 'defaultValues.main_driver.person',
                              },
                            },
                          ],
                        },
                      },
                    },
                  },
                },
                misc:        {
                  type:       'section',
                  properties: {
                    can_have_secondary_driver: {
                      label:         t('wizard.driver.has_secondary_driver'),
                      component:     'true_false',
                      visible:       {
                        property:  'contract_request.risk_object.kind',
                        assertion: 'notEqual',
                        value:     'motorbike',
                      },
                      onValueChange: {
                        set: [
                          {
                            to:        'secondary_driver',
                            value:     null,
                            condition: {
                              assertion: 'isFalse',
                            },
                          },
                        ],
                      },
                      default:       {
                        value: false,
                      },
                    },
                  },
                },
              },
            }),
            onNextStep: {
              recomputeTarifications,
            },
          },
          order:          3,
        },
        {
          path:           `${ matchPath }/secondary_driver`,
          label:          t('wizard.navigation.secondary_driver'),
          title:          t('wizard.secondary_driver.title'),
          warningTooltip: 'secondary_driver',
          purpose:        'secondary_driver_step',
          disabledRules:  [
            haveRiskObject,
            notLeadAndHaveAtLeastOneProduct,
            notLeadAndHaveSelectedCover,
            haveSelectedPolicyOwner,
            haveSelectedMainDriver,
          ],
          displayRules:   [
            canHaveSecondaryDriver,
          ],
          icon:           'driver',
          Component:      GenericStep,
          componentProps: {
            ...getPersonSchema('secondary_driver', {
              schema: {
                secondary_driver: {
                  properties_order: [
                    'search_in_bms',
                  ],
                  properties:       {
                    search_in_bms: {
                      component: 'bms',
                      prefix:    [
                        'secondary_driver',
                      ],
                    },
                  },
                },
              },
            }),
            onNextStep: {
              recomputeTarifications,
            },
          },
          order:          4,
        },
        {
          path:           `${ matchPath }/car`,
          label:          t('wizard.navigation.vehicle'),
          title:          t('wizard.car.title'),
          warningTooltip: 'car',
          purpose:        'car_step',
          disabledRules:  [
            haveRiskObject,
            notLeadAndHaveAtLeastOneProduct,
            notLeadAndHaveSelectedCover,
            haveSelectedPolicyOwner,
            haveSelectedMainDriver,
          ],
          displayRules:   [
            isCarContractRequest,
          ],
          icon:           'car',
          Component:      GenericStep,
          componentProps: {
            ...getCarSchema(),
            onNextStep: {
              recomputeTarifications,
            },
          },
          order:          7,
        },
        {
          path:           `${ matchPath }/motorbike`,
          label:          t('wizard.navigation.vehicle'),
          title:          t('wizard.motorbike.title'),
          warningTooltip: 'motorbike',
          purpose:        'motorbike_step',
          disabledRules:  [
            haveRiskObject,
            notLeadAndHaveAtLeastOneProduct,
            notLeadAndHaveSelectedCover,
            haveSelectedPolicyOwner,
            haveSelectedMainDriver,
          ],
          displayRules:   [
            isMotorbikeContractRequest,
          ],
          icon:           'motorbike',
          Component:      GenericStep,
          order:          5,
          componentProps: {
            ...getMotorbikeSchema(),
            onNextStep: {
              recomputeTarifications,
            },
          },
        },
        {
          path:           `${ matchPath }/bike`,
          label:          t('wizard.navigation.bike'),
          title:          t('wizard.bike.title'),
          warningTooltip: 'bike',
          purpose:        'bike_step',
          disabledRules:  [
            haveRiskObject,
            notLeadAndHaveAtLeastOneProduct,
            notLeadAndHaveSelectedCover,
            haveSelectedPolicyOwner,
            haveSelectedMainDriver,
          ],
          displayRules:   [
            isBikeContractRequest,
          ],
          icon:           'bike',
          Component:      GenericStep,
          componentProps: {
            ...getBikeSchema(),
            onNextStep: {
              recomputeTarifications,
            },
          },
          order:          6,
        },
        {
          path:           `${ matchPath }/van`,
          label:          t('wizard.navigation.vehicle'),
          title:          t('wizard.van.title'),
          warningTooltip: 'van',
          purpose:        'van_step',
          disabledRules:  [
            haveRiskObject,
            notLeadAndHaveAtLeastOneProduct,
            notLeadAndHaveSelectedCover,
            haveSelectedPolicyOwner,
            haveSelectedMainDriver,
          ],
          displayRules:   [
            isVanContractRequest,
          ],
          icon:           'van',
          Component:      GenericStep,
          componentProps: {
            ...getCarSchema(true),
            onNextStep: {
              recomputeTarifications,
            },
          },
          order:          8,
        },
      ],
    },
    getTarificationSteps(contractRequest, matchPath, [
      haveRiskObject,
      haveAtLeastOneProduct,
      haveSelectedCover,
      haveSelectedPolicyOwner,
      haveSelectedMainDriver,
    ], [
      isNotLead,
    ], 9),
    {
      path:           `${ matchPath }/resume`,
      title:          t('wizard.navigation.resume'),
      disabledRules:  [
        haveRiskObject,
        haveAtLeastOneProduct,
        haveSelectedCover,
        haveSelectedPolicyOwner,
        haveSelectedMainDriver,
      ],
      label:          t('wizard.navigation.resume'),
      icon:           'folder',
      purpose:        'resume_step',
      Component:      ResumeStep,
      componentProps: {
        subscriptionRequired: false,
      },
      order:          10,
      StepMainAction: () => {
        const isLead = useSelector((state) => contractRequestIsLead(state.wizard));
        const isIntermediary = useSelector((state) => currentUserIsIntermediary(state));
        const contractRequestId = useSelector((state) => state.wizard.contract_request.id);
        const history = useHistory();
        return (
          <React.Fragment>
            { !isLead && !isIntermediary && (
              <Link
                className="wizard-button primary"
                to="/folders"
                data-purpose="finish_button"
                onClick={ () => {
                  trackWizardStep(FINISH_FOLDER, {
                    contract_request_id: contractRequestId,
                  });
                } }
              >
                { t('general.actions.finish') }
              </Link>
            ) }
            { isLead && isIntermediary && (
              <LoadOnClickButton
                onClick={ () => finishDraftLead(contractRequestId).then(() => {
                  history.push(`/leads`);
                }) }
                primary
                large
                data-purpose="finish_button"
              >
                { t('leads.send_lead') }
              </LoadOnClickButton>
            ) }
          </React.Fragment>
        );
      },
    },
  ];
};

const MobilitySteps = ({ matchPath }) => {
  const dispatch = useDispatch();
  const contractRequest = useSelector(state => state.wizard.contract_request);
  const selectedProductSlug = has(contractRequest, 'tarifications[0].product.full_frank_slug') ? contractRequest.tarifications[0].product.full_frank_slug : null;
  const { recomputeTarifications } = useContext(WizardStepperContext);
  const constructorProductList = ['axa-constructor-stand-alone'];
  const vdMazdaList = ['van-dessel-mazda-insurance'];
  const [steps, setStepList] = useState(null);
  useEffect(() => {
    if (selectedProductSlug) {
      if (constructorProductList.includes(selectedProductSlug)) {
        dispatch(setDefaultRequiredFields(DEFAULT_REQUIRED_FIELDS_AXA_CONSTRUCTOR));
        return setStepList(getConstructorSteps(matchPath, contractRequest, recomputeTarifications));
      }
      if (vdMazdaList.includes(selectedProductSlug)) {
        dispatch(setDefaultRequiredFields(DEFAULT_REQUIRED_FIELDS_MAZDA_INSURANCE));
        return setStepList(getMazdaInsuranceSteps(matchPath, contractRequest, recomputeTarifications));
      }
    }
    setStepList(getMobilitySteps(matchPath, contractRequest, recomputeTarifications));
  }, [contractRequest.tarifications]);
  if (!steps) {
    return null;
  }
  return <StepList steps={ steps } />;
};

export default MobilitySteps;
