import React                             from 'react';
import { connect }                       from 'react-redux';
import { logOutCurrentUser }             from 'models/current_user/requests';
import Loader                            from 'components/ui/loader';
import {
  setUserInStore, storeIntermediaryToken,
}                                        from 'models/current_user/store_actions';
import { getUrlSearchParam }             from 'services/url';
import { intermediarySignIn }            from 'models/user/requests';
import { INTERMEDIARY_URL_SEARCH_PARAM } from 'models/intermediaries/constants';
import { trackEvent }                    from 'services/segment';
import { getAuthorizationToken }         from 'services/authorization_token';

class CheckForIntermediarySession extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }

  componentDidMount() {
    const { signInIntermediary } = this.props;
    const intermediaryTokenFromParams = getUrlSearchParam(INTERMEDIARY_URL_SEARCH_PARAM);

    if (intermediaryTokenFromParams && !getAuthorizationToken()) {
      signInIntermediary(intermediaryTokenFromParams).then(() => {
        this.setState({ loading: false });
      }).catch(() => {
        this.setState({
          loading: false,
        });
      });
    } else {
      this.setState({
        loading: false,
      });
    }
  }

  render() {
    const { children } = this.props;
    const { loading } = this.state;
    if (loading) {
      return <Loader middleOfPage />;
    } else {
      return children;
    }
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    signInIntermediary: (token) => {
      return new Promise((resolve, reject) => {
        dispatch(logOutCurrentUser(false)).then(() => {
          intermediarySignIn(token).then((currentUser) => {
            trackEvent('IntermediarySignedIn');
            dispatch(setUserInStore(currentUser));
            dispatch(storeIntermediaryToken(token));
            resolve();
          }).catch(reject);
        }).catch(reject);
      });
    },
  };
};

export default connect(null, mapDispatchToProps)(CheckForIntermediarySession);
