import React, { useEffect, useState } from 'react';
import fromPairs                      from 'lodash/fromPairs';
import map                            from 'lodash/map';
import without                        from 'lodash/without';
import { FormSection }                from 'redux-form';
import asJsonFormField                from 'components/ui/json_form/as_json_form_field';
import JsonFormFields                 from 'components/ui/json_form/json_form_fields';
import TooltipOrDialog                from 'components/ui/tooltip/tooltip_or_dialog';
import { t_html }                     from 'services/i18n';

const sortByKeys = (object, keyArray) => {
  const objectKeys = Object.keys(object);
  const filteredObjectKeys = without(objectKeys, keyArray);

  return fromPairs(
    map([
      ...keyArray,
      ...filteredObjectKeys,
    ], key => [key, object[key]]),
  );
};

const JsonFormSection = ({ namePrefix, field, form, change, fieldKey, inArray }) => {
  const [fields, setFields] = useState(null);
  let properties = field.properties;
  if (field.properties_order) {
    properties = sortByKeys(field.properties, field.properties_order);
  }
  useEffect(() => {
    setFields((
      <JsonFormFields
        schema={ properties }
        form={ form }
        change={ change }
        namePrefix={ namePrefix }
        inArray={ inArray }
      />
    ));
  }, [field]);
  const name = namePrefix ? `${ namePrefix }.${ fieldKey }` : fieldKey;
  return (
    <FormSection
      key={ name }
      name={ name }
      data-purpose={ `${ name }_section` }
    >
      <div className="uk-flex uk-flex-middle mb-20">
        { field.title && (<h2 className="mb-0">{ field.title }</h2>) }
        { field.title_tooltip && (
          <span className="ml-10 uk"><TooltipOrDialog content={ field.title_tooltip } /></span>) }
        { field.title_tooltip_html && (
          <span className="ml-10 uk">
            <TooltipOrDialog
              dialogTitle={ field.title }
              content={ t_html(field.title_tooltip_html) }
              forceDialog
            />
          </span>) }
      </div>
      { fields }
    </FormSection>
  );
};

export default asJsonFormField()(JsonFormSection);
