import {
  getCookie,
  setCookie,
  removeCookie,
} from 'services/cookies';

export const WINDOWS_TEST_DOMAIN = 'serveo.net';

export const getAuthorizationToken = () => {
  return getCookie('authorization_token');
};

export const setAuthorizationToken = (authorization_token) => {
  setCookie('authorization_token', authorization_token);
};

export const removeAuthorizationToken = () => {
  removeCookie('authorization_token');
};

export const getAuthorizationHeader = () => {
  if (getAuthorizationToken()) {
    return `Bearer ${ getAuthorizationToken() }`;
  }
  return undefined;
};
