import React, { useEffect }       from 'react';
import { useDispatch }            from 'react-redux';
import { updateWizardNavigation } from 'models/wizard/dispatch_actions';
import { fetchContractRequest }   from 'models/contract_request/requests';
import { setContractRequest }     from 'models/wizard/store_actions';

const RefreshContractRequest = ({ contractRequestId }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    fetchContractRequest(contractRequestId).then(({ contract_request }) => {
      dispatch(setContractRequest(contract_request));
      dispatch(updateWizardNavigation(contract_request));
    });
  }, []);
  return null;
};

export default RefreshContractRequest;
