import React                           from 'react';
import { connect }                     from 'react-redux';
import { formValueSelector }           from 'redux-form';
import every                           from 'lodash/every';
import asWizardStep                    from 'components/wizard_stepper/as_wizard_step';
import { getActivitiesPDF }            from 'models/activities/request';
import TarificationTable               from 'components/tarifications/table/table';
import CrEmptyStateImage               from 'images/customer_cr_empty_state.svg';
import Title                           from 'components/ui/titles';
import ContractRequestResume           from 'components/wizard/resume';
import Button                          from 'components/ui/buttons/button';
import GetDocxButton                   from 'components/tarifications/get_docx_button';
import { getTarificationTableColumns } from 'models/wizard/constants';
import { currentUserIsIntermediary }   from 'models/current_user/reducer';
import ConvertLeadButton               from 'components/lead/convert_lead_button';
import LeadFinished                    from 'components/lead/lead_finished';
import { isCrLead }                    from 'models/contract_request/constants';
import PrintTarificationButton         from 'components/tarifications/print_tarification_button';
import OpenDocumentTableInDialogButton from 'components/documents/open_document_table_dialog_button';

const TarificationActions = ({ selectedTarifications, contractRequest, className, ...rest }) => {
  const hasComputingTarification = !every(selectedTarifications, (tarif) => !tarif.computing);
  return (
    <div className={ `uk-flex uk-flex-middle ${ className }` } { ...rest }>
      <GetDocxButton
        tarifications={ selectedTarifications }
        contractRequest={ contractRequest }
        className="mr-10"
        disabled={ hasComputingTarification }
      />
      <PrintTarificationButton
        tarifications={ selectedTarifications }
        contractRequest={ contractRequest }
        inlineLoadingText
        disabled={ hasComputingTarification }
      />

    </div>
  );
};

const ResumeStep = (
  {
    contractRequestAuthorizations,
    contractRequest,
    tarifications,
    columns,
    selectedTarifications,
    isIntermediary,
    isLead,
  },
) => {
  const initialValues = {};
  tarifications.forEach((tarif) => {
    initialValues[`tarification_${ tarif.id }`] = true;
  });

  return (
    <div data-purpose="resume_step">
      { isLead && (
        <React.Fragment>
          { isIntermediary && (
            <LeadFinished className="mt-20" leadId={ contractRequest.id } />
          ) }
          {
            !isIntermediary && (
              <p className="uk-text-center">
                <ConvertLeadButton leadId={ contractRequest.id } />
              </p>
            )
          }
        </React.Fragment>
      ) }
      { !isLead && (
        <React.Fragment>
          <Title size="h3" className="mt-20 mb-20">
            { t('wizard.resume.about_contract_request') }
          </Title>
          <div className="uk-grid-divider" data-uk-grid>
            <ContractRequestResume
              contractRequest={ contractRequest }
              className="uk-child-width-1-2@s uk-width-expand@m"
            />
            <div>
              { !isIntermediary && (
                <div className="uk-flex uk-flex-center uk-flex-column uk-flex-right">
                  { contractRequestAuthorizations.activities_pdf_export && (
                    <Button
                      outline
                      type="button"
                      onClick={ () => getActivitiesPDF(contractRequest.id) }
                      data-purpose="activities"
                      icon="activity-report"
                    >
                      { t('clients.contract_requests.activities') }
                    </Button>
                  ) }
                  <OpenDocumentTableInDialogButton
                    className="mt-20"
                    contractRequestId={ contractRequest.id }
                    tarifications={ tarifications }
                  />
                </div>
              ) }
            </div>
          </div>
          <Title
            size="h3"
            className="mt-20 mb-20"
          >
            { t('wizard.resume.tarifications', { count: tarifications.length }) }
          </Title>
          { tarifications.length > 0 && (
            <React.Fragment>
              <TarificationActions
                selectedTarifications={ selectedTarifications }
                contractRequest={ contractRequest }
              />
              <TarificationTable
                tarifications={ tarifications }
                contractRequest={ contractRequest }
                columns={ columns }
                initialValues={ initialValues }
              />
              <TarificationActions
                selectedTarifications={ selectedTarifications }
                contractRequest={ contractRequest }
                className="mt-20"
              />
            </React.Fragment>
          ) }
          { !tarifications.length && (
            <div className="uk-text-center uk-flex uk-flex-middle uk-flex-column mt-50">
              <img
                src={ CrEmptyStateImage }
                className="mb-20 text-center"
                alt={ t('quick_tarification.no_tarification_available') }
              />
              <p>{ t('quick_tarification.table.saved_empty') }</p>
            </div>
          ) }
        </React.Fragment>
      ) }
    </div>
  );
};

const selector = formValueSelector('tarification_table');

const mapStateToProps = (state) => {

  const tarifications = state.wizard.contract_request.tarifications;
  const selectedTarifications = tarifications.filter((tarif) => {
    if (selector(state, `tarification_${ tarif.id }`)) {
      return tarif;
    }
  });

  return {
    tarifications,
    customerId:                    state.wizard.contract_request.customer_id,
    contractRequest:               state.wizard.contract_request,
    contractRequestAuthorizations: state.wizard.contract_request.authorizations,
    columns:                       getTarificationTableColumns(tarifications),
    selectedTarifications,
    allTarificationsSelected:      tarifications.length > 0 && tarifications.length === selectedTarifications,
    isIntermediary:                currentUserIsIntermediary(state),
    isLead:                        isCrLead(state.wizard.contract_request),
  };
};

export default connect(mapStateToProps)(asWizardStep()(ResumeStep));
