const surfaceOfficeDisabledConditions = [
  {
    property:  'building.use.is_office_use',
    assertion: 'isTrue',
  },
  {
    property:  'building.use.office_use_details',
    assertion: 'isNotEmpty',
  },
];
const surfaceCommercialDisabledConditions = [
  {
    property:  'building.use.is_business_use',
    assertion: 'isTrue',
  },
  {
    property:  'building.use.business_use_details',
    assertion: 'isNotEmpty',
  },
];
export const getAxaBuildingGridSchema = () => {
  return {
    grid: {
      type:               'section',
      title:              t('wizard.building.grid.title'),
      title_tooltip_html: 'wizard.building.grid.explanation_html',
      visible:            {
        property:  'building.evaluation.sub_type',
        assertion: 'equal',
        value:     'Grid',
      },
      properties:         {
        title_1:                            {
          type:      'title',
          title:     t('wizard.building.grid.subtitles.presence'),
          className: 'default-text-title mt-0',
        },
        title_2:                            {
          type:      'title',
          title:     t('wizard.building.grid.subtitles.kitchen'),
          className: 'small-title-dark mb-20',
        },
        is_built_in_appliances:             {
          validations: ['required'],
          label:       t('wizard.building.grid.is_built_in_appliances'),
          component:   'true_false',
          tooltip:     t('wizard.building.grid.is_built_in_appliances_tooltip'),
        },
        is_open_plan_kitchen:               {
          validations: ['required'],
          label:       t('wizard.building.grid.is_open_plan_kitchen'),
          component:   'true_false',
        },
        is_luxury_worktop:                  {
          validations: ['required'],
          label:       t('wizard.building.grid.is_luxury_worktop'),
          component:   'true_false',
        },
        title_3:                            {
          type:      'title',
          title:     t('wizard.building.grid.subtitles.doors'),
          className: 'small-title-dark',
        },
        is_security_doors:                  {
          validations: ['required'],
          label:       t('wizard.building.grid.is_security_doors'),
          component:   'true_false',
        },
        title_4:                            {
          type:      'title',
          title:     t('wizard.building.grid.subtitles.bathrooms'),
          className: 'small-title-dark',
        },
        number_of_bathrooms:                {
          component:   'multi_button',
          validations: ['required'],
          label:       t('wizard.building.grid.number_of_bathrooms'),
          tooltip:     t('wizard.building.grid.number_of_bedrooms_and_bathrooms_tooltip'),
          list:        [
            {
              value: '<3',
              label: '< 3',
            },
            {
              value: '3',
              label: '3',
            },
            {
              value: '>3',
              label: '> 3',
            },
          ],
        },
        title_5:                            {
          type:      'title',
          title:     t('wizard.building.grid.subtitles.other_in_private_parts'),
          className: 'small-title-dark',
        },
        is_airco_conditioning:              {
          validations: ['required'],
          label:       t('wizard.building.grid.is_airco_conditioning'),
          component:   'true_false',
        },
        is_integrated_furniture:            {
          validations: ['required'],
          label:       t('wizard.building.grid.is_integrated_furniture'),
          component:   'true_false',
          tooltip:     t('wizard.building.grid.is_integrated_furniture_tooltip'),
        },
        is_solid_floor_call:                {
          validations: ['required'],
          label:       t('wizard.building.grid.is_solid_floor_call'),
          component:   'true_false',
        },
        number_of_bedrooms:                 {
          component:   'multi_button',
          validations: ['required'],
          label:       t('wizard.building.grid.number_of_bedrooms'),
          list:        [
            {
              value: '<2',
              label: '< 2',
            },
            {
              value: '2',
              label: '2',
            },
            {
              value: '>2',
              label: '> 2',
            },
          ],
        },
        title_6:                            {
          type:      'title',
          title:     t('wizard.building.grid.subtitles.common_parts'),
          className: 'small-title-dark',
        },
        is_entrance_door_security:          {
          validations: ['required'],
          label:       t('wizard.building.grid.is_entrance_door_security'),
          component:   'true_false',
        },
        is_communal_area_solid_floor:       {
          validations: ['required'],
          label:       t('wizard.building.grid.is_communal_area_solid_floor'),
          component:   'true_false',
        },
        title_7:                            {
          type:               'title',
          title:              t('wizard.building.grid.subtitles.count'),
          title_tooltip: t('wizard.building.grid.subtitles.count_tooltip'),
          className:          'small-title-dark',
          size:               'h2',
        },
        surface_habitable:                  {
          component:   'text',
          label:       t('wizard.building.grid.surface_habitable'),
          validations: ['required', 'maxLength10', 'only_int'],
          symbol:      'm²',
          min:         0,
          step:        1,
          default:     {
            value: 0,
          },
        },
        surface_office:                     {
          component:     'text',
          validations:   ['required', 'maxLength10', 'only_int'],
          label:         t('wizard.building.grid.surface_office'),
          symbol:        'm²',
          min:           0,
          step:          1,
          visible:       [
            {
              property:  'building.use.residential_use',
              assertion: 'notEqual',
              value:     'Residential',
            },
            {
              property: 'building.use.is_office_use',
            },
          ],
          fieldAddition: {
            className: 'global-message warning xs p-5',
            message:   t('wizard.building.grid.computed_from_use_detail'),
            visible:   surfaceOfficeDisabledConditions,
          },
          disabled:      surfaceOfficeDisabledConditions,
        },
        surface_commercial:                 {
          component:     'text',
          validations:   ['required', 'maxLength10', 'only_int'],
          label:         t('wizard.building.grid.surface_commercial'),
          symbol:        'm²',
          min:           0,
          step:          1,
          visible:       [
            {
              property:  'building.use.residential_use',
              assertion: 'notEqual',
              value:     'Residential',
            },
            {
              property: 'building.use.is_business_use',
            },
          ],
          fieldAddition: {
            className: 'global-message warning xs p-5',
            message:   t('wizard.building.grid.computed_from_use_detail'),
            visible:   surfaceCommercialDisabledConditions,
          },
          disabled:      surfaceCommercialDisabledConditions,
        },
        surface_patios:                     {
          component:   'text',
          label:       t('wizard.building.grid.surface_patios'),
          validations: ['required', 'maxLength10', 'only_int'],
          symbol:      'm²',
          type:        'number',
          min:         0,
          step:        1,
          default:     {
            value: 0,
          },
        },
        surface_basement:                   {
          component:   'text',
          label:       t('wizard.building.grid.surface_basement'),
          validations: ['required', 'maxLength10', 'only_int'],
          symbol:      'm²',
          type:        'number',
          min:         0,
          step:        1,
          default:     {
            value: 0,
          },
        },
        surface_parking_places:             {
          component:   'text',
          label:       t('wizard.building.grid.surface_parking_places'),
          validations: ['required', 'maxLength10', 'only_int'],
          symbol:      'm²',
          type:        'number',
          min:         0,
          step:        1,
          default:     {
            value: 0,
          },
        },
        surface_solar_panels:               {
          component:   'text',
          label:       t('wizard.building.grid.surface_solar_panels'),
          validations: ['required', 'maxLength10', 'only_int'],
          symbol:      'm²',
          type:        'number',
          min:         0,
          step:        1,
          default:     {
            value: 0,
          },
        },
        number_of_lifts:                    {
          component:   'text',
          label:       t('wizard.building.grid.number_of_lifts'),
          validations: ['required', 'maxLength10', 'only_int'],
          type:        'number',
          step:        1,
          default:     {
            value: 0,
          },
        },
        sum_insured_swimming_pools:         {
          component:   'price',
          label:       t('wizard.building.grid.sum_insured_swimming_pools'),
          validations: ['required', 'maxLength10', 'only_int_price'],
          placeholder: '',
          step:        1,
          tooltip:     t('wizard.building.grid.sum_insured_swimming_pools_tooltip'),
          default:     {
            value: 0,
          },
        },
        sum_insured_communal_area_domotics: {
          component:   'price',
          label:       t('wizard.building.grid.sum_insured_communal_area_domotics'),
          validations: ['required', 'maxLength10', 'only_int_price'],
          placeholder: '',
          step:        1,
          tooltip:     t('wizard.building.grid.sum_insured_communal_area_domotics_tooltip'),
          default:     {
            value: 0,
          },
        },
        is_sculpted_facade:                 {
          validations: ['required'],
          label:       t('wizard.building.grid.is_sculpted_facade'),
          tooltip:     t('wizard.building.grid.is_sculpted_facade_tooltip'),
          component:   'true_false',
        },
        shared_boundary:                    {
          validations: ['required'],
          label:       t('wizard.building.grid.shared_boundary'),
          component:   'radio_list',
          list:        [
            {
              value: 'Full',
              label: t('wizard.building.grid.shared_boudary_options.full'),
            },
            {
              value: 'Partial',
              label: t('wizard.building.grid.shared_boudary_options.partial'),
            },
          ],
        },
      },
    },
  };
};
