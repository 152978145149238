import Api   from 'services/api';
import Alert from 'services/alert';

export const fetchLeads = (page = '1', filters = {}) => {
  return new Promise((resolve, reject) => {
    Api.get('leads', {
      params: {
        page,
        ...filters,
      },
    }).then((response) => {
      resolve(response.data);
    }).catch((error) => {
      Alert.error(t('leads.fetch_leads_error'));
      reject(error);
    });
  });
};


export const convertLead = (leadId) => {
  return new Promise((resolve, reject) => {
    Api.post(`leads/${ leadId }/to_contract_request`).then((response) => {
      resolve(response.data.contract_request);
    }).catch((error) => {
      Alert.error(t('leads.convert_lead_error'));
      reject(error);
    });
  });
};

export const finishDraftLead = (leadId) => {
  return new Promise((resolve, reject) => {
    Api.post(`leads/${ leadId }/complete`).then((response) => {
      resolve(response.data.contract_request);
    }).catch((error) => {
      Alert.error(t('leads.finish_lead_error'));
      reject(error);
    });
  });
};
