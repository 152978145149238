import { assert, expect } from 'chai';
import isNil              from 'lodash/isNil';
import every              from 'lodash/every';
import get                from 'lodash/get';
import find               from 'lodash/find';
import reduce             from 'lodash/reduce';
import groupBy            from 'lodash/groupBy';
import flatten            from 'lodash/flatten';
import has                from 'lodash/has';
import compact            from 'lodash/compact';
import isObject           from 'lodash/isObject';
import isBoolean          from 'lodash/isBoolean';

import { normalizeDate } from 'constants/application_constants';
import {
  maxLength500,
  maxLength200,
  maxLength33,
  maxLength35,
  maxLength12,
  maxLength20,
  maxLength10,
  maxLength7,
  maxLength6,
  maxLength5,
  maxLength4,
  maxLength3,
  maxLength1,
  maxLength,
  minLength4,
  minLength17,
  exactLength12,
  max99,
  not0,
  onlyInt,
  onlyLetter,
  onlyLetterAndSpace,
  onlyIntPrice, minLength10, min17years,
}                        from 'models/application/constants';
import moment            from 'moment';

const expectationMethod = (property, expectation, value) => {
  const t = new Function('expect', 'property', 'expectation', 'value', 'eval(`expect(property).${expectation}(...value)`)');
  return t(expect, property, expectation, value);
};

const assertionMethod = (property, assertion, value) => {
  return assert[assertion](property, value);
};

/*
  Condition format

  assertion
  {
    assertion: ''  -> http://aaronsofaly.github.io/chai-docs/api/assert/
    property: ''   -> Property to check
    value: ''      -> Value to compare
  }

  expectation
  {
    expectation: '' -> http://aaronsofaly.github.io/chai-docs/api/bdd/
    property: ''    -> Property to check
    value: ''       -> Value to compare
  }

 */

const validateEmail = (val) => {
  if (!val) {
    return t('general.forms.required');
  }
  const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return emailRegex.test(String(val).toLowerCase()) ? undefined : t('activerecord.attributes.user.invalid_email');
};

export const yearsValidation = (val, years, operator) => {
  const date = moment(val, 'YYYY-MM-DD');
  const today = moment();
  if (date.isValid() && val.length >= 10) {
    const diff = today.diff(date, 'years');
    if (operator === '<') {
      if (diff < years) {
        return null;
      } else {
        return t('components.ui.json_form.json_form_helpers.max_x_years', { years });
      }
    }
    if (operator === '>') {
      if (diff > years) {
        return null;
      } else {
        return t('components.ui.json_form.json_form_helpers.min_x_years', { years });
      }
    }
    if (operator === '>=') {
      if (diff >= years) {
        return null;
      } else {
        return t('components.ui.json_form.json_form_helpers.min_x_years', { years });
      }
    }
  }
  return undefined;
};

const yearsDiff = (d1, d2) => {
  let date1 = new Date(d1);
  let date2 = new Date(d2);
  let yearsDiff = date2.getFullYear() - date1.getFullYear();
  return yearsDiff;
};

const monthDiff = (d1, d2) => {
  let date1 = new Date(d1);
  let date2 = new Date(d2);
  let years = yearsDiff(d1, d2);
  let months = (years * 12) + (date2.getMonth() - date1.getMonth());
  return months;
};

export const defaultValidations = {
  'required':                            (value) => {
    if (typeof value === 'boolean') {
      return undefined;
    }
    if (typeof value === 'string') {
      return !value.length ? t('general.forms.required') : undefined;
    }
    return isNil(value) ? t('general.forms.required') : undefined;
  },
  'date_format':                         (value) => {
    const dateFormatRegex = new RegExp(/(\d{4})-(\d{2})-(\d{2})/);
    return dateFormatRegex.test(value) ? undefined : t('wizard.date.validate_format_message');
  },
  'maxLength500':                        maxLength500,
  'maxLength200':                        maxLength200,
  'maxLength33':                         maxLength33,
  'maxLength35':                         maxLength35,
  'maxLength20':                         maxLength20,
  'maxLength12':                         maxLength12,
  'maxLength16':                         maxLength(16),
  'maxLength10':                         maxLength10,
  'maxLength7':                          maxLength7,
  'maxLength6':                          maxLength6,
  'maxLength5':                          maxLength5,
  'maxLength4':                          maxLength4,
  'maxLength3':                          maxLength3,
  'maxLength1':                          maxLength1,
  'minLength4':                          minLength4,
  'minLength10':                         minLength10,
  'minLength17':                         minLength17,
  'exactLength12':                       exactLength12,
  'max99':                               max99,
  'not0':                                not0,
  'email':                               (val) => {
    if (!val) {
      return undefined;
    }
    return validateEmail(val);
  },
  'required_email':                      validateEmail,
  'only_int':                            onlyInt,
  'only_letter':                         onlyLetter,
  'only_letter_and_space':               onlyLetterAndSpace,
  'only_int_price':                      onlyIntPrice,
  'under1year':                          (val) => yearsValidation(val, 1, '<'),
  'under30years':                        (val) => yearsValidation(val, 30, '<'),
  'under25years':                        (val) => yearsValidation(val, 25, '<'),
  'min50years':                          (val) => yearsValidation(val, 50, '>='),
  'min25years':                          (val) => yearsValidation(val, 25, '>'),
  'min17years':                          min17years,
  'axaConstructorCompanyNameValidation': (val) => {
    const re = new RegExp(/[^a-zA-Z\d\s&'-@íÍàÀâÂäÄèÈéÉëËìÌîÎïÏôÔöÖùÙûÛüÜçÇ]/, 'g');
    return re.test(val) ? t('components.ui.json_form.json_form_helpers.axa_constructor_company_name_validation') : null;
  },
  'axaConstructorContractLength':        (val) => {
    if (val === undefined || val === null) {
      return undefined;
    }
    return `${ val }`.length != 12 ? t('axa.constructor.actual_contract_number_error') : undefined;
  },
  'year_not_in_future':                  (val) => {
    const thisYear = moment().format('YYYY');
    if (val.length === 4 && parseFloat(val) > thisYear) {
      return t('wizard.date.not_in_future');
    }
    return undefined;
  },
  'isBetween30and45yearsOld':            (val) => {
    if (val.length >= 10) {
      const age = moment().diff(moment(val), 'years');
      return age >= 30 && age <= 45;
    } else {
      return false;
    }
  },
  isAtLeast24monthsAnd1day:              (val) => {
    if (val.length >= 10) {
      const today = moment();
      const date = moment(val);
      const duration = moment.duration(today.diff(date));
      return duration.years() > 2 || (duration.years() === 2 && duration.days() > 0);
    } else {
      return false;
    }
  },
};

const defaultNormalizations = {
  'normalize_date': normalizeDate,
};

const DEBUG = false;

export const checkCondition = (condition) => {
  let result = null;
  if (condition.custom) {
    if (!defaultValidations[condition.custom]) {
      console.warn('custom validation not working');
    }
    const result = defaultValidations[condition.custom](condition.property, true);
    if ((!isNil(result) && !isBoolean(result) || (isBoolean(result) && !result))) {
      throw `Custom validation not checked ${ condition.custom }`;
    }
  }
  if (condition.assertion) {
    assertionMethod(condition.property, condition.assertion, condition.value);
  } else if (condition.expectation) {
    expectationMethod(condition.property, condition.expectation, condition.value);
  } else {
    DEBUG && console.warn('check condition no condition type passed', condition);
  }
  return result;
};

export const getDefaultValues = (schema, getPropertyValue) => {
  let initial = {};
  Object.keys(schema).forEach((key) => {
    const val = schema[key];
    if (val.type === 'section') {
      initial[key] = getDefaultValues(val.properties, getPropertyValue);
    } else if (val.type === 'group') {
      initial = {
        ...initial,
        ...getDefaultValues(val.properties, getPropertyValue),
      };
    } else {
      if (val.default) {
        if (!isNil(val.default.value)) {
          initial[key] = val.default.value;
        }
        if (val.default.from) {
          initial[key] = getPropertyValue(val.default.from);
        }
      }
    }
  });
  return initial;
};

export const getValidations = ({ validations }, getPropertyValue) => {
  const val = compact(validations);
  if (!val || !val.length) {
    return null;
  }
  return val.map((validate) => {
    if (typeof validate === 'string') {
      return defaultValidations[validate];
    }
    return (value) => {
      let valueToCheck = value;
      if (validate.properties) {
        const allProperties = validate.properties.map((propValidation) => {
          const { property: propValidationProperty, ...restPropValidation } = propValidation;
          try {
            checkCondition({
              property: getPropertyValue(propValidationProperty),
              ...restPropValidation,
            });
            return true;
          } catch (e) {
            return false;
          }
        });
        const allPropertiesChecked = every(allProperties);
        if (!allPropertiesChecked) {
          return validate.message;
        }
        return null;
      } else {
        if (!value) {
          return validate.message;
        }
        if (validate.property) {
          valueToCheck = getPropertyValue(validate.property);
        }
        try {
          checkCondition({
            property: valueToCheck,
            ...validate,
          });
          return null;
        } catch (e) {
          DEBUG && console.warn('e', e);
          return validate.message;
        }
      }
    };
  });
};

export const getConditionalValidations = (conditionalValidations, getPropertyValue) => {
  return flatten(compact(conditionalValidations.map(({ condition, validations }) => {
    try {
      checkCondition({
        ...condition,
        property: getPropertyValue(condition.property),
      });
      return getValidations({ validations }, getPropertyValue);
    } catch (e) {
      //log('conditional validations not checked', e);
    }
  })));
};

const checkConditionAndChange = (condition, change, onChangeItem) => {
  DEBUG && log('checkConditionAndChange', condition, change, onChangeItem);
  try {
    checkCondition(condition);
    if (!onChangeItem.changes) {
      change(onChangeItem.to, onChangeItem.value);
    } else {
      onChangeItem.changes.forEach((changeItem) => {
        change(changeItem.to, changeItem.value);
      });
    }
  } catch (e) {
    DEBUG && console.warn('OnValueChange condition not filled', onChangeItem);
  }
};

const prefillCondition = (newVal, condition, getPropertyValue) => {
  let conditionToCheck = condition;
  if (conditionToCheck.value && conditionToCheck.value.property) {
    conditionToCheck.value = getPropertyValue(conditionToCheck.value.property);
  }
  let property = newVal;
  if (has(condition, 'property')) {
    property = getPropertyValue(condition.property);
  }
  try {
    checkCondition({
      ...conditionToCheck,
      property,
    });
    return true;
  } catch (e) {
    return false;
  }
};

export const getOnChangeMethod = (onValueChange, change, getPropertyValue) => {
  if (onValueChange.setDefaultValues) {
    return (newVal) => {
      try {
        const groupedConditions = groupBy(onValueChange.conditions, 'operator');
        const everyAndConditionsResults = groupedConditions.and ? groupedConditions.and.map((condition) => prefillCondition(newVal, condition, getPropertyValue, false)) : [];
        const everyOrConditionsResults = groupedConditions.or ? groupedConditions.or.map((condition) => prefillCondition(newVal, condition, getPropertyValue, false)) : [];
        if (!every(everyAndConditionsResults) || (everyOrConditionsResults.length > 0 && !find(everyOrConditionsResults, condition => condition === true))) {
          throw 'condition not filled';
        }
        onValueChange.setDefaultValues.to.forEach((input) => {
          change(input, getPropertyValue(`defaultValues.${ input }`));
        });
      } catch (e) {
        console.log('will not set default values');
      }
    };
  }
  if (onValueChange.sum) {
    const splitFrom = onValueChange.sum.from.split('[].');
    return (newVal, inputName) => {
      const valueId = parseInt(inputName.match(/[(\d)]/)[0]);
      const arrayFieldPath = splitFrom[0];
      const targetValuePath = splitFrom[1];
      const values = getPropertyValue(arrayFieldPath).map((item) => parseFloat(get(item, targetValuePath)) || 0);
      const updatedValues = values.map((v, id) => {
        if (id === valueId) {
          return parseFloat(newVal) || 0;
        }
        return v;
      });
      const total = reduce(updatedValues, (sum, n) => sum + n, 0);
      change(onValueChange.sum.to, total);
    };
  }
  if (onValueChange.set) {
    return (newVal) => {
      if (Array.isArray(onValueChange.set)) {
        onValueChange.set.forEach((onValueChangeSet) => {
          checkConditionAndChange({ property: newVal, ...onValueChangeSet.condition }, change, onValueChangeSet);
        });
      } else {
        checkConditionAndChange({ property: newVal, ...onValueChange.condition }, change, onValueChange);
      }
    };
  }
  if (onValueChange.prefill) {
    return (newVal, prefillData, onMount = false, fieldName) => {
      try {
        if (onValueChange.prefill.conditions) {
          const groupedConditions = groupBy(onValueChange.prefill.conditions, 'operator');
          const everyAndConditionsResults = groupedConditions.and ? groupedConditions.and.map((condition) => prefillCondition(newVal, condition, getPropertyValue, onMount, fieldName)) : [];
          const everyOrConditionsResults = groupedConditions.or ? groupedConditions.or.map((condition) => prefillCondition(newVal, condition, getPropertyValue, onMount, fieldName)) : [];
          if (!every(everyAndConditionsResults) || (everyOrConditionsResults.length > 0 && !find(everyOrConditionsResults, condition => condition === true))) {
            throw 'condition not filled';
          }
        } else {
          let conditionToCheck = onValueChange.prefill.condition;
          if (conditionToCheck.value && conditionToCheck.value.property) {
            conditionToCheck.value = getPropertyValue(conditionToCheck.value.property);
          }
          checkCondition({ property: newVal, ...conditionToCheck });
        }
        if (onValueChange.prefill.changes) {
          onValueChange.prefill.changes.forEach((changeValue) => {
            let check = true;
            if (changeValue.condition) {
              try {
                checkCondition({
                  ...changeValue.condition,
                  property: getPropertyValue(changeValue.condition.property),
                });
                check = true;
              } catch (e) {
                check = false;
              }
            }
            const actualValue = getPropertyValue(changeValue.to);
            if (!actualValue || (actualValue && JSON.stringify(actualValue) === JSON.stringify(getPropertyValue(`defaultValues.${ changeValue.to }`)))) {
              if (get(prefillData, changeValue.from) && check) {
                change(changeValue.to, get(prefillData, changeValue.from));
              }
            }
          });
          return;
        }
        const actualValue = getPropertyValue(onValueChange.prefill.to);

        if (isObject(actualValue) && isObject(prefillData)) {
          Object.keys(prefillData).map((dataKey) => {
            const k = `${ onValueChange.prefill.to }.${ dataKey }`;
            if (!getPropertyValue(k)) {
              change(k, prefillData[dataKey]);
            }
          });
        }
        if (!actualValue) {
          change(onValueChange.prefill.to, prefillData);
        }
      } catch (e) {
        log('catched error', e);
        if (onValueChange.prefill.clearOnConditionUnfilled && !onMount) {
          if (onValueChange.prefill.changes) {
            onValueChange.prefill.changes.forEach((changeValue) => {
              change(changeValue.to, getPropertyValue(`defaultValues.${ changeValue.to }`));
            });
          } else {
            change(onValueChange.prefill.to, getPropertyValue(`defaultValues.${ onValueChange.prefill.to }`));
          }
        }
      }
    };
  }
};
