export const getPlocRiskSchema = () => {
  return {
    form:   'risk',
    schema: {
      risk: {
        type:       'section',
        properties: {
          risk_address:                             {
            type:       'section',
            title:      t('wizard.building.address.title'),
            properties: {
              address: {
                label:                t('driver.label.address'),
                component:            'address',
                inlineContainerClass: 'top',
                nameOverride:         'risk.risk_address',
                requiredFields:       {
                  street_name:   true,
                  street_number: true,
                  city:          true,
                  street_box:    true,
                  zip_code:      true,
                },
                displayMap:           true,
                noSection:            true,
              },
            },
          },
        },
      },
    },
  };
};
