import React, { useEffect, useState } from 'react';
import {
  useSelector,
  useDispatch,
}                                     from 'react-redux';
import {
  reduxForm,
  initialize,
  getFormValues,
}                                     from 'redux-form';
import merge                          from 'lodash/merge';
import JsonFormFields                 from 'components/ui/json_form/json_form_fields';
import { getDefaultValues }           from 'components/ui/json_form/json_form_helpers';
import get                            from 'lodash/get';
import JsonFormContext                from 'components/ui/json_form/json_form_context';
import PreventIfDirty                 from 'components/ui/json_form/prevent_if_dirty';

const JsonForm = (props) => {
  let properties = {};
  const { handleSubmit, schema, form, initialValues, change, disabled = false, jsonFormProperties = null, admin, anyTouched } = props;
  const [formFields, setFields] = useState(null);
  const [defaultValues, setDefaultValues] = useState({});
  const formValues = useSelector((state) => getFormValues(form)(state));
  if (!jsonFormProperties) {
    const required_fields = useSelector((state) => state.wizard.required_fields);
    const default_required_fields = useSelector((state) => state.wizard.default_required_fields);
    const contract_request = useSelector((state) => state.wizard.contract_request);
    const options_collections = useSelector((state) => state.config.options_collections);
    const available_locales = useSelector((state) => state.config.available_locales);
    const current_user = useSelector((state) => state.current_user);
    properties = {
      ...formValues,
      required_fields:   merge(required_fields, default_required_fields),
      contract_request,
      defaultValues,
      options_collections,
      current_user,
      available_locales: Object.keys(available_locales).map((key) => {
        return {
          value: key,
          label: available_locales[key],
        };
      }),
    };
  } else {
    properties = merge(
      jsonFormProperties,
      formValues,
    );
  }

  const getPropertyValue = (property) => {
    return get(properties, property);
  };

  const dispatch = useDispatch();

  useEffect(() => {
    const defaultFromSchema = getDefaultValues(schema, getPropertyValue);
    setDefaultValues(JSON.parse(JSON.stringify(defaultFromSchema)));
    const initialState = merge(defaultFromSchema, initialValues);
    dispatch(initialize(form, initialState));
  }, []);

  useEffect(() => {
    setFields(() => (
      <JsonFormFields
        form={ form }
        change={ change }
        schema={ schema }
      />
    ));
  }, [schema]);

  return (
    <JsonFormContext.Provider
      value={ {
        getPropertyValue,
        disabled,
        admin,
      } }
    >
      <form onSubmit={ handleSubmit } data-purpose={ `${ form }_form` }>
        { formFields }
      </form>
      <PreventIfDirty form={ form } anyTouched={ anyTouched } />
    </JsonFormContext.Provider>
  );
};

export default reduxForm({
  touchOnChange: true,
  touchOnBlur:   true,
})(JsonForm);
