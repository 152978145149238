import React         from 'react';
import { Field }     from 'redux-form';
import TextInput     from 'components/ui/form/text_input';
import CheckboxInput from 'components/ui/form/checkbox_input';

const LiabilityFields = ({ fields }) => {
  return (
    <ul className="select-button-list">
      { fields.map((liability, id, { get }) => {
        const lia = get(id);
        return (
          <li key={ id }>
            <Field
              name={ `${ liability }.basis` }
              component={ TextInput }
              type="hidden"
            />
            <Field
              name={ `${ liability }.is_selected` }
              component={ CheckboxInput }
              icon="check"
              label={ t(`wizard.building.tarification.cover_liability.${ lia.basis.toLowerCase() }`) }
            />
          </li>
        );
      }) }
    </ul>
  );
};

export default LiabilityFields;
