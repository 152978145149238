import React                            from 'react';
import { sendTarificationOffer }                        from 'models/tarifications/requests';
import Button                                           from 'components/ui/buttons/button';
import { useDispatch, useSelector }                     from 'react-redux';
import { setContractRequestStatus, updateTarification } from 'models/wizard/store_actions';
import { useHistory }                                   from 'react-router';

const SubmitStepAndCreateOffer = ({ onClick, tarificationId, loading, disabled, label }) => {
  const dispatch = useDispatch();
  const tarification = useSelector((state) => state.wizard.contract_request.tarifications.find(t => t.id === tarificationId));
  const history = useHistory();

  const {
          authorizations: {
                            offer: canCreateOffer,
                          },
        } = tarification;

  if (!canCreateOffer) {
    return null;
  }

  const createOffer = () => {
    sendTarificationOffer(tarification).then((tarificationOffer) => {
      dispatch(updateTarification(tarificationOffer));
      dispatch(setContractRequestStatus('offer'));
      history.push(`/folders/${ tarificationOffer.contract_request_id }/offer`);
    });
  };

  return (
    <Button
      onClick={ (ev) => {
        onClick(ev, createOffer);
      } }
      primary
      large
      loading={ tarification.computing || loading }
      disabled={ tarification.computing || loading || disabled }
      data-purpose="create_offer_button"
    >
      { label || t('tarifications.create_offer_in_company', { company_name: tarification.product.insurance_company.name }) }
    </Button>
  );
};

export default SubmitStepAndCreateOffer;
