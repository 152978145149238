import React, { useState } from 'react';
import SymbolTextInput     from 'components/ui/form/symbol_text_input';
import Icon                from 'components/ui/icon';
import { searchInBMSDB }   from 'models/bms/requests';
import Loader              from 'components/ui/loader';
import BMSPersonResult     from 'components/bms/bms_person_result';

const SearchInBMSForm = ({ onResultSelect }) => {
  const [search, setSearch] = useState('');
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [timeout, changeTimeout] = useState(null);

  const onSearch = ({ target: { value } }) => {
    setSearch(value);
    if (value.length < 3) {
      return;
    }
    if (timeout) {
      clearTimeout(timeout);
    }
    const t = setTimeout(() => {
      setLoading(true);
      searchInBMSDB(value).then((list) => {
        setResults(list);
        setLoading(false);
      }).catch((error) => {
        console.error('search error', error);
        setLoading(false);
      });
    }, 500);
    changeTimeout(t);
  };

  return (
    <div data-purpose="search_in_bms_form">
      <p className="mb-20">
        <SymbolTextInput
          input={ {
            name:     'search',
            onChange: onSearch,
            value:    search,
          } }
          meta={ {} }
          symbol={ <Icon icon="search" /> }
          placeholder={ t('wizard.bms.search_placeholder') }
        />
      </p>
      { loading && (<Loader />) }
      { !loading && results.length === 0 && (
        <p>{ t('wizard.bms.no_result') }</p>
      ) }
      { !loading && results.length > 0 && (
        <React.Fragment>
          { results.map((r, index) => (
            <BMSPersonResult key={ index } person={ r } className="mb-20" onClick={ () => onResultSelect(r) } />
          )) }
        </React.Fragment>
      ) }
    </div>
  );
};

export default SearchInBMSForm;
