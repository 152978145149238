import React             from 'react';
import asJsonFormField   from 'components/ui/json_form/as_json_form_field';
import { FieldArray }    from 'redux-form';
import FieldArrayContext from 'components/ui/json_form/json_field_array_context';
import BlockArrayFields  from 'components/ui/json_form/fields/block_array_fields';
import TableArrayFields  from 'components/ui/json_form/fields/table_array_fields';

const fieldArrayComponent = {
  'table': TableArrayFields,
};

const JsonFieldArray = (props) => {
  const { field, form, change, fieldKey } = props;
  return (
    <React.Fragment>
      { field.label && <h2>{ field.label }</h2> }
      <FieldArrayContext.Provider value={ {
        field,
        form,
        change,
        fieldKey,
      } }>
        <FieldArray
          key={ fieldKey }
          name={ fieldKey }
          component={ field.arrayType ? fieldArrayComponent[field.arrayType] : BlockArrayFields }
        />
      </FieldArrayContext.Provider>
    </React.Fragment>
  );

};

export default asJsonFormField()(JsonFieldArray);
