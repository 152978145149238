import { defaultValidations }           from 'components/ui/json_form/json_form_helpers';
import React, { useContext, useEffect } from 'react';
import moment                           from 'moment';
import withInlineContainer              from 'components/ui/json_form/fields/with_inline_container';
import JsonFormContext                  from 'components/ui/json_form/json_form_context';
import { Field }                        from 'redux-form';
import Input                            from 'components/ui/json_form/fields/input';
import { extraFormComponentProps }      from 'components/ui/json_form/json_form_field';

const TarificationCoverStartDatesField = ({ fieldKey, change, input: { name }, __field, ...props }) => {
  const { getPropertyValue } = useContext(JsonFormContext);
  const tarification = getPropertyValue('contract_request.tarifications[0]') || getPropertyValue('tarification');
  const selectedCovers = tarification.covers.filter((cover) => cover.selected);
  const setDefault = (date, inputName) => {
    if (date.length === 10) {
      selectedCovers.map((selectedCover, index) => {
        if (index > 0) {
          const targetInput = inputName.replace('[0]', `[${ index }]`);
          if (!getPropertyValue(targetInput)) {
            change(targetInput, moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD'));
          }
        }
      });
    }
  };

  useEffect(() => {
    selectedCovers.map((selectedCover, index) => {
      change(`${ name }[${ index }].category`, selectedCover.category);
    });
  }, []);
  return (
    <table className="uk-table uk-table-small uk-table-justify uk-table-middle uk-table-divider mt-0">
      <thead>
        <tr>
          <th className="uk-width-1-2">{ t('axa.constructor.offer.cover') }</th>
          <th>{ t('axa.constructor.offer.start_date') }</th>
        </tr>
      </thead>
      <tbody>
        {
          selectedCovers.map((selectedCover, index) => {
            const first = index === 0;
            return (
              <tr key={ selectedCover.category }>
                <td>
                  <Field
                    name={ `${ fieldKey }[${ index }].category` }
                    value={ selectedCover.category }
                    component={ Input }
                    type="hidden"
                  />
                  { selectedCover.name }
                </td>
                <td>
                  <Field
                    component={ Input }
                    name={ `${ fieldKey }[${ index }].start_date` }
                    { ...extraFormComponentProps.date }
                    onValueChange={ first && setDefault }
                    validate={ defaultValidations.date_format }
                  />
                </td>
              </tr>
            );
          })
        }
      </tbody>
    </table>
  );
};

export default withInlineContainer()(TarificationCoverStartDatesField);
