import React            from 'react';
import Number           from 'services/number';
import IconErrorSuccess from 'components/ui/icon/icon_error_success';
import { useSelector }  from 'react-redux';


const CarPanelResume = (props) => {
  const { children, vehicle: vehicleFromProps } = props;
  let vehicle = vehicleFromProps;
  if (!vehicle && props.form) {
    vehicle = useSelector(state => state.form[props.form].values.vehicle.source_data);
  }
  if (!vehicle) {
    return null;
  }
  return (
    <React.Fragment>
      { vehicle.plate_number && (<p className="uk-text-bold mb-10">
        { t('clients.typed_contract_requests.car_selection_modal.vehicle_found_for_vin_number', { plate_number: vehicle.plate_number }) }
      </p>) }
      <div
        className="uk-section-muted p-20 mb-20 horizontal-list"
        data-purpose="car_by_licence_number_result"
      >
        { vehicle.brand && (
          <div className="part">
            <p className="part-title">{ t('activerecord.attributes.car.brand') }</p>
            <p className="part-description">{ vehicle.brand }</p>
          </div>
        ) }
        { vehicle.model && (
          <div className="part">
            <p className="part-title">{ t('activerecord.attributes.car.model') }</p>
            <p className="part-description">{ vehicle.model }</p>
          </div>
        ) }
        { vehicle.version && (
          <div className="part">
            <p className="part-title">{ t('activerecord.attributes.car.version') }</p>
            <p className="part-description">{ vehicle.version }</p>
          </div>
        ) }
        { vehicle.power && (
          <div className="part">
            <p className="part-title">{ t('activerecord.attributes.car.power') }</p>
            <p className="part-description">{ vehicle.power }</p>
          </div>
        ) }
        { vehicle.extra_attributes && (
          <React.Fragment>
            <div className="part">
              <p className="part-title">{ t('informex_extra_data.co2') }</p>
              <p className="part-description">{ vehicle.extra_attributes.co2 }</p>
            </div>
            <div className="part">
              <p className="part-title">{ t('informex_extra_data.claim_60_days') }</p>
              <p className="part-description"><IconErrorSuccess value={ vehicle.extra_attributes.claim_60_days } /></p>
            </div>
            <div className="part">
              <p className="part-title">{ t('informex_extra_data.claim_12_months') }</p>
              <p className="part-description"><IconErrorSuccess value={ vehicle.extra_attributes.claim_12_months } />
              </p>
            </div>
            <div className="part">
              <p className="part-title">{ t('informex_extra_data.windshield_replacement') }</p>
              <p className="part-description">{ Number.formatCurrency(vehicle.extra_attributes.windshield_replacement, '€') }</p>
            </div>
            <div className="part">
              <p className="part-title">{ t('informex_extra_data.average_repair_cost') }</p>
              <p className="part-description">{ Number.formatCurrency(vehicle.extra_attributes.average_repair_cost, '€') }</p>
            </div>
          </React.Fragment>
        ) }
        { vehicle.chassis_number && (
          <div className="part">
            <p className="part-title">{ t('activerecord.attributes.car.chassis_number') }</p>
            <p className="part-description">{ vehicle.chassis_number }</p>
          </div>
        ) }
        { vehicle.fuel_type && (
          <div className="part">
            <p className="part-title">{ t('activerecord.attributes.car.fuel_type') }</p>
            <p className="part-description">{ vehicle.fuel_type }</p>
          </div>
        ) }
        { vehicle.build_year && (
          <div className="part">
            <p className="part-title">{ t('activerecord.attributes.car.build_year') }</p>
            <p className="part-description">{ vehicle.build_year }</p>
          </div>
        ) }
        { vehicle.doors_count && (
          <div className="part">
            <p className="part-title">{ t('activerecord.attributes.car.doors_count') }</p>
            <p className="part-description">{ vehicle.doors_count }</p>
          </div>
        ) }
        { vehicle.catalogue_price_without_vat && (
          <div className="part">
            <p className="part-title">{ t('activerecord.attributes.car.catalogue_price_without_vat') }</p>
            <p className="part-description">{ vehicle.catalogue_price_without_vat }</p>
          </div>
        ) }
      </div>
      { children }
    </React.Fragment>
  );
};

export default CarPanelResume;
