export const getBikeSchema = () => {
         return {
           form:   'bike',
           schema: {
             vehicle: {
               type:       'section',
               properties: {
                 kind:                                {
                   type:    'hidden',
                   default: {
                     from: 'contract_request.risk_object.kind',
                   },
                 },
                 brand:           {
                   component:   'text',
                   label:       t('activerecord.attributes.bike.brand'),
                   validations: ['required'],
                   warning:     'bike.brand',
                   visible:     {
                     property: 'required_fields.vehicle.brand',
                   },
                 },
                 model:           {
                   component:   'text',
                   label:       t('activerecord.attributes.bike.model'),
                   validations: ['required'],
                   warning:     'bike.model',
                   visible:     {
                     property: 'required_fields.vehicle.model',
                   },
                 },
                 assistance_kind: {
                   component:   'radio_input',
                   list:        { from: 'bike_assistance_kinds' },
                   validations: ['required'],
                   label:       t('activerecord.attributes.bike.assistance_kind'),
                   warning:     'bike.assistance_kind',
                   visible:     {
                     property: 'required_fields.vehicle.assistance_kind',
                   },
                 },
                 maximum_speed:   {
                   component:   'radio_input',
                   list:        [],
                   validations: ['required'],
                   label:       t('activerecord.attributes.bike.maximum_speed'),
                   warning:     'bike.maximum_speed',
                   visible:     [
                     {
                       property: 'required_fields.vehicle.maximum_speed',
                     },
                     {
                       property:  'bike.assistance_kind',
                       assertion: 'notEqual',
                       value:     'none',
                     },
                   ],
                 },
                 power:           {
                   component:   'text',
                   symbol:      'Watts',
                   validations: ['required'],
                   label:       t('activerecord.attributes.bike.power'),
                   warning:     'bike.power',
                   visible:     [
                     {
                       property: 'required_fields.vehicle.power',
                     },
                   ],
                 },
                 purchase_date:   {
                   component:     'date',
                   validations:   ['required', 'date_format'],
                   label:         t('activerecord.attributes.bike.purchase_date'),
                   visible:       [
                     {
                       property: 'required_fields.vehicle.purchase_date',
                     },
                   ],
                 },
                 purchase_price:  {
                   component:   'price',
                   validations: ['required'],
                   label:       t('activerecord.attributes.bike.purchase_price'),
                   warning:     'bike.purchase_price',
                   visible:     [
                     {
                       property: 'required_fields.vehicle.purchase_price',
                     },
                   ],
                 },
                 plate_number:    {
                   component:   'text',
                   validations: ['required'],
                   label:       t('activerecord.attributes.bike.plate_number'),
                   warning:     'bike.plate_number',
                   visible:     [
                     {
                       property: 'required_fields.vehicle.plate_number',
                     },
                   ],
                 },
                 frame_number:    {
                   component:   'text',
                   validations: ['required'],
                   label:       t('activerecord.attributes.bike.frame_number'),
                   warning:     'bike.frame_number',
                   visible:     [
                     {
                       property: 'required_fields.vehicle.frame_number',
                     },
                   ],
                 },
               },
             },
           },
         };
       }
;
