import React                      from 'react';
import {
  DOCK_SELECTED_TARIFICATIONS,
  trackWizardStep,
}                                 from 'models/wizard/constants';
import compact                    from 'lodash/compact';
import LoadOnClickButton          from 'components/ui/buttons/load_on_click_button';
import { openLinkOrDownloadFile } from 'services/file';

const GetProductTemplate = ({ tarifications, contractRequestId, as = 'button', label, ...rest }) => {
  if (tarifications.length === 0 || tarifications.length > 1) {
    return null;
  }
  const productTemplate = compact(tarifications[0].documents.filter((doc) => {
    const re = new RegExp('tarif-', 'g');
    return re.test(doc.id);
  }));
  if (!productTemplate.length) {
    return null;
  }

  const print = () => {
    trackWizardStep(DOCK_SELECTED_TARIFICATIONS, {
      contract_request_id: contractRequestId,
      tarification_ids:    tarifications.map((tarif) => tarif.id),
      account_template:    true,
    });
    return openLinkOrDownloadFile(productTemplate[0].url);
  };
  if (as === 'link') {
    return (
      <a
        href=""
        onClick={ (e) => {
          e.preventDefault();
          print();
        } }
      >
        { label || t('provider.tarification.actions.product_pdf') }
      </a>
    );
  }

  return (
    <LoadOnClickButton
      outline
      small
      data-purpose="product_template_button"
      onClick={ print }
      secondary
      icon="docx"
      { ...rest }
    >
      { label || t('provider.tarification.actions.product_pdf') }
    </LoadOnClickButton>
  );
};

export default GetProductTemplate;
