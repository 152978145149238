import React, { useEffect }                                      from 'react';
import { useDispatch, useSelector }                              from 'react-redux';
import has                                                       from 'lodash/has';
import ProductFactorySteps
                                                                 from 'components/wizard/product_factory/product_factory_steps';
import WizardNavigationStep
                                                                 from 'components/wizard_stepper/wizard_navigation_step';
import { cleanWizard }                                           from 'models/wizard_stepper/store_actions';
import MobilitySteps                                             from 'schemas/mobility/mobility_steps';
import { resetWizard }                                           from 'models/wizard/store_actions';
import TarificationSocket                                        from 'components/wizard/tarification_socket';
import BuildingSteps                                             from 'components/wizard/building/building_steps';
import HousingSteps                                              from 'components/wizard/housing/housing_steps';
import NavigationProductHeader                                   from 'components/wizard_stepper/product_header';
import GenericSteps                                              from 'schemas/generic/generic_steps';
import { useRouteMatch }                                         from 'react-router';
import { refreshWizardTarification, setPollingForTarifications } from 'models/wizard/dispatch_actions';
import { isCrLead }                                              from 'models/contract_request/constants';
import { isSocketConnected }                                     from 'models/config/reducer';
import WizardStepperContext                                      from 'components/wizard_stepper/wizard_context';
import EnterpriseSteps                                           from 'components/wizard/enterprise/enterprise_steps';

const Wizard = () => {
  const dispatch = useDispatch();

  const cleanWizardOnLeave = () => {
    dispatch(resetWizard());
    return dispatch(cleanWizard());
  };

  const tarificationPolling = (tarifications) => {
    dispatch(setPollingForTarifications(tarifications));
  };

  const refreshTarifications = (tarifications, contractRequestId) => {
    tarifications.forEach((tarif) => {
      dispatch(refreshWizardTarification(tarif, contractRequestId));
    });
  };

  const riskObject = useSelector((state) => state.wizard.contract_request.risk_object);
  const contractRequest = useSelector((state) => state.wizard.contract_request);
  const isLead = useSelector((state) => isCrLead(state.wizard.contract_request));
  const isSocketUp = useSelector((state) => isSocketConnected(state));
  const mobilityContractRequest = riskObject ? ['car', 'motorbike', 'van', 'bike'].includes(riskObject.kind) : true;
  const housingContractRequest = riskObject ? ['house_owner', 'house_tenant', 'apartment_owner', 'apartment_tenant'].includes(riskObject.kind) : false;
  const buildingContractRequest = riskObject ? ['building'].includes(riskObject.kind) : false;
  const genericContractRequest = riskObject ? ['travel'].includes(riskObject.kind) : false;
  const businessContractRequest = riskObject ? ['business'].includes(riskObject.kind) : false;
  const isProductFactory = has(contractRequest, 'tarifications[0].product.product_factory') && contractRequest.tarifications[0].product.product_factory;
  const { path } = useRouteMatch();

  const recomputeTarifications = () => {
    if (isLead || !contractRequest.authorizations.create_tarification) {
      return;
    }
    refreshTarifications(contractRequest.tarifications, contractRequest.id);
    if (!isSocketUp) {
      tarificationPolling(contractRequest.tarifications);
    }
  };

  useEffect(() => {
    return () => {
      cleanWizardOnLeave();
    };
  }, []);

  return (
    <React.Fragment>
      <TarificationSocket />
      <div className="wizard-container" data-purpose="wizard">
        <WizardStepperContext.Provider
          value={ {
            recomputeTarifications,
          } }
        >
          <div className="wizard-navigation-container">
            <NavigationProductHeader />
            <WizardNavigationStep />
          </div>
          <div className="wizard-step">
            { isProductFactory  && (<ProductFactorySteps matchPath={ path } />) }
            { !isProductFactory && mobilityContractRequest && (<MobilitySteps matchPath={ path } />) }
            { !isProductFactory && buildingContractRequest && (<BuildingSteps matchPath={ path } />) }
            { !isProductFactory && housingContractRequest  && (<HousingSteps matchPath={ path } />) }
            { !isProductFactory && genericContractRequest  && (<GenericSteps matchPath={ path } />) }
            { !isProductFactory && businessContractRequest && (<EnterpriseSteps matchPath={ path } />) }
          </div>
        </WizardStepperContext.Provider>
      </div>
    </React.Fragment>
  );
};

export default Wizard;
