import { combineReducers, createStore } from 'redux';
import { reducer as reduxFormReducer }  from 'redux-form';
import currentUserReducer               from 'models/current_user/reducer';
import configReducer                    from 'models/config/reducer';
import currentAccountReducer            from 'models/current_account/reducer';
import wizardReducer                    from 'models/wizard/reducer';
import integrationsReducer              from 'models/integrations/reducer';
import intermediariesReducer            from 'models/intermediaries/reducer';
import wizardStepperReducer             from 'models/wizard_stepper/reducer';
import documentsReducer                 from 'models/documents/reducer';

const createReducer = asyncReducers => combineReducers({
  current_user:   currentUserReducer,
  account:        currentAccountReducer,
  config:         configReducer,
  form:           reduxFormReducer,
  wizard:         wizardReducer,
  integrations:   integrationsReducer,
  intermediaries: intermediariesReducer,
  wizardStepper:  wizardStepperReducer,
  documents:      documentsReducer,
  ...asyncReducers,
});

const initializeStore = (...params) => {
  const store = createStore(
    createReducer(),
    ...params,
  );

  store.asyncReducers = {};
  store.injectReducer = (key, reducer) => {
    store.asyncReducers[key] = reducer;
    store.replaceReducer(createReducer(store.asyncReducers));
    return store;
  };
  return store;
};

export default initializeStore;

export const setInitialState = (state) => {
  return {
    ...state,
  };
};
