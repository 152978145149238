import DocumentsTable  from 'components/documents/table';
import asWizardStep    from 'components/wizard_stepper/as_wizard_step';
import React           from 'react';
import { useSelector } from 'react-redux';

const DocumentStep = () => {

  const contractRequest = useSelector((state) => {
    return state.wizard.contract_request;
  });

  return (
    <div>
      <DocumentsTable
        tarifications={ contractRequest.tarifications }
        contractRequestId={ contractRequest.id }
      />
    </div>
  );
};

export default asWizardStep()(DocumentStep);
