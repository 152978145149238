import React            from 'react';
import IconErrorSuccess from 'components/ui/icon/icon_error_success';
import VehicleIcon      from 'components/vehicle/vehicle_icon';

const ContractRequestResume = ({ contractRequest, className }) => {
  const { policy_owner, risk_object: { kind: riskObject } } = contractRequest;
  const showMainDriver = riskObject === 'car' || riskObject === 'motorbike' || riskObject === 'van';
  const showSecondaryDriver = riskObject === 'car' || riskObject === 'van';
  const showCar = riskObject === 'car';
  const showVan = riskObject === 'van';
  const showMotorbike = riskObject === 'motorbike';
  const showBike = riskObject === 'bike';
  return (
    <div className={ `${ className } cr-wizard-resume` }>
      { policy_owner && policy_owner && (
        <p>
          { t('wizard.resume.policy_owner', { name: policy_owner.display_name }) }
        </p>
      ) }
      { showMainDriver && contractRequest.main_driver && (
        <p>
          { t('wizard.resume.main_driver', { name: contractRequest.main_driver.display_name }) }
        </p>
      ) }
      { showSecondaryDriver && (
        <p className="text-icon">
          { t('wizard.resume.secondary_driver') }
          <IconErrorSuccess value={ !!contractRequest.can_have_secondary_driver } />
        </p>
      ) }
      { showCar && contractRequest.car && (
        <div>
          { t('wizard.resume.car', { name: contractRequest.car.display_name }) }
          <VehicleIcon vehicle={ contractRequest.car } size={ 20 } className="ml-10" />
        </div>
      ) }
      { showVan && contractRequest.van && (
        <p>
          { t('wizard.resume.van', { name: contractRequest.van.display_name }) }
        </p>
      ) }
      { showMotorbike && contractRequest.motorbike && (
        <p>
          { t('wizard.resume.motorbike', { name: contractRequest.motorbike.display_name }) }
        </p>
      ) }
      { showBike && contractRequest.bike && (
        <p>
          { t('wizard.resume.bike', { name: contractRequest.bike.display_name }) }
        </p>
      ) }
    </div>
  );
};

export default ContractRequestResume;
