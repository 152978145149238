
export const getTransmissionList = () => {
  const transmissions = t('general.transmission');
  return Object.keys(transmissions).map(key => {
    return {
      value: key,
      id:    key,
      label: transmissions[key],
    };
  });
};

export const getCarOptionsList = () => {
  const optionsList = t('general.driver_assistance');
  return Object.keys(optionsList).map(key => {
    return {
      value: key,
      label: optionsList[key],
    };
  });
};

const KW_CV_RATIO = 1.35962;

export const converter = {
  kw: (kw) => {
    return Math.ceil(kw * KW_CV_RATIO);
  },
  cv: (cv) => {
    return Math.round(cv / KW_CV_RATIO);
  },
};


export const convertSimpleList = (list, translateKey, valueLabelMethod) => (
  list.map(item => (
    {
      value: item,
      label: valueLabelMethod ? valueLabelMethod(item) : translateKey ? t(`${ translateKey }.${ item }`) : item,
    }
  ))
);

export const CAR_LOGO_API = 'https://carfee.herokuapp.com/api/v1/car/logo/';
