import React, { useEffect }         from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { submit }                   from 'redux-form';
import {
  PRODUCT_CONFIGURATION_STEP,
  trackWizardStep,
}                                   from 'models/wizard/constants';
import {
  setPollingForTarifications,
  updateWizardTarification,
}                                   from 'models/wizard/dispatch_actions';
import asWizardStep                 from 'components/wizard_stepper/as_wizard_step';
import BuildingTarificationForm
                                    from 'components/tarifications/building/building_tarification_form';
import { isSocketConnected }        from 'models/config/reducer';
import { useTraceUpdate }           from 'helpers/trace_updates';

const FORM_NAME = 'building_tarification_form';

const AxaBuildingTarificationStep = (props) => {
  const {
          setNextAction,
          tarification,
          nextStep,
          setNextLoading,
        } = props;
  useTraceUpdate(props);
  const dispatch = useDispatch();
  const isSocketUp = useSelector((state) => isSocketConnected(state));
  const saveTarification = (tarifToUpdate) => {
    if (!Object.keys(tarifToUpdate).length) {
      setNextLoading(false);
      return;
    }
    setNextLoading(true);
    trackWizardStep(PRODUCT_CONFIGURATION_STEP, {
      contract_request_id: tarification.contract_request_id,
      product_id:          tarifToUpdate.product.id,
      product_name:        tarifToUpdate.product.name,
    });
    return dispatch(updateWizardTarification(tarifToUpdate)).then(() => {
      if (!isSocketUp) {
        dispatch(setPollingForTarifications([tarifToUpdate]));
      }
      setNextLoading(false);
      nextStep();
    }).catch(() => {
      setNextLoading(false);
    });
  };

  useEffect(() => {
    setNextAction(() => {
      trackWizardStep(PRODUCT_CONFIGURATION_STEP, {
        contract_request_id: tarification.contract_request_id,
        product_name:        tarification.product.name,
        product_id:          tarification.product.id,
      });
      setNextLoading(true);
      dispatch(submit(FORM_NAME));
    });
  }, []);
  return (
    <BuildingTarificationForm
      enableReinitialize
      form={ FORM_NAME }
      initialValues={ {
        ...tarification,
      } }
      onSubmit={ saveTarification }
    />
  );
};


export default asWizardStep(true)(AxaBuildingTarificationStep);
