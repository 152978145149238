import { Condition } from 'models/condition/condition';

export const haveSelectedViviumBuilding = new Condition({
  property:  'contract_request.data.building',
  assertion: 'exists',
  message:   'schemas.vivium.building.constants.building_must_be_defined',
});

export const haveSelectedViviumEvaluation = new Condition({
  property:  'contract_request.data.evaluation',
  assertion: 'exists',
  message:   'schemas.vivium.building.constants.evaluation_must_be_defined',
});

export const haveSelectedViviumComplementaryDetails = new Condition({
  property:  'contract_request.data.complementary_details',
  assertion: 'exists',
  message:   'schemas.vivium.building.constants.complementary_details_must_be_defined',
});

export const hasLessThan4FlatsOrSumInsuredLessThan500K = [
  new Condition({
    operator:   'or',
    property:   'contract_request.data.building.flat_count',
    propertyAs: 'int',
    assertion:  'isBelow',
    value:      4,
  }),
  new Condition({
    operator:   'or',
    property:   'contract_request.data.evaluation.sum_insured.sum_insured',
    propertyAs: 'int',
    assertion:  'isBelow',
    value:      500000,
  }),
];

export const haveSelectedViviumContentCover = [
  new Condition({
    operator:   'or',
    property:   'contract_request.data.building.flat_count',
    propertyAs: 'int',
    assertion:  'isAbove',
    value:      4,
  }),
  new Condition({
    operator:   'or',
    property:   'contract_request.data.evaluation.sum_insured.sum_insured',
    propertyAs: 'int',
    assertion:  'isAbove',
    value:      500000,
  }),
  new Condition({
    operator:  'and',
    property:  'contract_request.data.content_cover',
    assertion: 'exists',
    message:   'schemas.vivium.building.constants.content_cover_must_be_defined',
  }),
];
