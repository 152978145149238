import React, { useEffect, useState } from 'react';
import classNames                     from 'classnames';
import FormErrorMessage               from 'components/ui/form/form_error_message';
import { fieldWrapper }               from 'components/ui/json_form/fields/field_wrapper';
import { withoutJsonFieldProps }      from 'components/ui/json_form/fields/constants';


const Input = (props) => {
  const {
          input: {
                   onChange,
                   ...restInput
                 },
          label,
          showLabel,
          placeholder,
          type,
          meta           = {},
          id,
          disabled,
          purpose,
          onInput,
          symbol,
          readOnly,
          fieldAddition,
          withValues,
          containerClass,
          normalizeOnMount,
          InputAddition,
          onValueChange,
          componentProps = {},
          ...    rest
        } = props;

  const [inputAddition, setInputAddition] = useState(null);
  useEffect(() => {
    if (normalizeOnMount) {
      onChange(normalizeOnMount(restInput.value));
    }
    if (InputAddition) {
      setInputAddition(<InputAddition />);
    }
  }, []);
  const replaceCommaByDot = (ev) => (
    onChange(ev.target.value.replace(',', '.'))
  );
  const { asyncValidating, ...restMeta } = meta;
  const enhancedOnChange = type === 'number' ? replaceCommaByDot : onChange;
  return (
    <React.Fragment>
      <div className={ classNames('input-container', {
        [containerClass]: !!containerClass,
      }) }
      >
        { label && showLabel && (
          <label htmlFor={ id }>{ label }</label>
        ) }
        <div className={ symbol && 'uk-inline' }>
          <input
            id={ id }
            className={ classNames('uk-input uk-form-small', {
              'uk-form-danger': meta.touched && meta.error,
              'input--warning': meta.touched && meta.warning,
            }) }
            onChange={ (ev) => {
              enhancedOnChange(ev);
              if (onValueChange) {
                onValueChange(ev.target.value, restInput.name);
              }
              if (onInput) {
                onInput(ev.target.value);
              }
            } }
            { ...restInput }
            placeholder={ placeholder || label }
            type={ type }
            data-purpose={ restInput.name }
            disabled={ readOnly || disabled || asyncValidating }
            min="0"
            readOnly={ readOnly }
            { ...withoutJsonFieldProps(rest) }
            { ...componentProps }
          />
          { symbol && (
            <span className="uk-form-icon uk-form-icon-flip">
            { symbol }
          </span>
          ) }
          <FormErrorMessage { ...restMeta } name={ restInput.name } label={ label || placeholder } />
        </div>
        { fieldAddition && (
          <React.Fragment>
            { fieldAddition.message && (
              <div className={ fieldAddition.className }>
                { fieldAddition.message }
              </div>
            ) }
            { fieldAddition.render && fieldAddition.render(withValues) }
          </React.Fragment>
        ) }
        { inputAddition && inputAddition }
      </div>
    </React.Fragment>
  );

};

export default fieldWrapper()(Input);
