import React, { useEffect, useState }       from 'react';
import Uppy                                 from '@uppy/core';
import XHRUpload                            from '@uppy/xhr-upload';
import { ProgressBar, StatusBar, DragDrop } from '@uppy/react';
import InlineContainer                      from 'components/ui/form/inline_container';
import { getAuthorizationHeader }           from 'services/authorization_token';
import Files                                from 'components/files';

const FileInput = ({
  id,
  label,
  warning,
  meta = {},
  files,
  inline,
  endpoint,
  containerClass,
  labelAddition,
  labelClass,
  onUploaded,
  onDelete,
}) => {
  const [uppy, setUppy] = useState(null);
  useEffect(() => {
    let u = Uppy({
      meta:        { type: 'avatar' },
      autoProceed: true,
    });

    u.use(XHRUpload, {
      endpoint,
      autoRetry: false,
      headers:   {
        'Authorization': getAuthorizationHeader(),
      },
      formData:  true,
      fieldName: 'file',
    });

    u.on('upload-success', (file, response) => {
      if (onUploaded) {
        onUploaded(response.body);
      }
    });

    setUppy(u);
  }, []);


  if (!uppy) {
    return null;
  }

  const fileInput = (
    <div className="uk-width-1-1">
      <StatusBar
        uppy={ uppy }
        hideUploadButton
        hideAfterFinish
        showProgressDetails
      />
      <ProgressBar
        uppy={ uppy }
        fixed
        hideAfterFinish
      />
      <DragDrop
        uppy={ uppy }
        locale={
          {
            strings: {
              addMore:                     t('uploader.add_more'),
              closeModal:                  t('uploader.close_modal'),
              addMoreFiles:                t('uploader.add_more_file'),
              importFrom:                  t('uploader.import_from'),
              dashboardWindowTitle:        t('uploader.dashboard_window_title'),
              dashboardTitle:              t('uploader.dashboard_title'),
              copyLinkToClipboardSuccess:  t('uploader.copy_link_to_clipboard_success'),
              copyLinkToClipboardFallback: t('uploader.copy_link_to_clipboard_fallback'),
              copyLink:                    t('uploader.copy_link'),
              fileSource:                  t('uploader.file_source'),
              done:                        t('uploader.done'),
              removeFile:                  t('uploader.remove_File'),
              editFile:                    t('uploader.edit_file'),
              editing:                     t('uploader.editing'),
              edit:                        t('uploader.edit'),
              finishEditingFile:           t('uploader.finish_editing_file'),
              myDevice:                    t('uploader.my_device'),
              dropPasteImport:             t('uploader.drop_paste_import', { browse: '%{browse}' }),
              dropPaste:                   t('uploader.drop_paste', { browse: '%{browse}' }),
              dropHereOr:                  t('uploader.drop_here_or', { browse: '%{browse}' }),
              browse:                      t('uploader.browse'),
              uploadComplete:              t('uploader.upload_complete'),
              resumeUpload:                t('uploader.resume_upload'),
              pauseUpload:                 t('uploader.pause_upload'),
              retryUpload:                 t('uploader.retry_upload'),
              xFilesSelected:              {
                0: t('uploader.x_single_file_selected'),
                1: t('uploader.x_multiple_file_selected'),
              },
              uploading:                   t('uploader.uploading'),
              complete:                    t('uploader.complete'),
            },
          }
        }
      />
      <Files files={ files } className="mt-20" onDelete={ (file) => {
        onDelete(file).then(onUploaded);
      } } />
    </div>
  );

  if (!inline) {
    return fileInput;
  }

  return (
    <InlineContainer
      labelFor={ id }
      label={ label }
      warning={ warning }
      field={ fileInput }
      meta={ meta }
      inlineContainerClass={ containerClass }
      labelAddition={ labelAddition }
      labelClass={ labelClass }
    />
  );
};

export default FileInput;
