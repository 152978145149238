import { getGenders, getActivities }       from 'models/person/constants';
import {
  getImpactedCovers,
  getInsuredYears,
}                                          from 'models/driver/constants';
import { getAxaConstructorOccupationList } from 'models/axa/constants/axa_constructor_constants';

export const getAxaConstructorMainDriverSchema = () => {
  return {
    form:   'main_driver',
    schema: {
      misc:        {
        type:       'section',
        properties: {
          can_have_secondary_driver: {
            label:     t('schemas.axa.constructor.axa_constructor_steps.has_occasional_driver'),
            component: 'true_false',
            resume:    false,
            visible:   {
              property:  'contract_request.risk_object.kind',
              assertion: 'notEqual',
              value:     'motorbike',
            },
            default:   {
              value: false,
            },
          },
        },
      },
      main_driver: {
        type:       'section',
        properties: {
          policy_owner_is_main_driver: {
            label:         t('wizard.policy_owner.policy_owner_is_main_driver'),
            component:     'true_false',
            visible:       {
              property:  'policy_owner.kind',
              assertion: 'equal',
              value:     'person',
            },
            resume:        false,
            admin:         false,
            default:       {
              value: true,
            },
            onValueChange: {
              prefill: {
                prefillOnMount:           true,
                from:                     {
                  store: 'policy_owner.person',
                },
                to:                       'main_driver.person',
                clearOnConditionUnfilled: true,
                conditions:               [
                  {
                    operator:  'and',
                    assertion: 'isTrue',
                  },
                  {
                    operator:  'or',
                    property:  'main_driver.person',
                    assertion: 'deepEqual',
                    value:     {
                      property: 'defaultValues.main_driver.person',
                    },
                  },
                ],
              },
            },
          },
          kind:                        {
            component: 'text',
            type:      'hidden',
            default:   {
              value: 'person',
            },
          },
          person:                      {
            type:       'section',
            properties: {
              first_name:                          {
                component:   'text',
                label:       t('person.label.first_name'),
                validations: ['required'],
              },
              last_name:                           {
                component:   'text',
                label:       t('person.label.last_name'),
                validations: ['required'],
              },
              date_of_birth:                       {
                label:         t('person.label.birthdate'),
                component:     'date',
                validations:   ['required', 'date_format', 'min17years'],
                onValueChange: {
                  set: [
                    {
                      to:    'vehicle.mono_volume',
                      value: null,
                    },
                  ],
                },
              },
              car_license_obtention_at:            {
                label:       t('driver.label.car_license_obtention_at'),
                component:   'date',
                validations: ['required', 'date_format'],
              },
              family_related_contract:             {
                component:   'true_false',
                label:       t('schemas.axa.constructor.axa_constructor_steps.family_related_contract'),
                validations: [
                  'required',
                ],
                visible:     [
                  {
                    property: 'main_driver.person.date_of_birth',
                    custom:   'under30years',
                  },
                ],
                visibleOr:   [
                  {
                    property: 'main_driver.person.date_of_birth',
                    custom:   'under25years',
                  },
                  {
                    property: 'main_driver.person.car_license_obtention_at',
                    custom:   'under1year',
                  },
                ],
              },
              occupation:                          {
                label:       t('person.label.occupation'),
                component:   'radio_list',
                list:        getAxaConstructorOccupationList(),
                validations: ['required'],
              },
              vat_number:                          {
                component:   'text',
                label:       t('clients.companies.form.vat_number'),
                validations: ['required', 'only_int', 'minLength10', 'maxLength10'],
                visible:     [{
                  property:  'main_driver.person.occupation',
                  assertion: 'equal',
                  value:     'Independent',
                },{
                  property:  'policy_owner.kind',
                  assertion: 'equal',
                  value:     'person',
                }],
              },
              activity:                            {
                label:       t('activity_sector.label'),
                component:   'select',
                list:        getActivities(),
                validations: ['required'],
                visible:     [
                  {
                    property:  'main_driver.person.occupation',
                    assertion: 'equal',
                    value:     'Independent',
                  },{
                    property:  'policy_owner.kind',
                    assertion: 'equal',
                    value:     'person',
                  },
                ],
              },
              gender:                              {
                component:   'radio_list',
                list:        getGenders(),
                label:       t('person.label.gender'),
                validations: ['required'],
              },
              insured_years_as_main_driver:        {
                label:       t('schemas.axa.axa_constructor_main_driver_schema.insured_years_as_main_driver'),
                component:   'radio_list',
                list:        getInsuredYears(),
                validations: ['required'],
              },
              has_vehicle_accidents:               {
                label:         t('constructor.driver.label.vehicle_accident'),
                component:     'true_false',
                validations:   ['required'],
                default:       {
                  value: false,
                },
                onValueChange: {
                  set: [
                    {
                      to:        'main_driver.person.vehicle_accidents',
                      value:     null,
                      condition: {
                        assertion: 'isFalse',
                      },
                    },
                    {
                      to:        'main_driver.person.vehicle_accidents',
                      value:     [{}],
                      condition: {
                        assertion: 'isTrue',
                      },
                    },
                  ],
                },
              },
              vehicle_accidents:                   {
                type:          'array',
                arrayType:     'table',
                array_actions: {
                  add_label:    t('clients.drivers.driver_form.add_vehicle_accident'),
                  remove_label: t('general.actions.delete'),
                },
                properties:    {
                  date:           {
                    label:       t('clients.policy_owners.form.date'),
                    component:   'date',
                    validations: ['required', 'date_format'],
                  },
                  impacted_cover: {
                    component:   'select',
                    list:        getImpactedCovers(['damage', 'r_c', 'theft']),
                    label:       t('clients.drivers.driver_form.impacted_cover'),
                    validations: ['required'],
                  },
                },
                visible:       [
                  {
                    property: 'main_driver.person.has_vehicle_accidents',
                  },
                ],
              },
              address:                             {
                label:                t('activerecord.attributes.driver.person.address'),
                component:            'address',
                inlineContainerClass: 'top',
                requiredFields:       {
                  street_name:   true,
                  street_number: true,
                  city:          true,
                  street_box:    true,
                  zip_code:      true,
                  letter:        false,
                },
              },
              check_title:                         {
                type:  'title',
                title: t('wizard.policy_owner.additional_information'),
              },
              has_handicap:                        {
                component:   'true_false',
                validations: ['required'],
                tooltip:     t('wizard.policy_owner.has_handicap'),
                forceHover:  true,
                label:       t('schemas.axa.constructor.axa_constructor_steps.has_handicap'),
                default:     {
                  value: false,
                },
              },
              had_driving_license_withdrawals:     {
                component:   'true_false',
                validations: ['required'],
                tooltip:     t('wizard.policy_owner.has_driving_license_withdrawals'),
                forceHover:  true,
                label:       t('schemas.axa.constructor.axa_constructor_steps.had_driving_license_withdrawals'),
                default:     {
                  value: false,
                },
              },
              was_drunk:                           {
                component:   'true_false',
                validations: ['required'],
                tooltip:     t('wizard.policy_owner.was_drunk'),
                forceHover:  true,
                label:       t('schemas.axa.constructor.axa_constructor_steps.was_drunk'),
                default:     {
                  value: false,
                },
              },
              policy_owner_respond_question_above: {
                component:   'true_false',
                validations: ['required'],
                label:       t('schemas.axa.constructor.axa_constructor_steps.policy_owner_respond_question_above'),
                default:     {
                  value: false,
                },
                visible:     [
                  {
                    property:  'main_driver.policy_owner_is_main_driver',
                    assertion: 'isFalse',
                  },
                ],
              },
            },
          },
        },
      },
    },
  };
};
