import React, { useContext } from 'react';
import SearchInBMSButton     from 'components/bms/search_in_bms_button';
import JsonFormContext       from 'components/ui/json_form/json_form_context';

const SearchBmsField = ({ change, prefix }) => {
  const { disabled } = useContext(JsonFormContext);
  return (
    <div className="uk-flex uk-flex-right">
      <SearchInBMSButton
        className="mb-20"
        disabled={ disabled }
        onResultSelect={ (result) => {
          Object.keys(result).forEach((k) => {
            if (k === 'company_type') {
            } else {
              prefix.forEach((p) => {
                const key = `${ p }.${ result.kind }.${ k }`;
                change(`${ p }.kind`, result.kind);
                change(key, result[k]);
              });
            }
          });
        } }
      />
    </div>
  );
};

export default SearchBmsField;
