import React, { useEffect, useState } from 'react';
import { GOOGLE_JAVASCRIPT_API_KEY }  from 'constants/application_constants';
import { getCurrentLocale }           from 'services/i18n';

const WithGoogleMap = ({ children }) => {
  const [gmapLoaded, toggleGmapLoaded] = useState(false);

  useEffect(() => {
    window.initMap = () => toggleGmapLoaded(true);
    const gmapScriptEl = document.createElement('script');
    gmapScriptEl.src = `https://maps.googleapis.com/maps/api/js?key=${ GOOGLE_JAVASCRIPT_API_KEY }&libraries=places&callback=initMap&language=${ getCurrentLocale() }`;
    document.querySelector('body').insertAdjacentElement('beforeend', gmapScriptEl);
  }, []);

  if (!gmapLoaded) {
    return null;
  }

  return children;
};

export default WithGoogleMap;
