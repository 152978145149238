import React from 'react';

export const getAxaConstructorContractSchema = () => {
  const contractSchema = {
    form:      'contract',
    formProps: {
      updateUnregisteredFields: true,
    },
    schema:    {
      contract_details: {
        type:       'section',
        properties: {
          date_title:                         {
            type:  'title',
            title: t('axa.constructor.offer.tarification_cover_start_dates'),
          },
          product_component_start_dates:      {
            component:    'tarification_cover_start_dates',
            inline:       false,
            resume_label: false,
            validations:  ['required'],
          },
          additional_title:                   {
            type:  'title',
            title: t('axa.constructor.offer.additional_details'),
          },
          additional_vehicle_details:         {
            type:       'section',
            properties: {
              vin:          {
                component:   'text',
                validations: ['required', 'minLength17', 'maxLength20'],
                label:       t('activemodel.attributes.car.chassis_number'),
              },
              plate_number: {
                component:   'text',
                validations: ['required', 'maxLength10'],
                label:       t('activemodel.attributes.car.plate_number'),
              },
            },
          },
          bank_direct_debit:                  {
            label:       t('schemas.axa.constructor.axa_constructor_contract_schema.bank_direct_debit'),
            component:   'true_false',
            validations: ['required'],
          },
          policy_owner_is_bank_account_owner: {
            label:       t('schemas.axa.constructor.axa_constructor_contract_schema.policy_owner_is_bank_account_owner'),
            component:   'true_false',
            validations: ['required'],
            visible:     [{
              property:  'contract_details.bank_direct_debit',
              assertion: 'isTrue',
            }],
          },
          iban_number:                        {
            component:   'text',
            label:       t('schemas.axa.constructor.axa_constructor_contract_schema.iban_number'),
            validations: ['required', 'maxLength16'],
            visible:     [{
              property:  'contract_details.bank_direct_debit',
              assertion: 'isTrue',
            }],
          },
          trailer_details:                    {
            type:       'section',
            properties: {
              trailer_title:  {
                type:  'title',
                title: t('schemas.axa.constructor.axa_constructor_contract_schema.trailer_details.title'),
              },
              brand:          {
                label:       t('schemas.axa.constructor.axa_constructor_contract_schema.trailer_details.brand'),
                component:   'text',
                validations: ['required'],
              },
              chassis_number: {
                label:       t('schemas.axa.constructor.axa_constructor_contract_schema.trailer_details.chassis_number'),
                component:   'text',
                validations: ['required'],
              },
              plate_number:   {
                label:       t('schemas.axa.constructor.axa_constructor_contract_schema.trailer_details.plate_number'),
                component:   'text',
                validations: ['required'],
              },
            },
            visible:    [{
              property:  'contract_request.data.vehicle.trailer_usage',
              assertion: 'isTrue',
            }],
          },
        },
      },
    },
  };
  return contractSchema;
};
