import React              from 'react';
import CreateLeadImage    from 'images/create_lead.svg';
import CreateFolderButton from 'components/folder/create_folder_button';

const CreateLeadState = () => {
  return (
    <div className="uk-flex uk-flex-middle uk-width-1-1 folder-empty-state p-20" data-purpose="lead_empty_state">
      <div className="uk-text-center uk-width-1-1">
        <img
          src={ CreateLeadImage }
          alt="No lead"
          className="uk-width-1-5"
        />
        <p className="uk-text-center mt-20 pl-40 pr-40">
          { t('leads.create_lead') }
        </p>
        <div className="uk-text-center">
          <CreateFolderButton forLead className="uk-button-primary large" />
        </div>
      </div>
    </div>
  );
};

export default CreateLeadState;
