import React            from 'react';
import PropTypes        from 'prop-types';
import Avatar           from 'components/avatar';
import { CAR_LOGO_API } from 'models/car/constants';
import Icon             from 'components/ui/icon';

const VehicleIcon = ({ vehicle, isVan, ...restProps }) => {
  return (
    <React.Fragment>
      { vehicle && vehicle.brand && (
        <Avatar picture={ `${ CAR_LOGO_API }${ vehicle.brand.toLowerCase() }` } { ...restProps } />
      ) }
      { (!vehicle || !vehicle.brand) && (
        <Icon icon={ isVan ? 'van' : 'car' } />
      ) }
    </React.Fragment>
  );
};

VehicleIcon.defaultProps = {
  isVan: false,
};

VehicleIcon.propTypes = {
  vehicle: PropTypes.object.isRequired,
  isVan:   PropTypes.bool,
};

export default VehicleIcon;
