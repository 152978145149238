import React, { useState }                               from 'react';
import DocumentsTable                                    from 'components/documents/table';
import LoadOnClickButton                                 from 'components/ui/buttons/load_on_click_button';
import { PRINT_SELECTED_TARIFICATIONS, trackWizardStep } from 'models/wizard/constants';
import { getTarificationsPdf }                           from 'models/tarifications/requests';

const DocumentsToPrintForm = ({ submitting, tarifications, onPrinted, contractRequestId }) => {
  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const print = async () => {
    trackWizardStep(PRINT_SELECTED_TARIFICATIONS, {
      contract_request_id: contractRequestId,
      tarification_ids:    tarifications.map(tarif => tarif.id),
    });
    await getTarificationsPdf(contractRequestId, selectedDocuments, tarifications).then(() => {
      if (onPrinted) {
        onPrinted();
      }
    });
  };

  return (
    <div
      data-purpose="add_document_to_print_form"
    >
      <p>
        { t('wizard.resume.print.select_document_explanation') }
      </p>
      <DocumentsTable
        tarifications={ tarifications }
        onSelectedDocumentChange={ setSelectedDocuments }
        canDownload={ false }
      />
      <p className="uk-text-center">
        <LoadOnClickButton
          className="uk-button uk-button-primary mr-20"
          disabled={ submitting }
          primary
          onClick={ print }
        >
          { t('wizard.resume.print.join_selected_document', { count: selectedDocuments ? selectedDocuments.length : 0 }) }
        </LoadOnClickButton>
      </p>
    </div>
  );
};

export default DocumentsToPrintForm;
