export const getViviumBuildingSchema = () => ({
  form:   'vivium_building',
  schema: {
    building: {
      type:       'section',
      properties: {
        policy_owner_address_is_building_address: {
          label:         t('vivium.building.policy_owner_address_is_building_address'),
          component:     'true_false',
          validations:   [
            'required',
          ],
          onValueChange: {
            prefill: {
              prefillOnMount:           true,
              from:                     {
                store: 'policy_owner',
              },
              changes:                  [
                {
                  from:      'company.address',
                  to:        'building.address',
                  condition: {
                    property:  'policy_owner.kind',
                    assertion: 'equal',
                    value:     'company',
                  },
                },
                {
                  from:      'person.address',
                  to:        'building.address',
                  condition: {
                    property:  'policy_owner.kind',
                    assertion: 'equal',
                    value:     'person',
                  },
                },
                {
                  from:      'acp.address',
                  to:        'building.address',
                  condition: {
                    property:  'policy_owner.kind',
                    assertion: 'equal',
                    value:     'acp',
                  },
                },
              ],
              clearOnConditionUnfilled: true,
              condition:                {
                assertion: 'isTrue',
              },
            },
          },
          default:       {
            value: true,
          },
        },
        address:                                  {
          label:          t('vivium.building.address'),
          labels:         {
            city: t('vivium.building.city'),
          },
          component:      'address',
          formFor:        'address',
          displayMap:     true,
          requiredFields: {
            street_name:   true,
            street_number: true,
            city:          true,
            street_box:    true,
            zip_code:      true,
          },
        },
        building_in_construction:                 {
          label:       t('vivium.building.building_in_construction'),
          component:   'true_false',
          validations: [
            'required',
          ],
        },
        build_year:                               {
          label:       t('vivium.building.build_year'),
          component:   'text',
          validations: [
            'required',
          ],
        },
        has_renovation:                           {
          component:   'true_false',
          validations: ['required'],
          label:       t('schemas.vivium.building.vivium_building_schema.has_renovation'),
          default:     {
            value: false,
          },
          visible:     [{
            property:   'building.build_year',
            propertyAs: 'year',
            assertion:  'isAbove',
            value:      20,
          }],
        },
        renovations:                              {
          type:       'section',
          properties: {
            roof_renovation_date:        {
              component: 'text',
              label:     t('schemas.vivium.building.vivium_building_schema.roof_renovation_date'),
            },
            discharge_renovation_date:   {
              component: 'text',
              label:     t('schemas.vivium.building.vivium_building_schema.discharge_renovation_date'),
            },
            electricity_renovation_date: {
              component: 'text',
              label:     t('schemas.vivium.building.vivium_building_schema.electricity_renovation_date'),
            },
          },
          visible:    [
            {
              property:   'building.build_year',
              propertyAs: 'year',
              assertion:  'isAbove',
              value:      20,
            },
            {
              property:  'building.has_renovation',
              assertion: 'isTrue',
            },
          ],
        },
        building_year_warning:                    {
          component: 'message',
          message:   t('schemas.vivium.building.vivium_building_schema.building_year_warning'),
          className: 'global-message warning',
          visible:   [
            {
              property:   'building.build_year',
              propertyAs: 'year',
              assertion:  'isAbove',
              value:      20,
            },
            {
              property:  'building.has_renovation',
              assertion: 'isFalse',
            },
          ],
        },
        renovation_warning:                       {
          component: 'message',
          message:   t('schemas.vivium.building.vivium_building_schema.renovation_warning'),
          className: 'global-message warning',
          visibleOr: [
            {
              property:   'building.renovations.roof_renovation_date',
              propertyAs: 'year',
              assertion:  'isAbove',
              value:      10,
            },
            {
              property:   'building.renovations.discharge_renovation_date',
              propertyAs: 'year',
              assertion:  'isAbove',
              value:      10,
            },
            {
              property:   'building.renovations.electricity_renovation_date',
              propertyAs: 'year',
              assertion:  'isAbove',
              value:      10,
            },
          ],
          visible:   [
            {
              property:   'building.build_year',
              propertyAs: 'year',
              assertion:  'isAbove',
              value:      20,
            },
            {
              property:  'building.has_renovation',
              assertion: 'isTrue',
            },
          ],
        },
        floor_count:                              {
          label:       t('vivium.building.floor_count'),
          component:   'text',
          type:        'number',
          validations: [
            'required',
          ],
        },
        flat_count:                               {
          label:       t('vivium.building.flat_count'),
          component:   'text',
          type:        'number',
          validations: [
            'required',
          ],
        },
        usage:                                    {
          label:       t('vivium.building.usage'),
          component:   'radio_list',
          validations: ['required'],
          list:        [
            {
              label: t('vivium.building.usage_list.habitation_only'),
              value: 'habitation_only',
            },
            {
              label: t('vivium.building.usage_list.commerce_without_horeca'),
              value: 'commerce_without_horeca',
            },
            {
              label: t('vivium.building.usage_list.horeca'),
              value: 'horeca',
            },
          ],
        },
        student_room:                             {
          label:       t('vivium.building.contain_student_room'),
          component:   'true_false',
          validations: ['required'],
        },
        combustible:                              {
          label:       t('vivium.building.combustible'),
          component:   'true_false',
          validations: ['required'],
        },
        combustible_message:                      {
          component: 'message',
          className: 'global-message danger uk-width-1-1',
          message:   t('vivium.building.combustible_message'),
          visible:   {
            property:  'building.combustible',
            assertion: 'isFalse',
          },
        },
      },
    },
  },
});
