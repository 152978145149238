import React, { useEffect, useState, useContext } from 'react';
import { useDispatch, useSelector }               from 'react-redux';
import { submit }                                 from 'redux-form';
import has                                        from 'lodash/has';
import isNil                                      from 'lodash/isNil';
import asWizardStep                               from 'components/wizard_stepper/as_wizard_step';
import {
  updateWizardTarificationOffer,
}                                                 from 'models/wizard/dispatch_actions';
import JsonForm                                   from 'components/ui/json_form/json_form';
import { flattenKeys }                            from 'services/object';
import $                                          from 'jquery';
import JsonFormErrors                             from 'components/ui/json_form/json_form_errors';
import WizardContext                              from 'components/wizard/wizard_context';
import Loader                                     from 'components/ui/loader';

let nextStepOverride = null;
const OfferStep = (props) => {
  const { toggleStepMainActionAvailable } = useContext(WizardContext);
  const [formErrors, setErrors] = useState([]);
  const dispatch = useDispatch();
  const contractRequest = useSelector((state) => state.wizard.contract_request);
  const {
          nextStep,
          setNextLoading,
          setNextAction,
          form,
          schema,
          formProps = {},
          tarificationId,
        } = props;

  const updateTarificationOfferData = (offerData) => {
    setNextLoading(true);
    if (offerData) {
      return dispatch(updateWizardTarificationOffer(tarificationId, offerData))
        .then(() => {
          setNextLoading(false);
        })
        .catch(() => {
          setNextLoading(false);
        });
    }
  };

  const onSubmit = (data) => {
    updateTarificationOfferData(data).then(() => {
      if (nextStepOverride && !isNil(nextStepOverride) && typeof nextStepOverride !== 'string') {
        return nextStepOverride();
      }
      nextStep();
    });
  };

  const submitForm = () => {
    dispatch(submit(form));
  };

  const initialValues = contractRequest.tarifications.find((tarif) => tarif.id === tarificationId);
  const { last_error_response } = initialValues.offer ? initialValues.offer : {};

  useEffect(() => {
    if (last_error_response || (initialValues.warnings && initialValues.warnings.length > 0)) {
      toggleStepMainActionAvailable(false);
    }
    setNextAction((onNextStepOverride) => {
      if (onNextStepOverride) {
        nextStepOverride = onNextStepOverride;
      }
      return submitForm();
    });
  }, []);

  if (initialValues.computing) {
    return (<Loader />);
  }

  return (
    <React.Fragment>
      <React.Fragment>
        <JsonForm
          form={ form }
          schema={ schema }
          disabled={ !contractRequest.tarifications[0].authorizations.offer }
          initialValues={ has(initialValues, 'offer.data') ? initialValues.offer.data : {} }
          onSubmit={ onSubmit }
          onSubmitFail={ (errors) => {
            if (!errors) {
              setErrors([]);
              return;
            }
            setTimeout(() => {
              const errorsByPath = flattenKeys(errors);
              const allErrors = Object.keys(errorsByPath).map((path) => {
                const $item = $(`[data-error="${ path }"]`);
                return {
                  path,
                  $item,
                  label:   $item.data('error-label'),
                  message: $item.text(),
                };
              });
              if (allErrors && allErrors.length > 0) {
                setErrors(allErrors);
              }
              const firstError = Object.keys(errorsByPath)[0];
              const target = $(`[data-error="${ firstError }"]`);
              if (!target[0]) {
                return;
              }
              $([document.documentElement, document.body]).animate({
                scrollTop: target.offset().top - 150,
              }, 500);
            }, 1000);
          } }
          { ...formProps }
        />
        { formErrors.length > 0 && (
          <JsonFormErrors errors={ formErrors } />
        ) }
      </React.Fragment>
    </React.Fragment>
  );
};

export default asWizardStep(true)(OfferStep);
