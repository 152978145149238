import React                             from 'react';
import compact                           from 'lodash/compact';
import TarificationStep                  from 'components/wizard/tarification_step';
import GenericStep                       from 'components/wizard_stepper/generic_step';
import { getAedesHousingContractSchema } from 'schemas/aedes/p_500/aedes_housing_contract_schema';

import {
  isOffer,
  haveSelectedPolicyOwner,
  haveSelectedRisk,
  haveSelectedOffer,
}                                   from 'models/wizard_navigation/constants';
import SubmitStepAndCreateOffer     from 'components/wizard/submit_step_and_create_offer';
import AedesP500OfferStep           from 'components/aedes/aedes_p500_offer_step';
import SingleProductResume          from 'components/resume/single_product_resume';
import { getPlocPolicyOwnerSchema } from 'schemas/aedes/p_loc/ploc_policy_owner_schema';
import { getPlocRiskSchema }        from 'schemas/aedes/p_loc/ploc_risk_schema';

export const getAedesPlocSchema = (matchPath, contractRequest, recomputeTarifications) => (
  compact([
    {
      label: t('wizard.navigation.about_contract_request'),
      icon:  'file',
      slug:  'contract_request',
      kind:  'group',
      order: 1,
      steps: [
        {
          path:           `${ matchPath }/policy_owner`,
          title:          t('wizard.policy_owner.title'),
          label:          t('wizard.navigation.policy_owner'),
          icon:           'company',
          Component:      GenericStep,
          order:          1,
          purpose:        'policy_owner',
          componentProps: {
            ...getPlocPolicyOwnerSchema(),
            onNextStep: {
              recomputeTarifications,
            },
          },
        },
        {
          path:           `${ matchPath }/risk`,
          disabledRules:  [
            haveSelectedPolicyOwner,
          ],
          title:          t('wizard.navigation.building'),
          label:          t('wizard.navigation.building'),
          icon:           contractRequest.risk_object.icon,
          order:          2,
          Component:      GenericStep,
          purpose:        'housing',
          componentProps: {
            ...getPlocRiskSchema(),
            onNextStep: {
              recomputeTarifications,
            },
          },
        },
      ],
    },
    {
      path:           `${ matchPath }/tarification_${ contractRequest.tarifications[0].id }`,
      order:          3,
      title:          t('wizard.navigation.configuration', { name: contractRequest.tarifications[0].product.display_name }),
      label:          t('wizard.navigation.solo_product', { product_name: contractRequest.tarifications[0].product.display_name }),
      error:          contractRequest.tarifications[0].last_error_response,
      loading:        contractRequest.tarifications[0].computing,
      icon:           'product-parameters',
      disabledRules:  [
        haveSelectedPolicyOwner,
        haveSelectedRisk,
      ],
      purpose:        'tarification',
      Component:      TarificationStep,
      componentProps: {
        tarificationId: contractRequest.tarifications[0].id,
        disabled:       contractRequest.status === 'offer',
        subtitle:       t('aedes.housing.tarification_subtitle'),
      },
    },
    {
      path:           `${ matchPath }/resume`,
      title:          t('wizard.navigation.resume'),
      order:          4,
      disabledRules:  [
        haveSelectedPolicyOwner,
        haveSelectedRisk,
      ],
      label:          t('wizard.navigation.tarification_documents'),
      icon:           'folder',
      purpose:        'resume_step',
      Component:      SingleProductResume,
      componentProps: {
        showCreateOffer: false,
      },
    },
    {
      path:           `${ matchPath }/ask_offer`,
      order:          5,
      title:          t('aedes.housing.wizard.ask_offer'),
      disabledRules:  [
        haveSelectedPolicyOwner,
        haveSelectedRisk,
      ],
      label:          t('aedes.housing.wizard.ask_offer'),
      icon:           'contract',
      Component:      GenericStep,
      componentProps: {
        ...getAedesHousingContractSchema({
          schema: {
            contract: {
              properties: {
                certifies_that: {
                  type:       'section',
                  title:      t('aedes.housing.policy_owner_certifies'),
                  properties: {
                    not_in_flood_zone:               {
                      component:   'checkbox',
                      label:       t('aedes.housing.not_in_floodzone'),
                      inlineLabel: false,
                      validations: [{
                        assertion: 'isTrue',
                        message:   t('general.forms.must_be_checked'),
                      }],
                    },
                    no_sinister_during_last_5_years: {
                      component:   'checkbox',
                      label:       t('aedes.housing.no_sinister_in_5_years'),
                      inlineLabel: false,
                      validations: [{
                        assertion: 'isTrue',
                        message:   t('general.forms.must_be_checked'),
                      }],
                    },
                  },
                },
              },
            },
          },
        }),
      },
      StepMainAction: (props) => {
        return (
          <SubmitStepAndCreateOffer
            tarificationId={ contractRequest.tarifications[0].id }
            { ...props }
            label={ t('aedes.housing.ask_offer_button') }
          />
        );
      },
    },
    {
      path:          `${ matchPath }/offer_reference`,
      order:         6,
      title:         t('aedes.housing.wizard.offer_reference_title'),
      disabledRules: [
        haveSelectedPolicyOwner,
        haveSelectedRisk,
        haveSelectedOffer,
        isOffer,
      ],
      label:         t('aedes.housing.wizard.offer_reference_title'),
      icon:          'offer',
      Component:     AedesP500OfferStep,
    },
  ])
);
