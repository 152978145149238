import axios                                                from 'axios';
import { getAuthorizationHeader, removeAuthorizationToken } from 'services/authorization_token';
import { getCurrentLocale }                                 from 'services/i18n';
import Alert                                                from 'services/alert';
import { API_DOMAIN, API_PROTOCOL }                         from 'constants/application_constants';

const setHeaders = () => {
  const headers = {
    'Accept':       'application/json',
    'API-Version':  1,
    'Content-Type': 'application/json',
  };
  if (getAuthorizationHeader()) {
    headers.Authorization = getAuthorizationHeader();
  }
  if (getCurrentLocale()) {
    headers.Locale = getCurrentLocale();
  }
  return headers;
};

const Api = axios.create({
  baseURL: `${ API_PROTOCOL }://${ API_DOMAIN }/distributor`,
  headers: setHeaders(),
});

// Add a request interceptor
Api.interceptors.request.use((config) => {
  config.headers = setHeaders();
  return config;
}, (error) => {
  return Promise.reject(error);
});

// Add a response interceptor
Api.interceptors.response.use((response) => {
  return response;
}, (error) => {
  if (error.response && error.response.status === 401) {
    removeAuthorizationToken();
    if (location.href.includes('/user/sign_in?session_expired=true')) {
      return Promise.reject(error);
    }
    location.href = `/${ getCurrentLocale() }/user/sign_in?session_expired=true`;
  } else if (error.response && error.response.status === 403) {
    return Alert.error(t('api.error_messages.forbidden'));
  }
  if (error.response) {
    return Promise.reject(error.response);
  }
  return Promise.reject(error);
});


export default Api;
