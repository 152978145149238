import React from 'react';

export const aedesHousingSchema = () => ({
  form:   'aedes_housing',
  schema: {
    housing: {
      type:       'section',
      properties: {
        same_address_than_policy_owner: {
          component:     'true_false',
          label:         t('aedes.housing.same_address_than_policy_owner'),
          onValueChange: {
            prefill: {
              prefillOnMount:           true,
              from:                     {
                store: 'policy_owner',
              },
              changes:                  [
                {
                  from:      'company.address',
                  to:        'housing.address',
                  condition: {
                    property:  'policy_owner.kind',
                    assertion: 'equal',
                    value:     'company',
                  },
                },
                {
                  from:      'person.address',
                  to:        'housing.address',
                  condition: {
                    property:  'policy_owner.kind',
                    assertion: 'equal',
                    value:     'person',
                  },
                },
              ],
              clearOnConditionUnfilled: true,
              condition:                {
                assertion: 'isTrue',
              },
            },
          },
          default:       {
            value: true,
          },
        },
        address:                        {
          label:          t('aedes.housing.building_address'),
          component:      'address',
          formFor:        'address',
          displayMap:     true,
          requiredFields: {
            street_name:   true,
            street_number: true,
            city:          true,
            street_box:    true,
            zip_code:      true,
          },
        },
        risk_category:                  {
          label:          t('aedes.housing.risk_category'),
          component:      'radio_list',
          list:           [
            {
              value: 'house',
              label: t('risks.reference.house'),
            },
            {
              value: 'appartment',
              label: t('risks.reference.appartment'),
            },
          ],
          validations:    ['required'],
          componentProps: {
            asButton: true,
          },
          default:        {
            value: 'house',
          },
        },
        certifies_that:                 {
          type:       'section',
          title:      t('aedes.housing.policy_owner_certifies'),
          properties: {
            no_annex_over_40_square_meter:              {
              component:   'checkbox',
              label:       t('aedes.housing.no_annex_over_40_square_meter'),
              inlineLabel: false,
              validations: [{
                assertion: 'isTrue',
                message:   t('general.forms.must_be_checked'),
              }],
            },
            no_more_than_1_kitchen:                  {
              component:   'checkbox',
              label:       t('aedes.housing.no_more_than_1_kitchen'),
              inlineLabel: false,
              validations: [{
                assertion: 'isTrue',
                message:   t('general.forms.must_be_checked'),
              }],
            },
            not_in_flood_zone:                       {
              component:   'checkbox',
              label:       t('aedes.housing.not_in_floodzone'),
              inlineLabel: false,
              validations: [{
                assertion: 'isTrue',
                message:   t('general.forms.must_be_checked'),
              }],
            },
            no_sinister_during_last_5_years:         {
              component:   'checkbox',
              label:       t('aedes.housing.no_sinister_in_5_years'),
              inlineLabel: false,
              validations: [{
                assertion: 'isTrue',
                message:   t('general.forms.must_be_checked'),
              }],
            },
            provides_minimum_2_pictures_of_building: {
              component:   'checkbox',
              label:       t('aedes.housing.provides_pictures'),
              inlineLabel: false,
              validations: [{
                assertion: 'isTrue',
                message:   t('general.forms.must_be_checked'),
              }],
            },
            no_theft_last_5_years:                   {
              component:   'checkbox',
              label:       t('aedes.housing.no_theft_last_5_years'),
              inlineLabel: false,
              validations: [{
                assertion: 'isTrue',
                message:   t('general.forms.must_be_checked'),
              }],
            },
            no_insurance_cancellations:              {
              component:   'checkbox',
              label:       t('aedes.housing.no_insurance_cancellations'),
              inlineLabel: false,
              validations: [{
                assertion: 'isTrue',
                message:   t('general.forms.must_be_checked'),
              }],
            },
          },
        },
      },
    },
  },
});
