import React       from 'react';
import Icon        from 'components/ui/icon';
import loadOnClick from 'components/ui/buttons/helpers/load_on_click';
import Button      from 'components/ui/buttons/button';

const LoadOnClickButton = loadOnClick()(Button);

const Files = ({ files = [], className, onDelete }) => {
  return (
    <div className={ `file-list ${ className }` }>
      { files.map((file) => {
        return (
          <a
            key={ file.id }
            href={ file.url }
            target="_blank"
            className="mb-20 mr-20"
          >
            <div className="file-item" title={ file.name }>
              <p className="file-icon">
                <Icon icon="file" />
              </p>
              <p className="file-name">{ file.name }</p>
              <LoadOnClickButton
                onClick={ (ev) => {
                  ev.preventDefault();
                  onDelete(file);
                } }
                className="error"
              >
                <Icon icon="delete" />
              </LoadOnClickButton>
            </div>
          </a>
        );
      }) }
    </div>
  );
};


export default Files;
