import React              from 'react';

class StripeScaCallback extends React.Component {
  componentDidMount() {
    window.top.postMessage('3DS-authentication-complete');
  }

  render() {
    return null;
  }
}

export default StripeScaCallback;
