import React            from 'react';
import ProductErrorList from 'components/product/product_error_list';

const OfferErrors = ({ offer }) => {
  if (!offer) {
    return null;
  }

  const { last_error_response } = offer;

  if (!last_error_response) {
    return null;
  }

  if (last_error_response.errors) {
    return (
      <div className="uk-alert-danger p-20">
        <ProductErrorList errors={ last_error_response.errors } />
      </div>
    );
  }
  if (last_error_response.message) {
    return (
      <div className="uk-alert-warning p-20 pb-5 mt-20 mb-20">
        { (last_error_response.message instanceof Array ? last_error_response.message : last_error_response.message.split('\n')).map((line) => (<p>{ line }</p>)) }
      </div>
    );
  }
};

export default OfferErrors;
