import React           from 'react';
import {
  Field,
  reduxForm,
}                      from 'redux-form';
import Margin          from 'components/ui/margin';
import InputLabel      from 'components/ui/form/input_label';
import SubmitButton    from 'components/ui/form/submit_button';
import SymbolTextInput from 'components/ui/form/symbol_text_input';
import Icon            from 'components/ui/icon';

class SearchByLicencePlateForm extends React.Component {
  componentDidMount() {
    $('input[name="plate_number"]').focus();
  }

  render() {
    const { handleSubmit, submitting, pristine, children, label } = this.props;
    return (
      <form onSubmit={ handleSubmit } data-purpose="licence_plate_form">
        <Margin>
          <InputLabel>
            { label || t('clients.typed_contract_requests.car_selection_modal.vin_number') }
          </InputLabel>
          <Field
            name="plate_number"
            type="text"
            placeholder={ t('clients.typed_contract_requests.car_selection_modal.search_by_vin_number') }
            disabled={ submitting }
            className="autocomplete-input"
            symbol={ <Icon icon="search" /> }
            component={
              SymbolTextInput
            }
          />
        </Margin>
        <div className="uk-flex uk-flex-between">
          { children }
          <SubmitButton
            type="submit"
            data-purpose="submit_licence_plate_form_button"
            disabled={ pristine }
            loading={ submitting }
            text={ t('clients.typed_contract_requests.car_selection_modal.search_vehicle') }
          />
        </div>
      </form>
    );
  }
}

SearchByLicencePlateForm.propTypes = {};

export default reduxForm({
  form: 'search_by_plate_number_form',
})(
  SearchByLicencePlateForm,
);
