import { getUsageList } from 'models/contract_request/constants';

export const getMotorbikeSchema = () => {
         return {
           form:   'motorbike',
           schema: {
             vehicle: {
               type:       'section',
               properties: {
                 kind:                                {
                   type:    'hidden',
                   default: {
                     from: 'contract_request.risk_object.kind',
                   },
                 },
                 date_of_first_circulation: {
                   component:   'date',
                   validations: ['required', 'date_format'],
                   label:       t('activerecord.attributes.car.date_of_first_circulation'),
                   warning:     'motorbike.date_of_first_circulation',
                   visible:     {
                     property: 'required_fields.vehicle.date_of_first_circulation',
                   },
                 },
                 brand:                     {
                   component:   'text',
                   label:       t('activerecord.attributes.motorbike.manufacturer'),
                   validations: ['required'],
                   warning:     'motorbike.brand',
                   visible:     {
                     property: 'required_fields.vehicle.brand',
                   },
                 },
                 model:                     {
                   component:   'text',
                   label:       t('activerecord.attributes.motorbike.motorbike_model'),
                   validations: ['required'],
                   warning:     'motorbike.model',
                   visible:     {
                     property: 'required_fields.vehicle.model',
                   },
                 },
                 fuel_type:                 {
                   component:   'radio_list',
                   list:        {
                     from: 'options_collections.motorbike_fuel_types',
                   },
                   validations: ['required'],
                   label:       t('activerecord.attributes.car.fuel_type'),
                   warning:     'motorbike.fuel_type',
                   visible:     {
                     property: 'required_fields.vehicle.fuel_type',
                   },
                 },
                 displacement:              {
                   component:           'text',
                   symbol:              'CC',
                   label:               t('activerecord.attributes.motorbike.displacement'),
                   warning:             'motorbike.displacement',
                   disabledIfEmptyList: true,
                   visible:             {
                     property: 'required_fields.vehicle.displacement',
                   },
                 },
                 horse_power:               {
                   component:   'text',
                   symbol:      'CV',
                   label:       t('activerecord.attributes.motorbike.motorbike_power'),
                   validations: ['required'],
                   warning:     'motorbike.power',
                   visible:     {
                     property: 'required_fields.vehicle.power',
                   },
                 },
                 power:                     {
                   component:   'text',
                   symbol:      'KW',
                   label:       t('activerecord.attributes.motorbike.motorbike_power'),
                   validations: ['required'],
                   warning:     'motorbike.power',
                   visible:     {
                     property: 'required_fields.vehicle.power',
                   },
                 },
                 motorbike_kind:            {
                   component:   'select',
                   label:       t('activerecord.attributes.motorbike.kind'),
                   validations: ['required'],
                   warning:     'motorbike.kind',
                   list:        {
                     from: 'options_collections.motorbike_kinds',
                   },
                   visible:     {
                     property: 'required_fields.vehicle.motorbike_kind',
                   },
                 },
                 genre:                     {
                   component:   'select',
                   label:       t('activerecord.attributes.motorbike.genre'),
                   validations: ['required'],
                   warning:     'motorbike.genre',
                   list:        {
                     from: 'options_collections.motorbike_genres',
                   },
                   visible:     {
                     property: 'required_fields.vehicle.genre',
                   },
                 },
                 catalogue_price:           {
                   component:   'price',
                   label:       t('activerecord.attributes.motorbike.catalogue_price_without_vat'),
                   validations: ['required'],
                   warning:     'motorbike.catalogue_price',
                   visible:     {
                     property: 'required_fields.vehicle.catalogue_price',
                   },
                 },
                 options_catalogue_price:   {
                   component:   'price',
                   label:       t('activerecord.attributes.motorbike.options_catalogue_price_without_vat'),
                   validations: ['required'],
                   warning:     'motorbike.catalogue_price',
                   visible:     {
                     property: 'required_fields.vehicle.options_catalogue_price_without_vat',
                   },
                 },
                 tare_weight:               {
                   component:   'text',
                   symbol:      'KG',
                   label:       t('activerecord.attributes.motorbike.tare_weight'),
                   validations: ['required'],
                   warning:     'motorbike.tare_weight',
                   visible:     {
                     property: 'required_fields.vehicle.tare_weight',
                   },
                 },
                 plate_number:              {
                   component:   'text',
                   label:       t('activerecord.attributes.motorbike.plate_number'),
                   validations: ['required'],
                   warning:     'motorbike.plate_number',
                   visible:     {
                     property: 'required_fields.vehicle.plate_number',
                   },
                 },
                 chassis_number:            {
                   component:   'text',
                   label:       t('activerecord.attributes.motorbike.chassis_number'),
                   validations: ['required'],
                   warning:     'motorbike.chassis_number',
                   visible:     {
                     property: 'required_fields.vehicle.chassis_number',
                   },
                 },
               },
             },
             usage:     {
               type:       'section',
               title:      t('wizard.car.about_usage'),
               visibleOr:  [
                 {
                   property: 'required_fields.vehicle.usage',
                 },
                 {
                   property: 'required_fields.vehicle.main_vehicle',
                 },
                 {
                   property: 'required_fields.vehicle.garage_parking',
                 },
               ],
               properties: {
                 vehicle_usage:  {
                   component:   'radio_list',
                   list:        getUsageList(),
                   label:       t('clients.typed_contract_requests.usage_modal.vehicle_usage'),
                   validations: ['required'],
                   warning:     'base.vehicle_usage',
                   visible:     {
                     property: 'required_fields.vehicle.usage',
                   },
                 },
                 main_vehicle:   {
                   component:   'true_false',
                   label:       t('clients.typed_contract_requests.usage_modal.main_vehicle'),
                   validations: ['required'],
                   warning:     'base.main_vehicle',
                   visible:     {
                     property: 'required_fields.vehicle.vehicle_usage',
                   },
                 },
                 garage_parking: {
                   component:   'true_false',
                   label:       t('clients.typed_contract_requests.usage_modal.garage_parking'),
                   validations: ['required'],
                   warning:     'base.garage_parking',
                   visible:     {
                     property: 'required_fields.vehicle.garage_parking',
                   },
                 },
               },
             },
           },
         };
       }
;
