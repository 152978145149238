export const getPlocPolicyOwnerSchema = () => {
  return {
    form:   'policy_owner',
    schema: {
      policy_owner: {
        type:       'section',
        properties: {
          locale:        {
            component:   'radio_list',
            list:        {
              from: 'available_locales',
            },
            default:     {
              from: 'current_user.profile.locale',
            },
            validations: ['required'],
            label:       t('schemas.shared.policy_owner_schema.locale'),
          },
          person:                   {
            type:       'section',
            properties: {
              first_name:    {
                component:   'text',
                label:       t('person.label.first_name'),
                validations: ['required'],
              },
              last_name:     {
                component:   'text',
                label:       t('person.label.last_name'),
                validations: ['required'],
              },
              date_of_birth: {
                label:       t('person.label.birthdate'),
                component:   'date',
                validations: ['required', 'date_format'],
              },
              email:         {
                component:   'text',
                type:        'email',
                label:       t('person.label.email'),
                validations: ['required_email'],
              },
              phone_number:  {
                component: 'text',
                label:     t('person.label.phone_number'),
              },
            },
          },
        },
      },
    },
  };
};
