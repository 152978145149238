import React from 'react';

const DocumentType = ({ type }) => {
  if (!type) {
    return null;
  }

  return (
    <span className="ob-type">
      { t(`components.documents.document_type.${ type }`) }
    </span>
  );
};

export default DocumentType;
