export const getVanDesselMazdaImpactedCovers = () => {
  return [
    {
      value: 'r_c',
      label: t('covers.reference.r_c'),
    },
  ];
};
export const getVanDesselMazdaResponsibilitiesOptions = () => {
  return [
    {
      value: 'at_fault',
      label: t('responsibility.at_fault'),
    },
    {
      value: 'unknown',
      label: t('responsibility.unknown'),
    },
  ];
};
