import { getYearOptionList } from 'models/application/constants';

export const getAxaBuildingClaimHistorySchema = () => {
  const claimTypeList = [
    {
      label: t('wizard.building.claim_history.claim_detail.claim_types.fire'),
      value: 'Fire',
    },
    {
      label: t('wizard.building.claim_history.claim_detail.claim_types.water'),
      value: 'Water',
    },
    {
      label: t('wizard.building.claim_history.claim_detail.claim_types.electricity'),
      value: 'Electricity',
    },
    {
      label: t('wizard.building.claim_history.claim_detail.claim_types.glass'),
      value: 'Glass',
    },
    {
      label: t('wizard.building.claim_history.claim_detail.claim_types.liability'),
      value: 'Liability',
    },
    {
      label: t('wizard.building.claim_history.claim_detail.claim_types.labour'),
      value: 'Labour',
    },
    {
      label: t('wizard.building.claim_history.claim_detail.claim_types.legal_assistance'),
      value: 'Legal_assistance',
    },
    {
      label: t('wizard.building.claim_history.claim_detail.claim_types.storm'),
      value: 'Storm',
    },
    {
      label: t('wizard.building.claim_history.claim_detail.claim_types.catnat'),
      value: 'Catnat',
    },
  ];
  const yearList = getYearOptionList(1950);
  return {
    claim_history: {
      type:       'section',
      title:      t('wizard.building.claim_history.title'),
      properties: {
        claim_history_type: {
          validations:   ['required'],
          label:         t('wizard.building.claim_history.claim_history_type'),
          component:     'select',
          list:          [
            {
              label: t('wizard.building.claim_history.claim_history_types.no_damage'),
              value: 'No_damage',
            },
            {
              label: t('wizard.building.claim_history.claim_history_types.document'),
              value: 'Document',
            },
            {
              label: t('wizard.building.claim_history.claim_history_types.filled_in'),
              value: 'Filled_in',
            },
            {
              label: t('wizard.building.claim_history.claim_history_types.unknown'),
              value: 'Unknown',
            },
          ],
          onValueChange: {
            set: [
              {
                to:        'claim_history.claim_detail[0]',
                value:     {},
                condition: {
                  assertion: 'equal',
                  value:     'Filled_in',
                },
              },
              {
                to:        'claim_history.claim_detail',
                value:     null,
                condition: {
                  assertion: 'notEqual',
                  value:     'Filled_in',
                },
              },
            ],
          },
        },
        upload_message:     {
          component: 'message',
          className: 'global-message info uk-width-1-1',
          message:   t('wizard.building.claim_history.claim_history_upload_description'),
          visible:   {
            property:  'claim_history.claim_history_type',
            assertion: 'equal',
            value:     'Document',
          },
        },
        claim_description:  {
          label:       t('wizard.building.claim_history.claim_description'),
          component:   'textarea',
          visible:     {
            property:  'claim_history.claim_history_type',
            assertion: 'equal',
            value:     'Unknown',
          },
        },
        claim_detail:       {
          type:          'array',
          label:         '',
          array_actions: {
            add_label:    t('wizard.building.claim_history.claim_detail.add_claim'),
            title:        t('wizard.building.claim_history.claim_detail.claim'),
            remove_label: t('general.actions.delete'),
          },
          visible:       {
            property:  'claim_history.claim_history_type',
            assertion: 'equal',
            value:     'Filled_in',
          },
          properties:    {
            claim_type:   {
              component:   'select',
              list:        claimTypeList,
              label:       t('wizard.building.claim_history.claim_detail.claim_type'),
              validations: ['required'],
            },
            claim_year:   {
              component:   'select',
              list:        yearList,
              label:       t('wizard.building.claim_history.claim_detail.claim_year'),
              validations: ['required'],
            },
            claim_amount: {
              component:   'price',
              label:       t('wizard.building.claim_history.claim_detail.claim_amount'),
              validations: ['required', 'only_int_price'],
            },
          },
        },
      },
    },
  };
};
