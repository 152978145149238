import React, { useEffect, useState } from 'react';
import InlineContainer                from 'components/ui/form/inline_container';
import { Field }                      from 'redux-form';
import SelectInput                    from 'components/ui/json_form/fields/select';
import Loader                         from 'components/ui/loader';
import { fetchCarData }               from 'models/car/requests';
import VehicleTable                   from 'components/vehicle/vehicle_table';

const InnerVehicleTableSelector = ({
  vehiclesLoading,
  vehicles,
  selectedVehicle,
  formName,
  selectVehicle,
  febiacMappings,
  carData,
  children,
}) => {

  const [buildYears, setBuildYears] = useState([]);
  const [buildYearsLoading, setBuildYearsLoading] = useState(false);

  const getBuildYears = () => {
    setBuildYearsLoading(true);
    const { build_year, ...restData } = carData;
    fetchCarData('build_years', restData).then((results) => {
      setBuildYears(results.map((item) => ({
        label: item,
        value: item,
      })));
      setBuildYearsLoading(false);
    }).catch(() => {
      setBuildYears([]);
      setBuildYearsLoading(false);
    });
  };

  useEffect(() => {
    getBuildYears();
  }, []);

  return (
    <React.Fragment>
      <InlineContainer
        label={ t('activerecord.attributes.car.build_year') }
      >
        <Field
          name="build_year"
          component={ SelectInput }
          list={ buildYears }
          preventDestroyValueOnUnmount
          loading={ buildYearsLoading }
        />
      </InlineContainer>
      { children }
      { !vehiclesLoading && (
        <VehicleTable
          vehicles={ vehicles }
          selectedVehicle={ selectedVehicle }
          formName={ formName }
          selectVehicle={ (vehicle) => selectVehicle(vehicle, febiacMappings) }
        />
      ) }
      { vehiclesLoading && (
        <Loader />
      ) }
    </React.Fragment>
  );
};

export default InnerVehicleTableSelector;
