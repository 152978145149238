import React                              from 'react';
import { useSelector }                    from 'react-redux';
import { Link }                           from 'react-router-dom';
import { FINISH_FOLDER, trackWizardStep } from 'models/wizard/constants';
import { contractRequestIsLead }          from 'components/wizard_stepper/constants';
import has                                from 'lodash/has';
import update                             from 'lodash/update';
import set                                from 'lodash/set';
import reject                             from 'lodash/reject';
import {
  canHaveSecondaryDriver,
  haveRiskObject,
  haveSelectedMainDriver,
  haveSelectedPolicyOwner,
  isCarContractRequest,
  isNotLead,
  canCreateTarification,
  haveSelectedVehicle,
  haveTarification,
}                                         from 'models/wizard_navigation/constants';
import RiskObjectStep                     from 'components/wizard/risk_object_step';
import TarificationStep                   from 'components/wizard/tarification_step';
import ResumeStep                         from 'components/wizard/resume_step';
import LoadOnClickButton                  from 'components/ui/buttons/load_on_click_button';
import { useHistory }                     from 'react-router';
import { finishDraftLead }                from 'models/leads/request';
import { getPolicyOwnerSchema }           from 'schemas/shared/policy_owner_schema';
import GenericStep                        from 'components/wizard_stepper/generic_step';
import { getPersonSchema }                from 'schemas/mobility/person_schema';
import { getCarSchema }                   from 'schemas/mobility/car_schema';
import { currentUserIsIntermediary }      from 'models/current_user/reducer';
import {
  getVanDesselMazdaImpactedCovers,
  getVanDesselMazdaResponsibilitiesOptions,
}                                         from 'schemas/van_dessel/constants';

export const getMazdaInsuranceSteps = (matchPath, contractRequest, recomputeTarifications) => {
  const carSchema = set(getCarSchema(), 'schema.usage.properties.vehicle_usage.list', [
    {
      value: 'private_and_professional',
      label: t('general.vehicle_usage.private_and_professional'),
      id:    'private_and_professional',
    },
    {
      value: 'professional',
      label: t('general.vehicle_usage.professional'),
      id:    'professional',
    },
  ]);
  let driverSchema = getPersonSchema('main_driver', {
    schema: {
      main_driver: {
        properties_order: [
          'search_in_bms',
          'policy_owner_is_main_driver',
        ],
        properties:       {
          search_in_bms:               {
            component: 'bms',
            prefix:    [
              'main_driver',
            ],
          },
          policy_owner_is_main_driver: {
            label:         t('wizard.policy_owner.policy_owner_is_main_driver'),
            component:     'true_false',
            visible:       {
              property:  'policy_owner.kind',
              assertion: 'equal',
              value:     'person',
            },
            default:       {
              value: true,
            },
            onValueChange: {
              prefill: {
                prefillOnMount:           true,
                from:                     {
                  store: 'policy_owner.person',
                },
                to:                       'main_driver.person',
                clearOnConditionUnfilled: true,
                conditions:               [
                  {
                    operator:  'and',
                    assertion: 'isTrue',
                  },
                  {
                    operator:  'or',
                    property:  'main_driver.person',
                    assertion: 'deepEqual',
                    value:     {
                      property: 'defaultValues.main_driver.person',
                    },
                  },
                ],
              },
            },
          },
        },
      },
      misc:        {
        type:       'section',
        properties: {
          can_have_secondary_driver: {
            label:     t('wizard.driver.has_secondary_driver'),
            component: 'true_false',
            visible:   {
              property:  'contract_request.risk_object.kind',
              assertion: 'notEqual',
              value:     'motorbike',
            },
            default:   {
              value: false,
            },
          },
        },
      },
    },
  });

  update(carSchema, 'schema.vehicle.properties.febiac_form.properties.brand', () => {
    return {
      component:      'text',
      label:          t('activerecord.attributes.car.brand'),
      validations:    ['required'],
      default:        {
        from: 'contract_request.tarifications[0].product.metadata.car_brand',
      },
      componentProps: {
        disabled: true,
      },
    };
  });

  update(carSchema, 'schema.vehicle.properties.febiac_form.properties.date_of_first_circulation.onValueChange.set', (oldValues) => (
    reject(oldValues, (oldValue) => oldValue.to === 'vehicle.brand')
  ));

  driverSchema = set(driverSchema, 'schema.main_driver.properties.person.properties.vehicle_accidents.properties.responsibility.list', getVanDesselMazdaResponsibilitiesOptions());
  driverSchema = set(driverSchema, 'schema.main_driver.properties.person.properties.vehicle_accidents.properties.impacted_cover.list', getVanDesselMazdaImpactedCovers());

  return [
    {
      path:      `${ matchPath }/risk_object`,
      label:     t('wizard.navigation.risk_object'),
      title:     t('wizard.risk_object.title'),
      purpose:   'risk_object_step',
      icon:      contractRequest.risk_object ? contractRequest.risk_object.icon : 'car',
      Component: RiskObjectStep,
      order:     1,
    },
    {
      label:          t('wizard.navigation.about_contract_request'),
      icon:           'file',
      slug:           'contract_request',
      kind:           'group',
      order:          4,
      warningTooltip: ['base', 'car', 'policy_owner', 'main_driver', 'secondary_driver'],
      steps:          [
        {
          path:           `${ matchPath }/policy_owner`,
          disabledRules:  [
            haveRiskObject,
          ],
          title:          t('wizard.policy_owner.title'),
          label:          t('wizard.navigation.policy_owner'),
          icon:           'company',
          Component:      GenericStep,
          purpose:        'policy_owner_step',
          componentProps: {
            ...getPolicyOwnerSchema(),
            onNextStep: {
              recomputeTarifications,
            },
          },
          order:          2,
        },
        {
          path:           `${ matchPath }/main_driver`,
          label:          t('wizard.navigation.main_driver'),
          title:          t('wizard.main_driver.title'),
          warningTooltip: 'main_driver',
          purpose:        'main_driver_step',
          disabledRules:  [
            haveRiskObject,
            haveSelectedPolicyOwner,
          ],
          icon:           'driver',
          Component:      GenericStep,
          componentProps: {
            ...driverSchema,
            onNextStep: {
              recomputeTarifications,
            },
          },
          order:          3,
        },
        {
          path:           `${ matchPath }/secondary_driver`,
          label:          t('wizard.navigation.secondary_driver'),
          title:          t('wizard.secondary_driver.title'),
          warningTooltip: 'secondary_driver',
          purpose:        'secondary_driver_step',
          disabledRules:  [
            haveRiskObject,
            haveSelectedPolicyOwner,
            haveSelectedMainDriver,
          ],
          displayRules:   [
            canHaveSecondaryDriver,
          ],
          icon:           'driver',
          Component:      GenericStep,
          componentProps: {
            ...getPersonSchema('secondary_driver', {
              schema: {
                secondary_driver: {
                  properties_order: [
                    'search_in_bms',
                  ],
                  properties:       {
                    search_in_bms: {
                      component: 'bms',
                      prefix:    [
                        'secondary_driver',
                      ],
                    },
                  },
                },
              },
            }),
            onNextStep: {
              recomputeTarifications,
            },
          },
          order:          4,
        },
        {
          path:           `${ matchPath }/vehicle`,
          label:          t('wizard.navigation.vehicle'),
          title:          t('wizard.car.title'),
          warningTooltip: 'car',
          purpose:        'car_step',
          disabledRules:  [
            haveRiskObject,
            haveSelectedPolicyOwner,
            haveSelectedMainDriver,
          ],
          displayRules:   [
            isCarContractRequest,
          ],
          icon:           'car',
          Component:      GenericStep,
          componentProps: {
            ...carSchema,
            onNextStep: {
              recomputeTarifications,
            },
          },
          order:          5,
        },
      ],
    },
    has(contractRequest, 'tarifications[0]') && {
      path:          `${ matchPath }/tarification_${ contractRequest.tarifications[0].id }`,
      title:         t('axa.constructor.navigation.tarification'),
      order:         9,
      disabledRules: [
        haveRiskObject,
        haveSelectedPolicyOwner,
        haveSelectedMainDriver,
        haveSelectedVehicle,
      ],
      displayRules:  [
        isNotLead,
        canCreateTarification,
      ],
      label:         t('axa.constructor.navigation.tarification'),
      icon:          'product-parameters',
      error:         contractRequest.tarifications[0].last_error_response,
      loading:       contractRequest.tarifications[0].computing,
      purpose:       'axa_building_tarification_step',
      Component:     (props) => (
        <TarificationStep { ...props } tarificationId={ contractRequest.tarifications[0].id } />
      ),
    },
    {
      path:           `${ matchPath }/resume`,
      title:          t('wizard.navigation.resume'),
      disabledRules:  [
        haveRiskObject,
        haveSelectedPolicyOwner,
        haveSelectedMainDriver,
        haveSelectedVehicle,
        haveTarification,
      ],
      label:          t('wizard.navigation.resume'),
      icon:           'folder',
      purpose:        'resume_step',
      Component:      ResumeStep,
      componentProps: {
        subscriptionRequired: false,
      },
      order:          10,
      StepMainAction: () => {
        const isLead = useSelector((state) => contractRequestIsLead(state.wizard));
        const isIntermediary = useSelector((state) => currentUserIsIntermediary(state));
        const contractRequestId = useSelector((state) => state.wizard.contract_request.id);
        const history = useHistory();
        return (
          <React.Fragment>
            { !isLead && !isIntermediary && (
              <Link
                className="wizard-button primary"
                to="/folders"
                data-purpose="finish_button"
                onClick={ () => {
                  trackWizardStep(FINISH_FOLDER, {
                    contract_request_id: contractRequestId,
                  });
                } }
              >
                { t('general.actions.finish') }
              </Link>
            ) }
            { isLead && isIntermediary && (
              <LoadOnClickButton
                onClick={ () => finishDraftLead(contractRequestId).then(() => {
                  history.push('/leads');
                }) }
                primary
                large
                data-purpose="finish_button"
              >
                { t('leads.send_lead') }
              </LoadOnClickButton>
            ) }
          </React.Fragment>
        );
      },
    },
  ];
};

export default getMazdaInsuranceSteps;
