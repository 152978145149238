import React  from 'react';
import moment from 'moment';

const OfferInformations = ({ offer, tarification }) => {
  if (!offer) {
    return null;
  }
  const { requested_at, signed_at } = offer;
  if (!requested_at && !signed_at) {
    return null;
  }
  return (
    <div className="global-message info p-20">
      { tarification && tarification.last_simulated_at && (
        <p className="uk-text-center mb-0">{ t('distributor.components.offers.offer_informations.tarification_updated_at', { date: moment(tarification.last_simulated_at).format('DD/MM/YYYY - HH:mm') }) }</p>
      ) }
      { requested_at && (
        <p className="uk-text-center mb-0">{ t('distributor.components.offers.offer_informations.requested_at', { date: moment(requested_at).format('DD/MM/YYYY - HH:mm') }) }</p>
      ) }
      { signed_at && (
        <p className="uk-text-center mt-20 mb-0">{ t('distributor.components.offers.offer_informations.signed_at', { date: moment(signed_at).format('DD/MM/YYYY - HH:mm') }) }</p>
      ) }
    </div>
  );
};

export default OfferInformations;
