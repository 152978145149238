import React, { useState } from 'react';
import Icon                from 'components/ui/icon';

const IEAlert = () => {
  const [ready, toggleReady] = useState(false);
  setTimeout(() => {
    toggleReady(true);
  }, 1000); // I18njs not correctly set up with default locale
  const ua = window.navigator.userAgent;
  const isIE = /MSIE|Trident/.test(ua);
  const [showAlert, toggleShowAlert] = useState(isIE);

  if (!ready || !isIE) {
    return null;
  }
  return (
    <div className={ `ie-alert ${ showAlert ? 'show' : '' }` }>
      { !showAlert && (
        <p className="ie-small-alert" onClick={ () => toggleShowAlert(true) }>
          <Icon icon="warning" />
          { t('general.browser_alert.title') }
        </p>
      ) }
      <Icon icon="warning" />
      <div className="text">
        <h5>{ t('general.browser_alert.title') }</h5>
        <p>
          { t_html('general.browser_alert.text_html') }
        </p>
      </div>
      <div className="other-browser-button">
        { t_html('general.browser_alert.use_chrome_html') }
      </div>
      <button
        type="button"
        className="close-alert"
        onClick={ () => toggleShowAlert(false) }
      >
        { t('general.actions.close') }
      </button>
    </div>
  );
};

export default IEAlert;
