export const getAxaBuildingPreviousContractSchema = () => {
  return {
    previous_contract: {
      type:       'section',
      title:      t('wizard.building.previous_contract.title'),
      properties: {
        is_previously_insured:     {
          component:   'true_false',
          validations: ['required'],
          label:       t('wizard.building.previous_contract.is_previously_insured'),
          default:     {
            value: false,
          },
        },
        is_contract_available:     {
          component:   'true_false',
          validations: ['required'],
          label:       t('wizard.building.previous_contract.is_contract_available'),
          visible:     {
            property:  'previous_contract.is_previously_insured',
            assertion: 'isTrue',
          },
        },
        previous_contract_details: {
          type:       'section',
          properties: {
            premium_rate:                         {
              component:   'text',
              type:        'number',
              validations: ['required', 'maxLength6'],
              step:        '0.01',
              label:       t('wizard.building.previous_contract.previous_contract_details.premium_rate'),
              default:     {
                value: 1,
              },
            }
          },
          visible:    [
            {
              property:  'previous_contract.is_previously_insured',
              assertion: 'isTrue',
            },
            {
              property:  'previous_contract.is_contract_available',
              assertion: 'isFalse',
            },
          ],
        },
        upload_description:        {
          component: 'message',
          className: 'global-message info uk-width-1-1',
          message:   t('wizard.building.previous_contract.previous_contract_upload_description'),
          visible:   [
            {
              property:  'previous_contract.is_previously_insured',
              assertion: 'isTrue',
            },
            {
              property:  'previous_contract.is_contract_available',
              assertion: 'isTrue',
            },
          ],
        },
      },
    },
  };
};
