export const getViviumContentCoverSchema = () => ({
  form:   'vivium_building_content_cover',
  schema: {
    content_cover: {
      type:       'section',
      properties: {
        content_cover_asked: {
          label:     'Couverture du contenu',
          component: 'true_false',
        },
        content_description: {
          type:       'section',
          visible: {
            property:  'content_cover.content_cover_asked',
            assertion: 'isTrue',
          },
          properties: {
            content:    {
              label:     'Capital contenu',
              component: 'price',
            },
            first_risk: {
              label:     'Premier risque',
              component: 'true_false',
            },
          },
        },
        theft_and_vandalism: {
          type:       'section',
          visible: {
            property:  'content_cover.content_cover_asked',
            assertion: 'isTrue',
          },
          properties: {
            occupation:      {
              label:     'Occupation',
              component: 'select',
              list:      [
                {
                  value: 'regular_occupation',
                  label: 'Occupation régulière',
                },
                {
                  value: 'irregular_occupation',
                  label: 'Occupation irrégulière',
                },
              ],
            },
            content_covered: {
              label:     'Couverture du contenu',
              component: 'select',
              list:      [
                {
                  value: 'up_to_100',
                  label: 'Jusque 100%',
                },
                {
                  value: 'up_to_50',
                  label: 'Jusque 50%',
                },
              ],
            },
            means_of_protection: {
              label:     'Moyens de protection',
              component: 'select',
              list:      [
                {
                  value: 'no_protections',
                  label: 'Pas de protection particulière',
                },
                {
                  value: 'incert_protections',
                  label: 'Protection Incert',
                },
              ],
            },
          },
        },
      },
    },
  },
});
