import React                                                  from 'react';
import compact                                                from 'lodash/compact';
import {
  haveSelectedPolicyOwner,
}                                                             from 'models/wizard_navigation/constants';
import ResumeStep                                             from 'components/wizard/resume_step';
import GenericStep                                            from 'components/wizard_stepper/generic_step';
import {
  viviumHousingSchema,
}                                                             from 'schemas/vivium/housing/vivium_housing_schema';
import { getPolicyOwnerSchema, getPolicyOwnerSchemaFiltered } from 'schemas/shared/policy_owner_schema';
import { getTarificationSteps }                               from 'schemas/shared/tarification_steps';
import { isCrLead }                                           from 'models/contract_request/constants';

export const getViviumHousingSteps = (matchPath, contractRequest, recomputeTarifications) => {
  const policyOwnerSchema = getPolicyOwnerSchemaFiltered(isCrLead(contractRequest));
  return compact([
    {
      label: t('wizard.navigation.about_contract_request'),
      icon:  'file',
      slug:  'contract_request',
      order: 1,
      kind:  'group',
      steps: [
        {
          path:           `${ matchPath }/policy_owner`,
          title:          t('wizard.policy_owner.title'),
          label:          t('wizard.navigation.policy_owner'),
          icon:           'company',
          Component:      GenericStep,
          purpose:        'policy_owner',
          order:          2,
          componentProps: {
            ...policyOwnerSchema,
            onNextStep: {
              recomputeTarifications,
            },
          },
        },
        {
          path:           `${ matchPath }/housing`,
          disabledRules:  [
            haveSelectedPolicyOwner,
          ],
          title:          t('wizard.navigation.building'),
          order:          3,
          label:          t('wizard.navigation.building'),
          icon:           contractRequest.risk_object.icon,
          Component:      GenericStep,
          purpose:        'housing',
          componentProps: {
            ...viviumHousingSchema(),
            onNextStep: {
              recomputeTarifications,
            },
          },
        },
      ],
    },
    getTarificationSteps(contractRequest, matchPath, [
      haveSelectedPolicyOwner,
    ], [], 3),
    {
      path:          `${ matchPath }/resume`,
      title:         t('wizard.navigation.resume'),
      disabledRules: [
        haveSelectedPolicyOwner,
      ],
      label:         t('wizard.navigation.resume'),
      icon:          'folder',
      Component:     ResumeStep,
    },
  ]);
};
