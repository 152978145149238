import React            from 'react';
import { fieldWrapper } from 'components/ui/json_form/fields/field_wrapper';
import JsonFormField    from 'components/ui/json_form/json_form_field';

const JsonTableField = (props) => {
  const { table_columns, properties, formName, change } = props;
  const rows = Object.keys(properties);
  return (
    <table className="uk-table uk-table-divider uk-table-small">
      <thead>
        <tr>
          <th />
          { table_columns.map((column) => (
            <th
              key={ column.name }
              className="uk-text-center"
            >
              { column.label }
            </th>
          )) }
        </tr>
      </thead>
      <tbody>
        { rows.map((row) => (
          <tr key={ row }>
            <td>
              <label>
                { properties[row].label }
              </label>
            </td>
            { table_columns.map((column) => (
              <td className="uk-text-center" key={ `${ row }_${ column.name }` }>
                <JsonFormField
                  field={ properties[row] }
                  form={ formName }
                  change={ change }
                  fieldKey={ row }
                  namePrefix={ column.name }
                />
              </td>
            )) }
          </tr>
        )) }
      </tbody>
    </table>
  );
};

export default fieldWrapper()(JsonTableField);
