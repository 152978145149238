import { setContractRequestAuthorizations } from 'models/wizard/store_actions';
import React                                from 'react';
import { useDispatch, useSelector }         from 'react-redux';
import { updateTarificationAndWarnings }    from 'models/wizard/dispatch_actions';
import ApplicationActionCableConsumer       from 'components/application/action_cable_consumer';

const TarificationSocket = () => {
  const dispatch = useDispatch();

  const tarifications = useSelector((state) => state.wizard.contract_request.tarifications);
  const currentUser = useSelector((state) => state.current_user.profile);

  const handleReceived = (data) => {
    if (data.requester_id !== currentUser.id) {
      return;
    }
    dispatch(updateTarificationAndWarnings(data.tarification, tarifications));
    if (data.contract_request_authorizations) {
      dispatch(setContractRequestAuthorizations(data.contract_request_authorizations.authorizations));
    }
  };

  return <ApplicationActionCableConsumer channel="TarificationsChannel" onReceived={ handleReceived } />;
};

export default TarificationSocket;
