import { withRouter }      from 'react-router';
import React               from 'react';
import snakeCase           from 'lodash/snakeCase';
import Loader              from 'components/ui/loader';
import Icon                from 'components/ui/icon';
import ArchiveFolderButton from 'components/folder/archive_folder_button';
import ProductTinyList     from 'components/product/product_tiny_list';
import Status              from 'components/status';

const Table = withRouter(({
                            columns, data, history, loading, sortedBy, sortDirection, sortBy, onArchived, statuses,
                          }) => {
  return (
    <React.Fragment>
      <table
        className="uk-table uk-table-small uk-table-justify uk-table-divider uk-table-hover folder-table"
      >
        <thead>
        <tr>
          { columns.map(col => (
            <th
              key={ snakeCase(col.label) }
              { ...col.htmlAttrs }
              onClick={ () => {
                if (col.sortKey) {
                  sortBy(col.sortKey);
                }
              } }
            >
              { col.label }
              { sortedBy === col.sortKey && (
                <React.Fragment>
                  { sortDirection === 'asc' && (<Icon icon="arrow-sort-up" />) }
                  { sortDirection === 'desc' && (<Icon icon="arrow-sort-down" />) }
                </React.Fragment>
              ) }
            </th>
          )) }
          <th className="uk-table-shrink" />
        </tr>
        </thead>
        { !loading && (
          <tbody key={ loading }>
          { data.map((folder) => (
            <tr
              key={ folder.link }
              onClick={ () => history.push(folder.link) }
            >
              { columns.map((col) => {
                return (
                  <td
                    key={ `${ folder.id }_${ snakeCase(col.label) }` }
                    { ...col.htmlAttrs }
                  >
                    { col.accessor === 'products' && (
                      <ProductTinyList products={ folder[col.accessor] } />
                    ) }
                    { col.accessor !== 'products' && col.accessor !== 'status' && (
                      folder[col.accessor]
                    ) }
                    { col.accessor === 'status' && (
                      <Status status={ folder[col.accessor] } statuses={ statuses } />
                    ) }
                  </td>
                );
              }) }
              <td>
                { folder.authorizations.archive && (
                  <ArchiveFolderButton folderId={ folder.id } onArchived={ onArchived } />
                ) }
              </td>
            </tr>
          )) }
          </tbody>
        ) }
        { loading && (
          <tbody key={ loading }>
          <tr>
            <td
              className="uk-text-center"
              colSpan={ columns.length }
            >
              <Loader />
            </td>
          </tr>
          </tbody>
        ) }
      </table>
    </React.Fragment>
  );
});

export default Table;
