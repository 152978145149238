import React, { useState, useEffect } from 'react';
import { useDispatch }                from 'react-redux';
import Loader                         from 'components/ui/loader';
import { loadAndInitContractRequest } from 'models/wizard/dispatch_actions';
import { setDefaultLeadCr }           from 'models/wizard/store_actions';
import { useParams }                  from 'react-router';
import NotFound                       from 'components/ui/errors/404_not_found';

const wizardWithContractRequest = () => (Component) => {
  return (props) => {
    const [loading, toggleLoading] = useState(true);
    const [errorStatusCode, setErrorStatusCode] = useState(null);
    const dispatch = useDispatch();

    const { folder_id, lead_id } = useParams();
    const checkForCr = () => {
      return new Promise((resolve, reject) => {
        if (folder_id === 'new' || lead_id === 'new') {
          if (lead_id) {
            return resolve(dispatch(setDefaultLeadCr()));
          }
          return resolve();
        }
        return resolve(dispatch(loadAndInitContractRequest(parseInt(folder_id || lead_id))));
      });
    };
    useEffect(() => {
      checkForCr().then(() => {
        toggleLoading(false);
      }).catch((e) => {
        if (e.status) {
          setErrorStatusCode(e.status);
        }
        toggleLoading(false);
      });
    }, []);

    if (loading) {
      return <Loader />;
    }
    if (errorStatusCode) {
      if (errorStatusCode === 404) {
        return <NotFound />;
      }
      if (errorStatusCode === 403) {
        return <NotFound />;
      }
    }
    return <Component { ...props } />;
  };
};

export default wizardWithContractRequest;
