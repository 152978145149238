export const getViviumBuildingEvaluationApartmentsGridSchema = () => {
  return {
    apartments: {
      type:          'array',
      label:         t('vivium.evaluation.room_grid.apartment_description'),
      array_actions: {
        max:          4,
        add_label:    t('vivium.evaluation.room_grid.add_apartment'),
        title:        t('vivium.evaluation.room_grid.apartment'),
        remove_label: t('vivium.evaluation.room_grid.delete'),
      },
      visible:       {
        property:  'evaluation.evaluation_method',
        assertion: 'equal',
        value:     'p_v_number_of_rooms_grid',
      },
      properties:    {
        living_count:              {
          label:       t('vivium.evaluation.room_grid.living_count'),
          component:   'text',
          type:        'number',
          validations: ['required'],
        },
        bedroom_count:             {
          label:       t('vivium.evaluation.room_grid.bedroom_count'),
          component:   'text',
          type:        'number',
          validations: ['required'],
        },
        office_count:              {
          label:       t('vivium.evaluation.room_grid.office_count'),
          component:   'text',
          type:        'number',
          validations: ['required'],
        },
        gaming_room_count:         {
          label:       t('vivium.evaluation.room_grid.gaming_room_count'),
          component:   'text',
          type:        'number',
          validations: ['required'],
          default:     {
            value: 0,
          },
        },
        wellness_room_count:       {
          label:       t('vivium.evaluation.room_grid.wellness_room_count'),
          component:   'text',
          type:        'number',
          validations: ['required'],
          default:     {
            value: 0,
          },
        },
        dressing_count:            {
          label:       t('vivium.evaluation.room_grid.dressing_count'),
          component:   'text',
          type:        'number',
          validations: ['required'],
          default:     {
            value: 0,
          },
        },
        veranda_count:             {
          label:       t('vivium.evaluation.room_grid.veranda_count'),
          component:   'text',
          type:        'number',
          validations: ['required'],
          default:     {
            value: 0,
          },
        },
        professional_office_count: {
          label:       t('vivium.evaluation.room_grid.professional_office_count'),
          component:   'text',
          type:        'number',
          validations: ['required'],
          default:     {
            value: 0,
          },
        },
        garage_place_count:        {
          label:       t('vivium.evaluation.room_grid.garage_place_count'),
          component:   'text',
          type:        'number',
          validations: ['required'],
          default:     {
            value: 0,
          },
        },
        utility_room_count:        {
          label:       t('vivium.evaluation.room_grid.utility_room_count'),
          component:   'text',
          type:        'number',
          validations: ['required'],
          default:     {
            value: 0,
          },
        },
        other_room_count:          {
          label:       t('vivium.evaluation.room_grid.other_room_count'),
          component:   'text',
          type:        'number',
          validations: ['required'],
          default:     {
            value: 0,
          },
        },
        kitchen_count:             {
          label:       t('vivium.evaluation.room_grid.kitchen_count'),
          component:   'text',
          type:        'number',
          validations: ['required'],
          default:     {
            value: 0,
          },
        },
        warning_message:           {
          component: 'message',
          className: 'global-message danger p-20',
          message:   t('vivium.evaluation.room_grid.warning_message'),
          visible:   {
            total_of_properties: [
              'evaluation.arrayFieldName.living_count',
              'evaluation.arrayFieldName.bedroom_count',
              'evaluation.arrayFieldName.office_count',
              'evaluation.arrayFieldName.gaming_room',
              'evaluation.arrayFieldName.wellness_room_count',
              'evaluation.arrayFieldName.dressing_count',
              'evaluation.arrayFieldName.veranda_count',
              'evaluation.arrayFieldName.professional_office_count',
              'evaluation.arrayFieldName.garage_place_count',
              'evaluation.arrayFieldName.utility_room_count',
              'evaluation.arrayFieldName.other_room_count',
              'evaluation.arrayFieldName.kitchen_count',
            ],
            assertion:           'isAbove',
            value:               13,
          },
        },
      },
    },
    finishing:       {
      type:       'section',
      title:      t('vivium.finishing.title'),
      properties: {
        fitted_cellar: {
          label:     t('vivium.finishing.fitted_cellar'),
          component: 'true_false',
        },
        outside_pool:  {
          label:     t('vivium.finishing.outside_pool'),
          component: 'true_false',
        },
        passive_house: {
          label:     t('vivium.finishing.passive_house'),
          component: 'true_false',
        },
        solar_panels:  {
          label:     t('vivium.finishing.solar_panels'),
          component: 'true_false',
        },
        annex:         {
          label:     t('vivium.finishing.annex'),
          component: 'true_false',
        },
      },
      visible:       {
        property:  'evaluation.evaluation_type',
        assertion: 'equal',
        value:     'p_v_number_of_rooms_grid',
      },
    },
  };
};
