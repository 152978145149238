import React   from 'react';
import compact from 'lodash/compact';
import flatten from 'lodash/flatten';
import openDialogOnClick
               from 'components/ui/buttons/helpers/open_dialog_on_click';

import DocumentsToPrintForm
                               from 'components/tarifications/documents_to_print_form';
import Button                  from 'components/ui/buttons/button';
import DialogContext           from 'components/ui/buttons/helpers/dialog_context';
import { getTarificationsPdf } from 'models/tarifications/requests';

const PrintButton = ({ onClick, disabled, className, docx }) => {
  return (
    <Button
      outline
      small
      disabled={ disabled }
      className={ `mr-10 ${ className }` }
      data-purpose="print_selection_button"
      onClick={ () => {
        onClick();
      } }
      secondary
      icon={ docx ? 'answer' : 'print' }
    >
      { t('general.actions.print_selection') }
    </Button>
  );
};

const PrintDialogButton = openDialogOnClick()(PrintButton);

const PrintTarificationButton = ({ tarifications, disabled, className, contractRequest, ...rest }) => {
  const documentsToAttach = compact(flatten(tarifications.map((tarif) => {
    return tarif.documents;
  })));

  const printTarification = ({ documents }) => {
    return getTarificationsPdf(contractRequest.id, compact(documents).filter(doc => doc.selected).map(d => d.id), tarifications);
  };

  if (documentsToAttach.length > 0) {
    return (
      <PrintDialogButton
        disabled={ (disabled || !tarifications.length) }
        dialogTitle={ t('wizard.resume.print.additionnal_documents') }
        className={ className }
      >
        <DialogContext.Consumer>
          { ({ closeDialog }) => (
            <DocumentsToPrintForm
              contractRequestId={ contractRequest.id }
              tarifications={ tarifications }
              onPrinted={ closeDialog }
            />
          ) }
        </DialogContext.Consumer>
      </PrintDialogButton>
    );
  }

  return (
    <PrintButton
      onClick={ () => printTarification({ documents: [] }) }
      disabled={ disabled || !tarifications.length }
    />
  );
};

export default PrintTarificationButton;
