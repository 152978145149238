import every                              from 'lodash/every';
import compact                            from 'lodash/compact';
import reject                             from 'lodash/reject';
import {
  updateTarification,
  removeTarification,
  setContractRequest,
  setRequiredFields,
  setTarifications,
  setContractRequestLoading,
  setWarningsFromTarifications,
  setRequiredFieldsLoading,
}                                         from 'models/wizard/store_actions';
import {
  createContractRequest,
  fetchContractRequest,
  updateContractRequest,
}                                         from 'models/contract_request/requests';
import { getRequiredFieldsForProducts }   from 'models/wizard/requests';
import {
  deleteTarification,
  fetchTarification,
  recomputeTarification,
  resetTarification,
  updateTarification as fetchTarificationUpdate,
  updateTarificationContract,
  updateTarificationOffer,
}                                         from 'models/tarifications/requests';
import { setNavigation }                  from 'models/wizard_stepper/store_actions';
import { getProductsFromContractRequest } from 'models/wizard/constants';

export const updateWizardNavigation = (contract_request, selectedProducts) => {
  return dispatch => {
    if (window['wizardNavigation']) {
      const selected_products = getProductsFromContractRequest(contract_request) || [];
      const wizardNavigation = window['wizardNavigation'].generate({
        contract_request,
        selected_products: selectedProducts || selected_products,
      });
      dispatch(setNavigation(wizardNavigation.navigation));
    }
  };
};

export const getRequiredFieldsAndUpdateInStore = (contractRequestId) => {
  return async (dispatch) => {
    dispatch(setRequiredFieldsLoading(true));
    try {
      const requiredFields = await getRequiredFieldsForProducts(contractRequestId);
      dispatch(setRequiredFields(requiredFields));
    } catch (e) {
      log('got error while fetching requiredFields', e);
    }
    dispatch(setRequiredFieldsLoading(false));
  };
};

const updateContractRequestWithSelectedCovers = async (contractRequestId, selectedCovers, covers) => {
  const updatedCr = await updateContractRequest(contractRequestId, {
    selected_cover_categories:                   covers.filter((cover) => selectedCovers[cover]),
    product_configuration_formula:               selectedCovers.product_configuration_formula,
    product_configuration_deductible_type:       selectedCovers.product_configuration_deductible_type,
    product_configuration_deductible_amount:     selectedCovers.product_configuration_deductible_amount,
    product_configuration_derogation_percentage: selectedCovers.product_configuration_derogation_percentage,
    product_configuration_degression:            selectedCovers.product_configuration_degression,
    payment_staggering:                          selectedCovers.payment_staggering,
  }, { includes: 'customer' });
  return updatedCr;
};

export const updateContractRequestPreferences = (contractRequestId, selectedCovers, covers) => {
  return async (dispatch) => {
    const { contract_request: updatedContractRequest } = await updateContractRequestWithSelectedCovers(contractRequestId, selectedCovers, covers);
    dispatch(setContractRequest(updatedContractRequest));
    await dispatch(getRequiredFieldsAndUpdateInStore(contractRequestId));
  };
};

export const refreshWizardTarification = (tarification) => {
  return async (dispatch) => {
    recomputeTarification(tarification.id).then((newTarification) => {
      dispatch(updateTarification(newTarification));
    });
  };
};

export const updateWizardTarification = (tarification) => {
  return async (dispatch) => {
    fetchTarificationUpdate(tarification).then((newTarification) => {
      dispatch(updateTarification(newTarification));
    });
  };
};

export const updateWizardTarificationOffer = (tarificationId, offerData) => {
  return async (dispatch) => {
    return updateTarificationOffer(tarificationId, offerData).then((newTarification) => {
      dispatch(updateTarification(newTarification));
    });
  };
};

export const updateWizardTarificationContract = (tarificationId, contractData) => {
  return (dispatch) => {
    return updateTarificationContract(tarificationId, contractData).then((newTarification) => {
      dispatch(updateTarification(newTarification));
    });
  };
};

export const resetWizardTarification = (tarificationId) => {
  return async (dispatch) => {
    resetTarification(tarificationId).then((newTarification) => {
      dispatch(updateTarification(newTarification));
    });
  };
};

export const updateTarificationAndWarnings = (tarificationToUpdate, tarifications = []) => {
  const tarifsWithUpdated = tarifications.map((tarif) => {
    if (tarif.id === tarificationToUpdate.id) {
      return tarificationToUpdate;
    }
    return tarif;
  });
  return (dispatch) => {
    dispatch(updateTarification(tarificationToUpdate));
    dispatch(setWarningsFromTarifications(tarifsWithUpdated));
  };
};

export const loadAndInitContractRequest = (contractRequestId) => {
  return (dispatch) => {
    dispatch(setContractRequestLoading(true));
    return new Promise((resolve, reject) => {
      fetchContractRequest(contractRequestId).then(({ contract_request: existingContractRequest }) => {
        const { tarifications } = existingContractRequest;
        if (existingContractRequest.selected_cover_categories) {
          existingContractRequest.selected_cover_categories.map((selected_cover_category) => {
            existingContractRequest[selected_cover_category] = true;
          });
        }
        dispatch(setContractRequest(existingContractRequest));
        dispatch(updateWizardNavigation(existingContractRequest));
        dispatch(setContractRequestLoading(false));
        if (tarifications.length > 0) {
          try {
            dispatch(getRequiredFieldsAndUpdateInStore(contractRequestId));
            dispatch(setTarifications(tarifications));
            dispatch(setWarningsFromTarifications(tarifications));
          } catch (e) {
            reject(e);
          }
        }
        resolve();
      }).catch((e) => {
        reject(e);
      });
    });
  };
};

export const deleteTarificationsAndUpdateWarnings = (tarificationsToRemove, tarifications, contractRequest) => {
  return (dispatch) => {
    try {
      let tarificationsToKeep = tarifications;
      const removePromises = tarificationsToRemove.map(async (tarificationToRemove) => {
        tarificationsToKeep = reject(tarificationsToKeep, tarif => tarificationToRemove.id === tarif.id);
        await dispatch(removeTarification(tarificationToRemove));
        await deleteTarification(tarificationToRemove);
      });
      dispatch(getRequiredFieldsAndUpdateInStore(contractRequest.id));
      dispatch(setWarningsFromTarifications(tarificationsToKeep));
      return Promise.all(removePromises);
    } catch (e) {
      log('got er on deleteTarificationsAndUpdateWarnings', e);
    }
  };
};

export const updateProductsAndRequiredFields = (contractRequest, selectedProducts, tarifications) => {
  return (dispatch) => {
    const everySelectedProductHasATarification = every(selectedProducts, (product) => {
      return tarifications.find((tarif) => tarif.product.id === product.id);
    });

    const tarificationWithoutSelectedProducts = compact(tarifications.map((tarif) => {
      if (selectedProducts.findIndex((selectedProduct) => selectedProduct.id === tarif.product.id) === -1) {
        return tarif;
      }
    }));

    if (tarificationWithoutSelectedProducts.length > 0) {
      dispatch(deleteTarificationsAndUpdateWarnings(tarificationWithoutSelectedProducts, tarifications, contractRequest));
    }

    if (everySelectedProductHasATarification) {
      return Promise.resolve();
    }

    return new Promise(async (resolve) => {
      dispatch(getRequiredFieldsAndUpdateInStore(contractRequest.id));
      return resolve();
    });
  };
};

export const initOrUpdateContractRequest = (contractRequestToInit, selectedProducts = []) => {
  return (dispatch) => {
    return new Promise(async (resolve, reject) => {
      try {
        let contractRequest = null;
        if (contractRequestToInit.id) {
          const { contract_request } = await updateContractRequest(contractRequestToInit.id, {
            ...contractRequestToInit,
            product_ids: selectedProducts.map((product) => product.id),
          });
          contractRequest = contract_request;
        } else {
          const { contract_request } = await createContractRequest(contractRequestToInit, selectedProducts);
          contractRequest = contract_request;
        }
        dispatch(getRequiredFieldsAndUpdateInStore(contractRequest.id));
        dispatch(setContractRequest(contractRequest));
        dispatch(updateWizardNavigation(contractRequest));
        resolve(contractRequest);
      } catch (e) {
        log('did catch error', e);
        reject(e);
      }
    });
  };
};

export const updateContractRequestAndUpdateInStore = (contractRequestToUpdate) => {
  return (dispatch) => {
    return new Promise(async (resolve, reject) => {
      try {
        const { contract_request } = await updateContractRequest(contractRequestToUpdate.id, contractRequestToUpdate);
        dispatch(setContractRequest(contract_request));
        dispatch(getRequiredFieldsAndUpdateInStore(contract_request.id));
        dispatch(updateWizardNavigation(contract_request));
        resolve(contract_request);
      } catch (e) {
        console.error('got error while updating cr', e);
        reject(e);
      }
    });
  };
};

export const setPollingForTarifications = (tarifications, callback) => {
  return (dispatch) => {
    tarifications.forEach((tarif) => {
      const interval = setInterval(() => {
        fetchTarification(tarif.id).then(({ tarification }) => {
          if (!tarification.computing) {
            dispatch(updateTarificationAndWarnings(tarification, tarifications));
            clearInterval(interval);
            if (callback) {
              callback(tarification);
            }
          }
        });
      }, 1000);
    });
  };
};

export const fetchContractRequestAndUpdateInStore = (contractRequestId) => {
  return (dispatch) => {
    fetchContractRequest(contractRequestId).then(({ contract_request }) => {
      dispatch(setContractRequest(contract_request));
    });
  };
};
