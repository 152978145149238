import React, { useEffect, useState }         from 'react';
import { useDispatch, useSelector }           from 'react-redux';
import { submit }                             from 'redux-form';
import isNil                                  from 'lodash/isNil';
import asWizardStep                           from 'components/wizard_stepper/as_wizard_step';
import {
  updateContractRequestAndUpdateInStore, updateWizardTarificationOffer,
}                                             from 'models/wizard/dispatch_actions';
import {
  UPDATE_CONTRACT_REQUEST,
  trackWizardStep,
}                                             from 'models/wizard/constants';
import JsonForm                               from 'components/ui/json_form/json_form';
import { flattenKeys }                        from 'services/object';
import $                                      from 'jquery';
import JsonFormErrors                         from 'components/ui/json_form/json_form_errors';
import { prepareDataToUpdateContractRequest } from 'models/contract_request/constants';

let nextStepOverride = null;
const GenericStep = (props) => {
  const [formErrors, setErrors] = useState([]);
  const dispatch = useDispatch();
  const contractRequest = useSelector((state) => state.wizard.contract_request);
  const {
          nextStep,
          setNextLoading,
          setNextAction,
          onNextStep,
          form,
          schema,
          formProps     = {},
          initialValuesOverride,
          disableStep,
          stepFor       = 'contract_request',
          initialValues = null,
        } = props;

  const updateContractRequest = (contractRequestToUpdate) => {
    trackWizardStep(UPDATE_CONTRACT_REQUEST, { contract_request_id: contractRequestToUpdate.id });
    setNextLoading(true);
    if (contractRequestToUpdate) {
      if (!contractRequestToUpdate.authorizations.update) {
        setNextLoading(false);
        return Promise.resolve();
      }
      return dispatch(updateContractRequestAndUpdateInStore(contractRequestToUpdate))
        .then(() => {
          setNextLoading(false);
          if (onNextStep.recomputeTarifications) {
            onNextStep.recomputeTarifications();
          }
        })
        .catch(() => {
          setNextLoading(false);
        });
    }
  };

  const updateOffer = (offerData) => {
    setNextLoading(true);
    return dispatch(updateWizardTarificationOffer(contractRequest.tarifications[0].id, offerData))
      .then(() => {
        setNextLoading(false);
        if (onNextStep.recomputeTarifications) {
          onNextStep.recomputeTarifications();
        }
      })
      .catch(() => {
        setNextLoading(false);
      });
  };

  const onSubmit = (data) => {
    let request = null;
    if (stepFor === 'contract_request') {
      request = updateContractRequest(prepareDataToUpdateContractRequest(data, contractRequest), contractRequest.id);
    } else if (stepFor === 'offer') {
      request = updateOffer(data);
    } else {
      return null;
    }
    request.then(() => {
      if (nextStepOverride && !isNil(nextStepOverride) && typeof nextStepOverride !== 'string') {
        return nextStepOverride();
      }
      nextStep();
    });
  };

  const submitForm = () => {
    dispatch(submit(form));
  };

  useEffect(() => {
    setNextAction((onNextStepOverride) => {
      if (onNextStepOverride) {
        nextStepOverride = onNextStepOverride;
      }
      return submitForm();
    });
  }, []);

  return (
    <React.Fragment>
      <JsonForm
        form={ form }
        schema={ schema }
        disabled={ !isNil(disableStep) ? disableStep : !contractRequest.authorizations.update }
        initialValues={ initialValues || contractRequest[initialValuesOverride || 'data'] }
        onSubmit={ onSubmit }
        onSubmitFail={ (errors) => {
          if (!errors) {
            setErrors([]);
            return;
          }
          setTimeout(() => {
            const errorsByPath = flattenKeys(errors);
            const allErrors = Object.keys(errorsByPath).map((path) => {
              const $item = $(`[data-error="${ path }"]`);
              return {
                path,
                $item,
                label:   $item.data('error-label'),
                message: $item.text(),
              };
            });
            if (allErrors && allErrors.length > 0) {
              setErrors(allErrors);
            }
            const firstError = Object.keys(errorsByPath)[0];
            const target = $(`[data-error="${ firstError }"]`);
            if (!target[0]) {
              return;
            }
            $([document.documentElement, document.body]).animate({
              scrollTop: target.offset().top - 150,
            }, 500);
          }, 1000);
        } }
        { ...formProps }
      />
      { formErrors.length > 0 && (
        <JsonFormErrors errors={ formErrors } />
      ) }
    </React.Fragment>
  );
};

export default asWizardStep(true)(GenericStep);
