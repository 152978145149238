import React                    from 'react';
import compact                  from 'lodash/compact';
import { useSelector }          from 'react-redux';
import StepList                 from 'components/wizard_stepper/step_list';
import ResumeStep               from 'components/wizard/resume_step';
import GenericStep              from 'components/wizard_stepper/generic_step';
import { getPolicyOwnerSchema } from 'schemas/shared/policy_owner_schema';

import {
  haveSelectedPolicyOwner,
} from 'models/wizard_navigation/constants';
import TarificationStep
  from 'components/wizard/tarification_step';

export const getGenericSteps = (matchPath, contractRequest) => (
  compact([
    {
      label: t('wizard.navigation.about_contract_request'),
      icon:  'file',
      slug:  'contract_request',
      kind:  'group',
      order: 1,
      steps: [
        {
          path:           `${ matchPath }/policy_owner`,
          title:          t('wizard.policy_owner.title'),
          label:          t('wizard.navigation.policy_owner'),
          icon:           'company',
          Component:      GenericStep,
          order:          2,
          purpose:        'policy_owner_step',
          componentProps: {
            ...getPolicyOwnerSchema(),
          },
        },
      ],
    },
    {
      path:           `${ matchPath }/tarification_${ contractRequest.tarifications[0].id }`,
      order:          2,
      title:          t('wizard.navigation.configuration', { name: contractRequest.tarifications[0].product.display_name }),
      label:          t('wizard.navigation.solo_product', { product_name: contractRequest.tarifications[0].product.display_name }),
      error:          contractRequest.tarifications[0].last_error_response,
      loading:        contractRequest.tarifications[0].computing,
      icon:           'product-parameters',
      disabledRules:  [
        haveSelectedPolicyOwner,
      ],
      purpose:        'generic_tarification_step',
      Component:      TarificationStep,
      componentProps: {
        tarificationId: contractRequest.tarifications[0].id,
      },
    },
    {
      path:          `${ matchPath }/resume`,
      title:         t('wizard.navigation.resume'),
      purpose:       'resume_step',
      order:         3,
      disabledRules: [
        haveSelectedPolicyOwner,
      ],
      label:         t('wizard.navigation.resume'),
      icon:          'folder',
      Component:     ResumeStep,
    },
  ])
);

const GenericSteps = ({ matchPath }) => {
  const contractRequest = useSelector((state) => state.wizard.contract_request);
  const steps = getGenericSteps(matchPath, contractRequest);

  return <StepList steps={ steps } />;
};

export default GenericSteps;
