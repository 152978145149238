import React, { useContext } from 'react';
import JsonFormContext       from 'components/ui/json_form/json_form_context';
import Button                from 'components/ui/buttons/button';
import JsonFormFields        from 'components/ui/json_form/json_form_fields';
import { getDefaultValues }  from 'components/ui/json_form/json_form_helpers';
import FieldArrayContext     from 'components/ui/json_form/json_field_array_context';

const BlockArrayFields = ({ fields }) => {
  const { field, form, change } = useContext(FieldArrayContext);
  const { getPropertyValue, disabled } = useContext(JsonFormContext);
  return (
    <div className="uk-width-1-1" data-purpose={ fields.name }>
      { fields.map((array_item, id) => (
        <div
          className="mb-20"
          data-purpose={ `${ fields.name }[${ id }]` }
          key={ `${ fields.name }[${ id }]` }
        >
          <div className="uk-flex uk-flex-middle mb-20 uk-heading-divider">
            <h3 className="mb-0 uk-margin-auto-right ">{ `${ field.array_actions.title } #${ id + 1 }` }</h3>
            <Button
              tiny
              type="button"
              warning
              icon="delete"
              data-purpose="remove_item_button"
              disabled={ disabled }
              onClick={ () => {
                fields.remove(id);
              }
              }
            >
              { field.array_actions.remove_label }
            </Button>
          </div>
          <div className="uk-section-muted p-20">
            <JsonFormFields
              schema={ field.properties }
              namePrefix={ array_item }
              change={ change }
              form={ form }
              inArray={ true }
            />
          </div>
        </div>
      )) }
      <p className="mt-20 mb-20 uk-text-center">
        <Button
          type="button"
          primary
          data-purpose="add_item_button"
          disabled={ disabled || (field.array_actions.max && fields.length >= field.array_actions.max) }
          onClick={ () => {
            if (field.array_actions.max && fields.length === field.array_actions.max) {
              return;
            }
            fields.push(getDefaultValues(field.properties, getPropertyValue));
          } }
        >
          { field.array_actions.add_label }
        </Button>
      </p>
    </div>
  );
};

export default BlockArrayFields;
