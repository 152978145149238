import update from 'immutability-helper';

export const getBtnCrRef = (crId) => `cr_${ crId }`;

const documentsReducer = (state = {}, action) => {
  switch (action.type) {
    case 'DOCUMENTS/TOGGLE_DOCUMENT_LOADING':
      const btnCrRef = getBtnCrRef(action.contract_request_id);
      return update(state, {
        [btnCrRef]: { $set: action.loading },
      });
    default:
      return state;
  }
};

export default documentsReducer;
