import React, { useEffect, useState } from 'react';
import { goToNewFolder }              from 'models/folders/constants';
import Button                         from 'components/ui/buttons/button';
import openDialogOnClick              from 'components/ui/buttons/helpers/open_dialog_on_click';
import SelectProductByCompany         from 'components/product/select_product_by_company';
import { getProducts }                from 'models/product/requests';
import { useSelector }                from 'react-redux';

const OpenDialogButton = openDialogOnClick()(({ onClick, disabled, loading, forLead }) => {
  return (
    <Button
      primary
      onClick={ onClick }
      data-purpose={ forLead ? 'create_lead_button' : 'create_folder_button' }
      disabled={ loading || disabled }
      loading={ loading }
    >
      { forLead ? t('leads.new_lead') : t('folders.new_folder') }
    </Button>
  );
});

const CreateFolderButton = ({ className, dispatch, alignLeft, forLead, ...restProps }) => {
  const [products, setProducts] = useState([]);
  const [loading, toggleProductLoading] = useState(true);
  const [init, toggleInit] = useState(false);
  const isAgent = useSelector((state) => state.current_user.profile.authorizations.is_agent);
  const fetchProducts = () => {
    getProducts({
      includes: 'insurance_company,risk_objects',
    }).then(({ products }) => {
      setProducts(products.filter(p => !p.multi_tarif));
      toggleProductLoading(false);
      toggleInit(true);
    }).catch(() => {
      toggleProductLoading(false);
      toggleInit(true);
    });
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  if (!init) {
    return null;
  }

  return (
    <div className={ `uk-inline ${ alignLeft ? 'uk-margin-auto-left' : '' }` }>
      { products.length > 0 && (
        <React.Fragment>
          <OpenDialogButton
            dialogTitle={ forLead ? t('leads.new_lead') : t('folders.new_folder') }
            loading={ loading }
            forLead={ forLead }
          >
            <SelectProductByCompany
              products={ products }
              forLead={ forLead }
            />
          </OpenDialogButton>
        </React.Fragment>
      ) }
      { !products.length && (
        <React.Fragment>
          { !isAgent && (
            <button
              className={ `uk-button ${ className }` }
              { ...restProps }
              onClick={ (ev) => goToNewFolder(ev, null, null, forLead) }
              data-purpose={ forLead ? 'create_lead_button' : 'create_folder_button' }
            >
              { forLead ? t('leads.new_lead') : t('folders.new_folder') }
            </button>
          ) }
          { isAgent && (
            <OpenDialogButton
              dialogTitle={ t('components.folder.create_folder_button.no_products_for_agent_title') }
            >
              <p data-purpose="warning_no_product_for_agent">
                { t('components.folder.create_folder_button.no_products_for_agent_description') }
              </p>
            </OpenDialogButton>
          ) }
        </React.Fragment>
      ) }
    </div>
  );
};

export default CreateFolderButton;
