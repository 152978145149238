import React, { useEffect, useState }           from 'react';
import PropTypes                                from 'prop-types';
import pick                                     from 'lodash/pick';
import forOwn                                   from 'lodash/forOwn';
import includes                                 from 'lodash/includes';
import map                                      from 'lodash/map';
import filter                                   from 'lodash/filter';
import useDebounce                              from 'hooks/use_debounce';
import TextInput                                from 'components/ui/form/text_input';
import { setUrlSearchParam, getUrlSearchParam } from 'services/url';
import Icon                                     from 'components/ui/icon';
import Select                                   from 'react-select';

import './index.scss';

const FilterBar = ({ filters, onChange, options = {}, defaultValues = {}, className }) => {
  const defaultStatuses = () => {
    const paramFromUrl = getUrlSearchParam('status');
    if (paramFromUrl !== '' && paramFromUrl !== null) {
      const arrayFromUrl = paramFromUrl.split(',');
      return filter(options.status.options, (option) => includes(arrayFromUrl, option.value));
    }
    return defaultValues.status;
  };

  const [selectedStatuses, setSelectedStatuses] = useState(defaultStatuses());
  const [searchTerm, setSearchTerm] = useState(getUrlSearchParam('searchTerm') || "");
  const [myObjectsOnly, setMyObjectsOnly] = useState(getUrlSearchParam('myObjectsOnly') === 'true');

  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  const handleSearchTermChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const shouldShowFilter = (localFilter) => (
    includes(filters, localFilter)
  );

  const filtersWithValues = () => (
    pick({
      searchTerm: debouncedSearchTerm,
      status:     selectedStatuses,
      myObjectsOnly
    }, filters)
  );

  const updateUrl = () => {
    forOwn(filtersWithValues(), (key, value) => {
      if (key !== null || value === 'status') {
        if (value === 'status') {
          setUrlSearchParam(value, map(selectedStatuses, (selectedStatus) => selectedStatus.value).join(','));
        } else if (value === 'customer') {
          if (key.id) {
            setUrlSearchParam(value, key.id);
            setUrlSearchParam('cutomer_display_name', key.display_name);
          }
        } else {
          setUrlSearchParam(value, key);
        }
      }
    });
  };

  useEffect(() => {
    onChange(filtersWithValues());
    updateUrl();
  }, [debouncedSearchTerm, selectedStatuses, myObjectsOnly]);

  return (
    <div uk-grid="true" className="uk-child-width-1-4 uk-flex-middle mb-10">
      { shouldShowFilter('searchTerm') && (
        <div className={ `full-text-search-container ${className}` }>
          <TextInput
            placeholder={ t('administrator.tarifications.search_placeholder') }
            input={ { onChange: handleSearchTermChange, value: searchTerm } }
            style={ { width: '100%', padding: '5px' } }
          />
          <span className="full-text-search-icon">
            { !searchTerm && (<Icon icon="search" />) }
            { searchTerm && (<a href="#" onClick={ () => { setSearchTerm(''); } }><Icon icon="close" /></a>) }
          </span>
        </div>
      ) }
      { shouldShowFilter('status') && (
        <div>
          <Select
            options={ options.status.options }
            onChange={ (values) => { setSelectedStatuses(values); } }
            defaultValue={ defaultStatuses() }
            isMulti
          />
        </div>
      ) }
      { shouldShowFilter('myObjectsOnly') && (
        <div>
          <label htmlFor="i_manage" className="ml-20">
            { t('providers.demands.index.header.i_manage_only') }
            <input
              type="checkbox"
              className="uk-checkbox ml-10"
              id="i_manage"
              checked={ myObjectsOnly }
              onChange={ () => { setMyObjectsOnly(!myObjectsOnly); } }
            />
          </label>
        </div>
      ) }
    </div>
  );
};

FilterBar.propTypes = {
  filters:       PropTypes.array.isRequired,
  onChange:      PropTypes.func.isRequired,
  options:       PropTypes.object.isRequired,
  defaultValues: PropTypes.object.isRequired
};

export default FilterBar;
