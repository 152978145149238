import React, { useContext } from 'react';
import { connect }           from 'react-redux';
import classNames            from 'classnames';
import NumberService         from 'services/number';
import { converter }         from 'models/car/constants';
import Button                from 'components/ui/buttons/button';
import Icon                  from 'components/ui/icon';
import dialogContext         from 'components/ui/buttons/helpers/dialog_context';

const VehicleTable = ({ vehicles, selectedVehicle, febiacMappings, selectVehicle }) => {
  const { closeDialog } = useContext(dialogContext);
  return (
    <div className="uk-overflow-auto">
      <div className="table-fixed-header" data-purpose="version_modal_content">
        <table
          className="uk-table uk-table-striped uk-table-hover uk-table-small uk-table-middle table-fixed-header"
        >
          <thead>
            <tr>
              <th>{ t('wizard.car.table.brand') }</th>
              <th>{ t('wizard.car.table.model') }</th>
              <th>{ t('wizard.car.table.build_year') }</th>
              <th>{ t('wizard.car.table.detailed_model') }</th>
              <th>{ t('wizard.car.table.engine_displacement') }</th>
              <th>{ t('wizard.car.table.doors') }</th>
              <th>{ t('wizard.car.table.seats') }</th>
              <th>{ t('wizard.car.table.power') }</th>
              <th>{ t('wizard.car.table.horse_power') }</th>
              <th>{ t('wizard.car.table.genre') }</th>
              <th>{ t('wizard.car.table.catalog_price_without_vat') }</th>
              <th>{ t('wizard.car.table.febiac_id') }</th>
              <th />
            </tr>
          </thead>
          <tbody>
            { vehicles.map((vehicle) => {
              const isSelected = vehicle === selectedVehicle;
              return (
                <tr
                  key={ vehicle.id }
                  className={ classNames('selectable', {
                    'active': isSelected,
                  }) }
                  data-purpose={ `${ vehicle.febiac_id }_item` }
                  onClick={ () => {
                    selectVehicle(vehicle);
                    closeDialog();
                  } }
                >
                  <td>{ vehicle.brand }</td>
                  <td>{ vehicle.model }</td>
                  <td>{ vehicle.build_year }</td>
                  <td>{ vehicle.detailed_model }</td>
                  <td>{ vehicle.engine_displacement } cc</td>
                  <td>{ vehicle.doors }</td>
                  <td>{ vehicle.seats }</td>
                  <td>{ vehicle.power } kw</td>
                  <td>{ converter.kw(vehicle.power) } cv</td>
                  <td>{ t(`wizard.car.febiac_mappings.genre.${ febiacMappings.genre[vehicle.genre] }`) }</td>
                  <td>{ NumberService.formatCurrency(vehicle.catalog_price.replace('.', ','), '€') }</td>
                  <td>{ vehicle.febiac_id }</td>
                  <td className="uk-text-center">
                    { isSelected && (
                      <Icon icon="check" />
                    ) }
                    { !isSelected && (
                      <Button
                        secondary
                        small
                        className="display-on-hover"
                        data-purpose="select_button"
                        onClick={ () => {
                          selectVehicle(vehicle);
                          closeDialog();
                        } }
                      >
                        { t('general.actions.select') }
                      </Button>
                    ) }
                  </td>
                </tr>
              );
            }) }
          </tbody>
        </table>
      </div>
    </div>
  );
};

const mapStateToProps = ({ config: { febiac_mappings } }) => {
  return {
    febiacMappings: febiac_mappings,
  };
};

export default connect(mapStateToProps)(VehicleTable);
