import React             from 'react';
import isObject          from 'lodash/isObject';
import openDialogOnClick from 'components/ui/buttons/helpers/open_dialog_on_click';
import TooltipIcon       from 'components/ui/tooltip/tooltip_icon';
import Icon              from 'components/ui/icon';

const OpenContentInDialogIcon = openDialogOnClick()((props) =>
  <Icon { ...props } icon="information" />,
);

const TooltipOrDialog = ({ content, className, dialogTitle, forceDialog = false, forceHover = false }) => {
  const inDialog = content.length > 100;
  if (forceHover) {
    return (
      <TooltipIcon
        cssClass={ className }
        tooltipContent={ content }
        tooltipPos="right"
      />
    );
  }
  if (!inDialog && !forceDialog) {
    return (
      <TooltipIcon
        cssClass={ className }
        tooltipContent={ content }
        tooltipPos="right"
      />
    );
  }

  return (
    <OpenContentInDialogIcon
      dialogTitle={ dialogTitle }
      className={ `${ className } pointer` }
    >
      { isObject(content) && content }
      { !isObject(content) && (<div dangerouslySetInnerHTML={ { __html: content } } />) }
    </OpenContentInDialogIcon>
  );
};

export default TooltipOrDialog;
