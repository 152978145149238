import React            from 'react';
import FormErrorMessage from 'components/ui/form/form_error_message';

const ValueFormErrorMessage = ({ meta, className, ...rest }) => {
  return (
    <React.Fragment>
      <span className={ `input-container ${ className }` }>
        <input
          type="hidden"
          { ...rest }
        />
        <FormErrorMessage { ...meta } />
      </span>
    </React.Fragment>
  );
};

export default ValueFormErrorMessage;
