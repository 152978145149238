import React              from 'react';
import PropTypes          from 'prop-types';
import ActionCableContext from 'services/action_cable/context';

const ActionCableProvider = ({ cable, children }) => (
  <ActionCableContext.Provider
    value={ { cable } }
  >
    { children }
  </ActionCableContext.Provider>
);

ActionCableProvider.propTypes = {
  cable:    PropTypes.object.isRequired,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.node]).isRequired
};

export default ActionCableProvider;
