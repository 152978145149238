import React                              from 'react';
import { useSelector }                    from 'react-redux';
import GetPdfButton                       from 'components/tarifications/get_pdf_button';
import Loader                             from 'components/ui/loader';
import { useHistory }                     from 'react-router-dom';
import { FINISH_FOLDER, trackWizardStep } from 'models/wizard/constants';
import Button                             from 'components/ui/buttons/button';
import RefreshContractRequest             from 'components/contract_request/refresh_contract_request';
import QuoteCreatedMessage                from 'components/tarifications/quote_created_message';
import GetProductTemplate                 from 'components/tarifications/get_product_template_button';

const AedesP500OfferStep = () => {
  const contractRequest = useSelector(state => state.wizard.contract_request);
  const offerComputing = contractRequest.tarifications.find((tarif) => tarif.computing);
  const history = useHistory();

  if (offerComputing) {
    return (
      <p className="uk-text-center">
        <span className="small-italic">{ t('providers.offers.creating_offer') }</span>
        <Loader />
      </p>
    );
  }

  return (
    <div>
      <RefreshContractRequest contractRequestId={ contractRequest.id } />
      <div className="uk-grid">
        <div className="uk-width-1-1 uk-width-4-5@l">
          <QuoteCreatedMessage tarification={ contractRequest.tarifications[0] } />
        </div>
        <div className="uk-width-1-1 uk-width-1-5@l">
          <GetProductTemplate
            label={ t('provider.tarification.actions.product_pdf') }
            contractRequestId={ contractRequest.id }
            tarifications={ contractRequest.tarifications }
            className="mt-10"
            data-purpose="get_product_pdf_button"
          />
        </div>
      </div>
      <p className="uk-flex uk-flex-center">
        <Button
          primary
          large
          className="wizard-button primary"
          data-purpose="finish_button"
          onClick={ () => {
            trackWizardStep(FINISH_FOLDER, {
              contract_request_id: contractRequest.id,
            });
            history.push('/folders');
          } }
        >
          { t('general.actions.finish') }
        </Button>
      </p>
    </div>
  );
};

export default AedesP500OfferStep;
