import React                          from 'react';
import { useSelector }                from 'react-redux';
import has                            from 'lodash/has';
import Title                          from 'components/ui/titles';
import SingleProductCreateOfferButton from 'components/tarifications/single_product_create_offer_button';
import asWizardStep                   from 'components/wizard_stepper/as_wizard_step';
import SingleProductTarificationError from 'components/resume/single_product_tarification_error';
import ProductLogo                    from 'components/tarifications/table/product_logo';
import DocumentsTable                 from 'components/documents/table';
import QuoteCreatedMessage            from 'components/tarifications/quote_created_message';
import SentToBackofficeMessage        from 'components/backoffice/sent_to_backoffice_message';
import OfferErrors                    from 'components/offer/offer_errors';

const SingleProductResume = ({ showCreateOffer = true }) => {
  const contractRequest = useSelector((state) => state.wizard.contract_request);
  return (
    <div>
      <Title size="h2">{ t('wizard.resume.tarifications', { count: contractRequest.tarifications.length }) }</Title>
      { contractRequest.tarifications.map((tarification) => (
        <div
          key={ tarification.id }
        >
          <div className="tarification-resume uk-flex uk-flex-middle">
            <div className="uk-width-1-1@s uk-width-3-5@m uk-width-4-5@l title">
              <ProductLogo product={ tarification.product } />
              { `${ tarification.product.insurance_company.name } - ${ tarification.product.name }` }
            </div>
            <div className="uk-width-1-1@s uk-width-2-5@m uk-width-1-5@l uk-text-right">
              { showCreateOffer && contractRequest.authorizations.create_tarification && (
                <SingleProductCreateOfferButton
                  tarification={ tarification }
                />
              ) }
            </div>
          </div>
          { !tarification.computing && (
            <React.Fragment>
              { tarification.error_encountered && (
                <div className="mt-20">
                  <SingleProductTarificationError
                    tarification={ tarification }
                    className="p-20 tarification-product-error"
                  />
                </div>
              ) }
              <OfferErrors offer={ tarification.offer } />
            </React.Fragment>
          ) }
          { has(tarification, 'offer.insurer_offer_id') && (
            <div className="mt-20">
              <QuoteCreatedMessage tarification={ tarification } />
            </div>
          ) }
        </div>
      )) }
      <DocumentsTable
        tarifications={ contractRequest.tarifications }
        contractRequestId={ contractRequest.id }
      />
      <SentToBackofficeMessage
        tarification={ contractRequest.tarifications[0] }
        contractRequest={ contractRequest }
      />
    </div>
  );
};

export default asWizardStep()(SingleProductResume);
