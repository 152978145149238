import React, { useEffect, useState } from 'react';
import groupBy                        from 'lodash/groupBy';
import snakeCase                      from 'lodash/snakeCase';
import { goToNewFolder }              from 'models/folders/constants';
import ProductCompanyLogo             from 'components/product/product_company_logo';
import ProductItem                    from 'components/product/product_item';
import Button                         from 'components/ui/buttons/button';
import ProductEmptyState              from 'components/product/product_empty_state';
import MultiTarificationButton        from 'components/product/multi_tarification_buttton';

const Card = ({ company, products, onClick }) => {
  return (
    <div
      key={ company }
      className="product-card-item"
      onClick={ onClick }
      data-purpose={ snakeCase(products[0].insurance_company.name) }
    >
      { products[0] && products[0].insurance_company.logo && (
        <img
          src={ products[0].insurance_company.logo.big.url }
          alt={ products[0].insurance_company.name }
          width="80px"
        />
      ) }
      <div className="product-card-name">
        { products[0].insurance_company.name }
      </div>
    </div>
  );
};

const SelectProductByCompany = ({ products, forLead }) => {
  const [productsByCompany, setProducts] = useState(null);
  const [selectedCompanyProducts, setSelectedCompany] = useState(null);
  useEffect(() => {
    setProducts(groupBy(products, (product) => {
      return snakeCase(product.insurance_company.name);
    }));
  }, []);

  if (!productsByCompany) {
    return <ProductEmptyState />;
  }

  if (!selectedCompanyProducts) {
    return (
      <div
        className="product-card-list uk-flex-center"
        data-purpose="select_product_by_company"
      >
        <MultiTarificationButton forLead={ forLead } />
        { Object.keys(productsByCompany).map((company) => {
          return (
            <Card
              key={ company }
              company={ company }
              products={ productsByCompany[company] }
              onClick={ (ev) => {
                ev.preventDefault();
                setSelectedCompany(productsByCompany[company]);
              } }
            />
          );
        }) }
      </div>
    );
  }

  return (
    <div data-purpose={ `select_product_from_${ selectedCompanyProducts[0].insurance_company }` }>
      <h3 className="product-card-list-title">
        <ProductCompanyLogo
          product={ selectedCompanyProducts[0] }
          className="mr-10"
          width="40px"
        />
        { t('wizard.products.available_products_for', { company_name: selectedCompanyProducts[0].insurance_company.name }) }
      </h3>
      <div className="product-card-list uk-flex-center">
        { selectedCompanyProducts.map((product) => {
          return product.risk_objects.map((riskObject) => {
            return (
              <ProductItem
                key={ `${ product.id }_${ riskObject.id }` }
                product={ product }
                riskObjectIcon={ riskObject.icon }
                selectProduct={ (productToSelect) => {
                  return goToNewFolder(null, riskObject, productToSelect, forLead);
                } }
              />
            );
          });
        }) }
      </div>
      <p className="uk-text-center mb-0">
        <Button
          secondary
          onClick={ (ev) => {
            ev.preventDefault();
            setSelectedCompany(null);
          } }
        >
          { t('general.actions.back') }
        </Button>
      </p>
    </div>
  );
};

export default SelectProductByCompany;
