import React                          from 'react';
import { sendContract }               from 'models/contracts/requests';
import { useDispatch, useSelector }   from 'react-redux';
import { updateTarification }         from 'models/wizard/store_actions';
import LoadOnClickButton              from 'components/ui/buttons/load_on_click_button';
import { setPollingForTarifications } from 'models/wizard/dispatch_actions';
import { isSocketConnected }          from 'models/config/reducer';

const SendContractButton = ({ tarification, onContractSent }) => {
  const dispatch = useDispatch();

  const isSocketUp = useSelector((state) => isSocketConnected(state));
  if (!tarification.authorizations.contract) {
    return null;
  }

  const send = () => {
    return sendContract(tarification).then(({ tarification: contractSentTarification }) => {
      dispatch(updateTarification(contractSentTarification));
      if (!isSocketUp) {
        dispatch(setPollingForTarifications([tarification]));
      }
      if (onContractSent) {
        onContractSent();
      }
    });
  };

  return (
    <LoadOnClickButton
      primary
      onClick={ send }
      loading={ tarification.computing }
      large
    >
      { t('components.contract.send_contract_button.send') }
    </LoadOnClickButton>
  );
};

export default SendContractButton;
