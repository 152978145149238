import React                    from 'react';
import {
  DOCK_SELECTED_TARIFICATIONS,
  trackWizardStep,
}                               from 'models/wizard/constants';
import { getTarificationsDocx } from 'models/tarifications/requests';
import Button                   from 'components/ui/buttons/button';
import loadOnClick              from 'components/ui/buttons/helpers/load_on_click';

const LoadOnClickButton = loadOnClick()(Button);

const DocxButton = ({ onClick, disabled, className }) => {
  return (
    <LoadOnClickButton
      outline
      small
      disabled={ disabled }
      className={ className }
      data-purpose="print_selection_button"
      onClick={ onClick }
      secondary
      icon="docx"
    >
      { t('wizard.resume.use_custom_template') }
    </LoadOnClickButton>
  );
};

const GetDocxButton = ({ tarifications, contractRequest, hasTemplate, disabled, ...rest }) => {
  if (!contractRequest.authorizations.account_template_export) {
    return null;
  }
  const print = () => {
    trackWizardStep(DOCK_SELECTED_TARIFICATIONS, {
      contract_request_id: contractRequest.id,
      tarification_ids:    tarifications.map(tarif => tarif.id),
      account_template:    true,
    });
    return getTarificationsDocx(contractRequest.id, tarifications);
  };
  return <DocxButton onClick={ print } disabled={ disabled || !tarifications.length } { ...rest } />;
};

export default GetDocxButton;
