import React                      from 'react';
import Alert                      from 'services/alert';
import Icon                       from 'components/ui/icon';
import Button                     from 'components/ui/buttons/button';
import { archiveContractRequest } from 'models/contract_request/requests';
import loadOnClick                from 'components/ui/buttons/helpers/load_on_click';
import confirmBeforeAction        from 'components/ui/buttons/helpers/confirm_before_action';

const ConfirmBeforeButton = confirmBeforeAction()(loadOnClick()(Button));

const ArchiveFolderButton = ({ folderId, onArchived }) => {

  const archiveFolder = (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    return archiveContractRequest(folderId).then(({ contract_request }) => {
      Alert.enableNotification();
      Alert.success(t('folders.archive_success'));
      Alert.preventNotification();
      if (onArchived) {
        onArchived(contract_request);
      }
    });
  };

  return (
    <ConfirmBeforeButton
      className="button-transparent"
      onClick={ archiveFolder }
      confirmText={ t('folders.archive_confirm_text') }
      confirmTitle={ t('folders.archive_confirm_title') }
      title={ t('general.actions.archive') }
      data-purpose="archive_folder_button"
    >
      <Icon icon="archive" />
    </ConfirmBeforeButton>
  );
};

export default ArchiveFolderButton;
