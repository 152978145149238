import React                from 'react';
import Icon                 from 'components/ui/icon';
import moment               from 'moment';
import { getCurrentLocale } from 'services/i18n';
import compact              from 'lodash/compact';
import TogglableDate        from 'components/ui/form/togglable_date';

export const convertForTable = (contractRequests, isIntermediary) => {
  moment.locale(getCurrentLocale());
  return compact(contractRequests.map((contractRequest) => {
    if (!isIntermediary && contractRequest.status === 'active') {
      return null;
    }
    return {
      id:                            contractRequest.id,
      link:                          `/leads/${ contractRequest.id }#resume`,
      risk_object:                   (
                                       <Icon
                                         icon={ contractRequest.risk_object.icon }
                                         data-uk-tooltip={ contractRequest.risk_object.kind_text }
                                       />
                                     ),
      technical_reference:           contractRequest.technical_reference,
      customer:                      contractRequest.customer_name,
      manager_user_display_name:     contractRequest.manager_user_display_name,
      active:                        contractRequest.status === 'active',
      status:                        t(`leads.status.${ contractRequest.status }`),
      updated_at:                    (
                                       <TogglableDate date={ contractRequest.updated_at } />
                                     ),
    };
  }));
};
