import Api   from 'services/api';
import Alert from 'services/alert';

export const dataPortabilityRightRequests = (data) => {
  return new Promise((resolve, reject) => {
    Api.post('gdpr/data_portability_right_requests', {
      data_portability_right_request: data,
    }).then((response) => {
      Alert.success(t('gdpr.options.request_success'));
      resolve(response.data);
    }).catch((error) => {
      Alert.error(t('gdpr.options.request_error'));
      reject(error);
    });
  });
};

export const processingLimitRequests = (data) => {
  return new Promise((resolve, reject) => {
    Api.post('gdpr/data_processing_limit_requests', {
      data_processing_limit_request: data,
    }).then((response) => {
      Alert.success(t('gdpr.options.request_success'));
      resolve(response.data);
    }).catch((error) => {
      Alert.error(t('gdpr.options.request_error'));
      reject(error);
    });
  });
};

export const dataRectificationRequests = (data) => {
  return new Promise((resolve, reject) => {
    Api.post('gdpr/data_rectification_requests', {
      data_rectification_request: data,
    }).then((response) => {
      Alert.success(t('gdpr.options.request_success'));
      resolve(response.data);
    }).catch((error) => {
      Alert.error(t('gdpr.options.request_error'));
      reject(error);
    });
  });
};

export const accountRemovalRequests = (data) => {
  return new Promise((resolve, reject) => {
    Api.post('gdpr/account_removal_requests', {
      account_removal_request: data,
    }).then((response) => {
      Alert.success(t('gdpr.options.request_success'));
      resolve(response.data);
    }).catch((error) => {
      Alert.error(t('gdpr.options.request_error'));
      reject(error);
    });
  });
};
