import React, { useContext, useEffect, useState } from 'react';
import { Field }                                  from 'redux-form';
import moment                                     from 'moment';
import isNil                                      from 'lodash/isNil';
import has                                        from 'lodash/has';
import asJsonFormField                            from 'components/ui/json_form/as_json_form_field';
import AddressFormJson                            from 'components/address/address_form_json';
import DocumentFields                             from 'components/ui/form/document_fields';
import {
  getOnChangeMethod,
  getValidations,
  getConditionalValidations,
}                                                 from 'components/ui/json_form/json_form_helpers';
import { getOnlyNums, normalizePrice }            from 'constants/application_constants';
import JsonFormContext                            from 'components/ui/json_form/json_form_context';
import JsonVehicleSelector                        from 'components/ui/json_form/json_vehicle_selector';
import Button                                     from 'components/ui/buttons/button';
import JsonFormFields                             from 'components/ui/json_form/json_form_fields';
import Input                                      from 'components/ui/json_form/fields/input';
import MultiButton                                from 'components/ui/json_form/fields/multi_button';
import Select                                     from 'components/ui/json_form/fields/select';
import RadioList                                  from 'components/ui/json_form/fields/radio_list';
import CheckboxList                               from 'components/ui/json_form/fields/checkbox_list';
import CheckboxInput                              from 'components/ui/json_form/fields/checkbox';
import TextArea                                   from 'components/ui/json_form/fields/textarea';
import FormMessage                                from 'components/ui/json_form/form_warning';
import TrueFalse                                  from 'components/ui/json_form/fields/true_false';
import SearchBmsField                             from 'components/ui/json_form/fields/search_bms_fields';
import SearchByPlateField
                                                  from 'components/ui/json_form/fields/search_by_plate_field';
import TarificationCoverStartDatesField           from 'components/offer/tarification_cover_start_dates_field';
import JsonTableField                             from 'components/ui/json_form/fields/json_table_field';

const DATE_FORMAT_TO_STORE = 'YYYY-MM-DD';

export const formatDate = (value) => {
  if (!value) {
    return '';
  }
  let onlyNums = getOnlyNums(value);
  if (value.length >= 10) {
    onlyNums = moment(value, DATE_FORMAT_TO_STORE).format('DDMMYYYY');
  }
  if (onlyNums.length <= 2) {
    return onlyNums;
  }
  if (onlyNums.length <= 4) {
    return `${ onlyNums.slice(0, 2) }/${ onlyNums.slice(2, 4) }`;
  }
  return `${ onlyNums.slice(0, 2) }/${ onlyNums.slice(2, 4) }/${ onlyNums.slice(4, 8) }`;
};

const formComponent = {
  address:                        AddressFormJson,
  text:                           Input,
  true_false:                     TrueFalse,
  date:                           Input,
  multi_button:                   MultiButton,
  select:                         Select,
  radio_list:                     RadioList,
  checkbox_list:                  (props) => {
    return (
      <CheckboxList name={ props.fieldKey } { ...props } />
    );
  },
  message:                        FormMessage,
  uploader:                       DocumentFields,
  checkbox:                       CheckboxInput,
  textarea:                       TextArea,
  button:                         ({ label, secondary, onClick, change }) => (
    <Button
      type="button"
      onClick={ () => {
        if (onClick && onClick.set) {
          change(onClick.set.property, onClick.set.value);
        }
      } }
      secondary={ secondary }
    >
      { label }
    </Button>
  ),
  price:                          Input,
  vehicle_selector:               JsonVehicleSelector,
  bms:                            SearchBmsField,
  search_by_plate:                SearchByPlateField,
  tarification_cover_start_dates: TarificationCoverStartDatesField,
  table:                          JsonTableField,
};

export const extraFormComponentProps = {
  price: {
    type:      'text',
    symbol:    '€',
    min:       1,
    format:    normalizePrice,
    normalize: (val) => {
      if (val) {
        return val.replace(/[^\d,.]/g, '').replace(',', '.');
      }
      return null;
    },
  },
  date:  {
    normalize: (val) => {
      if (!val) {
        return null;

      }
      if (val.length < 10) {
        return val;
      }
      return moment(val, 'DD/MM/YYYY').format(DATE_FORMAT_TO_STORE);
    },
    format:    formatDate,
  },
  int:   {
    normalize: (val) => {
      if (isNil(val)) {
        return val;
      }
      return parseFloat(val);
    },
  },
};

const JsonFormField = (props) => {
  const [fieldValidations, setFieldValidations] = useState(null);
  const { field, namePrefix, change, form, fieldKey, inArray, withValues, disabled } = props;
  const { component, validations, onValueChange, conditional_validations, name: nameOverride, ...restVal } = field;
  const { getPropertyValue, admin } = useContext(JsonFormContext);
  let conditionalValidations = [];
  let comp = formComponent[field.component];

  if (has(field, 'admin') && !field.admin && admin) {
    return null;
  }
  if (!comp) {
    console.warn('comp not handled', field.component);
    return null;
  }

  const name = namePrefix ? `${ namePrefix }.${ fieldKey }` : fieldKey;

  useEffect(() => {
    setFieldValidations(getValidations(field, getPropertyValue));
  }, []);

  if (conditional_validations) {
    conditionalValidations = getConditionalValidations(conditional_validations, getPropertyValue);
  }

  return (
    <Field
      key={ name }
      name={ name }
      nameOverride={ nameOverride }
      inline
      validate={ conditional_validations ? conditionalValidations : fieldValidations }
      component={ comp }
      fieldKey={ fieldKey }
      change={ change }
      onValueChange={ onValueChange ? getOnChangeMethod(onValueChange, change, getPropertyValue) : null }
      prefillFrom={ onValueChange && onValueChange.prefill && onValueChange.prefill.from }
      prefillOnMount={ onValueChange && onValueChange.prefill && onValueChange.prefill.prefillOnMount }
      { ...restVal }
      { ...extraFormComponentProps[field.extraPropsFor ? field.extraPropsFor : field.component] }
      inArray={ inArray }
      formName={ form }
      withValues={ withValues }
      disabled={ disabled }
      __field={ {
        ...field,
        validations: fieldValidations,
      } }
      InputAddition={ field.extraFields ? (
        () => (
          <div className={ field.extraFields.className || 'ml-10' }>
            <JsonFormFields
              schema={ field.extraFields.properties }
              change={ change }
              form={ form }
              namePrefix={ namePrefix }
            />
          </div>
        )) : null }
    />
  );
};

export default asJsonFormField()(JsonFormField);
