export const getAxaBuildingCharacteristicsSchema = () => {
  return {
    title_characteristics: {
      type:  'title',
      title: t('wizard.building.characteristics.title'),
      size:  'h2',
    },
    build_year:            {
      component:               'text',
      label:                   t('wizard.building.build_decade'),
      conditional_validations: [{
        condition:   {
          property:  'building.build_decade',
          assertion: 'notExists',
        },
        validations: ['required', 'maxLength4', 'year_not_in_future'],
      }],
      containerClass:          'axa-building-build-year',
      onValueChange:           {
        set: [
          {
            to:        'building.build_decade',
            value:     null,
            condition: {
              assertion: 'exists',
            },
          },
        ],
      },
      extraFields:             {
        className:  'uk-flex uk-flex-middle',
        properties: {
          or_text:      {
            type:      'text',
            className: 'build-year-or-decade',
            text:      t('wizard.building.or'),
          },
          build_decade: {
            component:               'select',
            inline:                  false,
            onValueChange:           {
              set: [
                {
                  to:        'building.build_year',
                  value:     null,
                  condition: {
                    assertion: 'exists',
                  },
                },
              ],
            },
            list:                    [
              {
                value: 'Pre_1980',
                label: t('wizard.building.decade_options.pre_1980'),
              },
              {
                value: '1980_1989',
                label: t('wizard.building.decade_options.from_1980_to_1989'),
              },
              {
                value: '1990_1999',
                label: t('wizard.building.decade_options.from_1990_to_1999'),
              },
              {
                value: '2000_2009',
                label: t('wizard.building.decade_options.from_2000_to_2009'),
              },
              {
                value: 'Post_2010',
                label: t('wizard.building.decade_options.post_2010'),
              },
            ],
            conditional_validations: [{
              condition:   {
                property:  'building.build_year',
                assertion: 'notExists',
              },
              validations: ['required'],
            }],
          },
        },
      },
    },
    characteristics:       {
      type:       'section',
      properties: {
        number_of_units:      {
          validations: ['required', 'maxLength5', 'only_int'],
          label:       t('wizard.building.characteristics.number_of_units'),
          component:   'text',
          type:        'number',
          tooltip:     t('wizard.building.characteristics.number_of_units_tooltip'),
        },
        number_of_floors:     {
          validations: ['required', 'maxLength5', 'only_int'],
          label:       t('wizard.building.characteristics.number_of_floors'),
          component:   'text',
          type:        'number',
        },
        is_underground_units: {
          component:   'true_false',
          label:       t('wizard.building.characteristics.is_underground_units'),
          validations: ['required'],
        },
        combustible:          {
          component:   'select',
          label:       t('wizard.building.characteristics.combustible'),
          tooltip:     t('wizard.building.characteristics.combustible_tooltip'),
          validations: ['required'],
          list:        [
            {
              value: 'No',
              label: t('wizard.building.characteristics.combustible_options.not_combustible'),
            },
            {
              value: '<=25%',
              label: t('wizard.building.characteristics.combustible_options.lower_or_25_percent'),
            },
            {
              value: '>25%',
              label: t('wizard.building.characteristics.combustible_options.over_25_percent'),
            },
          ],
        },
      },
    },
  };
};
