import React                 from 'react';
import { Link }              from 'react-router-dom';
import ConfigureIllustration from 'images/configure_integration.svg';
import { useSelector }       from 'react-redux';

const ProductEmptyState = () => {
  const canConfigureIntegrations = useSelector(state => state.current_user.profile.role === 'admin');
  return (
    <div className="uk-flex uk-flex-middle uk-width-1-1 folder-empty-state p-20" data-purpose="product_empty_state">
      <div className="uk-text-center uk-width-1-1">
        <img
          src={ ConfigureIllustration }
          alt="No folder ilustration"
          className="uk-width-1-5"
        />
        <p className="uk-text-center mt-20 pl-40 pr-40">
          { canConfigureIntegrations ? t('wizard.products.empty_state') : t('wizard.products.associate_empty_state') }
        </p>
        { canConfigureIntegrations && (
          <p className="uk-text-center">
            <Link
              to="/account/integrations"
              className="uk-button uk-button-primary large"
              data-purpose="go_to_integrations_button"
            >
              { t('providers.account.integrations.title') }
            </Link>
          </p>
        ) }
      </div>
    </div>
  );
};

export default ProductEmptyState;
