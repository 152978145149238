import classNames      from 'classnames';
import React, {
  useEffect,
  useState,
}                      from 'react';
import compact         from 'lodash/compact';
import JsonFieldArray  from 'components/ui/json_form/json_field_array';
import JsonFormField   from 'components/ui/json_form/json_form_field';
import JsonFormSection from 'components/ui/json_form/json_form_section';
import JsonFormGroup   from 'components/ui/json_form/json_form_group';
import CarPanelResume  from 'components/vehicle/car_panel_resume';
import asJsonFormField from 'components/ui/json_form/as_json_form_field';
import TooltipOrDialog from 'components/ui/tooltip/tooltip_or_dialog';
import InlineContainer from '../form/inline_container';

const customComponents = {
  car_resume:                asJsonFormField()((props) => {
    return <CarPanelResume { ...props } />;
  }),
  car_from_informex_message: asJsonFormField()((props) => {
    const cleanInformexData = (ev) => {
      ev.preventDefault();
      const informexData = [
        'vehicle.doors_count',
        'vehicle.displacement',
        'vehicle.seats_count',
        'vehicle.pva_number',
        'vehicle.eu_pva_number',
        'vehicle.car_completed_from_informex',
        'vehicle.car_resume',
        'vehicle.date_of_first_circulation',
        'vehicle.brand',
        'vehicle.fuel_type',
        'vehicle.power',
        'vehicle.model',
        'vehicle.build_year',
        'vehicle.plate_number',
        'vehicle.source',
      ];
      informexData.forEach(data => props.change(data, null));
    };
    return (
      <div className="uk-alert-disabled uk-flex uk-flex-middle mb-20 p-20"
           data-purpose="car_completed_by_informex_message">
        { t_html('wizard.car.informex_vin_number_html', { vin_number: props.withValues.vin_number }) }
        <button
          type="button"
          className="uk-button uk-button-text uk-button-small uk-margin-auto-left"s
          data-purpose="clear_car_from_informex_button"
          onClick={ cleanInformexData }
        >
          { t('wizard.car.other_vehicle') }
        </button>
      </div>
    );
  }),
};

const getFields = (schema, form, change, namePrefix, inArray, fieldOverride = {}) => {
  return compact(Object.keys(schema).map((key, index) => {
    const field = {
      ...schema[key],
      ...fieldOverride,
    };
    if (field.type === 'group') {
      return (
        <JsonFormGroup
          field={ field }
          form={ form }
          change={ change }
          namePrefix={ namePrefix }
          inArray={ inArray }
          key={ index }
        />
      );
    }
    if (field.type === 'section') {
      return (
        <JsonFormSection
          field={ field }
          form={ form }
          change={ change }
          namePrefix={ namePrefix }
          fieldKey={ key }
          key={ index }
          inArray={ inArray }
        />
      );
    }
    if (field.type === 'array') {
      return (
        <JsonFieldArray
          field={ field }
          form={ form }
          change={ change }
          namePrefix={ namePrefix }
          fieldKey={ key }
          key={ index }
          inArray={ inArray }
        />
      );
    }
    if (field.type === 'title') {
      const Htag = `${ field.size || 'h3' }`;
      return (
        <div>
          <Htag key={ index } className={ `${ field.className } uk-flex uk-flex-middle` }>
            { field.title }
            { field.title_tooltip && (
              <TooltipOrDialog className="ml-10" dialogTitle={ field.title } content={ field.title_tooltip } />
            ) }
            { field.title_tooltip_html && (
              <TooltipOrDialog
                className="ml-10"
                dialogTitle={ field.title }
                content={ t_html(field.title_tooltip_html) } />
            ) }
          </Htag>
        </div>
      );
    }
    if (field.type === 'text') {
      if (field.inline) {
        return (
          <InlineContainer>
            { field.text_html && (
              <div
                key={ index }
                dangerouslySetInnerHTML={ { __html: field.text_html } }
                className={ field.className }
              />
            ) }
            { field.text && (
              <p key={ index } className={ field.className }>{ field.text }</p>
            ) }
          </InlineContainer>
        );
      } else {
        if (field.text_html) {
          return (
            <div
              key={ index }
              dangerouslySetInnerHTML={ { __html: field.text_html } }
              className={ field.className }
            />
          );
        }
        return (
          <p key={ index } className={ field.className }>{ field.text }</p>
        );
      }
    }
    if (field.type === 'custom') {
      const CustomComponent = customComponents[field.component];
      return (
        <CustomComponent
          form={ form }
          field={ field }
          key={ index }
          change={ change }
        />
      );
    }
    return (
      <JsonFormField
        field={ field }
        form={ form }
        change={ change }
        fieldKey={ key }
        namePrefix={ namePrefix }
        key={ index }
        inArray={ inArray }
      />
    );
  }));
};

const JsonFormFields = ({ schema, form, change, namePrefix, inArray, fieldWrapper, fieldOverride }) => {
  const [fields, setFields] = useState(null);
  useEffect(() => {
    let f = getFields(schema, form, change, namePrefix, inArray, fieldOverride);
    if (fieldWrapper) {
      const Wrap = fieldWrapper;
      f = f.map((item, id) => <Wrap key={ id }>{ item }</Wrap>);
    }
    setFields(f);
  }, [schema, namePrefix]);
  return fields;
};

export default JsonFormFields;
