import React               from 'react';
import FormErrorMessage    from 'components/ui/form/form_error_message';
import withInlineContainer from 'components/ui/json_form/fields/with_inline_container';

const RadioTrueFalse = ({
  input,
  trueLabel,
  falseLabel,
  disabled,
  onCheck,
  onValueChange,
  meta,
  prefillData,
}) => {
  const onChange = (val) => {
    input.onChange(val);
    if (onCheck) {
      onCheck(val);
    }
    if (onValueChange) {
      onValueChange(val, prefillData);
    }
  };

  return (
    <div className="field uk-grid-small">
      <label htmlFor={ `${ input.name }_yes` }>
        <input
          className="uk-radio mr-10"
          type="radio"
          name={ `${ input.name }_yes` }
          id={ `${ input.name }_yes` }
          data-purpose={ `${ input.name }_yes` }
          checked={ input.value === true }
          disabled={ disabled }
          onChange={ () => {
            onChange(true);
          } }
        />
        { trueLabel || t('general.actions.yes_button') }
      </label>
      <label htmlFor={ `${ input.name }_no` }>
        <input
          className="uk-radio mr-10"
          type="radio"
          name={ `${ input.name }_no` }
          data-purpose={ `${ input.name }_no` }
          id={ `${ input.name }_no` }
          checked={ input.value === false }
          disabled={ disabled }
          onChange={ () => {
            onChange(false);
          } }
        />
        { falseLabel || t('general.actions.no_button') }
      </label>
      <FormErrorMessage { ...meta } />
    </div>
  );
};

export default withInlineContainer()(RadioTrueFalse);
