import React, { useEffect, useRef } from 'react';
import transform                    from 'lodash/transform';
import isEqual                      from 'lodash/isEqual';
import isObject                     from 'lodash/isObject';

export const difference = (object, base) => {
  const changes = (object, base) => {
    return transform(object, (result, value, key) => {
      if (!isEqual(value, base[key])) {
        result[key] = (isObject(value) && isObject(base[key])) ? changes(value, base[key]) : value;
      }
    });
  };
  return changes(object, base);
};

export const useTraceUpdate = (props, from) => {
  const prev = useRef(props);
  useEffect(() => {
    const changedProps = Object.entries(props).reduce((ps, [k, v]) => {
      if (prev.current[k] !== v) {
        let diff = null;
        if (typeof v === 'object') {
          diff = difference(v, prev.current[k]);
        }
        ps[k] = {
          previous: prev.current[k],
          new:      v,
          diff,
        };
      }
      return ps;
    }, {});
    if (Object.keys(changedProps).length > 0) {
      console.log(`${ from } Changed props:`, changedProps);
    }
    prev.current = props;
  });
};
