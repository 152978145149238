import React, {
  useContext,
  useRef,
  useState,
  useEffect,
} from 'react';

import WizardContext          from 'components/wizard/wizard_context';
import confirmBeforeAction
                              from 'components/ui/buttons/helpers/confirm_before_action';
import Button                 from 'components/ui/buttons/button';
import { useSelector }        from 'react-redux';
import {
  hasSubmitSucceeded,
  isDirty,
  isSubmitting,
}                             from 'redux-form';
import { Prompt, useHistory } from 'react-router';

const ConfirmBeforeActionButton = confirmBeforeAction()(Button);

const PreventIfDirty = ({
  form,
  anyTouched,
}) => {
  const history = useHistory();
  const [nextLocation, setNextLocation] = useState(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);
  const [shouldSave, toggleShouldSave] = useState(false);
  const formIsDirty = useSelector((state) => isDirty(form)(state));
  const formIsSubmitting = useSelector((state) => isSubmitting(form)(state));
  const formHasSubmitSucceeded = useSelector((state) => hasSubmitSucceeded(form)(state));
  const { onNext } = useContext(WizardContext);
  const buttonRef = useRef(null);
  const handlePrevent = (nextLoc) => {
    if (buttonRef && formIsDirty && !confirmedNavigation && anyTouched) {
      if (formIsSubmitting || formHasSubmitSucceeded) {
        return true;
      }
      setNextLocation(nextLoc);
      buttonRef.current.toggleShouldConfirm();
      return false;
    }
    return true;
  };

  const continueWithoutSave = () => {
    toggleShouldSave(false);
    setConfirmedNavigation(true);
  };

  const saveAndContinue = () => {
    toggleShouldSave(true);
    onNext();
    setTimeout(() => {
      setConfirmedNavigation(true);
    }, 5000);
  };

  useEffect(() => {
    if (confirmedNavigation && nextLocation) {
      if (shouldSave && formHasSubmitSucceeded) {
        history.push(nextLocation.pathname);
      }
      if (!shouldSave) {
        history.push(nextLocation.pathname);
      }
    }
  }, [confirmedNavigation, formHasSubmitSucceeded, shouldSave]);

  return (
    <React.Fragment>
      <Prompt message={ handlePrevent } />
      <ConfirmBeforeActionButton
        className="hidden"
        data-purpose="warn_if_dirty_dialog"
        confirmTitle={ t('components.ui.json_form.prevent_if_dirty.change_detected') }
        confirmText={ t('components.ui.json_form.prevent_if_dirty.change_detected_text') }
        cancelButtonLabel={ t('components.ui.json_form.prevent_if_dirty.continue_without_save') }
        confirmButtonLabel={ t('components.ui.json_form.prevent_if_dirty.save_and_continue') }
        confirmButtonClass="confirm-primary"
        onClick={ saveAndContinue }
        onCancel={ continueWithoutSave }
        ref={ buttonRef }
      />
    </React.Fragment>
  );
};

export default PreventIfDirty;
