import React, { useState, useEffect, useContext } from 'react';
import classNames                                 from 'classnames';
import FormErrorMessage                           from 'components/ui/form/form_error_message';
import { fieldWrapper }                           from 'components/ui/json_form/fields/field_wrapper';
import JsonFormContext                            from 'components/ui/json_form/json_form_context';

const TrueFalse = (props) => {
  const [field, setField] = useState(null);
  const { getPropertyValue } = useContext(JsonFormContext);
  let prefillData = null;
  const {
          input,
          trueLabel,
          falseLabel,
          disabled,
          onCheck,
          onValueChange,
          prefillFrom,
          prefillOnMount,
          label,
          meta,
        } = props;
  if (prefillFrom) {
    if (prefillFrom.store) {
      prefillData = getPropertyValue(prefillFrom.store);
    }
  }

  const onChange = (val) => {
    input.onChange(val);
    if (onCheck) {
      onCheck(val);
    }
    if (onValueChange) {
      onValueChange(val, prefillData, false, input.name);
    }
  };

  useEffect(() => {
    if (onValueChange && prefillOnMount) {
      onValueChange(input.value, prefillData, true, input.name);
    }
  }, []);

  const trueFalseField = (
    <div className={ classNames('field uk-grid-small', {
      'error': meta.touched && meta.error,
    }) }>
      <label htmlFor={ `${ input.name }_yes` }>
        <input
          className="uk-radio mr-10"
          type="radio"
          name={ `${ input.name }_yes` }
          id={ `${ input.name }_yes` }
          data-purpose={ `${ input.name }_yes` }
          checked={ input.value === true }
          disabled={ disabled }
          onChange={ () => {
            onChange(true);
          } }
        />
        { trueLabel || t('general.actions.yes_button') }
      </label>
      <label htmlFor={ `${ input.name }_no` }>
        <input
          className="uk-radio mr-10"
          type="radio"
          name={ `${ input.name }_no` }
          id={ `${ input.name }_no` }
          data-purpose={ `${ input.name }_no` }
          checked={ input.value === false }
          disabled={ disabled }
          onChange={ () => {
            onChange(false);
          } }
        />
        { falseLabel || t('general.actions.no_button') }
      </label>
      <FormErrorMessage { ...meta } name={ input.name } label={ label } />
    </div>
  );

  useEffect(() => {
    setField(trueFalseField);
  }, [input.value, meta]);

  return field;
};

export default fieldWrapper()(TrueFalse);
