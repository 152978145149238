import React  from 'react';
import $      from 'jquery';
import Button from 'components/ui/buttons/button';

const JsonFormErrors = ({ errors }) => {
  return (
    <div className="p-10 global-message danger">
      <p className="mb-10">{ t('errors.messages.form_error_detected') }</p>
      <ul className="uk-list uk-list-bullet m-0">
        { errors.map((error, id) => {
          return (
            <li key={ id } className="ml-10 uk-flex uk-flex-middle">
              { error.label } : { error.message }
              { error.$item && error.$item[0] && (
                <Button
                  type="button"
                  tiny
                  icon="search"
                  className="ml-10"
                  onClick={ () => {
                    $([document.documentElement, document.body]).animate({
                      scrollTop: error.$item.offset().top - 150,
                    }, 500);
                  } }
                >
                  { t('general.actions.see') }
                </Button>
              ) }
            </li>
          );
        }) }
      </ul>
    </div>
  );
};

export default JsonFormErrors;
