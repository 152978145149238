import React, { useEffect, useState, useContext } from 'react';
import { connect }                                from 'react-redux';
import {
  formValueSelector,
  change,
  Field,
}                                                 from 'redux-form';
import compact                                    from 'lodash/compact';
import isNil                                      from 'lodash/isNil';
import Button                                     from 'components/ui/buttons/button';
import openDialogOnClick                          from 'components/ui/buttons/helpers/open_dialog_on_click';
import includes                                   from 'lodash/includes';
import ValueFormErrorMessage                      from 'components/ui/form/value_form_error_message';
import { required }                               from 'models/application/constants';
import InlineContainer                            from 'components/ui/form/inline_container';
import { fetchVehicles }                          from 'models/car/requests';
import InnerVehicleTableSelector                  from 'components/ui/json_form/inner_vehicle_table_selector';
import JsonFormContext                            from 'components/ui/json_form/json_form_context';

const OpenVehicleTableButton = openDialogOnClick()(({ onClick, selectedVehicle, disabled, loading }) => {
  const valueLabels = ['brand', 'build_year', 'detailed_model', 'power', 'catalog_price'];
  const selectedParts = selectedVehicle ? compact(valueLabels.map(label => selectedVehicle[label])) : [];
  return (
    <React.Fragment>
      { selectedVehicle && (
        <button
          type="button"
          className="uk-button uk-button-default uk-button-small mr-10"
          data-purpose="selected_vehicle_resume"
        >
          { selectedParts.join(' - ') }
        </button>
      ) }
      <Button
        secondary
        disabled={ disabled }
        onClick={ onClick }
        loading={ loading }
        data-purpose="version_modal_button"
      >
        { selectedVehicle ? t('general.actions.change') : t('wizard.car.select_car_version') }
      </Button>
    </React.Fragment>
  );
});

const JsonVehicleSelector = ({ febiac_id, formName, selectVehicle, febiacMappings, loading, label, carData }) => {
  const { disabled } = useContext(JsonFormContext);
  const [vehicles, setVehicles] = useState([]);
  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const [paramsForList, setParamsForList] = useState(null);
  const [vehiclesLoading, setVehiclesLoading] = useState(false);
  let fetching = false;
  const getVehicles = () => {
    if (fetching || vehiclesLoading) {
      return;
    }
    if (isNil(carData.model) || isNil(carData.brand) || isNil(carData.power) || isNil(carData.fuel_type) || isNil(carData.date_of_first_circulation)) {
      return null;
    }
    if (paramsForList === carData) {
      return;
    }

    setVehiclesLoading(true);
    fetching = true;
    fetchVehicles(carData).then((results) => {
      setParamsForList(carData);
      setVehicles(results);
      setVehiclesLoading(false);
      fetching = false;
    }).catch(() => {
      setVehicles([]);
      setVehiclesLoading(false);
      fetching = false;
    });
  };
  useEffect(() => {
    getVehicles();
  }, []);

  useEffect(() => {
    getVehicles();
  }, [carData.build_year]);

  useEffect(() => {
    if (!isNil(carData.model) && !isNil(carData.brand) && !isNil(carData.power) && !isNil(carData.fuel_type)) {
      getVehicles();
    }
  }, [carData]);

  useEffect(() => {
    const selected = vehicles.find((vehicle) => `${ vehicle.febiac_id }` === `${ febiac_id }`);
    setSelectedVehicle(selected);
  }, [febiac_id, vehicles]);

  return (
    <InlineContainer
      label={ label }
    >
      <OpenVehicleTableButton
        selectedVehicle={ selectedVehicle }
        dialogTitle={ t('wizard.car.select_car_version') }
        dialogClass="large"
        disabled={ disabled || !vehicles.length }
        loading={ loading }
      >
        <InnerVehicleTableSelector
          vehiclesLoading={ vehiclesLoading }
          vehicles={ vehicles }
          selectedVehicle={ selectedVehicle }
          formName={ formName }
          selectVehicle={ selectVehicle }
          febiacMappings={ febiacMappings }
          carData={ carData }
        />
      </OpenVehicleTableButton>
      <Field
        name="febiac_id"
        validate={ [required] }
        component={ ValueFormErrorMessage }
        className="ml-10"
      />
    </InlineContainer>
  );
};

const mapStateToProps = (state, { formName, vehicleLabel }) => {
  const selector = formValueSelector(formName);
  return {
    febiac_id:      selector(state, `${ vehicleLabel }.febiac_id`),
    febiacMappings: state.config.febiac_mappings,
    carData:        {
      risk_object:               state.wizard.contract_request.risk_object.kind,
      model:                     selector(state, `${ vehicleLabel }.model`),
      build_year:                selector(state, `${ vehicleLabel }.build_year`),
      brand:                     selector(state, `${ vehicleLabel }.brand`),
      fuel_type:                 selector(state, `${ vehicleLabel }.fuel_type`),
      power:                     selector(state, `${ vehicleLabel }.power`),
      date_of_first_circulation: selector(state, `${ vehicleLabel }.date_of_first_circulation`),
    },
  };
};

const mapDispatchToProps = (dispatch, { formName, vehicleLabel }) => {
  return {
    selectVehicle: (vehicle, febiacMappings) => {
      const updateInput = (input, val) => dispatch(change(formName, input, val));
      updateInput(`${ vehicleLabel }.febiac_id`, vehicle.febiac_id);
      updateInput(`${ vehicleLabel }.build_year`, vehicle.build_year);
      updateInput(`${ vehicleLabel }.catalogue_price_without_vat`, vehicle.catalog_price);
      updateInput(`${ vehicleLabel }.doors_count`, vehicle.doors);
      updateInput(`${ vehicleLabel }.genre`, febiacMappings.genre[vehicle.genre]);
      updateInput(`${ vehicleLabel }.febiac_genre`, febiacMappings.genre[vehicle.genre]);
      updateInput(`${ vehicleLabel }.displacement`, vehicle.engine_displacement);
      updateInput(`${ vehicleLabel }.seats_count`, vehicle.seats);
      updateInput(`${ vehicleLabel }.pva_number`, vehicle.pva_number);
      updateInput(`${ vehicleLabel }.eu_pva_number`, vehicle.eu_pva_number);
      updateInput(`${ vehicleLabel }.monovolume_vehicle`, !!includes(['AF', 'OM', 'FA'], vehicle.genre));
      updateInput(`${ vehicleLabel }.sport_vehicle`, !!includes(['AE'], vehicle.genre));
      updateInput(`${ vehicleLabel }.convertible`, febiacMappings.genre[vehicle.genre] === 'convertible');
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(JsonVehicleSelector);
