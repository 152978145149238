import React, {
  useState,
  useEffect,
  useContext,
}                                from 'react';
import isNil                     from 'lodash/isNil';
import classNames                from 'classnames';
import { useHistory }            from 'react-router';
import { useSelector }           from 'react-redux';
import Icon                      from 'components/ui/icon';
import WizardContext             from 'components/wizard/wizard_context';
import SubscriptionRequired      from 'components/subscription/subscription_required';
import ChangeStepButton          from 'components/wizard_stepper/change_step_button';
import WizardStepperContext      from 'components/wizard_stepper/wizard_context';
import { hasActiveSubscription } from 'models/current_account/reducer';

const asWizardStep = (manualLoading = false) => Component => {
  return (initialProps) => {
    const {
            title,
            StepMainAction,
            subscriptionRequired = true,
            ...props
          } = initialProps;
    const requiredFieldsLoading = useSelector(state => state.wizard.loaders.required_fields);
    const activeSubscription = useSelector(state => hasActiveSubscription(state));
    const subscriptionNeededForContractRequest = useSelector(state => state.wizard.contract_request.authorizations.need_subscription);
    const [nextLoading, setNextLoading] = useState(false);
    const [onNextMethod, setOnNext] = useState(null);
    const [onPreviousMethod, setOnPrevious] = useState(null);
    const [disabledNext, setNextDisabled] = useState(false);
    const [disabledPrevious, setPreviousDisabled] = useState(false);
    const [stepMainActionAvailable, toggleStepMainActionAvailable] = useState(!!StepMainAction);
    const [mounted, setMounted] = useState(false);
    const history = useHistory();
    const { activeStep } = useContext(WizardStepperContext);
    useEffect(() => {
      setMounted(true);
      return () => {
        setMounted(false);
      };
    });

    const onNext = (ev, nextStepOverride) => {
      if (ev) {
        ev.preventDefault();
      }
      if (onNextMethod && !manualLoading) {
        setNextLoading(true);
        const nextPromise = onNextMethod();
        if (isNil(nextPromise)) {
          return;
        }
        nextPromise.then(() => {
          if (!mounted) {
            return;
          }
          setNextLoading(false);
        }).catch(() => {
          setNextLoading(false);
        });
      }
      if (onNextMethod && manualLoading) {
        return onNextMethod(nextStepOverride);
      }
      return goToNextStep();
    };

    const onPrevious = (ev) => {
      if (ev) {
        ev.preventDefault();
      }
      if (onPreviousMethod) {
        return onPreviousMethod();
      }
      return goToPreviousStep();
    };

    const goToNextStep = () => {
      const { next: nextPath } = window['wizardNavigation'].getNextAndPreviousPath(activeStep);
      history.push(nextPath);
    };

    const goToPreviousStep = () => {
      const { previous: previousPath } = window['wizardNavigation'].getNextAndPreviousPath(activeStep);
      history.push(previousPath);
    };

    return (
      <React.Fragment>
        <div className={
          classNames('loading-required-fields', {
            'visible': requiredFieldsLoading,
          })
        }>
            <span className="loading-message">
              <Icon icon="loading" className="be-spin" />
              { t('wizard.required_fields_loading') }
            </span>
        </div>
        { !activeSubscription && subscriptionRequired && subscriptionNeededForContractRequest && (
          <div className="step-subscription-warning">
            <SubscriptionRequired title={ t('wizard.subscription.step_not_available') } />
          </div>
        ) }
        <div
          className={
            classNames('', {
              'grayscale': requiredFieldsLoading || (!activeSubscription && subscriptionRequired && subscriptionNeededForContractRequest),
            })
          }
        >
          <WizardContext.Provider
            value={ {
              setNextAction:     (method) => setOnNext(() => method),
              setPreviousAction: (method) => setOnPrevious(() => method),
              toggleStepMainActionAvailable,
              setNextLoading,
              onNext,
              onPrevious,
            } }
          >
            <Component
              toggleButton={ (targetButton, disabled) => {
                if (targetButton === 'next') {
                  setNextDisabled(disabled);
                } else {
                  setPreviousDisabled(disabled);
                }
              } }
              setNextAction={ (method) => {
                return setOnNext(() => method);
              } }
              setPreviousAction={ (method) => {
                return setOnPrevious(() => method);
              } }
              setNextLoading={ setNextLoading }
              nextStep={ goToNextStep }
              previousStep={ goToPreviousStep }
              { ...props }
            />
            <div className="wizard-button-container">
              <ChangeStepButton
                forPrevious
                onClick={ onPrevious }
                disabled={ disabledPrevious }
              />
              { !stepMainActionAvailable && (
                <ChangeStepButton
                  disabled={ disabledNext }
                  onClick={ onNext }
                  loading={ nextLoading }
                />
              ) }
              { StepMainAction && stepMainActionAvailable && (
                <StepMainAction
                  data-purpose="main_step_action"
                  onClick={ onNext }
                  loading={ nextLoading }
                  disabled={ disabledNext }
                />
              ) }
            </div>
          </WizardContext.Provider>
        </div>
      </React.Fragment>
    );
  };
};

export default asWizardStep;
