import React                          from 'react';
import { sendTarificationOffer }      from 'models/tarifications/requests';
import { useDispatch, useSelector }   from 'react-redux';
import Button                         from 'components/ui/buttons/button';
import { updateTarification }         from 'models/wizard/store_actions';
import { setPollingForTarifications } from 'models/wizard/dispatch_actions';
import { isSocketConnected }          from 'models/config/reducer';

const SingleProductCreateOfferButton = ({
                                          tarification,
                                          onCreated,
                                          ...restProps
                                        }) => {
  const dispatch = useDispatch();
  const isSocketUp = useSelector((state) => isSocketConnected(state));
  const {
          authorizations: {
                            offer: canCreateOffer,
                          },
        } = tarification;
  if (!canCreateOffer) {
    return null;
  }
  const createOffer = (ev) => {
    ev.preventDefault();
    sendTarificationOffer(tarification).then((tarificationOffer) => {
      dispatch(updateTarification(tarificationOffer));
      if (onCreated) {
        onCreated(tarificationOffer);
      }
      if (!isSocketUp) {
        dispatch(setPollingForTarifications([tarificationOffer]));
      }
    });
  };
  let label = t('tarifications.create_offer_in_company', { company_name: tarification.product.insurance_company.name });
  if (tarification.product.slug === 'axa-building') {
    label = t('wizard.tarifications.send_to_axa');
  }
  return (
    <Button
      onClick={ createOffer }
      primary
      loading={ tarification.computing }
      disabled={ tarification.error_encountered }
      { ...restProps }
      icon="send"
      data-purpose="create_offer_button"
    >
      { label }
    </Button>
  );
};

export default SingleProductCreateOfferButton;
