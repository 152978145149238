import React, { useEffect, useState } from 'react';
import { DESKTOP_APP }                from 'constants/application_constants';
import { trackEvent }                 from 'services/segment';
import Icon                           from 'components/ui/icon';

const DesktopApplication = () => {
  const [online, toggleOnline] = useState(true);
  const updateOnlineStatus = () => {
    /*isOnline().then((online) => {
      toggleOnline(online);
    });*/
    toggleOnline(true)
  };
  useEffect(() => {
    if (DESKTOP_APP()) {
      trackEvent('DesktopApplicationUsed');
      /*updateOnlineStatus();
      window.addEventListener('online', updateOnlineStatus);
      window.addEventListener('offline', updateOnlineStatus);*/
    }
  }, []);
  if (!online) {
    return (
      <div className="banner offline">
        <Icon icon="warning" className="mr-10" />
        { t('general.no_internet_alert') }
      </div>
    );
  }
  return null;
};

export default DesktopApplication;
