import React from 'react';

const QuoteCreatedMessage = ({ tarification, className }) => {
  const quoteSentMessage = {
    ['aedes-p500']: (insurer_quote_id) => t('aedes.housing.offer_sent', { insurer_quote_id }),
  };
  if (!tarification.offer.insurer_offer_id) {
    return null;
  }
  return (
    <p className="m-0 global-message info uk-text-center">
      { quoteSentMessage[tarification.product.slug] ? quoteSentMessage[tarification.product.slug](tarification.offer.insurer_offer_id) : t_html('wizard.tarifications.sent_to_axa_html', { insurer_quote_id: tarification.offer.insurer_offer_id }) }
    </p>
  );
};

export default QuoteCreatedMessage;
