import React, { useContext } from 'react';
import every                 from 'lodash/every';
import isString              from 'lodash/isString';
import InlineContainer       from 'components/ui/form/inline_container';
import TooltipOrDialog       from 'components/ui/tooltip/tooltip_or_dialog';
import JsonFormContext       from 'components/ui/json_form/json_form_context';

const withInlineContainer = () => Component => {
  return (props) => {
    const {
            inline,
            warning,
            inlineContainerClass,
            tooltip,
            labelAddition,
            labelClass,
            withValues,
            inlineLabel,
            forceDialog,
            forceHover,
            ...restProps
          } = props;

    const { id, label, meta } = restProps;
    const { getPropertyValue } = useContext(JsonFormContext);
    if (restProps.requiredFields && Object.keys(restProps.requiredFields).length > 0) {
      const checkRequiredFields = Object.keys(restProps.requiredFields).map((v) => {
        const reqField = restProps.requiredFields[v];
        if (isString(reqField)) {
          return getPropertyValue(restProps.requiredFields[v]);
        } else {
          return reqField;
        }
      });
      const noRequiredFields = every(checkRequiredFields, (item) => !item);
      if (noRequiredFields) {
        return null;
      }
    }
    if (!inline || (props.type && props.type === 'hidden')) {
      return (
        <Component
          showLabel={ !inline }
          withValues={ withValues }
          { ...restProps }
        />
      );
    }

    return (
      <InlineContainer
        labelFor={ id }
        label={ label }
        warning={ warning }
        inlineLabel={ inlineLabel }
        field={ <Component
          showLabel={ !inline }
          { ...restProps }
          withValues={ withValues }
        /> }
        meta={ meta }
        labelClass={ labelClass }
        inlineContainerClass={ inlineContainerClass }
        withValues={ withValues }
        tooltip={ tooltip &&
        <TooltipOrDialog className="ml-10"
                         content={ tooltip }
                         dialogTitle={ label }
                         forceDialog={ forceDialog }
                         forceHover={ forceHover } /> }
        labelAddition={ labelAddition }
        purpose={ restProps.input.name }
      />
    );

  };
};

export default withInlineContainer;
