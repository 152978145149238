import {
  maxLength3,
  maxLength33,
  maxLength35, maxLength4,
  maxLength7,
  minLength4, onlyInt,
  required,
}                                              from 'models/application/constants';
import { getAxaBuildingUseSchema }             from 'schemas/axa/building/axa_building_use_schema';
import { getAxaBuildingCharacteristicsSchema } from 'schemas/axa/building/axa_building_characteristics_schema';

export const getAxaBuildingSchema = () => {
  return {
    form:   'building_wizard_form',
    schema: {
      building: {
        type:       'section',
        properties: {
          policy_owner_address_is_building_address: {
            component:     'true_false',
            label:         t('wizard.policy_owner.policy_owner_address_is_building_address'),
            onValueChange: {
              prefill: {
                from:                     {
                  store: 'policy_owner',
                },
                changes:                  [
                  {
                    from:      'company.address',
                    to:        'building.risk_address',
                    condition: {
                      property:  'policy_owner.kind',
                      assertion: 'equal',
                      value:     'company',
                    },
                  },
                  {
                    from:      'person.address',
                    to:        'building.risk_address',
                    condition: {
                      property:  'policy_owner.kind',
                      assertion: 'equal',
                      value:     'person',
                    },
                  },
                ],
                to:                       'building.risk_address',
                clearOnConditionUnfilled: true,
                condition:                {
                  assertion: 'isTrue',
                },
              },
            },
            default:       {
              value: false,
            },
          },
          risk_address:                             {
            type:       'section',
            title:      t('wizard.building.address.title'),
            properties: {
              address: {
                label:                t('driver.label.address'),
                component:            'address',
                inlineContainerClass: 'top',
                nameOverride:         'building.risk_address',
                requiredFields:       {
                  street_name:   true,
                  street_number: true,
                  city:          true,
                  street_box:    true,
                  zip_code:      true,
                },
                displayMap:           true,
                noSection:            true,
                validates:            {
                  street_name:   [required, maxLength33],
                  street_number: [required, maxLength4],
                  city:          [required, maxLength35],
                  street_box:    [maxLength3],
                  zip_code:      [required, minLength4, maxLength7, onlyInt],
                },
              },
            },
          },
          ...getAxaBuildingUseSchema(),
          ...getAxaBuildingCharacteristicsSchema(),
        },
      },
    },
  };
};
