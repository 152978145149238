import React                                  from 'react';
import { connect }                            from 'react-redux';
import { withRouter }                         from 'react-router-dom';
import PropTypes                              from 'prop-types';
import Helmet                                 from 'react-helmet';
import Loader                                 from 'components/ui/loader';
import { setI18nLocale, setI18nTranslations } from 'services/i18n';
import { setCookie }                          from 'services/cookies';

class CheckForLanguage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      check: false,
    };
  }

  componentDidMount() {
    const { locale } = this.props;
    setI18nLocale(locale);
    setCookie('user_locale', locale);
    setI18nTranslations();
    this.setState({
      check: true,
    });
  }

  render() {
    const { children, locale } = this.props;
    const { check } = this.state;
    if (!check) {
      return (<Loader middleOfPage />);
    }
    return (
      <React.Fragment>
        <Helmet>
          <html lang={ locale } />
        </Helmet>
        { children }
      </React.Fragment>
    );
  }
}

CheckForLanguage.defaultProps = {
  locale: 'nl',
};

CheckForLanguage.propTypes = {
  children: PropTypes.any.isRequired,
  locale:   PropTypes.string,
};

const mapStateToProps = ({ current_user, config: { translations_json_url } }, { match: { params: { locale: URLLocale } } }) => {
  return {
    localesPath: translations_json_url,
    locale:      URLLocale || current_user.profile.locale,
  };
};

export default withRouter(connect(mapStateToProps)(CheckForLanguage));
