import merge                from 'lodash/merge';
import { getPersonSchema }  from 'schemas/mobility/person_schema';
import { getCompanySchema } from 'schemas/mobility/company_schema';
import omit                 from 'lodash/omit';
import update               from 'lodash/update';
import set                  from 'lodash/set';

export const getPolicyOwnerSchema = (extraProperties, withRequiredFields = true) => {
  const schema = merge({
    form:   'policy_owner',
    schema: {
      policy_owner: {
        properties_order: [
          'search_in_bms',
        ],
        type:             'section',
        properties:       {
          search_in_bms: {
            component: 'bms',
            prefix:    [
              'policy_owner',
            ],
            resume:    false,
          },
          locale:        {
            component:   'radio_list',
            list:        {
              from: 'available_locales',
            },
            default:     {
              from: 'current_user.profile.locale',
            },
            validations: ['required'],
            label:       t('schemas.shared.policy_owner_schema.locale'),
          },
          kind:          {
            component: 'multi_button',
            list:      [{
              label: t('general.policy_owner_type.PhysicalPolicyOwner'),
              value: 'person',
            }, {
              label: t('general.policy_owner_type.CorporationPolicyOwner'),
              value: 'company',
            }],
            label:     t('wizard.policy_owner.policy_owner_type'),
            default:   {
              value: 'person',
            },
          },
          person:        {
            type:       'section',
            properties: getPersonSchema('policy_owner', {
              schema: {
                policy_owner: {
                  properties: {
                    person: {
                      properties: {
                        address: {
                          label: t('activerecord.attributes.policy_owner.policy_owner_address'),
                        },
                      },
                    },
                  },
                },
              },
            }, withRequiredFields).schema.policy_owner.properties.person.properties,
            visible:    {
              property:  'policy_owner.kind',
              assertion: 'equal',
              value:     'person',
            },
          },
          company:       {
            type:       'section',
            properties: getCompanySchema('policy_owner', {
              schema: {
                policy_owner: {
                  properties: {
                    address: {
                      label: t('activerecord.attributes.policy_owner.policy_owner_address'),
                    },
                  },
                },
              },
            }, withRequiredFields).schema.policy_owner.properties,
            visible:    {
              property:  'policy_owner.kind',
              assertion: 'equal',
              value:     'company',
            },
          },
        },
      },
    },
  }, extraProperties);
  return schema;
};
export const filterSchema = (schema, isLead) => {
  let filtered = schema;
  filtered = omit(filtered, 'schema.policy_owner.properties.person.properties.phone_number.visible');
  filtered = omit(filtered, 'schema.policy_owner.properties.person.properties.email.visible');
  filtered = omit(filtered, 'schema.policy_owner.properties.company.properties.phone_number.visible');
  filtered = omit(filtered, 'schema.policy_owner.properties.company.properties.email.visible');
  if (!isLead) {
    filtered = update(filtered, 'schema.policy_owner.properties.person.properties.phone_number.validations', (validations) => validations.filter((val) => val !== 'required'));
    filtered = update(filtered, 'schema.policy_owner.properties.person.properties.email.validations', (validations) => validations.filter((val) => val !== 'required_email'));
    filtered = update(filtered, 'schema.policy_owner.properties.company.properties.phone_number.validations', (validations) => validations.filter((val) => val !== 'required'));
    filtered = update(filtered, 'schema.policy_owner.properties.company.properties.email.validations', (validations) => validations.filter((val) => val !== 'required_email'));
    filtered = set(filtered, 'schema.policy_owner.properties.person.properties.phone_number.label', t('person.label.phone_number_optional'));
    filtered = set(filtered, 'schema.policy_owner.properties.person.properties.email.label', t('person.label.email_optional'));
    filtered = set(filtered, 'schema.policy_owner.properties.company.properties.phone_number.label', t('person.label.phone_number_optional'));
    filtered = set(filtered, 'schema.policy_owner.properties.company.properties.email.label', t('person.label.email_optional'));
  }
  return filtered;
};

export const getPolicyOwnerSchemaFiltered = (isLead) => {
  return filterSchema(getPolicyOwnerSchema(), isLead);
};
