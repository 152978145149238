import React         from 'react';
import { Field }     from 'redux-form';
import TextInput     from 'components/ui/form/text_input';
import CheckboxInput from 'components/ui/form/checkbox_input';

const AtradiusFields = ({ fields }) => {
  return (
    <ul className="select-button-list">
      { fields.map((atradius, id, { get }) => {
        const at = get(id);
        return (
          <li key={ id }>
            <Field
              name={ `${ atradius }.basis` }
              component={ TextInput }
              type="hidden"
            />
            <Field
              name={ `${ atradius }.is_selected` }
              component={ CheckboxInput }
              icon="check"
              label={ `${ at.basis } €` }
            />
          </li>
        );
      }) }
    </ul>
  );
};

export default AtradiusFields;
