import merge                                    from 'lodash/merge';
import { getAxaBuildingPreviousContractSchema } from 'schemas/axa/building/axa_building_previous_contract_schema';
import { getAxaBuildingClaimHistorySchema }     from 'schemas/axa/building/axa_building_claim_history_schema';

export const getAxaBuildingPreviousContractStepSchema = (extraProperties) => {
  return merge({
    form:   'building_evaluation_wizard_form',
    schema: {
      ...getAxaBuildingPreviousContractSchema(),
      ...getAxaBuildingClaimHistorySchema(),
    },
  }, extraProperties);
};
