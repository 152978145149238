import React                                        from 'react';
import compact                                      from 'lodash/compact';
import AxaBuildingTarificationStep                  from 'components/axa/building/axa_building_tarification_step';
import GenericStep                                  from 'components/wizard_stepper/generic_step';
import { getPolicyOwnerSchema }                     from 'schemas/shared/policy_owner_schema';
import { getAxaCoOwnedSyndicateSchema }             from 'schemas/axa/building/axa_co_owned_syndicate_schema';
import { getAxaBuildingSchema }                     from 'schemas/axa/building/axa_building_schema';
import { getAxaBuildingCommentSchema }              from 'schemas/axa/building/axa_building_comment_schema';
import { getCompanyTypes }                          from 'models/company/constants';
import {
  haveSelectedAxaBuilding, haveSelectedAxaBuildingEvaluation, haveSelectedAxaBuildingPreviousContract,
  haveSelectedCoOwnedSyndicate,
  haveSelectedPolicyOwner, isNotLead,
}                                                   from 'models/wizard_navigation/constants';
import { getAxaBuildingEvaluationStepSchema }       from 'schemas/axa/building/axa_building_evaluation_step_schema';
import { getAxaBuildingPreviousContractStepSchema } from 'schemas/axa/building/axa_building_previous_contract_step_schema';
import { useSelector }                              from 'react-redux';
import { contractRequestIsLead }                    from 'components/wizard_stepper/constants';
import { useHistory }                               from 'react-router';
import { Link }                                     from 'react-router-dom';
import { FINISH_FOLDER, trackWizardStep }           from 'models/wizard/constants';
import LoadOnClickButton                            from 'components/ui/buttons/load_on_click_button';
import { finishDraftLead }                          from 'models/leads/request';
import { currentUserIsIntermediary }                from 'models/current_user/reducer';
import SingleProductResume                          from 'components/resume/single_product_resume';

export const getAxaBuildingSteps = (matchPath, contractRequest) => (
  compact([
    {
      order: 1,
      label: t('wizard.navigation.about_contract_request'),
      icon:  'file',
      slug:  'contract_request',
      kind:  'group',
      steps: [
        {
          order:          2,
          path:           `${ matchPath }/policy_owner`,
          title:          t('wizard.policy_owner.title'),
          label:          t('wizard.navigation.policy_owner'),
          purpose:        'policy_owner_step',
          icon:           'company',
          Component:      GenericStep,
          componentProps: {
            ...getPolicyOwnerSchema({
              schema: {
                policy_owner: {
                  properties: {
                    person:  {
                      properties: {
                        email: {
                          validations: ['email'],
                          label:       t('person.label.email_optional'),
                        },
                      },
                    },
                    company: {
                      properties: {
                        company_type: {
                          list: getCompanyTypes(['ACP', 'ASBL', 'SA', 'SCA', 'SCRI', 'SCRL', 'SCS', 'SNC', 'SPRL', 'SPRL_S', 'SRL']),
                        },
                      },
                    },
                  },
                },
              },
            }),
            onNextStep: {
              refreshTarifications: false,
            },
          },
        },
        {
          order:          3,
          path:           `${ matchPath }/co_owned_syndicate`,
          disabledRules:  [
            haveSelectedPolicyOwner,
          ],
          title:          t('wizard.building.co_owned_syndicate.title'),
          label:          t('wizard.navigation.co_owned_syndicate'),
          icon:           'employees',
          purpose:        'co_owned_syndicate_step',
          Component:      GenericStep,
          componentProps: {
            ...getAxaCoOwnedSyndicateSchema(),
            onNextStep: {
              refreshTarifications: false,
            },
          },
        },
        {
          order:          4,
          path:           `${ matchPath }/building`,
          disabledRules:  [
            haveSelectedPolicyOwner,
            haveSelectedCoOwnedSyndicate,
          ],
          title:          t('wizard.building.title'),
          label:          t('wizard.navigation.building'),
          purpose:        'building_step',
          icon:           'apartment',
          Component:      GenericStep,
          componentProps: {
            ...getAxaBuildingSchema(),
            onNextStep: {
              refreshTarifications: false,
            },
          },
        },
        {
          order:          5,
          path:           `${ matchPath }/evaluation`,
          title:          t('wizard.building.evaluation.title'),
          label:          t('wizard.building.evaluation.title'),
          icon:           'evaluation',
          purpose:        'evaluation_step',
          disabledRules:  [
            haveSelectedPolicyOwner,
            haveSelectedCoOwnedSyndicate,
            haveSelectedAxaBuilding,
          ],
          Component:      GenericStep,
          componentProps: {
            ...getAxaBuildingEvaluationStepSchema(),
            onNextStep: {
              refreshTarifications: false,
            },
          },
        },
        {
          order:          6,
          path:           `${ matchPath }/previous_contract`,
          title:          t('wizard.building.previous_contract.title'),
          label:          t('wizard.building.previous_contract.title'),
          icon:           'business-deal-handshake-circle',
          purpose:        'previous_contract_step',
          disabledRules:  [
            haveSelectedPolicyOwner,
            haveSelectedCoOwnedSyndicate,
            haveSelectedAxaBuilding,
            haveSelectedAxaBuildingEvaluation,
          ],
          Component:      GenericStep,
          componentProps: {
            ...getAxaBuildingPreviousContractStepSchema(),
            onNextStep: {
              refreshTarifications: false,
            },
          },
        },
        {
          order:          7,
          path:           `${ matchPath }/comment`,
          disabledRules:  [
            haveSelectedPolicyOwner,
            haveSelectedCoOwnedSyndicate,
            haveSelectedAxaBuilding,
            haveSelectedAxaBuildingEvaluation,
            haveSelectedAxaBuildingPreviousContract,
          ],
          title:          t('wizard.building.comment.title'),
          label:          t('wizard.building.comment.title'),
          icon:           'file',
          purpose:        'comment_step',
          Component:      GenericStep,
          componentProps: {
            ...getAxaBuildingCommentSchema(),
          },
        },
      ],
    },
    contractRequest.tarifications && contractRequest.tarifications.length > 0 && {
      path:           `${ matchPath }/tarification_${ contractRequest.tarifications[0].id }`,
      title:          t('axa.building.navigation.tarification'),
      order:          3,
      disabledRules:  [
        haveSelectedPolicyOwner,
        haveSelectedCoOwnedSyndicate,
        haveSelectedAxaBuilding,
        haveSelectedAxaBuildingEvaluation,
        haveSelectedAxaBuildingPreviousContract,
      ],
      displayRules:   [
        isNotLead,
      ],
      label:          t('axa.building.navigation.tarification'),
      icon:           'product-parameters',
      error:          contractRequest.tarifications[0].last_error_response,
      loading:        contractRequest.tarifications[0].computing,
      purpose:        'axa_building_tarification_step',
      Component:      AxaBuildingTarificationStep,
      componentProps: {
        tarification: contractRequest.tarifications[0],
      },
    },
    {
      order:          4,
      path:           `${ matchPath }/resume`,
      title:          t('wizard.building.resume.title'),
      disabledRules:  [
        haveSelectedPolicyOwner,
        haveSelectedCoOwnedSyndicate,
        haveSelectedAxaBuilding,
        haveSelectedAxaBuildingEvaluation,
        haveSelectedAxaBuildingPreviousContract,
      ],
      label:          t('wizard.navigation.resume'),
      icon:           'folder',
      purpose:        'resume_step',
      error:          contractRequest.tarifications[0].offer && contractRequest.tarifications[0].offer.last_error_response,
      Component:      SingleProductResume,
      StepMainAction: () => {
        const isIntermediary = useSelector((state) => currentUserIsIntermediary(state));
        const isLead = useSelector((state) => contractRequestIsLead(state.wizard));
        const contractRequestId = useSelector((state) => state.wizard.contract_request.id);
        const history = useHistory();
        return (
          <React.Fragment>
            { !isLead && !isIntermediary && (
              <Link
                className="wizard-button primary"
                to="/folders"
                data-purpose="finish_button"
                onClick={ () => {
                  trackWizardStep(FINISH_FOLDER, {
                    contract_request_id: contractRequestId,
                  });
                } }
              >
                { t('general.actions.finish') }
              </Link>
            ) }
            { isLead && isIntermediary && (
              <LoadOnClickButton
                onClick={ () => finishDraftLead(contractRequestId).then(() => {
                  history.push('/leads');
                }) }
                primary
                large
                data-purpose="finish_button"
                className="send-lead-button"
              >
                { t('leads.send_lead') }
              </LoadOnClickButton>
            ) }
          </React.Fragment>
        );
      },
    },
  ])
);
