import compact                     from 'lodash/compact';
import GenericStep                 from 'components/wizard_stepper/generic_step';
import { getPolicyOwnerSchema }    from 'schemas/shared/policy_owner_schema';
import { haveSelectedPolicyOwner } from 'models/wizard_navigation/constants';
import TarificationStep            from 'components/wizard/tarification_step';
import ResumeStep                  from 'components/wizard/resume_step';

export const getMaafExpatSteps = (matchPath, contractRequest) => (
  compact([
    {
      label: t('wizard.navigation.about_contract_request'),
      icon:  'file',
      slug:  'contract_request',
      kind:  'group',
      order: 1,
      steps: [
        {
          path:           `${ matchPath }/policy_owner`,
          title:          t('wizard.policy_owner.title'),
          label:          t('wizard.navigation.policy_owner'),
          icon:           'company',
          Component:      GenericStep,
          order:          2,
          purpose:        'policy_owner_step',
          componentProps: {
            form:   'policy_owner',
            schema: {
              policy_owner: {
                properties_order: [
                  'search_in_bms',
                ],
                type:             'section',
                properties:       {
                  search_in_bms: {
                    component: 'bms',
                    prefix:    [
                      'policy_owner',
                    ],
                    resume:    false,
                  },
                  locale:        {
                    component:   'radio_list',
                    list:        {
                      from: 'available_locales',
                    },
                    default:     {
                      from: 'current_user.profile.locale',
                    },
                    validations: ['required'],
                    label:       t('schemas.shared.policy_owner_schema.locale'),
                  },
                  person:        {
                    type:       'section',
                    properties: {
                      first_name:    {
                        component:   'text',
                        label:       t('person.label.first_name'),
                        validations: ['required'],
                      },
                      last_name:     {
                        component:   'text',
                        label:       t('person.label.last_name'),
                        validations: ['required'],
                      },
                      date_of_birth: {
                        label:       t('person.label.birthdate'),
                        component:   'date',
                        validations: ['required', 'date_format'],
                      },
                    },
                  },
                  has_partner:   {
                    component: 'true_false',
                    label:     t('schemas.maaf.expat.maaf_expat_steps.has_partner'),
                    default:   {
                      value: false,
                    },
                  },
                  partner:       {
                    type:       'section',
                    visible:    [{
                      property:  'policy_owner.has_partner',
                      assertion: 'isTrue',
                    }],
                    properties: {
                      date_of_birth: {
                        label:       t('schemas.maaf.expat.maaf_expat_steps.partner_date_of_birth'),
                        component:   'date',
                        validations: ['required', 'date_format'],
                      },
                    },
                  },
                  has_children:  {
                    component:     'true_false',
                    label:         t('schemas.maaf.expat.maaf_expat_steps.has_children'),
                    default:       {
                      value: false,
                    },
                    onValueChange: {
                      set: [
                        {
                          to:        'policy_owner.children[0]',
                          value:     {},
                          condition: {
                            assertion: 'isTrue',
                          },
                        },
                        {
                          to:        'policy_owner.children',
                          value:     null,
                          condition: {
                            assertion: 'isFalse',
                          },
                        },
                      ],
                    },
                  },
                  children:      {
                    type:          'array',
                    label:         '',
                    arrayType:     'table',
                    array_actions: {
                      max:          2,
                      add_label:    t('schemas.maaf.expat.maaf_expat_steps.add_children'),
                      title:        t('schemas.maaf.expat.maaf_expat_steps.children'),
                      remove_label: t('general.actions.delete'),
                      empty_label:  t('wizard.building.use.office_use.empty'),
                    },
                    visible:       [{
                      property:  'policy_owner.has_children',
                      assertion: 'isTrue',
                    }],
                    properties:    {
                      date_of_birth: {
                        label:       t('person.label.birthdate'),
                        component:   'date',
                        validations: ['required', 'date_format'],
                      },
                    },
                  },
                },
              },
            },
          },
        },
        {
          path:           `${ matchPath }/tarification_${ contractRequest.tarifications[0].id }`,
          order:          2,
          title:          t('wizard.navigation.configuration', { name: contractRequest.tarifications[0].product.display_name }),
          label:          t('wizard.navigation.solo_product', { product_name: contractRequest.tarifications[0].product.display_name }),
          error:          contractRequest.tarifications[0].last_error_response,
          loading:        contractRequest.tarifications[0].computing,
          icon:           'product-parameters',
          disabledRules:  [
            haveSelectedPolicyOwner,
          ],
          purpose:        'generic_tarification_step',
          Component:      TarificationStep,
          componentProps: {
            tarificationId: contractRequest.tarifications[0].id,
          },
        },
        {
          path:          `${ matchPath }/resume`,
          title:         t('wizard.navigation.resume'),
          purpose:       'resume_step',
          order:         3,
          disabledRules: [
            haveSelectedPolicyOwner,
          ],
          label:         t('wizard.navigation.resume'),
          icon:          'folder',
          Component:     ResumeStep,
        },
      ],
    }])
);
