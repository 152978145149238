import React                           from 'react';
import merge                           from 'lodash/merge';
import { getPaymentStaggeringOptions } from 'models/contract_request/constants';

export const getAedesHousingContractSchema = (extraProperties) => {
  return merge({
    form:   'aedes_housing_contract',
    schema: {
      contract:           {
        type:       'section',
        properties: {
          start_date:             {
            label:       t('aedes.housing.contract.contract_start_date'),
            component:   'date',
            validations: ['required', 'date_format'],
          },
          payment_staggering:     {
            label:       t('activerecord.attributes.car_contract_request.payment_staggering'),
            component:   'select',
            list:        getPaymentStaggeringOptions(['monthly', 'annually']),
            validations: ['required'],
            default:     {
              value: 'annually',
            },
          },
          iban_number:            {
            label:       t('aedes.housing.contract.iban_number'),
            component:   'text',
            validations: [
              'required',
            ],
            visible:     {
              property:  'contract.payment_staggering',
              assertion: 'equal',
              value:     'monthly',
            },
          },
          contact_email:          {
            label:       t('aedes.housing.contract.contact_email'),
            component:   'text',
            symbol:      '@',
            validations: ['required_email'],
            default:     {
              from: 'policy_owner.email',
            },
          },
          is_previously_insured:  {
            label:       t('aedes.housing.contract.already_insured'),
            component:   'true_false',
            validations: ['required'],
          },
          actual_company_name:    {
            label:       t('aedes.housing.contract.actual_company_name'),
            component:   'text',
            validations: ['required'],
            visible:     {
              property: 'contract.is_previously_insured',
            },
          },
          actual_contract_number: {
            label:       t('aedes.housing.contract.actual_contract_number'),
            component:   'text',
            validations: ['required'],
            visible:     {
              property: 'contract.is_previously_insured',
            },
          },
        },
      },
      extra_informations: {
        type:       'section',
        properties: {
          documents: {
            component:     'uploader',
            label:         t('wizard.housing.pictures', { count: 0 }),
            labelAddition: () => (
              <div className="global-message info mt-20">
                <p className="m-0">{ t('aedes.housing.pictures_message') }</p>
              </div>
            ),
          },
          comment:   {
            label:                t('aedes.housing.comment'),
            className:            'uk-height-medium',
            inlineContainerClass: 'top',
            containerClass:       'uk-width-1-1',
            component:            'textarea',
          },
        },
      },
    },
  }, extraProperties);
};
