import React                              from 'react';
import { useSelector }                    from 'react-redux';
import StepList                           from 'components/wizard_stepper/step_list';
import { getProductsFromContractRequest } from 'models/wizard/constants';
import { getMaafExpatSteps }              from 'schemas/maaf/expat/maaf_expat_steps';
import { getGenericSteps }                from 'schemas/generic/generic_steps';

const EnterpriseSteps = ({ matchPath }) => {
  const contractRequest = useSelector((state) => state.wizard.contract_request);
  const selectedProductSlug = getProductsFromContractRequest(contractRequest)[0].slug;
  let steps = [];
  if (selectedProductSlug === 'maaf-expat') {
    steps = getMaafExpatSteps(matchPath, contractRequest);
  }
  if (selectedProductSlug === 'openbroker-example') {
    steps = getGenericSteps(matchPath, contractRequest);
  }
  if (selectedProductSlug === 'legal-village-pj') {
    steps = getGenericSteps(matchPath, contractRequest);
  }
  return <StepList steps={ steps } />;
};

export default EnterpriseSteps;
