import React                     from 'react';
import {
  Switch,
  Route,
}                                from 'react-router-dom';
import Wizard                    from 'components/wizard_stepper/wizard';
import wizardWithContractRequest from 'components/wizard/wizard_with_contract_request';
import LeadTable                 from 'components/lead/table';


const WizardWithContractRequest = wizardWithContractRequest()(Wizard);

const LeadContainer = () => {
  return (
    <React.Fragment>
      <Switch>
        <Route
          path="/leads/:lead_id"
          component={ WizardWithContractRequest }
        />
        <Route
          path="/leads"
          component={ LeadTable }
        />
      </Switch>
    </React.Fragment>
  );
};

export default LeadContainer;
