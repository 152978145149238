import { getAxaEvaluationSchema }              from 'schemas/axa/building/axa_evaluation_schema';
import { getAxaBuildingGridSchema }            from 'schemas/axa/building/axa_building_grid_schema';
import { getBuildingAssessmentSchema }         from 'schemas/axa/building/axa_building_assessment_schema';
import { getBuildingSpecificationsSchema }     from 'schemas/axa/building/axa_building_specifications_schema';
import { getBuildingConditionOfAverageSchema } from 'schemas/axa/building/axa_building_condition_of_average_schema';

export const getAxaBuildingEvaluationStepSchema = () => {
  return {
    form:   'building_evaluation_wizard_form',
    schema: {
      building: {
        type:       'section',
        properties: {
          ...getAxaEvaluationSchema(),
          ...getAxaBuildingGridSchema(),
          ...getBuildingAssessmentSchema(),
          ...getBuildingSpecificationsSchema(),
          ...getBuildingConditionOfAverageSchema(),
        },
      },
    },
  };
};
