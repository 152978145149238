import React             from 'react';
import {
  Switch,
  Redirect,
  Route,
}                        from 'react-router-dom';
import {
  IntermediaryMainMenu,
  ProviderMainMenu,
}                        from 'models/menus/provider_menu';
import { connect }       from 'react-redux';
import Alert             from 'services/alert';
import MainHeader        from 'components/main_header';
import GetAccount        from 'helpers/get_account';
import AccountShow       from 'components/account/show';
import LeadContainer     from 'components/lead/container';
import FolderContainer   from 'components/folder/container';
import Profile           from 'components/user/profile';
import GlobalMessage     from 'components/global_message';
import CreateFolderRoute from 'components/folder/create_folder_route';

class ProviderMainContainer extends React.Component {
  componentDidMount() {
    Alert.preventNotification();
  }

  componentWillUnmount() {
    Alert.enableNotification();
  }

  render() {
    const { currentUserRole } = this.props;
    return (
      <React.Fragment>
        <GetAccount />
        <MainHeader mainMenu={ currentUserRole !== 'intermediary' ? ProviderMainMenu : IntermediaryMainMenu } />
        <GlobalMessage />
        <div className="p-20">
          <Switch>
            <Route
              path="/new-folder/:product_slug"
              exact={ true }
              component={ CreateFolderRoute }
            />
            { currentUserRole !== 'intermediary' && (
              [
                <Route
                  path="/folders"
                  component={ FolderContainer }
                  key="folders"
                />,
                <Route
                  path="/account"
                  component={ AccountShow }
                  key="account"
                />,
              ]) }
            ) }
            <Route
              path="/leads"
              key="leads"
              component={ LeadContainer }
            />
            <Route
              exact
              path="/profile"
              component={ Profile }
            />
            <Redirect from="/" to={ currentUserRole === 'intermediary' ? '/leads' : '/folders' } />
          </Switch>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentUserRole: state.current_user.profile.role,
  };
};

export default connect(mapStateToProps)(ProviderMainContainer);
