import update from 'immutability-helper';

const defaultState = {
  list:     [],
  products: [],
  loading:  false,
};

const intermediariesReducer = (state = defaultState, action) => {
  switch (action.type) {
    case 'INTERMEDIARIES/SET_LOADER':
      return update(state, {
        loading: {
          $set: action.loading,
        },
      });
    case 'INTERMEDIARIES/SET_LIST':
      return update(state, {
        list: {
          $set: action.list,
        },
      });
    case 'INTERMEDIARIES/ADD_IN_LIST':
      return update(state, {
        list: {
          $push: [action.intermediary],
        },
      });
    case 'INTERMEDIARIES/UPDATE_IN_LIST':
      return update(state, {
        list: {
          $set: state.list.map((intermediary) => {
            if (action.intermediary.id === intermediary.id) {
              return action.intermediary;
            }
            return intermediary;
          }),
        },
      });
    case 'INTERMEDIARIES/SET_PRODUCTS':
      return update(state, {
        products: { $set: action.products },
      });
    case 'INTERMEDIARIES/REMOVE_FROM_LIST':
      return update(state, {
        list: {
          $set: state.list.filter((intermediary) => intermediary.id !== action.intermediaryId),
        },
      });
    default:
      return state;
  }
};

export default intermediariesReducer;
