import React                     from 'react';
import {
  Switch,
  Route,
}                                from 'react-router-dom';
import Wizard                    from 'components/wizard_stepper/wizard';
import wizardWithContractRequest from 'components/wizard/wizard_with_contract_request';
import FolderTable               from 'components/folder/table';

const WizardWithContractRequest = wizardWithContractRequest()(Wizard);

const FolderContainer = () => {
  return (
    <React.Fragment>
      <Switch>
        <Route
          path="/folders/:folder_id"
          render={ () => <WizardWithContractRequest /> }
        />
        <Route
          path="/folders"
          component={ FolderTable }
        />
      </Switch>
    </React.Fragment>
  );
};

export default FolderContainer;
