import React, { useState, useContext, useEffect } from 'react';
import every                                      from 'lodash/every';
import { checkCondition }                         from 'components/ui/json_form/json_form_helpers';
import values                                     from 'lodash/values';
import isNil                                      from 'lodash/isNil';
import JsonFormContext                            from 'components/ui/json_form/json_form_context';

const FieldWithAsyncList = (props) => {
  const [loading, toggleLoading] = useState(false);
  const [list, setList] = useState([]);
  const [listFrom, setListFrom] = useState(null);
  const { getPropertyValue } = useContext(JsonFormContext);
  const { field, Component } = props;
  const params = {};
  field.asyncList.params.forEach((param) => {
    const value = getPropertyValue(param.property);
    if (param.validations) {
      const everyValidationChecked = every(param.validations, (val) => {
        try {
          checkCondition({
            property: value,
            ...val,
          });
          return true;
        } catch (e) {
          return false;
        }
      });
      if (everyValidationChecked) {
        params[param.param_name] = value;
      } else {
        params[param.param_name] = null;
      }
    } else {
      params[param.param_name] = null;
    }
  });

  const fetchList = (params) => {
    setListFrom(params);
    toggleLoading(true);
    field.asyncList.get(params).then((results) => {
      setList(results);
      toggleLoading(false);
    }).catch((error) => {
      toggleLoading(false);
    });
  };

  useEffect(() => {
    const p = values(params);
    const sameLengthOfParams = p.length === field.asyncList.params.length;
    const everyParamsDefined = every(p, v => !isNil(v));
    const paramsChanged = JSON.stringify(listFrom) !== JSON.stringify(params);
    if (paramsChanged && list.length > 0) {
      setList([]);
      setListFrom(null);
      return;
    }
    if (sameLengthOfParams && everyParamsDefined && !loading) {
      if (list.length === 0 && paramsChanged) {
        fetchList(params);
      }
    }
  }, [params]);
  const { field: fieldProps, ...restProps } = props;
  return (
    <Component
      field={
        {
          ...fieldProps,
          loading,
          list,
        }
      }
      { ...restProps }
    />
  );
};

export default FieldWithAsyncList;
