import React           from 'react';
import fromPairs       from 'lodash/fromPairs';
import map             from 'lodash/map';
import without         from 'lodash/without';
import asJsonFormField from 'components/ui/json_form/as_json_form_field';
import JsonFormFields  from 'components/ui/json_form/json_form_fields';

const sortByKeys = (object, keyArray) => {
  const objectKeys = Object.keys(object);
  const filteredObjectKeys = without(objectKeys, keyArray);
  return fromPairs(
    map([
      ...keyArray,
      ...filteredObjectKeys,
    ], key => [key, object[key]]),
  );
};

const JsonFormGroup = ({ namePrefix, field, form, change, fieldKey, inArray }) => {
  let properties = field.properties;
  if (field.properties_order) {
    properties = sortByKeys(field.properties, field.properties_order);
  }
  return (
    <div
      key={ namePrefix ? `${ namePrefix }.${ fieldKey }` : fieldKey }
    >
      { field.title && (<h2>{ field.title }</h2>) }
      <JsonFormFields
        schema={ properties }
        form={ form }
        change={ change }
        namePrefix={ namePrefix }
        inArray={ inArray }
      />
    </div>
  );
};

export default asJsonFormField()(JsonFormGroup);
